import React from 'react';
import { Box, Button, Flex, Text, useToast, Tr, Td } from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { listProductManagement, colProductManagement } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import LangContext from '../context/languageContext';
import ApiManager from '../config/apiManager';

const ProductManagement = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };
  // console.log("dataaa>>>",dataa)
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllProducts', body)
      .then(res => {
        if (res.message === 8250) {
          res?.record?.docs?.map((value, index) => {
            console.log('check>>>', value);
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    // console.log('body>>>>', body);
    apimanager
      .post('deleteProduct', body)
      .then(response => {
        if (response.message === 8248) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Product List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Product Management',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateProduct')}
            >
              Create
            </Button>
            <Button
              {...style.bulkbutton}
              onClick={() => history.push({
                pathname:'/AddBulk',
                status:'productbulk',
              })}
            >
              Add Bulk
            </Button>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colProductManagement}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              let startdate = new Date(item?.startDate * 1000);
              let enddate = new Date(item?.endDate * 1000);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{startdate.toLocaleDateString()}</Td>
                  <Td>{enddate.toLocaleDateString()}</Td>
                  <Td>{item?.xoxProductId}</Td>
                  <Td>{item?.name}</Td>
                  <Td>{item?.valueRM}</Td>
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Button
                      {...style.editbtn}
                      onClick={() =>
                        history.push({
                          pathname: '/CreateProduct',
                          status: 'create',
                          state: { val: item },
                        })
                      }
                    >
                      {currentLangData?.app?.edit}
                    </Button>
                    <Button {...style.Delbtn} onClick={() => deleteItem(item)}>
                      {currentLangData?.app?.delete}
                    </Button>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default ProductManagement;
