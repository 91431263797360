import React, { useEffect, useRef } from 'react';
import {
  AuthProvider,
  useAuthState,
  useAuthDispatch,
} from './context/authContext';
import './App.css';

import { ChakraProvider, Center, Text, theme } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { LangProvider } from './context/languageContext';
import { createStandaloneToast } from '@chakra-ui/toast';

import { App as MainApp, SecondApp } from './navigation/mainApp';
import AuthApp from './navigation/authApp';
import store from './redux/store/store';

// For disabling all consoles in project //

console.log = () => {};
console.error = () => {};
console.debug = () => {};

const AppContainer = () => {
  const isInitialMount = useRef(false);
  const authState = useAuthState();
  const dispatch = useAuthDispatch();

  const reloadAppState = () => {
    let savedUser = localStorage.getItem('user');
    let session = localStorage.getItem('mdgSession');
    if (savedUser) {
      savedUser = JSON.parse(savedUser);
      dispatch('AUTHENTICATED', savedUser);
      dispatch('STOP_LOADING');
      isInitialMount.current = true;
    }
  };

  useEffect(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      reloadAppState();
    }
  }, [authState, dispatch]);

  if (authState?.authenticated === true) {
    return <MainApp />;
  } else if (authState?.user === null) {
    console.log('authState', authState?.user);
    return <AuthApp />;
  }
};

const App = () => {
  const { ToastContainer, toast } = createStandaloneToast();
  return (
    <AuthProvider>
      <LangProvider>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            {/* <ColorModeSwitcher /> */}
            <AppContainer>
              {/* <ToastContainer /> */}
            </AppContainer>
          </ChakraProvider>
        </Provider>
      </LangProvider>
    </AuthProvider>
  );
};

export default App;
