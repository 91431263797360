import React, { useState, useEffect } from 'react';
import { Flex, SimpleGrid, Divider, Spacer, useToast } from '@chakra-ui/react';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';

import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

const CreateAdmin = () => {
  let initialValue = {
    email: '',
    role: '',
  };

  const [value, setValue] = React.useState(initialValue);
  const [roles, setRoles] = React.useState([]);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [changeApi, setChangeApi] = useState(false);
  const [loading, setLoading] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const location = useLocation();
  const toast = useToast();

  let mandatoryFields = {
    email: true,
    role: true,
  };

  useEffect(() => {
    getAllCmsRoless();
    if (location?.state?.data != null || location?.state?.data != undefined) {
      setChangeApi(changeApi);
      console.log('PREVIOUS DATA', location?.state?.data?.email);

      getCMSUser(location?.state?.data?.email);
    }
  }, []);

  const getCMSUser = email => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let body = {
      email: 'superuser@xox.com',
    };
    console.log('BODYY=>', body);
    apimanager
      .get('getCMSUser', +'superuser@xox.com')
      .then(res => {
        console.log('getCMSUser=>RES', res);
        // if (res.message === 4009) {
        //   console.log('Get getCMSUser', res);
        //   // pageCount = res.results.totalDocs;
        //   res.records.docs.map((value, index) => {
        //     console.log('valuesssss', value?.roleName);
        //     // tempRolesId.push([value?.roleName, value?.roleId]);
        //     // tempArray.push(value?.roleName);
        //   });
        //   // setRoles(tempArray);
        //   // setNameRoles(tempRolesId);
        //   // setpaginate(res.results);
        //   // setData(tempArray);
        //   setLoading(false);
        // }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAllCmsRoless = () => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let body = {
      // page: page,
      // nextPage: 3,
      // numRecords: value,
    };
    apimanager
      .get('getAllCMSRoles', body)
      .then(res => {
        console.log('getAllCMSRoles=>RES', res);
        if (res.message === 4009) {
          console.log('Get getAllCMSRoles', res);
          // pageCount = res.results.totalDocs;
          res.records.docs.map((value, index) => {
            console.log('valuesssss', value?.roleName);
            tempRolesId.push([value?.roleName, value?.roleId]);
            tempArray.push(value?.roleName);
          });
          setRoles(tempArray);
          setNameRoles(tempRolesId);
          // setpaginate(res.results);
          // setData(tempArray);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    setValue({
      ...value,
      [key]: ev,
    });
    console.log('value=>', value);
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      console.log('nameRoles', nameRoles);
      let idSingle = '';
      let id = nameRoles?.map((dat, index) => {
        console.log('dat==<>', dat);
        console.log('dat==<>', dat[0]);
        console.log('dat==<>', dat[1]);
        if (dat[0] === value?.role) {
          console.log('dat[1]', dat[1]);
          idSingle = dat[1];
        }
      });
      console.log('IDDD==>', id);
      console.log('idSingle==>', idSingle);

      const body = {
        email: value?.email,
        role: idSingle,
      };
      console.log('body>>>>', body);

      if (!changeApi) {
        apimanager
          .post('addCMSUser', body)
          .then(response => {
            console.log(response, 'response');
            if (response.message === 4002) {
              setSuccess('Successfully Recorded!');
              setLoading(false);
              toast({
                title: success,
                duration: 3000,
                isClosable: true,
                status: 'success',
                description: `An email has been sent with temporary Password!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 4000);
            }
            if (response.message === 4001) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Role is not defined',
                description: `Please Select Role!`,
              });
            }
            if (response.message === 7063) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Email Duplication',
                description: `Use Different Email!`,
              });
            } else {
              setLoading(false);
              setError('Please enter mandatory fields');
            }
          })
          .catch(error => {
            setLoading(false);
            console.log(error);
          });
      } else {
        const body = {
          email: value?.email,
          role: idSingle,
        };
        apimanager
          .post('addCMSUser', body)
          .then(response => {
            console.log(response, 'response');
            if (response.message === 4002) {
              setSuccess('Successfully Recorded!');
              setLoading(false);
              toast({
                title: success,
                duration: 3000,
                isClosable: true,
                status: 'success',
                description: `An email has been sent with temporary Password!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 4000);
            }
            if (response.message === 4001) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Role is not defined',
                description: `Please Select Role!`,
              });
            }
            if (response.message === 7063) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Email Duplication',
                description: `Use Different Email!`,
              });
            } else {
              setLoading(false);
              setError('Please enter mandatory fields');
            }
          })
          .catch(error => {
            setLoading(false);
            console.log(error);
          });
      }
    }
  };
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Create User',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'User',
          useSlash: true,
          path2: 'Admin',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Email',
            mandatory: true,
            onChange: e => handleonChange(e, 'email'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {dropdownCustom({
            title: 'Role',
            mandatory: true,
            temparray: roles,
            onChange: e => handleonChange(e, 'role'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateAdmin;
