import React, { useState } from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import ControlledEditorReselect from '../components/textEditor/textEditor';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';
import ControlledEditor from '../components/textEditor/classEditor';
import { stateToHTML } from 'draft-js-export-html';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import { convertFromRaw } from 'draft-js';

const CreatePrivacyPolicies = () => {
  let initialValue = {
    category: '',
    name: '',
    EN: '',
    CN: '',
    MY: '',
  };
  let mandatoryFields = {
    category: true,
    name: true,
    EN: true,
    CN: true,
    MY: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [catagory, setCatagory] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = React.useState([]);

  let history = useHistory();
  let location = useLocation();
  let toast = useToast();

  React.useEffect(() => {
    getAllCategory();
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val)
      setValue(_p => ({ ..._p, name: location?.state?.val?.name }));
    setValue(_p => ({
      ..._p,
      category: location?.state?.val?.privacyCategory,
    }));
    setValue(_p => ({ ..._p, EN: location?.state?.val?.contentEN }));
    setValue(_p => ({ ..._p, CN: location?.state?.val?.contentCN }));
    setValue(_p => ({ ..._p, MY: location?.state?.val?.contentMY }));
    console.log('pathdata', location?.state);
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
    console.log('value=>', value);
  };

  // const handleSingleonChange = (evt, key) => {
  //   let ev = '';
  //   console.log('EVT->', evt);
  //   let tempData = [];

  //   evt.blocks.map((value, index) => {
  //     console.log('value of editor in onChange', value);
  //     tempData.push(value.text);
  //     ev = value.text;
  //   });
  //   // const ev = evt;
  //   console.log('tempData=>', tempData);
  //   // ev = tempData;
  //   setValue({
  //     ...value,
  //     [key]: ev,
  //   });
  // };
  // console.log('value=>', value);

  const handleSingleonChange = (evt, key) => {
    let ev = evt;
    console.log('EVT->', evt);

    let val = IFame(evt);

    convertCommentFromJSONToHTML(val?.__html, key);
  };
  const IFame = dat => {
    let data = dat;
    console.log('location data=>', data);
    return {
      __html: data,
    };
  };

  const convertCommentFromJSONToHTML = (text, key) => {
    console.log('convert Text=>', text);
    let toHTML = stateToHTML(convertFromRaw(text));
    console.log('toHTML', toHTML);
    // setHtml(toHTML);
    setValue({
      ...value,
      [key]: toHTML,
    });
  };

  const onClickSubmit = () => {
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      let body = {
        privacyCategory: value.category,
        name: value.name,
        contentEN: value.EN,
        contentCN: value.CN,
        contentMY: value.MY,
      };
      // console.log('Value==>', value, 'body=>', body);
      apiManager
        .post('addPrivacyPolicy', body)
        .then(res => {
          console.log('res=>', res);
          if (res.message === 8236) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          } else {
            setError('Please enter mandatory fields');
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };

  const onClickUpdate = () => {
    console.log('Api Run');
    const body = {
      id: location.state.val._id,
      name: value.name,
      contentEN: value.EN,
      contentCN: value.CN,
      contentMY: value.MY,
    };
    console.log('body>>>>', body);
    apiManager
      .post('updatePrivacyPolicy', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8239) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getAllCategory = () => {
    let tempArray = [];
    let ObjArray = [];
    let tempNameArray = [];

    apiManager
      .post('getCategory', { modelName: 'privacy_policy_categories' })
      .then(res => {
        console.log('RES privacy_policy_categories=>', res);
        if (res.message === 8188) {
          res?.result?.docs?.map((value, index) => {
            console.log('check>>>', value);
            Object.values(value?.list[0]).map((listVal, listIndex) => {
              ObjArray.push(listVal);
              console.log('listVal=>', listVal);
              console.log('listVal=> name', listVal?.name);
              tempNameArray.push(listVal?.name);
              let { name, id } = listVal;
              tempArray.push([name, id]);
              tempArray.push([name]);
            });
            console.log('tempNameArray=>', tempNameArray);
            setCatagory(tempNameArray);
            // setCatagory(tempArray);
          });
        }

        // console.log('getAllSoftpinsCategories=>RES', res);
        // if (res.message === 8188) {
        //   console.log('Get all categiry responce', res.results);
        //   res.result.docs.map((value, index) => {
        //     console.log('valuesssss', value?.modelName);
        //     tempArray.push(value?.modelName);
        //   });
        //   setCatagory(tempArray);
        // }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit privacy Policies',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Privacy Policies Management',
              useSlash: true,
              path2: 'Edit Privacy policies',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Category',
                mandatory: true,
                temparray: catagory,
                // isDisabled: true,
                placeholder: value['category'],
                onChange: e => handleonChange(e, 'Category'),
              })}
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
              spacingY={'20px'}
            >
              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
              >
                <Text marginX={1} marginY={2} fontSize={'sm'}>
                  Content EN *
                </Text>
                {/* <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'EN')}
                /> */}
                <ControlledEditorReselect
                  value={value['EN'] ? value['EN'] : ''}
                  tempData={e => handleSingleonChange(e, 'EN')}
                />
              </Flex>

              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
              >
                <Text marginX={1} marginY={2} fontSize={'sm'}>
                  Content CN *
                </Text>
                {/* <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'CN')}
                /> */}
                <ControlledEditorReselect
                  value={value['CN'] ? value['CN'] : ''}
                  tempData={e => handleSingleonChange(e, 'CN')}
                />
              </Flex>
              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
              >
                <Text marginX={1} marginY={2} fontSize={'sm'}>
                  Content MY *
                </Text>
                {/* <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'MY')}
                /> */}
                <ControlledEditorReselect
                  value={value['MY'] ? value['MY'] : ''}
                  tempData={e => handleSingleonChange(e, 'MY')}
                />
              </Flex>
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Privacy Policies',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Privacy Policies Management',
              useSlash: true,
              path2: 'Create Privacy policies',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Category',
                mandatory: true,
                placeholder: 'Select Option',
                temparray: catagory,
                onChange: e => handleonChange(e, 'category'),
              })}
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
              spacingY={'20px'}
            >
              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
              >
                <Text marginX={1} marginY={2} fontSize={'sm'}>
                  Content EN *
                </Text>
                <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'EN')}
                />
              </Flex>

              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
              >
                <Text marginX={1} marginY={2} fontSize={'sm'}>
                  Content CN *
                </Text>
                <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'CN')}
                />
              </Flex>
              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
              >
                <Text marginX={1} marginY={2} fontSize={'sm'}>
                  Content MY *
                </Text>
                <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'MY')}
                />
              </Flex>
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreatePrivacyPolicies;
