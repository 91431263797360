import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  Input,
  Tr,
  Th,
  Td,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import style from '../../components/common/style';
import { ChecktableTwo } from '../../components/common/ChecktableTwo';
import { listCities, colSMSMasterLibraryCategory } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import ApiManager from '../../config/apiManager';
import { useHistory } from 'react-router-dom';
import { deleteItem } from './BannerTypes';
import LangContext from '../../context/languageContext';
import { options } from '../../context/data';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

export const SMSMasterLibraryCategory = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const [obj, setobj] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [success, setSuccess] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  let ObjArray = [];
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecord: limit,
      page: page,
      modelName: 'sms_library',
    };
    apimanager
      .post('getCategory', body)
      .then(res => {
        if (res.message === 8188) {
          res?.result?.docs?.map((value, index) => {
            // console.log('check>>>', value);
            tempArray.push(value);
            {
              Object.values(value.list[0]).map((listVal, listIndex)=> {
                ObjArray.push(listVal)
              });
              setobj(ObjArray)
            }
          });
          setDataa(tempArray);
          setpaginate(res?.result);
    setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
    setLoader(true);
      });
  };
  const deleteItem = item => {
    console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      key: item.id,
      modelName: 'sms_library',
    };
    // console.log('body>>>>', body);
    apimanager
      .post('deleteCategory', body)
      .then(response => {
        if (response.message === 8194) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader} boxShadow={'lg'} borderBottomWidth={'1px'}>
        {MiniHeader({
          title: 'SMS Master Library Category',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: 'true',
          path2: 'SMSMasterLibraryCategory',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateSmsMasterLibraryCategory')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            {/* <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
              <Input {...style.searchinput}></Input>
            </Flex> */}
          </Flex>
          <ChecktableTwo
            datalist={obj}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colSMSMasterLibraryCategory}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              // console.log('renderitem', item);
              return (
                <Tr key={index} width={'100%'}>
                    <Td>{item?.name}</Td>
                    <Td
                      position={'relative'}
                      display={'inline-flex'}
                      verticalAlign={'middle'}
                    >
                       <Tooltip label={currentLangData?.app?.edit}>
                      <Button
                        {...style.editbtn}
                        leftIcon={<EditIcon />}
                        onClick={() =>
                          history.push({
                            pathname: '/CreateSmsMasterLibraryCategory',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      > 
                      </Button>
                      </Tooltip>
                      
                      <Tooltip label={currentLangData?.app?.delete} bg={'red'}>
                      <Button
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      >
                      </Button>
                      </Tooltip>
                    </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};