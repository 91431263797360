import React, { useState, useEffect, useContext } from 'react';
import {
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Table,
  Tbody,
  Select,
  Button,
  Spacer,
  Thead,
  useToast,
  Tooltip
} from '@chakra-ui/react';
import { ChecktableOne } from '../../components/common/ChecktableOne';

import { options, colStatusForBanners } from '../../context/data';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import style from '../../components/common/style';
import styles from '../../components/common/style';
import ApiManager from '../../config/apiManager';
import LangContext from '../../context/languageContext';
import Loading from '../../components/common/Loading';
import { MiniHeader } from '../../components/common/customComponent';
import { customInputLabel } from '../../components/createAppVersionComponent/createAppVersionComponent';
import { deleteFile } from 'react-s3';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const PrivacyPolicyCategories = () => {
  let toast = useToast();
  let history = useHistory();
  const [obj, setobj] = React.useState([]);
  const [dataa, setDataa] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('10');
  const [data, setData] = React.useState([]);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = React.useState('');
  const [paginate, setpaginate] = React.useState();
  const [pageChange, setPageChange] = useState('');
  const { currentLangData } = useContext(LangContext);
  const [valueSearch, setValueSearch] = React.useState();
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);

  let tempArray = [];
  let ObjArray = [];

  let totalDatalength = paginate?.length;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  let pageCount = paginate;
  const pageCeilingCount = Math.ceil().paginate;
  console.log(pageCeilingCount, 'PAGE COUNT', pageCount);

  // console.log("dataaaaaa>>>>>",dataa)

  React.useEffect(() => {
    getALLItems();
  }, []);

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('next and previous', loading);
    setCurrentPage(selectedPage);
    // getAllCategory(selectedPage + 1,value);
    console.log('clicked', selectedPage + 1);
  };

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const searchItems = (value, limit, page) => {
    setLoading(true);
    console.log('searchItems', value, limit, page);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    // apiManager
    //   .post('searchMaintenance', body)
    //   .then(res => {
    //     console.log('res=>', res.message);
    //     if (res.message === 8263) {
    //       res?.record?.docs.map((value, index) => {
    //         tempArray.push(value);
    //       });
    //       setData(tempArray);
    //       setpaginate(res?.record);
    //       setLoading(false);
    //     }
    //   })
    //   .catch(error => {
    //     console.log('error', error);
    //     setLoading(false);
    //   });
  };

  const getALLItems = () => {
    setLoading(true);
    let totalPage = [];
    let apimanager = ApiManager.getInstance();
    setLoader(false);
    const body = {
      modelName: 'privacy_policy_categories',
    };

    apimanager
      .post('getCategory', body)
      .then(res => {
        // console.log("res",res)
        if (res.message === 8188) {
          console.log(' res.result.totalDocs', res.result.totalDocs);
          pageCount = res.result.totalDocs;
          res?.result?.docs?.map((value, index) => {
            console.log('check>>>', value);
            // tempArray.push(value);

            Object.values(value?.list[0]).map((listVal, listIndex) => {
              ObjArray.push(listVal);
              console.log('listVal=>', listVal);
              let { name, id } = listVal;
              console.log('name', name);
              tempArray.push([name, id]);
              totalPage.push(id);
            });
            // setDataa(tempArray);
          });
          console.log('tempArray=>', tempArray);
          console.log('res.result.totalDocs=>', res.result.totalDocs);
          setData(tempArray);
          setpaginate(res.result.totalDocs);
          setLoader(true);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
        setLoader(true);
      });
  };

  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    setValueSearch(ev);
    searchItems(ev, value, 1);
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreatePrivacyPolicyCategory',
      state: { data: editData, useEditApi: true },
    });
  };

  const deleteRecord = data => {
    setLoading(true);

    let body = {
      key: data,
      modelName: 'privacy_policy_categories',
    };
    console.log('deleteRecord body', body);

    apiManager
      .post('deleteCategory', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 8194) {
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          // getAllCategory(1);
          setLoading(false)
          getALLItems();
        } else {
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          // getAllCategory(1);
          setLoading(false)
          getALLItems();
        }
      })
      .catch(error => {
        setLoading(false)
        console.log('error', error);
      });
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Privacy Policy Categories List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Privacy Policy Categories List',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main} padding={5}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreatePrivacyPolicyCategory')}
            >
              Create
            </Button>
          </Flex>

          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleonChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            {/* <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingX={'5px'}>{currentLangData?.app?.search}: </Text>
              {customInputLabel({
                size: 'sm',
                onChange: e => handleOnSearch(e),
              })}
            </Flex> */}
          </Flex>
          <Flex {...style.tableFlex}>
          <Table {...styles.table} padding={'1%'}>
            <Thead {...styles.thead}>
              <Tr>
                <Th {...styles.th}>Name</Th>
                <Th {...styles.th}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody {...styles.tbody}>
              {!loading ? (
                data &&
                data?.map((value, index) => {
                  console.log(index, 'index value', value);

                  if (index >= 0) {
                    return (
                      <>
                        <Tr key={index} {...styles.tr}>
                          <Td
                            flex={1}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            {value[0]}
                          </Td>

                          <Td
                            width={'20%'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                          >
                            <Tooltip label={currentLangData?.app?.edit}>
                            <Button
                              {...style.editbtn}
                              leftIcon={<EditIcon />}
                              onClick={() => navigateToEditPage(value)}
                            >
                            </Button>
                            </Tooltip>
                            <Tooltip label={currentLangData?.app?.delete} bg={'red'}>
                            <Button
                              {...style.Delbtn}
                              leftIcon={<DeleteIcon />}
                              onClick={() => deleteRecord(value[1])}
                            > 
                            </Button>
                            </Tooltip>
                          </Td>
                        </Tr>
                      </>
                    );
                  } else {
                    return (
                      <Tr height={'40vh'}>
                        <Td></Td>
                        <Td>
                          <Text>NO DATA AVAILABLE</Text>
                        </Td>
                        <Td></Td>
                      </Tr>
                    );
                  }
                })
              ) : (
                <Tr>
                  {/* <Td></Td> */}
                  <Td></Td>
                  <Td paddingY={'15vh'}>
                    <Flex
                      style={{
                        w: '100%',
                        h: '100%',
                        d: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Loading />
                    </Flex>
                  </Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          </Flex>
          <Flex {...style.tablefooter}>
          <Flex>
          <Text>
            Showing {data.length} to {PER_PAGE} of {totalDatalength} entries
          </Text>
          </Flex>
          <Flex {...style.paginatecontainer}>
            <ReactPaginate
              nextLabel={'Next'}
              pageCount={pageCount}
              previousLabel={'Previous'}
              activeClassName={'active'}
              nextClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              containerClassName={'pagination'}
              onPageChange={handlePageClick}
              previousLinkClassName={'page-link'}
            />
          </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default PrivacyPolicyCategories;
