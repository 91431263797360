import React from 'react';
import {
  Text,
  Button,
  Flex,
  useToast,
  Td,
  Tr,
  Circle,
  Tooltip,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { colPromotionAndNewsManagement } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import LangContext from '../context/languageContext';
import ApiManager from '../config/apiManager';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const PromotionAndNewsManagement = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllPromotionAndNews', body)
      .then(res => {
        if (res.message === 8359) {
          res?.record?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deletePromotionAndNews', body)
      .then(response => {
        if (response.message === 8357) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const changePromoNewsName = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'Promotion';
    } else if (value === 1) {
      return 'News';
    }
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Promotion & News List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Promotion & News management',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreatePromoAndNews')}
            >
              Create
            </Button>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colPromotionAndNewsManagement}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              let startdate = new Date(item?.startDate * 1000);
              let enddate = new Date(item?.endDate * 1000);
              console.log('renderitem', item);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{startdate.toLocaleDateString()}</Td>
                  <Td>{enddate.toLocaleDateString()}</Td>
                  <Td>{item?.title}</Td>
                  <Td>{changePromoNewsName(item?.promotionNewsCategory)}</Td>
                  {item?.status === 0 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="black"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.inactive}
                        </Text>
                      </Circle>
                    </Td>
                  ) : item?.status === 1 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.active}
                        </Text>
                      </Circle>
                    </Td>
                  ) : (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'12px'}>---</Text>
                      </Circle>
                    </Td>
                  )}
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Tooltip label={currentLangData?.app?.edit}>
                      <Button
                      leftIcon={<EditIcon/>}
                        {...style.editbtn}
                        onClick={() =>
                          history.push({
                            pathname: '/CreatePromoAndNews',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      >
                        {/* {currentLangData?.app?.edit} */}
                      </Button>
                    </Tooltip>
                    <Tooltip label={currentLangData?.app?.delete} bg={'red'}>
                    <Button {...style.Delbtn} 
                      leftIcon={<DeleteIcon/>}
                      onClick={() => deleteItem(item)}>
                      
                    </Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default PromotionAndNewsManagement;
