import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  useToast,
  Input,
  Tbody,
  Circle,
  Table,
  Thead,
  // Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableOne } from '../components/common/ChecktableOne';
import {
  listOtpLogs,
  colOtpLogs,
} from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
import { options } from '../context/data';
import {
  customInputLabel,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { SmallAddIcon } from '@chakra-ui/icons';

const OtpLogs = () => {
  let history = useHistory();
  let apimanager = ApiManager.getInstance();
  let toast = useToast();
  const { currentLangData } = React.useContext(LangContext);
  const [dataa, setDataa] = React.useState([]);
  const [value, setValue] = React.useState('10');
  const [valueSearch, setValueSearch] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [paginat, setpaginate] = React.useState();

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    console.log("event", ev)
    setValue(ev);
  };
  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };

  let tempArray = [];
  
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  // React.useEffect(() => {
  //   if (valueSearch || value){
  //     searchItems(valueSearch, value, 1);
  //   }
  // }, [valueSearch, value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAllOtpLogs', body)
      .then(res => {
        if (res.message === 7071) {
          let data = res?.results?.docs;
          // console.log("data>>>>>>>data",data)
          res?.results?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.results);
          setLoader(true);
        }
        if (res.message === 7070){
          setLoader(true);
          toast({
            title: 'Mongodb pagination Failed' ,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const searchItems = (value, limit, page) => {
    // console.log(limit,"limit,page",page)
    setLoader(false);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log("body",body)
    apimanager
      .post('searchOtpWithMSISDN',body)
      .then(res => {
        // console.log('res=>', res.message);
        if (res.message === 7102) {
          // console.log('Get Condition responce', res);
          // let data = res?.results?.docs;
          // console.log("data>>>>>>>data",data)
          res?.result?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.result);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'OTP Logs',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'OTP logs',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            {/* <Button {...style.createbutton}>Create</Button> */}
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex {...style.showentries} alignItems={'center'} justifyContent={'center'}>
              <Text fontSize={'sm'} paddingY={'5px'} mr={'4px'} mt={'2px'}>{currentLangData?.app?.search}:</Text>
                {customInputLabel({
                  size:'sm',
                  onKeyDown: e => handleOnSearch(e, 'searchPhrase'),
                })}
            </Flex>
          </Flex>
          <ChecktableOne
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            screenName="Otplogs"
            headerNames={colOtpLogs}
            onPageChange={page=>
            {
              if(valueSearch){
                searchItems(valueSearch,value, page)
              }
              else{
                getALLItems(value, page)
              }
            }}
            // onPageChange={page => getALLItems(value, page)}
            // onSearchChange={page => searchItems(value, page)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default OtpLogs;
