import React, { useEffect } from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  Text,
  Select,
  Textarea,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import { useHistory } from 'react-router-dom';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
  customInputImage,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { dropdownCustomSvalue } from '../components/bannerComponents/customBannerComponents';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import { uploadImage } from '../config/imageUploader';
import { DATABUTTON, DATABUTTONS } from '../context/data';
import theme from '../config/color';
import config from '../config/config';
import { AiTwotoneExperiment } from 'react-icons/ai';

const CreateBlackAppUser = () => {
  let initialValue = {
    title: '',
    // route: '',
    category: '',
    SelectLink: '',
    url: '',
    description: '',
    sequence: '',
  };
  let mandatoryFields = {
    title: true,
    category: true,
    description: true,
    sequence: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [success, setsuccess] = React.useState(null);
  const [title, setTitle] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [route, setRoute] = React.useState([]);
  const [s3image, setS3Image] = React.useState('');
  const [imageView, setImageView] = React.useState('');
  const [error, setError] = React.useState(null);
  const [imageLoading, setImageLoading] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();
  let temptitle = [];
  let tempRoute = [];
  useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val?.title)
      setValue(_p => ({ ..._p, title: location.state?.val?.title }));
    if (location.state?.val?.category)
      setValue(_p => ({ ..._p, category: location?.state?.val?.category }));
    if (location.state?.val?.description)
      setValue(_p => ({
        ..._p,
        description: location?.state?.val?.description,
      }));
    if (location.state?.val?.route)
      setValue(_p => ({ ..._p, route: location?.state?.val?.route }));
    if (location.state?.val?.sequence)
      setValue(_p => ({ ..._p, sequence: location?.state?.val?.sequence }));
    if (location.state?.val?.icon)
      setValue(_p => ({ ..._p, icon: location?.state?.val?.icon }));
    if (location.state?.val?.status)
      setValue(_p => ({ ..._p, status: location?.state?.val?.status }));
    console.log('pathdata', location?.state?.val);
  }, [location]);
  // console.log('value>>>>', value);
  useEffect(() => {
    DATABUTTON.map((value, index) => {
      console.log(value.title, 'Check', index);
      temptitle.push(value.title);
      tempRoute.push(value.route);
    });
    setTitle(temptitle);
    setRoute(tempRoute);
  }, []);
  console.log('value', value);
  const handleonSwitch = (evt, key) => {
    const ev = evt;
    console.log('Switch', ev, 'key', key);
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const handleonChange = (evt, key) => {
    // console.log('event', evt);
    const ev = evt.target.value;
    // console.log('event ev', ev);
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const upload = e => {
    setImageLoading(true);
    // console.log('e.target=>',e.target.files[0]);
    uploadImage(e.target.files[0])
      .then(res => {
        // console.log('image uri', res?.key);
        setS3Image(res?.key);
        // setImageView(res?.location);
        setImageLoading(false);
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  // console.log('value==', value);
  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter mandatory fields',
      });
    } else if (s3image === '' || s3image === undefined) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter mandatory fields',
      });
    } else {
      const body = {
        title: value?.title,
        isUrl: value?.SelectLink ? value?.SelectLink : 'false',
        route: value?.url,
        description: value?.description,
        category: value?.category,
        sequence: value?.sequence,
        suggestion: value?.suggestion,
        icon: s3image,
        status: value?.status,
      };
      // console.log('body>>>>', body);
      apimanager
        .post('addBlackAppPage', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8266) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
          if (response.message === 7000) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `User Not Found`,
            });
          }
          if (response.message === 8264) {
            setLoading(false);
            setError('Title Or Sequence must be Unique');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Title Or Sequence must be Unique`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const onClickUpdate = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      console.log('value.title', value.title);
      const body = {
        id: location.state.val._id,
        title: value?.title,
        description: value?.description,
        category: value?.category,
        sequence: value?.sequence,
        icon: s3image,
        status: value?.status,
      };
      // console.log('body>>>>', body);
      apimanager
        .post('editBlackAppPage', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8274) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  return (
    <>
      {status ? (
        <Flex flexDirection={'column'} background={'#fafdfb'}>
          <Flex {...styles.miniheader}>
            {MiniHeader({
              title: 'Edit Black User',
              path: 'Dashboard',
              path1: 'Edit Black user',
              cursor: 'pointer',
            })}
          </Flex>
          <Flex {...styles.box}>
            <Flex
              padding={'2%'}
              background={'white'}
              flexDirection={'column'}
              justifyContent={'space-around'}
            >
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Title',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'title'),
                  value: value['title'],
                  //location.state.val.name,
                })}
                {customInputLabel({
                  title: 'Category',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'category'),
                  value: value['category'],
                  //location.state.val.name,
                })}
              </SimpleGrid>
              <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
                {customInputImage({
                  title: 'Icon',
                  mandatory: true,
                  type: 'file',
                  src: process.env.REACT_APP_S3URL + value?.icon,
                  onChange: e => upload(e),
                })}
                {customInputLabel({
                  title: 'Sequence',
                  mandatory: true,
                  value: value['sequence'],
                  onChange: e => handleonChange(e, 'sequence'),
                })}
                {customSwtich({
                  title: 'Active',
                  label: 'Status',
                  checked: value['status'],
                  check: e => handleonSwitch(e.target.checked, 'status'),
                })}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={1}
                spacingX={'50px'}
                marginTop={5}
              >
                {customTextarea({
                  title: 'Description',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'description'),
                  value: value['description'],
                })}
              </SimpleGrid>

              {customUpdateCancelBtn({
                title1: 'Update',
                title2: 'Cancel',
                isLoading: loading,
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickUpdate(),
              })}
            </Flex>
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection={'column'} background={'#fafdfb'}>
          <Flex {...styles.miniheader}>
            {MiniHeader({
              title: 'Create Black App Page',
              path: 'Dashboard',
              path1: 'Black App Page',
              useSlash: true,
              path2: 'Create Black App Page',
            })}
          </Flex>
          <Flex {...styles.box}>
            <Flex
              padding={'2%'}
              background={'white'}
              flexDirection={'column'}
              justifyContent={'space-around'}
            >
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Title',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'title'),
                })}
                {/* <Flex flexDirection={'column'}>
                  <Flex flexDirection={'row'}>
                    <Text
                      color={theme.customColors.blackAdminColor['100']}
                      fontSize="md"
                      marginBottom={'2'}
                    >
                      Route
                    </Text>
                    <Text color={'red'} marginLeft={'1'}>
                      *
                    </Text>
                  </Flex>
                  <Select
                    placeholder="Select"
                    color={'grey'}
                    onChange={e => handleonChange(e, 'route')}
                  >
                    {console.log('tempTitle', title)}
                    {DATABUTTON &&
                      DATABUTTON.map((value, index) => {
                        console.log('VALUE TITLE', value.title);
                        return (
                          <option value={value.route}>{value.title}</option>
                        );
                      })}
                  </Select>
                </Flex> */}
                {/* {console.log('DATABUTTON', temptitle)} */}
                {customInputLabel({
                  title: 'Category',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'category'),
                  // value:location.state.name
                })}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={3}
                spacingX={'50px'}
                marginTop={'5'}
              >
                <Flex>
                  {customSwtich({
                    title: 'URL/Route',
                    label: 'Select Link Type',
                    checked: value?.SelectLink,
                    check: e => handleonSwitch(e.target.checked, 'SelectLink'),
                  })}
                </Flex>
                {customInputLabel({
                  title: 'URL',
                  // value: value['url'],
                  // mandatory: true,
                  disabled:
                    value?.SelectLink === true
                      ? true
                      : value?.SelectLink === false
                      ? false
                      : false,
                  onChange: e => handleonChange(e, 'url'),
                })}
                {dropdownCustomSvalue({
                  title: 'Route',
                  placeholder: value?.url ? value?.url : 'Select Option',
                  isDisabled:
                    value?.SelectLink === true
                      ? false
                      : value?.SelectLink === false
                      ? true
                      : true,
                  temparray: DATABUTTONS,
                  onChange: e => handleonChange(e, 'url'),
                })}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={1}
                spacingX={'50px'}
                marginTop={5}
              >
                {customTextarea({
                  title: 'Description',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'description'),
                })}
              </SimpleGrid>
              <SimpleGrid width={'100%'} columns={4} spacingX={'50px'} mt={5}>
                {!imageLoading ? (
                  customInputImage({
                    title: 'Icon',
                    mandatory: true,
                    type: 'file',
                    src: process.env.REACT_APP_S3URL + s3image,
                    onChange: e => upload(e),
                  })
                ) : (
                  <Skeleton height="70px" />
                )}
                {customSwtich({
                  title: 'Active',
                  label: 'Status',
                  check: e => handleonSwitch(e.target.checked, 'status'),
                })}
                {customSwtich({
                  title: 'Suggest',
                  label: 'Is Suggested',
                  check: e => handleonSwitch(e.target.checked, 'Suggestion'),
                })}
                {customInputLabel({
                  title: 'Sequence(in Number)',
                  mandatory: true,
                  type: 'number',
                  onChange: e => handleonChange(e, 'sequence'),
                  // value:location.state.name
                })}
              </SimpleGrid>
              {customUpdateCancelBtn({
                title1: 'Submit',
                title2: 'Cancel',
                isLoading: loading,
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickSubmit(),
              })}
            </Flex>
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateBlackAppUser;