import React, { useState, useEffect } from 'react';
import { Flex, Spacer, Divider, useToast, SimpleGrid } from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';
import style from '../components/common/style';
import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

const CreateSmsMasterLibrary = () => {
  let category = ['Mobile', 'Sms'];
  let position = ['Right', 'Down', 'Left', 'Up'];
  let language = ['English', 'Malaysian (Malay) ', 'Chinese'];

  const toast = useToast();
  let history = useHistory();
  const location = useLocation();
  let apimanager = ApiManager.getInstance();

  let initialValue = {
    smsCategory: '',
    smsId: '',
    language: '',
    contentENG: '',
    contentML: '',
    contentCN: '',
  };

  let mandatoryFields = {
    smsCategory: '',
    smsId: '',
    language: '',
    content: '',
  };

  const [error, setError] = useState(null);
  const [catagory, setCatagory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [status, setstatus] = React.useState(false);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  useEffect(() => {
    getAllCategory();
    if (location.status === 'create') {
      setstatus(true);
    }

    if (location.state?.val) {
      setValue({
        ...value,
        smsCategory: location?.state.val?.smsCategory,
        smsId: location?.state?.val?.smsId,
        contentENG: location?.state?.val?.contentENG,
        contentML: location?.state?.val?.contentMY,
        contentCN: location?.state?.val?.contentCN,
        language: location?.state?.val?.language,
      });
    }

    console.log('pathdata', location?.state);
  }, [location]);

  console.log('value=>', value);

  let tempArray = [];
  let ObjArray = [];
  let tempNameArray = [];

  const getAllCategory = () => {
    apimanager
      .post('getCategory', { modelName: 'sms_library' })
      .then(res => {
        if (res.message === 8188) {
          res?.result?.docs?.map((value, index) => {
            console.log('check>>>', value);
            Object.values(value?.list[0]).map((listVal, listIndex) => {
              ObjArray.push(listVal);
              console.log('listVal=>', listVal);
              console.log('listVal=> name', listVal?.name);
              tempNameArray.push(listVal?.name);
              let { name, id } = listVal;
              tempArray.push([name, id]);
              tempArray.push([name]);
            });
            console.log('tempNameArray=>', tempNameArray);
            setCatagory(tempNameArray);
            // setCatagory(tempArray);
          });
        }

        // console.log('getAllSoftpinsCategories=>RES', res);
        // if (res.message === 8188) {
        //   console.log('Get all categiry responce', res.results);
        //   res.result.docs.map((value, index) => {
        //     console.log('valuesssss', value?.modelName);
        //     tempArray.push(value?.modelName);
        //   });
        //   setCatagory(tempArray);
        // }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      // console.log('Resolved');
      let body = {
        smsCategory: value?.smsCategory,
        smsId: value?.smsId,
        language: value?.language,
        contentENG: value?.contentENG,
        contentMY: value?.contentML,
        contentCN: value?.contentCN,
      };
      console.log('body=>', body);
      apimanager
        .post('addSMSMasterLibrary', body)
        .then(res => {
          console.log('res=>', res);
          if (res.message === 8176) {
            setLoading(false);
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          } else {
            setLoading(false);
            setError('Please enter mandatory fields');
          }
          if (res.message === 8204) {
            setLoading(false);
            setError('Error in Minimum Stock Quantity');
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error', error);
        });
    }
  };

  const onClickUpdate = () => {
    setLoading(true);
    // console.log('Api Run');
    const body = {
      id: location.state.val._id,
      smsCategory: value?.smsCategory,
      smsId: value?.smsId,
      language: value?.language,
      contentENG: value?.contentENG,
      contentMY: value?.contentML,
      contentCN: value?.contentCN,
    };
    console.log('body>>>>', body);
    apimanager
      .post('updateSMSMasterLibrary', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8179) {
          setLoading(false);
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Sms Master Library',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Sms Master Library',
              useSlash: true,
              path2: 'Edit Sms Master Library',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {/* {dropdownCustom({
                mandatory: true,
                title: 'SMS Category',
                // value: value['smsCategory'],
                temparray: category,
                onChange: e => handleonChange(e, 'smsCategory'),
              })} */}

              {dropdownCustom({
                title: 'SMS Category',
                mandatory: true,
                placeholder: value['smsCategory'],
                temparray: category,
                onChange: e => handleonChange(e, 'smsCategory'),
              })}
              {customInputLabel({
                title: 'SMS ID',
                type: 'number',
                mandatory: true,
                onChange: e => handleonChange(e, 'smsId'),
                value: value['smsId'],
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={4}
            >
              {dropdownCustom({
                title: 'Language',
                mandatory: true,
                temparray: language,
                placeholder: value['language'],
                onChange: e => handleonChange(e, 'language'),
                value: value['language'],
              })}
              {customTextarea({
                title: 'Content English',
                mandatory: true,
                onChange: e => handleonChange(e, 'contentENG'),
                value: value['contentENG'],
              })}
              {customTextarea({
                title: 'Content Malaysian',
                mandatory: true,
                onChange: e => handleonChange(e, 'contentML'),
                value: value['contentML'],
              })}
              {customTextarea({
                title: 'Content Chinese',
                mandatory: true,
                onChange: e => handleonChange(e, 'contentCN'),
                value: value['contentCN'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Sms Master Library',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Sms Master Library',
              useSlash: true,
              path2: 'Create Sms Master Library',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustom({
                mandatory: true,
                title: 'SMS Category',
                temparray: catagory,
                placeholder: 'Select Category',
                onChange: e => handleonChange(e, 'smsCategory'),
              })}
              {customInputLabel({
                title: 'SMS ID',
                type: 'number',
                mandatory: true,
                onChange: e => handleonChange(e, 'smsId'),
                value: value['smsId'],
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={4}
            >
              {dropdownCustom({
                title: 'Language',
                mandatory: true,
                placeholder: 'Select Language',
                temparray: language,
                onChange: e => handleonChange(e, 'language'),
                value: value['language'],
              })}
              {customTextarea({
                title: 'Content English',
                mandatory: true,
                onChange: e => handleonChange(e, 'contentENG'),
                value: value['contentENG'],
              })}
              {customTextarea({
                title: 'Content Malaysian',
                mandatory: true,
                onChange: e => handleonChange(e, 'contentML'),
                value: value['contentML'],
              })}
              {customTextarea({
                title: 'Content Chinese',
                mandatory: true,
                onChange: e => handleonChange(e, 'contentCN'),
                value: value['contentCN'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateSmsMasterLibrary;
