import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Spacer,
  Input,
  Select,
  Tr,
  Th,
  Td,
  Table,
  Tbody,
  Thead,
  useToast,
} from '@chakra-ui/react';
import { options } from '../context/data';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import styles from '../components/common/style';
import { listAdmins, colAdmins } from '../context/data';
import LangContext from '../context/languageContext';
import Loading from '../components/common/Loading';
import { ChecktableTwo } from '../components/common/ChecktableOne';
import { MiniHeader } from '../components/common/customComponent';

const Admins = () => {
  let tempArray = [];

  let toast = useToast();
  let history = useHistory();

  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('10');
  const [data, setData] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = React.useState('');
  const [paginate, setpaginate] = React.useState();
  const [pageChange, setPageChange] = useState('');
  const { currentLangData } = useContext(LangContext);
  const [roleAccess, setRoleAccess] = React.useState([]);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [valueSearch, setValueSearch] = React.useState();
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);

  useEffect(() => {
    getRoles();
    getAllCategory(page, value);
  }, []);

  // Utility functions //
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('next and previous', loading);
    setCurrentPage(selectedPage);
    getAllCategory(selectedPage + 1, value);
    console.log('clicked', selectedPage + 1);
  };

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('event', ev);
    setValue(ev);
    getAllCategory(page, ev);
  };

  let totalDatalength = paginate?.totalDocs;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  let pageCount = paginate?.totalPages;
  const pageCeilingCount = paginate?.totalPages;
  console.log(pageCeilingCount, 'PAGE COUNT', pageCount);

  useEffect(() => {}, [page]);
  useEffect(() => {}, [value]);

  const getRoles = () => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let tempRoleAccess = [];

    let body = {
      // page: page,
      // nextPage: 3,
      // numRecords: value,
    };

    apiManager
      .get('getAllCMSRoles', body)
      .then(res => {
        console.log('getAllCMSRoles=>RES', res);
        if (res.message === 4009) {
          console.log('Get getAllCMSRoles', res);

          res.records.docs.map((value, index) => {
            console.log('valuesssss', value?.roleName);
            tempRolesId.push([value?.roleName, value?.roleId]);
            tempArray.push(value?.roleName);
            tempRoleAccess.push([value?.roleAccess, value?.roleId]);
          });

          setRoleAccess(tempRoleAccess);
          setRoles(tempArray);
          setNameRoles(tempRolesId);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAllCategory = (page, value) => {
    setLoading(true);
    let tempArray = [];
    console.log('page=>', page);
    let body = {
      page: page,
      // nextPage: 3,
      numRecords: value,
    };
    apiManager
      .get('getAllActions', body)
      .then(res => {
        console.log('getAllActions=>RES', res);
        if (res.message === 4008) {
          console.log('Get getAllActions', res);
          pageCount = res.records.totalDocs;
          res.records.docs.map((value, index) => {
            // console.log('valuesssss', value);
            tempArray.push(value);
          });
          setpaginate(res.results);
          setData(tempArray);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const deleteRecord = data => {
    setLoading(true);

    console.log('deleteRecord', data);
    // deleteS3(data.companyLogo);
    let body = {
      id: data?._id,
    };
    apiManager
      .post('deleteGameReward', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 8605) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          getAllCategory(1, value);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleOnSearch = (evt, key) => {
    console.log("e.key === 'Enter==>", evt);
    const ev = evt.target.value;

    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };

  const searchItems = (value, limit, page) => {
    console.log('searchItems', value, limit, page);
    setLoading(true);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apiManager
      .post('searchGameRewards', body)
      .then(res => {
        console.log('res=>', res.message);
        if (res.message === 8614) {
          res?.record?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setData(tempArray);
          setpaginate(res?.record);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreateActions',
      state: { data: editData, useEditApi: true },
    });
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Actions',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Users',
          useSlash: true,
          path2: 'Actions',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          {/* <Flex {...style.buttoncontainer}> */}
            {/* <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateActions')}
            >
              Create
            </Button> */}
          {/* </Flex> */}

          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            {/* <Flex alignItems={'center'} {...style.showentries}> */}
                {/* <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
                <Input {...style.searchinput}></Input> */}
            {/* </Flex> */}
          </Flex>
          {/* <Flex padding={'1%'} flexDirection={'column'}> */}
          <Flex {...style.tableFlex}>
            <Table {...styles.table} padding={'1%'}>
              <Thead {...styles.thead}>
                <Tr>
                  {/* <Th {...styles.th}>Name</Th> */}
                  <Th {...styles.th}>Action Name</Th>
                  <Th {...styles.th}></Th>
                  <Th {...styles.th}>Action Api</Th>
                  {/* <Th {...styles.th}>Actions</Th> */}
                </Tr>
              </Thead>
              <Tbody {...styles.tbody}>
                {!loading ? (
                  data &&
                  data?.map((value, index) => {
                    console.log('Data value=>', value);

                    let tempName = '';
                    let tempId = '';
                    let nam = nameRoles.map(val => {
                      // console.log('value', val[1]);
                      if (value?.role === val[1]) {
                        tempName = val[0];
                        tempId = val[1];
                        return val[0];
                      }
                    });

                    if (index >= 0) {
                      return (
                        <>
                          <Tr key={index} {...styles.tr}>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.actionName}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {nam}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.actionApi}
                            </Td>

                            {/* <Td
                              width={'15%'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'flex-end'}
                            > */}
                              {/* {tempRoleAccess} */}
                              {/* <Button
                                {...style.editbtn}
                                onClick={() => navigateToEditPage(value)}
                              >
                                Edit
                              </Button>
                              <Button
                                {...style.Delbtn}
                                onClick={() => deleteRecord(value)}
                              >
                                Delete
                              </Button> */}
                            {/* </Td> */}
                          </Tr>
                        </>
                      );
                    } else {
                      return (
                        <Tr height={'40vh'}>
                          <Td></Td>
                          <Td></Td>
                          <Td>
                            <Text>NO DATA AVAILABLE</Text>
                          </Td>
                          <Td></Td>
                          <Td></Td>
                        </Tr>
                      );
                    }
                  })
                ) : (
                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td paddingY={'15vh'}>
                      <Flex
                        style={{
                          w: '100%',
                          h: '100%',
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Loading />
                      </Flex>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            </Flex>
            {/* </Flex> */}
           <Flex {...style.tablefooter}>
            <Flex>
            <Text>
              Showing {data.length} to {PER_PAGE} of {totalDatalength} entries
            </Text>
            </Flex>
            <Flex {...style.paginatecontainer}>
              <ReactPaginate
                nextLabel={'Next'}
                pageCount={pageCount}
                previousLabel={'Previous'}
                activeClassName={'active'}
                nextClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                containerClassName={'pagination'}
                onPageChange={handlePageClick}
                previousLinkClassName={'page-link'}
              />
            </Flex>
            </Flex>
          </Flex>       
      </Flex>
    </Flex>
  );
};

export default Admins;
