import React from 'react';
import {
  Flex,
  Text,
  Skeleton,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import styles from '../../styles/mobileBannerStyles';
import style from '../../components/common/style';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ApiManager from '../../config/apiManager';
import { csvUploader } from '../../config/csvUploader';
import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
  customInputLabelButton,
  customFileInputLabel,
} from '../../components/createAppVersionComponent/createAppVersionComponent';
import { NotificationType } from '../../context/data';
import { dropdownCustomSvalue } from '../../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../../components/common/customComponent';

const NotifySelectedUsers = () => {
  let initialValue = {
    file: '',
    title: '',
    title: '',
    appLink: '',
    message: '',
    notificationType: '',
  };

  let mandatoryFields = {
    file: true,
    title: true,
    message: true,
  };

  const [value, setValue] = React.useState(initialValue);
  // const [csvfile, setCsvfile] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [csvLoad, setCsvLoad] = React.useState(false);
  const [displayFile, setDisplayFile] = React.useState(false);
  const [success, setsuccess] = React.useState(null);
  const [error, setError] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  let location = useLocation();
  let toast = useToast();

  console.log('value', value);

  const csvUpload = e => {
    setCsvLoad(true);
    console.log('csvUploader>>', e.target.files[0].name);
    csvUploader(e.target.files[0])
      .then(res => {
        console.log('CsvResponse', res);
        setCsvLoad(false);
        setDisplayFile(true);
        toast({
          title: 'File Added',
          status: 'success',
          duration: 3000,
          isClosable: true,
          description: `Proceed to Submit`,
        });
        setValue({
          ...value,
          ['file']: res?.key,
        });
      })
      .catch(error => {
        console.log('Error', error);
        setCsvLoad(false);
        setDisplayFile(false);
        // setError('Failed to Upload File.');
        toast({
          title: 'Failed to Upload File.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: `Server is Not Responding`,
        });
      });
  };
  console.log('fileName==', value?.file);

  const handleonChange = (evt, key) => {
    console.log('Event==',evt,key)
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  console.log('value=>', value?.file);

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    // console.log(value, 'Value');
    if (ifError) {
      setLoading(false);
      setError('Failed to Submit');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Please enter mandatory fields',
      });
    } else {
      const body = {
        title: value?.title,
        fileLink: value?.file,
        deepLink: value?.appLink,
        message: value?.message,
        notificationType: value?.notificationType,
      };
      console.log('body>>>>', body);
      apimanager
        .post('sendNotificationToBulkUsers', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 12002) {
            setLoading(false);
            setsuccess('Successfully Sent To Selected Users');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Sent`,
            });
            setTimeout(() => {
              history.push('/users/NotificationCompaigns');
            });
          }
          if (response.message === 12003) {
            setLoading(false);
            setError('Oops Something went wrong');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Message Topic is invalid`,
            });
          }
          if (response.message === 12005) {
            setLoading(false);
            setError('Failed to Submit');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Key Does Not Exist`,
            });
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Notification To Selected Users',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Notification',
          useSlash: true,
          path2: 'Notification To Selected Users',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {csvLoad === false ? (
            <>
              {customFileInputLabel({
                title: 'Submit Users List:',
                mandatory: true,
                type: 'file',
                accept: '.csv,',
                uploadedProp: displayFile,
                uploadedFileName: value?.file,
                onChange: e => csvUpload(e),
                // onClickButton: () => deleteFile(),
              })}
            </>
          ) : (
            <Skeleton height={'70px'} />
          )}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Enter Title :',
            mandatory: true,
            onChange: e => handleonChange(e, 'title'),
          })}

          {/* {dropdownCustomSvalue({
            title: 'App linking :',
            placeholder: 'Select Option',
            temparray: DATABUTTONS,
            onChange: e => handleonChange(e, 'appLink'),
          })} */}

          {dropdownCustomSvalue({
            title: 'Notification Type :',
            placeholder: 'Select Option',
            // isLoading: pageLoading,
            temparray: NotificationType,
            onChange: e => handleonChange(e, 'notificationType'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customTextarea({
            title: 'Enter Message :',
            mandatory: true,
            onChange: e => handleonChange(e, 'message'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>

      <Spacer />

      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />

      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default NotifySelectedUsers;
