import React from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  Button,
} from '@chakra-ui/react';

export const customSaveButton = ({ title1, title2, title3, title4 }) => {
  return (
    <Flex alignSelf={'flex-start'} width={'100%'} marginTop={5}>
      <Button
        backgroundColor={'#63ed7a'}
        size={'sm'}
        height={'32px'}
        width={'8%'}
        color={'white'}
        marginRight={2}
        boxShadow={
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }
        _hover={{
          bg: 'white.500',
          background: 'gray',
          transform: 'translateY(-2px)',
          // boxShadow: 'lg',
        }}
        _focus={{
          bg: 'gray',
        }}
      >
        <Text size={'sm'}>{title1}</Text>
      </Button>
      <Button
        backgroundColor={'#fc544b'}
        size={'sm'}
        color={'white'}
        height={'30px'}
        width={'13%'}
        boxShadow={'0 2px 4px 0 #fc544b, 0 3px 4px 0 rgba(0, 0, 0, 0.19)'}
        _hover={{
          bg: 'white.500',
          transform: 'translateY(-2px)',
        }}
        _focus={{
          bg: 'gray',
        }}
      >
        <Text size={'xs'}> {title2}</Text>
      </Button>
      <Spacer />
      <Button
        backgroundColor={'black'}
        size={'sm'}
        height={'32px'}
        width={'8%'}
        color={'white'}
        marginRight={2}
        boxShadow={
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }
        _hover={{
          bg: 'white.500',
          background: 'gray',
          transform: 'translateY(-2px)',
          // boxShadow: 'lg',
        }}
        _focus={{
          bg: 'gray',
        }}
      >
        <Text size={'sm'}>{title3}</Text>
      </Button>
      <Button
        backgroundColor={'#fc544b'}
        size={'sm'}
        color={'white'}
        height={'30px'}
        width={'8%'}
        boxShadow={'0 2px 4px 0 #fc544b, 0 3px 4px 0 rgba(0, 0, 0, 0.19)'}
        _hover={{
          bg: 'white.500',
          transform: 'translateY(-2px)',
        }}
        _focus={{
          bg: 'gray',
        }}
      >
        <Text size={'xs'}> {title4}</Text>
      </Button>
    </Flex>
  );
};
