import theme from '../config/color';
const styles = {
  mainFlex: {
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: 'white.100',
    backgroundColor: '#FAFDFB',
  },
  mainFlexResponsive: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: 'white.100',
    backgroundColor: '#FAFDFB',
  },
  loginCard: {
    width: '25%',
    display: 'flex',
    height: '50vh',
    boxShadow: 'lg',
    borderRadius: 5,
    // marginTop: '2%',
    alignItems: 'center',
    borderTopWidth: 2,
    borderColor: 'black',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  headingFlex: {
    width: '90%',
    justify: 'flex-start',
    alignItems: 'flex-start',
  },
  heading: {
    as: 'h6',
    // size: '18px',
    fontSize: '16px',
    color: theme.customColors.blackAdminColor[200],
    marginStart: 1,
    marginTop: 3,
  },
  divider: {
    marginY: 5,
    color: theme.customColors.gray[900],
  },
  stackOfForm: {
    spacing: 4,
    width: '90%',
  },
  checkbox: {
    size: 'sm',
    fontSize: 'xs',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.customColors.blackAdminColor[200],
  },
  link: {
    fontSize: 'xs',
    color: theme.customColors.blackAdminColor[200],
  },
  loginBtn: {
    bg: 'black',
    color: 'white',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    _hover: {
      bg: 'blue.500',
    },
  },
  labelpass: {
    fontSize: 'xs',
    color: theme.customColors.blackAdminColor[200]
  },
  socalLinkTxt: {
    fontSize: 'xs',
    marginBottom: 5,
    color: theme.customColors.blackAdminColor[200],
  },
  stackOfRememberForget: {
    align: 'start',
    justify: 'space-between',
  },
  btnTxtLogin: {
    fontSize: 'sm',
    fontWeight: 'light',
  },
};
export default styles;
