import React from 'react';
import {
  Flex,
  Text,
  Input,
  SimpleGrid,
  Divider,
  Spacer,
  Button,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import { AddIcon } from '@chakra-ui/icons';

import {
  customSwtich,
  customUpdateCancelBtn,
  customParentHead,
  customAddNewGameBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  MiniHeader,
} from '../components/common/customComponent';

const CreateGameSettings = () => {
  console.log('CreateGameSettings gg bro');
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      {MiniHeader({
        title: 'Create Game Settings',
        path: 'Dashboard /App Setup / Game Settings / Create Game Setting',
      })}

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {dropdownCustom({
            title: 'Game',
            mandatory: true,
          })}
          {customAddNewGameBtn({
            title1: 'Add New Game Setting',
            title2: 'Cancel',
          })}
        </SimpleGrid>

      
      </Flex>

      <Flex {...styles.parentFlex} marginTop={'2%'}>
        <Flex alignSelf={'flex-start'}>
          <Text
            fontSize={'16px'}
            color={theme.customColors.blackAdminColor[200]}
            fontWeight="bold"
          >
            Parent: 1
          </Text>
        </Flex>

        <Divider marginY={10} />

        {customParentHead()}

        <SimpleGrid width={'100%'} columns={5} spacingX={'50px'} marginTop={4}>
          <Input />
          <Flex marginLeft={'25%'}>{customSwtich({ title: 'file' })}</Flex>
          <Input />
          <Input />
          <Text color={'red'} fontSize={'sm'}>
            Remove
          </Text>
        </SimpleGrid>

        <SimpleGrid
          width={'100%'}
          columns={5}
          spacingX={'50px'}
          marginTop={'3%'}
        >
          <Button
            leftIcon={
              <AddIcon color={theme.customColors.blackAdminColor[200]} />
            }
            size={'sm'}
            colorScheme="gray"
            variant="outline"
          >
            <Text
              fontSize={'sm'}
              color={theme.customColors.blackAdminColor[200]}
            >
              Add Property
            </Text>
          </Button>
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Save',
          title2: 'Delete',
        })}
      </Flex>

      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateGameSettings;
