import React from 'react';
import './spinner.css';
import {Flex } from '@chakra-ui/react';
import { DoubleOrbit } from 'react-spinner-animated';
import 'react-spinner-animated/dist/index.css';

function SpinnerLoading() {
  return (
    <Flex className="spinner-wrapper">
      <DoubleOrbit
        text={'Loading...'}
        bgColor={'#ffffff'}
        center={false}
        width={'100px'}
        height={'100px'}
      />
    </Flex>
  );
}

export default SpinnerLoading;
