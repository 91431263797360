import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import {
  customSwtich,
  customInputLabel,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';
import style from '../components/common/style';
const CreateInboxMessage = () => {
  console.log('CreateInboxMessage gg bro');
  let history = useHistory();
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex 
      {...style.miniheader}
      marginBottom={'2%'}
      >
      {MiniHeader({
        title: 'Create Dynamic Campaign',
        path: 'Dashboard',
        onclickForRoute: () => history.push('/Dashboard'),
        path1:'CreateDynamicCompaign',
        useSlash:true,
        path2:'Create',
      })}
      </Flex>

      <Flex
        padding={'8'}
        width={'97%'}
        boxShadow={'lg'}
        alignSelf={'center'}
        alignItems={'center'}
        background={'white'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
          {customInputLabel({ title: 'Message Type', mandatory: true })}
          {customInputLabel({ title: 'SMS Category', mandatory: false })}
          {customInputLabel({ title: 'SMS ID', mandatory: true })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={2}>
          {customInputLabel({ title: 'Title', mandatory: true })}
        </SimpleGrid>

        {customTextarea({ title: 'Message EN', mandatory: true })}
        {customTextarea({ title: 'Message CN', mandatory: true })}
        {customTextarea({ title: 'Message MY', mandatory: true })}

        <Flex width={'100%'} marginTop={4}>
          {customSwtich({ title: 'Send All', noLabel: true })}
        </Flex>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          {customInputLabel({ title: 'Recipients', mandatory: false })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          {customInputLabel({
            title: 'CSV File',
            type: 'file',
          })}
        </SimpleGrid>
        {customSaveButton({
          title1: 'Save',
          title2: 'Save as Draft',
          title3: 'Send',
          title4: 'Cancel',
        })}
      </Flex>

      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateInboxMessage;
