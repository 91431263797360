import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import HtmlParser from 'react-html-parser';
import style from '../components/common/style';
import theme from '../config/color';
import ControlledEditor from '../components/textEditor/classEditor';
import ControlledEditorReselect from '../components/textEditor/textEditor';
import { useHistory } from 'react-router-dom';
import {
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
  Toasting,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { EditorState, convertToRaw } from 'draft-js';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import draftToMarkdown from 'draftjs-to-markdown';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

const CreateTermAndConditions = () => {
  let initialValue = {
    tAndcCategory: '',
    name: '',
    contentEN: '',
    contentCN: '',
    contentMY: '',
    editorState: EditorState.createEmpty(),
  };
  const [changeApi, setChangeApi] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [category, setCategory] = useState([]);
  const [status, setstatus] = useState(false);
  const [error, setError] = useState(null);
  const [html, setHtml] = useState('');

  let apimanager = ApiManager.getInstance();

  const location = useLocation();
  let history = useHistory();
  const toast = useToast();
  let ObjArray = [];
  let tempArray = [];

  let mandatoryFields = {
    tAndcCategory: true,
    contentEN: true,
    contentCN: true,
    contentMY: true,
  };

  useEffect(() => {
    if (location) {
      if (location?.state?.useEditApi === true) {
        let editData = location?.state?.data;
        console.log('data=>', editData);

        let useEditApi = location?.state.useEditApi;
        console.log('useEditApi', useEditApi);
        setChangeApi(useEditApi);

        setValue({
          ...value,
          name: location?.state?.data?.name,
          contentEN: location?.state?.data?.contentEN,
          contentCN: location?.state?.data?.contentCN,
          contentMY: location?.state?.data?.contentMY,
          tAndcCategory: location?.state?.data?.tAndcCategory,
        });
      }
    }
    console.log('pathdata', location?.state);
    // }
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
    // console.log('value=>', value);
  };

  const handleSingleonChange = (evt, key) => {
    let ev = evt;
    console.log('EVT->', evt);

    let val = IFame(evt);

    convertCommentFromJSONToHTML(val?.__html, key);
  };
  const IFame = dat => {
    let data = dat;
    console.log('location data=>', data);
    return {
      __html: data,
    };
  };

  const convertCommentFromJSONToHTML = (text, key) => {
    console.log('convert Text=>', text);
    let toHTML = stateToHTML(convertFromRaw(text));
    console.log('toHTML', toHTML);
    setHtml(toHTML);
    setValue({
      ...value,
      [key]: toHTML,
    });
  };

  useEffect(() => {
    getALLTermConditionCategory();
  }, []);

  const getALLTermConditionCategory = () => {
    let totalPage = [];
    let apimanager = ApiManager.getInstance();
    const body = {
      modelName: 't_and_c_categories',
    };

    apimanager
      .post('getCategory', body)
      .then(res => {
        console.log('res', res);
        if (res.message === 8188) {
          res?.result?.docs?.map((value, index) => {
            console.log('check>>>', value);
            Object.values(value?.list[0]).map((listVal, listIndex) => {
              ObjArray.push(listVal);
              // console.log('listVal=>', listVal);
              let { name, id } = listVal;
              // tempArray.push([name, id]);
              tempArray.push([name]);
            });
            setCategory(tempArray);
          });
          // console.log('tempArray=>', tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
        // setLoader(true);
      });
  };

  const onClickSubmit = () => {
    console.log('onClickSubmit');
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        tAndcCategory: value?.tAndcCategory,
        name: value?.name,
        contentEN: value?.contentEN,
        contentCN: value?.contentCN,
        contentMY: value?.contentMY,
      };
      console.log('body>>>>', body);
      apimanager
        .post('addTermsAndConditions', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8198) {
            setLoading(false);
            setSuccess('Successfully Recorded!');
            // Toasting({
            //   description: `Recorded, Please processed to List!`,
            //   title: success,
            //   status: 'success',
            // })
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          } else {
            setLoading(false);
            setError('Something went wrong!');
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const onClickUpdate = () => {
    setLoading(true);
    console.log('onClickUpdate');
    const body = {
      id: location?.state?.data._id,
      tAndcCategory: value?.tAndcCategory,
      name: value?.name,
      contentEN: value?.contentEN,
      contentCN: value?.contentCN,
      contentMY: value?.contentMY,
    };
    console.log('body>>>>', body);
    apimanager
      .post('updateTermsAndConditions', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8228) {
          setLoading(false);
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        } else {
          setLoading(false);
          setSuccess('Failed!');
          toast({
            title: success,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `${response?.errors[1].msg}!`,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  console.log('value==>', value);
  return (
    <>
      {changeApi ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Terms & Conditions',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Dashboard',
              useSlash: true,
              path2: 'Term and Conditions',
              path3: 'Edit Terms & Conditions',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Category',
                mandatory: true,
                temparray: category,
                onChange: e => handleonChange(e, 'tAndcCategory'),
                placeholder: value['tAndcCategory'],
              })}
              {customInputLabel({
                title: 'Name',
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
                // mandatory: true,
                // type: 'file',
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
                //   background={'red.100'}
              >
                <Flex flexDirection={'row'} marginTop={4}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Content EN
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                {/* <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'contentEN')}
                /> */}
                <ControlledEditorReselect
                  value={value['contentEN'] ? value['contentEN'] : ''}
                  tempData={e => handleSingleonChange(e, 'contentEN')}
                />
              </Flex>
              <Flex
                padding={5}
                marginTop={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
                //   background={'red.100'}
              >
                <Flex flexDirection={'row'} marginTop={4}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Content CN
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                <ControlledEditorReselect
                  value={value['contentCN'] ? value['contentCN'] : ''}
                  tempData={e => handleSingleonChange(e, 'contentCN')}
                />
                {/* <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'contentCN')}
                /> */}
              </Flex>

              <Flex
                marginTop={5}
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
                //   background={'red.100'}
              >
                <Flex flexDirection={'row'} marginTop={4}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Content MY
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                {/* <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'contentMY')}
                /> */}
                <ControlledEditorReselect
                  value={value['contentMY'] ? value['contentMY'] : ''}
                  tempData={e => handleSingleonChange(e, 'contentMY')}
                />
              </Flex>
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Terms & Conditions',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Dashboard',
              useSlash: true,
              path2: 'Term and Conditions',
              path3: 'Create Terms & Conditions',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Category',
                mandatory: true,
                placeholder: 'Select Option',
                temparray: category,
                onChange: e => handleonChange(e, 'tAndcCategory'),
              })}
              {customInputLabel({
                title: 'Name',
                onChange: e => handleonChange(e, 'name'),
                // mandatory: true,
                // type: 'file',
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              <Flex
                padding={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
                //   background={'red.100'}
              >
                <Flex flexDirection={'row'} marginTop={4}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Content EN
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'contentEN')}
                />
              </Flex>
              {/* {console.log("IFame().__html",HtmlParser(IFame().__html))} */}
              {/* <div style={{ backgroundColor: 'red' }}>
                {HtmlParser(IFame().__html)}
              </div> */}
              {/* <style>{IFame().__css}</style> */}
              {/* <html>
                <head>
                  <title>Server Rendered App</title>
                </head>
                <body>
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                  asdfads
                  <script src="/build/client.entry.js" />
                </body>
              </html> */}
              {/* {html} */}
              {/* {HtmlParser(IFame().__html)} */}
              {/* </div> */}
              <Flex
                padding={5}
                marginTop={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
                //   background={'red.100'}
              >
                <Flex flexDirection={'row'} marginTop={4}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Content CN
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'contentCN')}
                />
              </Flex>
              <Flex
                padding={5}
                marginTop={5}
                borderWidth={1}
                flexDirection={'column'}
                height={'50vh'}
                width={'100%'}
                //   background={'red.100'}
              >
                <Flex flexDirection={'row'} marginTop={4}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Content MY
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                <ControlledEditor
                  tempData={e => handleSingleonChange(e, 'contentMY')}
                />
              </Flex>
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateTermAndConditions;
