import React, { useEffect, useState } from 'react';
import { Flex, SimpleGrid, Divider, Spacer, useToast } from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';

import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import { useLocation, useHistory } from 'react-router-dom';

const CreateBannerType = () => {
  const [status, setstatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();

  useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location?.state?.val != null || location?.state?.val != undefined) {
      setValue({
        ...value,
        name: location?.state?.val?.name,
        description: location?.state?.val?.description,
      });
      // setValue(_p => ({ ..._p, name: location?.state?.val?.name }));
      // setValue(_p => ({
      //   ..._p,
      //   description: location?.state?.val?.description,
      // }));
    }
    // if (location.state?.val?.name)

    //   if (location.state?.val?.description)
    console.log('pathdata', location);
  }, [location]);

  let initialValue = {
    name: '',
    description: '',
  };
  let mandatoryFields = {
    name: true,
    description: true,
  };
  const [value, setValue] = React.useState(initialValue);
  let apimanager = ApiManager.getInstance();

  const handleonChange = (evt, key) => {
    console.log('event', evt);
    const ev = evt.target.value;
    console.log('event ev', ev);
    setValue({
      ...value,
      [key]: ev,
    });
    // console.log('value=>>>>>', value);
  };

  const onClickSubmit = () => {
    setLoading(true);

    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      // setError('Please enter mandatory fields');
      toast({
        title: 'Please enter mandatory fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        name: value?.name,
        description: value?.description,
      };
      console.log('body>>>>', body);
      apimanager
        .post('addBannerType', body)
        .then(response => {
          if (response.message === 8123) {
            setLoading(false);
            console.log(response, 'response');
            toast({
              title: 'Successfully Submitted.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: 'Please Process to list!',
            });

            setTimeout(() => {
              history.goBack();
            }, 3000);
          } else {
            toast({
              title: 'Failed to Submit.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description:
                'Something went wrong! Please contact XOX Administrator.',
            });
          setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const onClickUpdate = () => {
    setLoading(true);

    const body = {
      id: location.state.val._id,
      name: value?.name,
      description: value?.description,
    };
    console.log('body>>>>', body);
    apimanager
      .post('updateBannerType', body)
      .then(response => {
        if (response.message === 8126) {
          setLoading(false);
          console.log(response, 'response');
          toast({
            title: 'Successfully Updated.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: 'Please Process to list!',
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
        } else {
          toast({
            title: 'Failed to Submit.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description:
              'Something went wrong! Please contact XOX Administrator.',
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...styles.miniheader} marginBottom={'2%'}>
          {MiniHeader({
            title: 'Edit Banner Types',
            path: 'Dashboard',
            path1: 'App Setup',
            useSlash: true,
            path2: 'Edit Banner Types',
          })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
                //location.state.val.name,
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              {customTextarea({
                title: 'Description',
                value: value['description'],
                mandatory: true,
                onChange: e => handleonChange(e, 'description'),
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...styles.miniheader} marginBottom={'2%'}>
          {MiniHeader({
            title: 'Create Banner Types',
            path: 'Dashboard',
            path1: 'Application Setup',
            useSlash: true,
            path2: 'Create Banner Types',
          })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
                // value:location.state.name
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              {customTextarea({
                title: 'Description',
                mandatory: true,
                onChange: e => handleonChange(e, 'description'),
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateBannerType;
