import React, { lazy, Suspense } from 'react';
import '../styles/nav.css';
import ApiManager from '../config/apiManager';
// import Login from '../pages/Login';
import Register from '../pages/Register';
import AboutUs from '../pages/AboutUs';
import SocailLogin from '../pages/SocialLogin';
import RegisterUser from '../pages/RegisterUser';
import FaqUnauth from '../pages/FaqUnauth';
import TnCUnauth from '../pages/TnCUnauth';
// import Dashboard from '../pages/Dashboard';
// import TopBar from '../components/common/navBar';
import { useSelector, useDispatch } from 'react-redux';
import ContactUsUnauth from '../pages/ContactUsUnauth';
import ChangePassword from '../pages/ChangePassword';
// import ChangePassword from '../pages/ChangePassword';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import allActions from '../redux/actions/allActions';
import Spinner from '../components/Spinner/Spinner';
import PrivacyPoliciesUnauth from '../pages/PrivacyPoliciesUnauth';

const Login = lazy(() => import('../pages/Login'));

export default function Auth() {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<Spinner />}>
          <Route path="/" exact>
            <Login />
          </Route>
          <Route path="/Login">
            <Login />
          </Route>
          <Route path="/SocialLogin">
            <SocailLogin />
          </Route>
          <Route path="/Register">
            <Register />
          </Route>
          <Route path="/RegisterUser">
            <RegisterUser />
          </Route>
          <Route path="/ContactUsUnauth">
            <ContactUsUnauth />
          </Route>
          <Route path="/PrivacyPoliciesUnauth">
            <PrivacyPoliciesUnauth />
          </Route>
          <Route path="/FaqUnauth">
            <FaqUnauth />
          </Route>
          <Route path="/TnCUnauth">
            <TnCUnauth />
          </Route>
          <Route path="/AboutUs">
            <AboutUs />
          </Route>
          <Route path="/ChangePassword">
            <ChangePassword />
          </Route>
        </Suspense>

        {/* <Route path={'/ChangePassword'} exact>
          <ChangePassword />
        </Route> */}
      </Switch>
    </Router>
  );
}
