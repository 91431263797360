import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Divider,
  Spacer,
  Skeleton,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import {
  checkMandatory,
  customInputImage,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { uploadImage } from '../config/imageUploader';
import { useHistory, useLocation } from 'react-router-dom';
import { dropdownCustomSvalue } from '../components/bannerComponents/customBannerComponents';
import { CustomSelect,MultiSelect } from '../components/bannerComponents/customDropDowns';

const CreateMaintenance = () => {
  let initialState = {
    message: '',
    platform: '',
    status: '',
  };
  let mandatoryFields = {
    message: true,
    platform: true,
  };
  let optionArray = [{label:'Black App',value:'Black App'},{label:'Selfcare',value:'Selfcare'},{label:'Online-Store',value:'Online-Store'} ];
  let status = [
    { list: 'Active', val: 1 },
    { list: 'Inactive', val: 2 },
  ];

  const toast = useToast();
  let history = useHistory();
  let location = useLocation();
  let apimanager = ApiManager.getInstance();

  const [option, setOption] = useState();
  const [platform, setPlatform] = useState([]);
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState([]);
  const [s3image, setS3Image] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialState);
  const [endD, setEndD] = useState(new Date());
  const [startD, setStartD] = useState(new Date());
  const [imageView, setImageView] = useState('');
  const [statusEdit, setStatusEdit] = useState('');
  const [changeApi, setChangeApi] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  let dataOfEdit = '';

  // Predefined Functions //
  useEffect(() => {
    if (location?.state?.data != null || location?.state?.data != undefined) {
      dataOfEdit = location?.state?.data;
      let aray = [];
      let tArray = [];
      location?.state?.data?.platforms.map(e => {
        console.log('MAP DATA PLATFORM', e);
        tArray.push(e);
        aray.push({value:e ,label:e});
      });
      setPlatform(tArray);

      let editStatus = changeStatus(dataOfEdit?.status);
      setStatusEdit(editStatus);

      console.log('editStatus', editStatus);

      setValue({
        ...value,
        platform: aray,
        status: editStatus,
        // s3image: dataOfEdit?.image,
        message: dataOfEdit?.message,
      });
      setS3Image(dataOfEdit?.image)
      // console.log('image', process.env.REACT_APP_S3URL + s3image);
      // console.log('dataOfEdit', location?.state);
      let changeApi = location?.state?.useEditApi;
      setChangeApi(changeApi);

      var startDate = new Date(dataOfEdit?.startDate * 1000);
      var endDate = new Date(dataOfEdit?.endDate * 1000);

      setStartD(startDate);
      setEndD(endDate);

      !changeApi
        ? setOption(['Black App', 'Selfcare', 'Online-Store'])
        : setOption(aray);
    }
    setEditData(dataOfEdit);

    // console.log('daataOfEdit', dataOfEdit?.image);
  }, []);

  // Utility Functions //

  const changeStatus = status => {
    if (status === 1) {
      return 'Active';
    } else if (status === 2) {
      return 'Inactive';
    }
  };

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };



  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  // Api Implementation //
  const onClickSubmit = () => {
    console.log('edit data', editData);
    setLoading(true);
    // console.log('Api Run', value);
    // console.log('Api Run message', value['message']);
    // console.log('platformplatform=>', value.platform.length);

    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else if (value.platform === [] || value.platform.length === 0) {
      setLoading(loading);
      console.log('value.platform', value.platform);
      setError('Please enter mandatory fields "Platform"');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        platforms: platform,
        message: value?.message,
        status: value?.status,
        image: s3image,
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
      };
      console.log('body>>>>', body);

      if (!changeApi) {
        apimanager
          .post('addMaintenance', body)
          .then(response => {
            console.log(response, 'response');
            // console.log('response.message', response.message);
            if (response.message === 8255) {
              setLoading(loading);
              setSuccess('Successfully Added Maintenance');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Added Maintenance!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }
            if (response.errors !== undefined || response.errors !== null) {
              if (response.message === 5001) {
                setLoading(loading);

                console.log('ERROR to 5002');
                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[1]?.msg);

                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[1]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      } else {
        let payload = {
          platforms: platform,
          message: body?.message,
          startDate: body.startDate,
          image: s3image,
          endDate: body.endDate,
          status:value?.status,
          id: editData?._id,
        };
        console.log('update payload', payload);
        apimanager
          .post('updateMaintenance', payload)
          .then(response => {
            console.log(response, 'response');
            console.log('response.message', response.message);
            if (response.message === 8258) {
              setLoading(loading);
              setSuccess('Successfully Updated Maintenance');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Updated Maintenance!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }
            if (response.message === 8257) {
              setLoading(loading);
              setError('Updated Maintenance Error');
              toast({
                title: error,
                status: 'error',
                duration: 3000,
                isClosable: true,
                description: `Something Went Wrong!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 5000);
            }
            if (response.errors !== undefined || response.errors !== null) {
              if (response.message === 5001) {
                setLoading(loading);

                console.log('ERROR to 5002');
                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[1]?.msg);

                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[1]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      }
    }
  };

  // Event Triggerring //
  const onSelectedFuntion = e => {
    console.log('onSelect=>', e);
    let tempArray = [];
    e?.map((val,i)=>{
      console.log('val',val?.value)
      tempArray.push(val?.value)
    })
    // console.log('onSelect=>1',tempArray)
    setPlatform(tempArray)
    setValue({
      ...value,
      platform: e,
    });
  };
  // console.log('onSelect=>2',platform)

  const onRemoveFuntion = e => {
    console.log('onRemoveFuntion=>', e);
    if (e === '') {
      setValue({
        ...value,
        platform: '',
      });
    } else {
      setValue({
        ...value,
        platform: e,
      });
    }
  };

  const upload = e => {
    setImageLoading(true);
    console.log('e.target=>', e.target.files[0].name);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('image uri', res.location);
        setS3Image(e.target.files[0].name);
        setImageView(res?.location);
        setImageLoading(false);
      })
      .catch(error => {
        console.log('error', error);
      });
  };
console.log("value",value,"data",optionArray)
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: !changeApi ? 'Create Maintenance' : 'Update Maintenance',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'App Setup',
          useSlash: true,
          path2: !changeApi ? 'Create Maintenance' : 'Update Maintenance',
          path3: 'Edit',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
          <Flex flexDirection={'column'}>
            <Flex marginBottom={'4px'} marginTop={'4px'}>
              <Flex>
                <Text {...styles.redAsterisk}>Platform</Text>
                <Text color={'#ff0000'}>*</Text>
              </Flex>
            </Flex>

            {!changeApi ? (
          <>
          {MultiSelect({
            isMulti: true,
            options: optionArray,
            isClearable: true,
            value: value?.platform,
            onChange: e => onSelectedFuntion(e),
          })}
          </>
            ) : (
              <>
              {MultiSelect({
                isMulti: true,
                options: optionArray,
                isClearable: true,
                value: value?.platform,
                onChange: e => onSelectedFuntion(e),
              })}
              </>
              // <Multiselect
              //   isObject={false}
              //   maxHeight="50px"
              //   singleSelect={false}
              //   options={optionArray}
              //   hidePlaceholder={true}
              //   style={{ ...styles.multiSelect }}
              //   onRemove={e => onRemoveFuntion(e)}
              //   displayValue={value?.platform}
              //   // selectedValues={(e)=>value?.platform ?value?.platform:onSelectedFuntion(e)}
              //   selectedValues={value?.platform}
              //   onSelect={e => onSelectedFuntion(e)}
              // />
            )}
          </Flex>

          {/* {DatewithTimePicker({
            title: 'Start Date',
            mandatory: false,
            selectedDate: startD,
            // onChange: e => handleonChange(e, 'startDate'),
            onChange: e => setStartD(e),
          })}

          {DatewithTimePicker({
            title: 'End Date',
            mandatory: false,
            selectedDate: endD,
            onChange: e => setEndD(e),
          })} */}
          {customDateWithTitle({
            title: 'Start Date',
            mandatory: false,
            selectedDate: startD,
            onChange: e => setStartD(e),
          })}
          {customDateWithTitle({
            title: 'End Date',
            mandatory: false,
            selectedDate: endD,
            onChange: e => setEndD(e),
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customTextarea({
            title: 'Message',
            value: value['message'],
            mandatory: true,
            onChange: e => handleonChange(e, 'message'),
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={3} spacingX={'50px'} marginTop={5}>
          {dropdownCustomSvalue({
            title: 'Status',
            mandatory: true,
            temparray: status,
            placeholder: statusEdit
                  ? statusEdit
                  : 'Select Status',
            onChange: e => handleonChange(e, 'status'),
          })}

          {!imageLoading ? (
            customInputImage({
              title: 'Image',
              mandatory: true,
              type: 'file',
              src: !changeApi ? imageView : process.env.REACT_APP_S3URL + s3image,
              onChange: e => upload(e),
            })
          ) : (
            <Skeleton height="70px" />
          )}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Update',
          title2: 'Cancel',
          isLoading: loading,
          onClickSubmit: () => onClickSubmit(),
          onClickCancel: () => history.goBack(),
        })}
      </Flex>
      <Spacer />
      <Divider {...style.divider} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateMaintenance;

