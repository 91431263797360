import theme from '../../config/color';
import { SmallAddIcon, UpDownIcon } from '@chakra-ui/icons';
import { MdImportExport } from 'react-icons/md';
const style = {
  box: {
    flexDirection: 'column',
    padding: '3%',
  },
  dateComponentStyles: {
    marginX: 2,
    flexDirection: 'column',
    width: '100%',
  },
  rowForSelectors: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  iconDateContainer: {
    width: '100%',
    height: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.customColors.gray[100],
    // alignItems: 'center',
    marginTop: 2,
  },
  optionFlex: {
    padding: 5,
    justifyContent: 'spaceBetween',
    verticalAlign: 'middle',
  },
  cardALContainer: {
    width: '100%',
    // backgroundColor: '#fafdfb',
    flexDirection: 'column',
    padding: '3%',
    // marginLeft:"-30px",
    // marginRight:"-30px",
    // padding: "2%"
  },
  innerCard: {
    flexDirection: 'column',
    background: 'white',
    // boxShadow: 'sm',
    width:"100%"
  },
  datesContainer: {
    // padding: 10,
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  datesContainerWithoutPadding: {
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  downloadButton: {
    backgroundColor: theme.customColors.black[100],
    color: 'white',
    textAlign: 'center',
    border: '1px solid transparent',
    marginBottom: 5,
    borderRadius: '0.25rem',
    verticalAlign: 'middle',
    width: '8%',
    boxShadow: '0 4px 8px #728394',
    align: 'center',
    fontSize: '12',
    _hover:{
      bg:'#777777',
    }
    // size:"sm",
  },
  cancelButton: {
    backgroundColor: theme.customColors.errorColor[100],
    color: 'white',
    marginLeft: 2,
    border: '0px',
  },
  outerBoxOfFilters: {
    width: '100%',
  },
  //
  //
  //
  //
  mainContainer: {
    paddingY: 0,
    marginTop: 3,
    width: '100%',
    // background: "#539194",
    color: theme.customColors.common[100],
  },
  subMenuFlex: {
    paddingX: 16,
    paddingY: 1,
  },
  subTabMenu: {
    noOfLines: 1,
    fontSize: 'md',
    width: '150px',
    marginLeft: 2,
    color: 'white',
    fontWeight: 'semibold',
  },
  mainFlex: {
    flex: 1,
    as: 'nav',
    height: '100vh',
    flexDirection: 'row',
  },
  innerContainer: {
    flex: 2,
    // paddingY: 6,
    marginTop: 1,
    backgroundColor: '#629093',
  },
  tabsContainer: {
    paddingY: 4,
    marginTop: 3,
    width: '100%',
    background: '#539194',
    color: theme.customColors.common[100],
  },
  bottomFlex: {
    flex: 8,
    padding: 2,
    backgroundColor: '#f6f6fa',
  },
  rightreservedlabel: {
    opacity: 10,
    fontSize: 'lg',
    color: 'rgba(255, 255, 255, 0.6)',
    color: theme.customColors.common[0],
  },
  tabContainer: {
    color: 'white',
    paddingX: 8,
    paddingY: 2,
  },
  backgroundColorContianer: {
    marginTop: 1,
  },
  tabText: {
    w: '130px',
    marginLeft: 15,
    fontSize: 'lg',
    paddingRight: 3,
    fontWeight: 'bold',
    color: 'white',
  },
  antChainIcon: {
    fontSize: 'sm',
    fontSize: '18px',
    marginLeft: '-3',
    fontWeight: 'bold',
    color: theme.customColors.common[0],
  },
  iconContainer: {
    width: '110px',
    justifyContent: 'flex-end',
  },
  antchainImage: {
    marginX: '5',
    height: '30px',
    objectFit: 'fix',
  },
  signOutLabel: {
    color: 'white',
    paddingY: 1,
    marginLeft: 1,
    fontSize: 'lg',
    fontWeight: 'bold',
  },
  signoutButton: {
    size: 25,
    marginTop: 1,
  },
  sigoutContainer: {
    marginX: 8,
    marginTop: 6,
    paddingBottom: 50,
    flexDirection: 'row',
  },
  pwLabel: {
    fontSize: 'md',
    color: theme.customColors.common[0],
  },
  logoutTopContainer: {},
  emptyMidContainer: {
    margin: 4,
    borderTopWidth: 2,
  },
  mdContainer1: {
    marginTop: '5',
  },
  mdContainer2: {
    marginY: 2,
    marginRight: 2,
  },
  nav: {
    top: 0,
    as: 'nav',
    zIndex: 1,
    paddingX: 5,
    opacity: 0.9,
    paddingY: 2,

    margin: 'auto',
    bg: '#2A3042',
    wrap: 'wrap',
    width: '100%',
    align: 'center',
    color: 'gray.600',
    overflow: 'hidden',
    justify: 'space-between',
  },
  navUnauthantication: {
    top: 0,
    as: 'nav',
    zIndex: 1,
    paddingX: 5,
    bg: 'black',
    opacity: 0.9,
    paddingY: 5,
    wrap: 'wrap',
    margin: 'auto',
    width: '100%',
    align: 'center',
    color: 'gray.600',
    position: 'sticky',
    overflow: 'hidden',
    justify: 'space-between',
  },
  menuLinks: {
    spacing: 5,
    align: 'center',
  },
  searchInput: {
    paddingx: 5,
    borderRadius: 20,
    backgroundColor: theme.customColors.white[100],
  },
  icon: {
    size: 30,
    color: theme.customColors.white[100],
  },
  logoImage: {
    width: '130px',
    height: '30px',
  },
  logoImageInside: {
    width: '30px',
    height: '35px',
    marginTop: '-5px',
    marginLeft: '2px',
  },
  txtLogo: {
    fontSize: '3xl',
    fontWeight: 'bold',
    color: '#65bcc1',
  },
  logoContainer: {
    flexDirection: 'row',
  },
  switchButtonConatiner: {
    padding: 3,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonInnerCOntainer: {
    paddingY: 3,
    width: '40px',
    height: '40px',
    boxShadow: 'dark-lg',
    borderRadius: '20%',
    backgroundColor: theme.customColors.white[100],
  },
  drwawer: {
    size: 'full',
    placement: 'left',
  },
  logoMidContainer: {
    flexDirection: 'row',
  },

  powerByLabel: {
    paddingX: 6,
    color: theme.customColors.white[100],
    marginLeft: 10,
    fontSize: 'xs',
    color: 'white',
    marginRight: 3,
    fontWeight: '400',
  },
  antchainImage: {
    objectFit: 'contain',
  },
  antChainContainer: {
    paddingLeft: 2,
    fontWeight: 'semibold',
    fontSize: 'lg',
    color: theme.customColors.white[100],
  },
  hamBurgerMenu: {
    color: theme.customColors.white[100],
  },
  logoMainFlex: {
    zIndex: 10,
    paddingRight: 2,
    marginTop: ['0', '1', '2'],
    marginLeft: [-10, -5, 0, 0],
  },
  txtFlex: {
    p: '1',
    marginTop: -1,
    marginStart: 10,
    flexDirection: 'row',
  },
  txtTra: {
    color: '#70c5c4',
    marginTop: '3px',
    fontWeight: 'bold',
    marginRight: '2px',
    fontSize: { base: '24px', md: '24px', lg: '32px' },
  },
  imgX: {
    marginBottom: [4, 2, 2],
    height: ['30px', '20px', '30px', '40px'],
  },
  // -------------App Version List--------
  main: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    boxShadow: 'sm',
  },
  buttoncontainer: {
    maxW: '100%',
    padding: '20px 20px ',
    borderBottom: '1px solid #f9f9f9',
  },
  mainFlex: {
    padding: 5,
    height: '300px',
  },
  searchinput: {
    bgColor: '#fdfdff',
    borderColor: '#dadada',
    height: 'calc(1.8125rem + 2px)',
    padding: '0.25rem 0.5rem',
    fontSize: '.875rem',
    lineHeight: '1.5',
    borderRadius: '0.2rem',
    margin: '5px',
  },
  select: {
    display: 'inlineBlock',
    backgroundColor: '#fdfdff',
    borderColor: '#dadada',
    fontSize: '14px',
    paddingX: '5px',
    width: 'auto',
    color: '#495057',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    icon: (
      <UpDownIcon
        boxSize={'1em'}
        fontSize={'12px'}
        marginLeft={'-30px'}
        w={0}
        h={0}
      />
    ),
    size: 'sm',
  },
  createButtonContainer: {
    marginLeft: 5,
  },
  createbutton: {
    size: 'sm',
    leftIcon: (
      <SmallAddIcon bgColor={'#fff'} borderRadius={'50%'} color={'black'} />
    ),
    color: 'white',
    bgColor: 'black',
    borderRadius: '30px',
    _hover: { 
      bg: '#777777',
      transform: 'translateY(-2px)',
    },
    boxShadow: '0 2px 6px #1d1d1d',
    fontWeight: '600',
    fontsize: '12px',
  },

  downloadbutton: {
    boxShadow: '0 2px 6px #e6ecf1',
    bgColor: '#e3eaef',
    borderColor: '#e3eaef',
    color: '#191d21',
    borderRadius: '15px',
    size: 'sm',
    _hover:{
      transform: 'translateY(-2px)',
    },
  },
  showentries: {
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.customColors.blackAdminColor['100'],
  },
  outerBoxofPagination: {
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // ----------------TableComp-----------
  tableFlex: {
    borderBottom: '1px solid #ddd',
  },
  table: {
    variant: 'unstyled',
  },
  thead: {
    bgColor: 'rgba(0, 0, 0, 0.04)',
    color: '#666',
    paddingTop: '15px',
    paddingBottom: '15px',
    borderBottom: '1px solid #f9f9f9',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    borderBottom: '1px solid #ddd',
    leftIcon: <MdImportExport w={5} h={5} />,
    // icon: <Icon as={SmallAddIcon} w={6} h={6} color={'black'} />
  },
  tr: {
    _hover: { bg: '#ececec' },
  },
  editbtn: {
    size: 'sm',
    variant: 'ghost',
    color: 'black',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  Delbtn: {
    size: 'sm',
    variant: 'ghost',
    color: 'red',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  tablefooter: {
    paddingTop: '0.85em',
    whiteSpace: 'nowrap',
  },
  // -----------------------Pagination------------
  paginatecontainer: {
    justifyContent: 'right',
    borderRadius: '10',
    marginBottom: '10',
    width: '100%',
  },
  // -------------------------luckdrawreport----------
  daterange: {
    border: '1px solid black',
  },
  miniheader: {
    borderRadius: 0,
    boxShadow: 'sm',
    padding: '1%',
    bgColor: '#fff',
    paddingStart: 2,
    borderColor: '#f2f2f2',
    paddingBottom: '2.5%',
    borderBottomWidth: '1px',
  },
};
export default style;
