import React from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { useLocation } from 'react-router-dom';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
const CreateStore = () => {
  let initialValue = {
    storeType: '',
    branch: '',
    address: '',
    contactNumber: '',
    latitude: '',
    longitude: '',
  };
  let mandatoryField = {
    storeType: true,
    branch: true,
    address: true,
    contactNumber: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [success, setsuccess] = React.useState(null);
  const [status, setstatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const storeTypelist = [
    { val: 1, list: 'SELFCARE' },
    { val: 2, list: 'AD/MD' },
  ];
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();

  React.useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val) {
      let data = location?.state?.val;
      setValue({
        ...value,
        // id: data?._id,
        storeType: data?.storeType,
        branch: data?.branch,
        address: data?.fullAddress,
        contactNumber: data?.contact,
        latitude: data?.latitude,
        longitude: data?.longitude,
      });
    }
    console.log('pathdata', location?.state?.val);
  }, [location]);

  console.log('value>>>>', value);

  const handleonChange = (evt, key) => {
    // console.log('event', evt);
    const ev = evt.target.value;
    console.log('event ev', ev);
    setValue({
      ...value,
      [key]: ev,
    });
  };
  console.log('value', value);
  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter All mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter All mandatory fields',
      });
    } else {
      const body = {
        storeType: value?.storeType,
        branch: value?.branch,
        fullAddress: value?.address,
        contact: value?.contactNumber,
        latitude: String(value?.latitude),
        longitude: String(value?.longitude),
      };
      console.log('body>>>>', body);
      apimanager
        .post('addStore', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8505) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
          if (response.message === 7000) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `User Not Found`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const onClickUpdate = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      console.log('value.title', value.title);
      const body = {
        id: location?.state?.val?._id,
        storeType: value?.storeType,
        branch: value?.branch,
        fullAddress: value?.address,
        contact: value?.contactNumber,
        latitude: String(value?.latitude),
        longitude: String(value?.longitude),
      };
      console.log('body>>>>', body);
      apimanager
        .post('updateStore', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8508) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Store',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Store management',
              useSlash: true,
              path2: 'Edit Store Management',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustomSvalue({
                title: 'Store Type',
                mandatory: true,
                placeholder: 'Select Option',
                temparray: storeTypelist,
                onChange: e => handleonChange(e, 'storeType'),
                value: value['storeType'],
              })}
              {customInputLabel({
                title: 'Branch Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'branch'),
                value: value['branch'],
              })}
            </SimpleGrid>

            <SimpleGrid
              columns={1}
              marginTop={5}
              width={'100%'}
              spacingX={'50px'}
              spacingY={'20px'}
            >
              {customTextarea({
                title: 'Full Address',
                mandatory: true,
                onChange: e => handleonChange(e, 'address'),
                value: value['address'],
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Contact Number(s)',
                mandatory: true,
                onChange: e => handleonChange(e, 'contactNumber'),
                // type: 'Number',
                value: value['contactNumber'],
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Latitude',
                mandatory: false,
                onChange: e => handleonChange(e, 'latitude'),
                value: value['latitude'],
              })}
              {customInputLabel({
                title: 'Longitude',
                mandatory: false,
                onChange: e => handleonChange(e, 'longitude'),
                value: value['longitude'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Store',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Store management',
              useSlash: true,
              path2: 'Create Store Management',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustomSvalue({
                title: 'Store Type',
                mandatory: true,
                placeholder: 'Select Option',
                temparray: storeTypelist,
                onChange: e => handleonChange(e, 'storeType'),
              })}
              {customInputLabel({
                title: 'Branch Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'branch'),
              })}
            </SimpleGrid>

            <SimpleGrid
              columns={1}
              marginTop={5}
              width={'100%'}
              spacingX={'50px'}
              spacingY={'20px'}
            >
              {customTextarea({
                title: 'Full Address',
                mandatory: true,
                onChange: e => handleonChange(e, 'address'),
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Contact Number(s)',
                // type:'number',
                mandatory: false,
                onChange: e => handleonChange(e, 'contactNumber'),
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Latitude',
                mandatory: false,
                onChange: e => handleonChange(e, 'latitude'),
              })}
              {customInputLabel({
                title: 'Longitude',
                mandatory: false,
                onChange: e => handleonChange(e, 'longitude'),
              })}
            </SimpleGrid>
            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateStore;
