import React from 'react';
import {
  Flex,
  Text,
  Input,
  SimpleGrid,
  Textarea,
  Spacer,
  Divider,
} from '@chakra-ui/react';
import styles from '../styles/aboutUsStyles';
import theme from '../config/color';

import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';

const CreateAppVersion = () => {
  console.log('CreateAppVersion gg bro');
  return (
    <Flex flexDirection={'column'} padding={2}>
      <Flex width={'100%'} height={'15vh'} background={'red.100'}>
        <Text></Text>
        <Flex {...styles.firstFlexInParent}>
          <Text fontWeight={'bold'} fontSize={'26px'} color={'#34395e'}>
            Create Campaign
          </Text>
          <Text color={'gray.500'} fontSize={'sm'}>
            Dashboard / CreateCampaign
          </Text>
        </Flex>
      </Flex>
      <Flex
        padding={'8'}
        justifyContent={'space-around'}
        height={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({ title: 'First Name', mandatory: true })}
        </SimpleGrid>
        <Flex flexDirection={'column'} width={'100%'} marginTop={'10'}>
          <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Last tac code',
                mandatory: false,
                variant: 'filled',
              })}
              {customInputLabel({
                title: 'OTP count',
                mandatory: false,
                variant: 'filled',
              })}
            </SimpleGrid>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({ title: 'Mobile', mandatory: true })}
            </SimpleGrid>
          </SimpleGrid>
        </Flex>
        <Flex width={'100%'} marginY={10} flexDirection={'column'}>
          <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
            {customInputLabel({
              title: 'Age group',
              mandatory: true,
              variant: 'filled',
            })}
            {/* <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}> */}
            {customInputLabel({
              title: 'Race',
              mandatory: false,
              variant: 'filled',
            })}
            {/* </SimpleGrid> */}
          </SimpleGrid>
          <Flex flexDirection={'row'} marginTop={2}>
            <Text
              // color={theme.customColors.blackAdminColor['defaultFontColor']}
              fontSize="12px"
              marginBottom={'2'}
            >
              Description
            </Text>
            <Text color={'red'} marginLeft={'1'}>
              *
            </Text>
          </Flex>
          <Textarea
            //     value={value}
            //     onChange={handleInputChange}
            size="sm"
            height={'180px'}
          />
          <Flex marginTop={4}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Martial Status',
                mandatory: false,
                type:"file"
              })}
            </SimpleGrid>
          </Flex>

          {customUpdateCancelBtn({
            title1: 'Submit',
            title2: 'Cancel',
          })}
        </Flex>
      </Flex>
      <Divider orientation="horizontal" />

      {renderCopyRight()}
    </Flex>
  );
};
export default CreateAppVersion;
