import React from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  useToast,
  Box,
} from '@chakra-ui/react';
import styles from '../styles/aboutUsStyles';
import theme from '../config/color';

import {
  customSwtich,
  customResetBtn,
  customInputLabel,
  customUpdateCancelBtn,
  customDeactivateResetBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import style from '../components/common/style';
import ApiManager from '../config/apiManager';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const CreateAppVersion = () => {
  console.log('CreateAppVersion gg bro');
  let initialValue = {
    email: '',
    msisdn: '',
    profileId: '',
    lastName: '',
    firstName: '',
    userId: '',
  };
  let mandatoryFields = {
    email: true,
    msisdn: true,
    profileId: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [maped, setMap] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [ssUser, setSsoUser] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [status, setstatus] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const location = useLocation();
  const toast = useToast();

  let dataOfEdit = '';

  React.useEffect(() => {
    if (location?.state?.val != null || location?.state?.val != undefined) {
      dataOfEdit = location?.state?.val;
      // console.log('dataOfEdit', dataOfEdit.val.email);
      console.log('location?.state?.data', location?.state?.val);

      // setValue({
      //   ...value,
      //   email: dataOfEdit?.email,
      //   userId: dataOfEdit?.userId,
      //   msisdn: dataOfEdit?.MSISDN,
      //   profileId: dataOfEdit?.profileId,
      // });
      getUserDetailsForCMSApi(dataOfEdit?.userId);
    }
    console.log('pathdata', location?.state?.val);
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const getUserDetailsForCMSApi = userId => {
    console.log('UserIDD=>', userId);
    setLoading(true);
    let tempArray = [];
    let body = {
      userId: userId,
    };
    apimanager
      .post('getUserDetailsForCMS', body)
      .then(res => {
        console.log('getUserDetailsForCMS=>RES', res);
        if (res.message === 7079) {
          console.log('Get getUserDetailsForCMS', res);
          // pageCount = res.results.totalDocs;
          // setSsoUser(res?.result?.ssoUser);
          // setMiddleUser(res?.result?.middlewareUser);

          if (res?.result?.ssoUser) {
            setSsoUser(res?.result?.ssoUser);
            setValue({
              ...value,
              email: res?.result?.ssoUser?.email,
              // userId: res?.result?.ssoUser?.userId,
              msisdn: res?.result?.ssoUser?.MSISDN,
              profileId: res?.result?.ssoUser?.profileId,
            });
          } else if (res?.result?.result) {
            setSsoUser(res?.result?.result);
            setValue({
              ...value,
              email: res?.result?.result?.email,
              // userId: res?.result?.result?.userId,
              msisdn: res?.result?.result?.MSISDN,
              profileId: res?.result?.result?.profileId,
            });
          }

          // setValue({
          //   ...value,
          //   email: res?.result?.ssoUser?.email,
          //   // userId: res?.result?.ssoUser?.userId,
          //   msisdn: res?.result?.ssoUser?.MSISDN,
          //   profileId: res?.result?.ssoUser?.profileId,
          // });

          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const onClickUpdate = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter mandatory fields',
      });
    } else {
      const body = {
        // firstName: value['firstName'],
        // lastName: value['lastName'],
        email: value['email'],
        MSISDN: value['msisdn'],
        profileId: value['profileId'],
        userId: location.state.val.userId,
      };

      console.log('body>>>>', body);
      apimanager
        .post('updateUserFromCMS', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 4029) {
            setLoading(false);
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [4000]);
          } else if (response.message === 7000) {
            setLoading(false);
            setSuccess('Failed!');
            toast({
              title: success,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to processed! Email or Profile ID Duplication Error`,
            });
          } else {
            setLoading(false);
            setSuccess('Failed!');
            toast({
              title: success,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to processed! ${
                response?.err?.codeName ? response?.err?.codeName : ''
              }`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const onClickDeactivate = () => {
    const body = {
      email: location.state.val.email,
    };
    apimanager
      .post('suspendUser', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 7081) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, User Is successfully Deactivated`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const onClickActivate = () => {
    const body = {
      email: location.state.val.email,
    };
    apimanager
      .post('unSuspendUser', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 7082) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, User Is successfully Activated`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/UpdateUserFromCMS',
      state: { data: editData, useEditApi: true },
    });
  };

  const onClickReset = () => {
    const body = {
      email: location.state.val.email,
    };
    apimanager
      .post('resetPasswordFromAdmin', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 7107) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Reset Password`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
        if (response.message === 7106) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            // isClosable: true,
            description: `Recorded, User Must Be Active`,
          });
          // setTimeout(() => {
          //   history.goBack();
          // });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Edit Black User',
          path: 'Dashboard',
          path1: 'Edit Black user',
          cursor: 'pointer',
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex
          padding={'2%'}
          background={'white'}
          flexDirection={'column'}
          justifyContent={'space-around'}
        >
          <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
            {customInputLabel({
              title: 'First Name',
              mandatory: false,
              disabled: true,
              onChange: e => handleonChange(e, 'firstName'),
              value: value['firstName'],
            })}
            {customInputLabel({
              title: 'Last Name',
              mandatory: false,
              disabled: true,
              onChange: e => handleonChange(e, 'lastName'),
              value: value['lastName'],
            })}
          </SimpleGrid>
          <Flex flexDirection={'column'} width={'100%'} marginTop={'6'}>
            <Flex width={'100%'} flexDirection={'column'} marginBottom={'5'}>
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Email',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'email'),
                  value: value['email'],
                })}
                <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                  {customInputLabel({
                    title: 'User ID',
                    mandatory: false,
                    disabled: true,
                    // onChange: e => handleonChange(e, 'email'),
                    value: location?.state?.val?.userId
                      ? location?.state?.val?.userId
                      : 'Empty',
                  })}
                  {customInputLabel({
                    title: 'Profile ID',
                    mandatory: true,
                    // disabled: true,
                    onChange: e => handleonChange(e, 'profileId'),
                    value: value['profileId'],
                  })}
                </SimpleGrid>
              </SimpleGrid>
            </Flex>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Mobile',
                mandatory: true,
                value: value['msisdn'],
                onChange: e => handleonChange(e, 'msisdn'),
              })}
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Last tac code',
                  mandatory: false,
                  variant: 'filled',
                  onChange: e => handleonChange(e, 'lastTacCode'),
                  value: maped?.otp,
                })}
                {customInputLabel({
                  title: 'OTP count',
                  mandatory: false,
                  variant: 'filled',
                  onChange: e => handleonChange(e, 'ageGroup'),
                  // value: maped?.otpCount,
                  // defaultValue: 'defaultValue',
                })}
              </SimpleGrid>
            </SimpleGrid>
          </Flex>
          <Flex width={'100%'} marginY={1} flexDirection={'column'}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Age group',
                mandatory: false,
                variant: 'filled',
                onChange: e => handleonChange(e, 'ageGroup'),
                value: location?.state?.val?.ageGroup,
              })}
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Gender',
                  mandatory: false,
                  variant: 'filled',
                  onChange: e => handleonChange(e, 'ageGroup'),
                })}
                {customInputLabel({
                  title: 'Race',
                  mandatory: false,
                  variant: 'filled',
                  onChange: e => handleonChange(e, 'race'),
                })}
              </SimpleGrid>
            </SimpleGrid>
            <Flex marginTop={4}>
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Martial Status',
                  mandatory: false,
                  variant: 'filled',
                  onChange: e => handleonChange(e, 'martialStatus'),
                })}
                {customInputLabel({
                  title: 'Education level',
                  mandatory: false,
                  variant: 'filled',
                  onChange: e => handleonChange(e, 'educationLevel'),
                })}
              </SimpleGrid>
            </Flex>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
        </Flex>
        <Flex
          padding={'2%'}
          background={'white'}
          flexDirection={'column'}
          marginTop={'10'}
        >
          <Text fontSize={'lg'} marginBottom={2}>
            {/* Operation */}
          </Text>
          <Divider marginStart={10} orientation="horizontal" />
          <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
            <Flex alignItems={'center'} textAlign="center">
              {/* <Text>Status :</Text>
              {location?.state?.val?.resetPassword === true ? (
                <Text fontSize={'sm'}>
                  {location?.state?.val?.isSuspended === false ? (
                    <Text
                      fontSize={'lg'}
                      color={'green'}
                      marginTop={1}
                      marginLeft={1}
                      fontWeight="bold"
                    >
                      Active
                    </Text>
                  ) : (
                    <Text
                      fontSize={'lg'}
                      fontWeight="bold"
                      marginTop={1}
                      marginLeft={1}
                      color={'darkgray'}

                    >
                      InActive
                    </Text>
                  )}
                </Text>
              ) : location?.state?.val?.resetPassword === false ? (
                <Text fontSize={'sm'}>
                  <Text>Reset Password</Text>
                </Text>
              ) : (
                <Text fontSize={'sm'}>
                  {location?.state?.val?.isSuspended === false ? (
                    <Text
                      fontSize={'lg'}
                      fontWeight="bold"
                      marginLeft={1}
                      marginTop={1}
                      color={'green'}
                    >
                      Active
                    </Text>
                  ) : (
                    <Text
                      fontSize={'lg'}
                      fontWeight="bold"
                      marginLeft={1}
                      color={'red'}
                      marginTop={1}
                    >
                      InActive
                    </Text>
                  )}
                </Text>
              )} */}
            </Flex>
            {location?.state?.val?.resetPassword === false ? (
              <Box>
                {customResetBtn({
                  title: 'Update Password',
                  onClickReset: () =>
                    navigateToEditPage(location?.state?.val?.userId),
                })}
              </Box>
            ) : (
              <Box>
                {location?.state?.val?.isSuspended === false ? (
                  <Box>
                    {customDeactivateResetBtn({
                      title1: 'DeActivate',
                      title2: 'Update Password',
                      onClickSubmit: () => onClickDeactivate(),
                      onClickReset: () =>
                        navigateToEditPage(location?.state?.val?.userId),
                    })}
                  </Box>
                ) : (
                  <Box>
                    {customDeactivateResetBtn({
                      title1: 'Activate',
                      title2: 'Update Password',
                      isDisabled: true,
                      onClickSubmit: () => onClickActivate(),
                      onClickReset: () =>
                        navigateToEditPage(location?.state?.val?.userId),
                    })}
                  </Box>
                )}
              </Box>
            )}
          </SimpleGrid>
        </Flex>
      </Flex>

      <Divider marginStart={10} orientation="horizontal" />

      {renderCopyRight()}
    </Flex>
  );
};
export default CreateAppVersion;
