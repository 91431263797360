import React from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import { useHistory } from 'react-router-dom';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';
import style from '../components/common/style';
const CreateMobileBanner = () => {
  console.log('CreateMobileBanner gg bro');
  let history = useHistory();
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
        <Flex 
      {...style.miniheader}
      marginBottom={'2%'}
      >
      {MiniHeader({
        title: 'Create Mobile Banner',
        path: 'Dashboard',
        onclickForRoute: () => history.push('/Dashboard'),
        path1:'Create Mobile Banner',
        useSlash:true,
        path2:'Create',
      })}
      </Flex>
      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({ title: 'Name', mandatory: true })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={2}>
          {customInputLabel({ title: 'URL', mandatory: true })}
          {dropdownCustom({ title: 'Mobile Banners Status', mandatory: true })}
          {dropdownCustom({ title: 'Mobile Deeplink', mandatory: false })}
        </SimpleGrid>

        {customTextarea({ title: 'Description', mandatory: true })}

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={2}>
          {customInputLabel({ title: 'Start Date', mandatory: true })}
          {customInputLabel({ title: 'End Date', mandatory: true })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          {customInputLabel({
            title: 'Image',
            mandatory: true,
            type: 'file',
          })}
        </SimpleGrid>

        {/* <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Company Logo',
            mandatory: true,
            type: 'file',
          })}
          <Text
            fontSize={'xs'}
            color={theme.customColors.blackAdminColor['defaultFontColor']}
          >
            Minimum Height = 500px, Minimum Width = 500px, Ratio = 1 / Square
          </Text>
        </SimpleGrid> */}

      
        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateMobileBanner;
