import React, { Suspense, lazy, useState } from 'react';
import '../App.css';
// import SlideBar from '../components/common/sidebar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Spinner } from '../components/Spinner';
import { Flex } from '@chakra-ui/react';
import Header from '../components/common/header';
import { useLocation } from 'react-router-dom';
import SideMenu from '../components/menuComponent/SideMenu';
import CustomMenuItems from '../components/menuComponent/CustomSideMenu';
import { menuItems, CustomMenuItemsList } from './Routes';
// import allActions from '../redux/actions/allActions';
import EditAboutUs from '../pages/EditAboutUs';
import CreateAppVersion from '../pages/CreateAppVersion';
import YetToBeImplemented from '../navigation/YetToBeImplemented';
import CreateCampaign from '../pages/CreateCompaign';
import CreatePage from '../pages/CreatePage';
import CreateBanner from '../pages/createBanner';
import CreateBlackUser from '../pages/CreateBlackUser';
import CreateQuotes from '../pages/createQuotes';
import CreateLuckDrawReward from '../pages/CreateLuckDrawReward';
import CreateAskQuestion from '../pages/CreateAskQuestion';
import CreateFaqAskQuestion from '../pages/CreateFaqAskQuestion';
import CreateGame from '../pages/CreateGame';
import CreateGameReward from '../pages/CreateGameReward';
import CreateRewardItem from '../pages/CreateRewardItem';
import CreateRewardItemType from '../pages/CreateRewardItemType';
import CreateRewardTypes from '../pages/CreateRewardTypes';
import CreateInboxMessage from '../pages/CreateInboxMessage';
import CreateLoyaltyPoint from '../pages/CreateLoyaltyPoint';
import CreateMobileBanner from '../pages/CreateMobileBanner';
import CreateSpecialDeal from '../pages/CreateSpecialDeal';
import CreatePrivacyPolicies from '../pages/CreatePrivatePolicies';
import CreateProduct from '../pages/CreateProduct';
import CreatePromoCode from '../pages/CreatePromoCode';
import CreatePromoAndNews from '../pages/CreatePromotion&News';
import CreatePhysicalRedemptionForm from '../pages/CreatePhysicalRedemptionForm';
import CreateStore from '../pages/CreateStore';
import CreateSmsMasterLibrary from '../pages/CreateSmsMasterLibrary';
import CreateSoftpinCategory from '../pages/CreateSoftpinCategory';
import CreateSoftpin from '../pages/CreateSoftpin';
import CreateSoftpinImport from '../pages/CreateSoftpinImport';
import CreateTermAndConditions from '../pages/CreateTermAndCondition';
import CreateUpsellCompaign from '../pages/CreateUpsellCompaign';
import CreateBannerType from '../pages/CreateBannerTypes';
import CreateCampaignRewards from '../pages/CreateCampaignRewards';
import CreateCity from '../pages/CreateCity';
import CreateCountry from '../pages/CreateCountry';
import CreateFaqCategory from '../pages/CreateFaqCategory';
import CreateGameSettings from '../pages/CreateGameSettings';
import CreateHappyHourBackground from '../pages/CreateHappyHourBackgroud';
import CreateMaintenance from '../pages/CreateMaintenance';
import CreatePrivacyPolicyCategory from '../pages/CreatePrivacyPolicyCategory';
import CreatePromotionAndNewsCategory from '../pages/CreatePromotionAndNewsCategory';
import CreateProductCategory from '../pages/CreateProductCategory';
import CreateSmsMasterLibraryCategory from '../pages/CreateSmsMasterLibraryCategory';
import CreateState from '../pages/CreateState';
import CreateBannerStatus from '../pages/CreateBannerStatus';
import CreateStatusForPromotionAndNews from '../pages/CreateStatusForPandN';
import CreateTermAndConditionsCategory from '../pages/CreateTermAndConditionCategory';
import CreateAdmin from '../pages/CreateAdmin';
import CreateRole from '../pages/CreateRole';
import CreateIpay88Credential from '../pages/CreateIpay88Credential';
import CreateOauthXox from '../pages/CreateOauthXox';
import CreateMiddlewareAuthentication from '../pages/CreateMiddlewareAuth';
import CreatePermission from '../pages/CreatePermission';
// import Dashboard from '../pages/Dashboard';
import CreateFrequentlyAskedQuestion from '../pages/CreateFrequentlyAskedQuestions';
import CreateBlackAppPage from '../pages/CreateBlackAppPage';
import { ViewBlackUserDetail } from '../pages/ViewBlackUserDetail';
import { AddBulk } from '../pages/AddBulk';
import IAM from '../pages/IAM';
import SsoMiddlewareInfo from '../pages/SsoMiddlewareInfo';
import Actions from '../pages/Actions';
import CreateActions from '../pages/CreateActions';
import ChangePassword from '../pages/ChangePassword';
import UpdateRole from '../pages/UpdateRole';
import UpdateUserFromCMS from '../pages/UpdateCmsUserPassword';
import CreatePaymentGateway from '../pages/CreatePaymentGateway';
import AddMerchantCode from '../pages/AddMerchantCode';
import PaymentGatewayViewActions from '../pages/PaymentGatewayViewActions';
import AddProductRecomendation from '../pages/AddProductRecomendation';
import TnCUnauth from '../pages/TnCUnauth';
import PrivacyPoliciesUnauth from '../pages/PrivacyPoliciesUnauth';
import FaqUnauth from '../pages/FaqUnauth';
import CheckingRawHTML from '../pages/CheckingRawHTML';
import AssignMerchantCodesToActions from '../pages/AssignMerchantCodesToAction';
import MerchantActions from '../pages/MerchantActions';
// import AccessDenied from '../pages/AccessDenied';
import NotAllowed from '../components/common/NotAllowed';

// const StateContext = createContext({
//   authenticated: false,
//   user: null,
//   loading: true,
//   fcmToken: '',
// });

const Dashboard = lazy(() => import('../pages/Dashboard'));

const App = () => {
  // const apiManager = ApiManager.getInstance();
  const [inactive, setInactive] = useState(false);
  const [hiding, setHide] = useState(true);

  return (
    <>
      <Flex className="App" flexDirection={'column'}>
        <Router>
          {hiding ? (
            <Header
              hideSidebar={hide => {
                console.log('hideSidebar', hide);
                setHide(hide);
              }}
            ></Header>
          ) : null}

          <Switch>
            <Suspense fallback={<Spinner />}>
              <SideMenu
                stylesDisplay={!hiding ? 'none' : ''}
                onCollapse={inactive => {
                  console.log(inactive);
                  setInactive(inactive);
                }}
              />
              <div className={`container ${inactive ? 'inactive' : ''}`}>
                <Route path={'/'} exact>
                  <Dashboard />
                </Route>
                <Route path="/editAboutUs" exact>
                  <EditAboutUs />
                </Route>
                <Route path="/CreateAppVersion" exact>
                  <CreateAppVersion />
                </Route>
                <Route path="/ViewBlackUserDetail" exact>
                  <ViewBlackUserDetail />
                </Route>
                <Route path="/CreateBlackAppPage" exact>
                  <CreateBlackAppPage />
                </Route>
                <Route path="/CreateBanner" exact>
                  <CreateBanner />
                </Route>
                <Route path="/CreateBlackUser" exact>
                  <CreateBlackUser />
                </Route>
                <Route path="/CreateCampaign" exact>
                  <CreateCampaign />
                </Route>
                <Route path="/CreateQuotes" exact>
                  <CreateQuotes />
                </Route>
                <Route path="/CreateLuckDrawReward" exact>
                  <CreateLuckDrawReward />
                </Route>
                <Route path="/create/campaign" exact>
                  <CreateAskQuestion />
                </Route>
                <Route path="/CreateFaqAskQuestion" exact>
                  <CreateFaqAskQuestion />
                </Route>
                <Route path="/CreateGame" exact>
                  <CreateGame />
                </Route>
                <Route path="/CreateGameReward" exact>
                  <CreateGameReward />
                </Route>
                <Route path="/CreateRewardItem" exact>
                  <CreateRewardItem />
                </Route>
                <Route path={'/CreateRewardItemType'} exact>
                  <CreateRewardItemType />
                </Route>
                <Route path={'/CreateRewardTypes'} exact>
                  <CreateRewardTypes />
                </Route>
                <Route path={'/CreateInboxMessage'} exact>
                  <CreateInboxMessage />
                </Route>
                <Route path={'/CreateLoyaltyPoint'} exact>
                  <CreateLoyaltyPoint />
                </Route>
                <Route path={'/CreateMobileBanner'} exact>
                  <CreateMobileBanner />
                </Route>
                <Route path={'/CreateSpecialDeal'} exact>
                  <CreateSpecialDeal />
                </Route>
                <Route path={'/CreatePrivacyPolicies'} exact>
                  <CreatePrivacyPolicies />
                </Route>
                <Route path={'/CreateProduct'} exact>
                  <CreateProduct />
                </Route>
                <Route path={'/CreatePromoCode'} exact>
                  <CreatePromoCode />
                </Route>
                <Route path={'/CreatePromoAndNews'} exact>
                  <CreatePromoAndNews />
                </Route>
                <Route path={'/CreatePhysicalRedemptionForm'} exact>
                  <CreatePhysicalRedemptionForm />
                </Route>
                <Route path={'/CreateStore'} exact>
                  <CreateStore />
                </Route>
                <Route path={'/CreatePage'} exact>
                  <CreatePage />
                </Route>
                <Route path={'/AddBulk'} exact>
                  <AddBulk />
                </Route>
                <Route path={'/CreateSmsMasterLibrary'} exact>
                  <CreateSmsMasterLibrary />
                </Route>
                <Route path={'/CreateSoftpinCategory'} exact>
                  <CreateSoftpinCategory />
                </Route>
                <Route path={'/CreateSoftpin'} exact>
                  <CreateSoftpin />
                </Route>
                <Route path={'/CreateSoftpinImport'} exact>
                  <CreateSoftpinImport />
                </Route>
                <Route path={'/CreateTermAndConditions'} exact>
                  <CreateTermAndConditions />
                </Route>
                <Route path={'/CreateUpsellCompaign'} exact>
                  <CreateUpsellCompaign />
                </Route>
                <Route path={'/CreateBannerType'} exact>
                  <CreateBannerType />
                </Route>
                <Route path={'/CreateCampaignRewards'} exact>
                  <CreateCampaignRewards />
                </Route>
                <Route path={'/CreateCity'} exact>
                  <CreateCity />
                </Route>
                <Route path={'/CreateCountry'} exact>
                  <CreateCountry />
                </Route>
                <Route path={'/CreateFaqCategory'} exact>
                  <CreateFaqCategory />
                </Route>
                <Route path={'/CreateGameSettings'} exact>
                  <CreateGameSettings />
                </Route>
                <Route path={'/CreateHappyHourBackground'} exact>
                  <CreateHappyHourBackground />
                </Route>
                <Route path={'/CreateMaintenance'} exact>
                  <CreateMaintenance />
                </Route>
                <Route path={'/CreatePrivacyPolicyCategory'} exact>
                  <CreatePrivacyPolicyCategory />
                </Route>
                <Route path={'/CreateProductCategory'} exact>
                  <CreateProductCategory />
                </Route>{' '}
                <Route path={'/CreatePromotionAndNewsCategory'} exact>
                  <CreatePromotionAndNewsCategory />
                </Route>
                <Route path={'/CreateSmsMasterLibraryCategory'} exact>
                  <CreateSmsMasterLibraryCategory />
                </Route>
                <Route path={'/CreateState'} exact>
                  <CreateState />
                </Route>
                <Route path={'/CreateBannerStatus'} exact>
                  <CreateBannerStatus />
                </Route>
                <Route path={'/CreateStatusForPromotionAndNews'} exact>
                  <CreateStatusForPromotionAndNews />
                </Route>
                <Route path={'/CreateTermAndConditionsCategory'} exact>
                  <CreateTermAndConditionsCategory />
                </Route>
                <Route path={'/CreateAdmin'} exact>
                  <CreateAdmin />
                </Route>
                <Route path={'/CreateRole'} exact>
                  <CreateRole />
                </Route>
                <Route path={'/CreateIpay88Credential'} exact>
                  <CreateIpay88Credential />
                </Route>
                <Route path={'/CreateOauthXox'} exact>
                  <CreateOauthXox />
                </Route>
                <Route path={'/CreateMiddlewareAuthentication'} exact>
                  <CreateMiddlewareAuthentication />
                </Route>
                <Route path={'/CreatePermission'} exact>
                  <CreatePermission />
                </Route>
                <Route path={'/CreateFrequentlyAskedQuestion'} exact>
                  <CreateFrequentlyAskedQuestion />
                </Route>
                <Route path={'/YetToBeImplemented'} exact>
                  <YetToBeImplemented />
                </Route>
                <Route path={'/IAM'} exact>
                  <IAM />
                </Route>
                <Route path={'/SsoMiddlewareInfo'} exact>
                  <SsoMiddlewareInfo />
                </Route>
                <Route path={'/Actions'} exact>
                  <Actions />
                </Route>
                <Route path={'/CreateActions'} exact>
                  <CreateActions />
                </Route>
                <Route path={'/ChangePassword'} exact>
                  <ChangePassword />
                </Route>
                <Route path={'/UpdateRole'} exact>
                  <UpdateRole />
                </Route>
                <Route path={'/UpdateUserFromCMS'} exact>
                  <UpdateUserFromCMS />
                </Route>
                <Route path={'/CreatePaymentGateway'} exact>
                  <CreatePaymentGateway />
                </Route>
                <Route path={'/addMerchantCode'} exact>
                  <AddMerchantCode />
                </Route>
                <Route path={'/PaymentGatewayViewActions'} exact>
                  <PaymentGatewayViewActions />
                </Route>
                <Route path={'/AddProductRecomendation'} exact>
                  <AddProductRecomendation />
                </Route>
                <Route path={'/CheckingRawHTML'} exact>
                  <CheckingRawHTML />
                </Route>
                <Route path="/TnCUnauth">
                  <TnCUnauth />
                </Route>
                <Route path="/PrivacyPoliciesUnauth">
                  <PrivacyPoliciesUnauth />
                </Route>
                <Route path="/FaqUnauth">
                  <FaqUnauth />
                </Route>
                <Route path="/AssignMerchantCodesToActions">
                  <AssignMerchantCodesToActions />
                </Route>
                <Route path="/MerchantActions">
                  <MerchantActions />
                </Route>
                <Route path="/AccessDenied">
                  <NotAllowed />
                </Route>
                {/* <Route path="/NotAllowed">
                  <AccessDenied />
                </Route> */}
                {menuItems.map((menu, index) => (
                  <>
                    <Route key={menu.name} exact={menu.exact} path={menu.to}>
                      {menu.component}
                    </Route>

                    {menu.subMenus && menu.subMenus.length > 0
                      ? menu.subMenus.map((subMenu, i) => (
                          <Route key={subMenu.name} path={subMenu.to}>
                            <h1>{subMenu.component}</h1>
                          </Route>
                        ))
                      : null}
                  </>
                ))}
              </div>
            </Suspense>
          </Switch>
        </Router>
      </Flex>
    </>
  );
};

const SecondApp = () => {
  const [inactive, setInactive] = useState(false);
  const [hiding, setHide] = useState(true);
  return (
    <Flex className="App" flexDirection={'column'} style={{ marginTop: '-30' }}>
      <Router>
        {/* {!hiding ? ( */}
        <Header
          hideSidebar={hide => {
            console.log('hideSidebar', hide);
            setHide(hide);
          }}
        ></Header>
        {/* ) : null} */}
        <Switch>
          <Suspense fallback={<Spinner />}>
            <CustomMenuItems
              // stylesDisplay={!hiding ? 'none' : ''}
              onCollapse={inactive => {
                console.log(inactive);
                setInactive(inactive);
              }}
            />
            <div className={`container ${inactive ? 'inactive' : ''}`}>
              <Route path={'/'} exact>
                <IAM />
              </Route>
              <Route path={'/IAM'} exact>
                <IAM />
              </Route>
              {CustomMenuItemsList.map((menu, index) => (
                <>
                  <Route key={menu.name} exact={menu.exact} path={menu.to}>
                    {menu.component}
                  </Route>

                  {/* {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route key={subMenu.name} path={subMenu.to}>
                          <h1>{subMenu.component}</h1>
                        </Route>
                      ))
                    : null} */}
                </>
              ))}
            </div>
          </Suspense>
        </Switch>
      </Router>
    </Flex>
  );
};

const FinalNavigateRoute = () => {
  let location = useLocation();
  console.log('location=>', location);
  const [hiding, setHide] = useState(true);
  const [inactive, setInactive] = useState(false);

  return (
    <Router>
      <Switch>
        <Route path="/:id/MainNavigation">
          <App />
        </Route>
        <Route path="/:id/SubMainNavigation">
          <SecondApp />
        </Route>
      </Switch>
    </Router>
  );
};

export { App, SecondApp, FinalNavigateRoute };
