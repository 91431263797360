import React from 'react';
import {
  Flex,
  useToast,
  Tr,
  Td,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo'
import { useHistory } from 'react-router-dom';
// import { options } from '../context/data';
import {
  colMerchantActions,
} from '../context/data';
// import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
import { useLocation } from 'react-router-dom';

const MerchantActions = () => {
let initialValue = {
  id:'',
  gatewayId:'',
  gatewayName:'',
  merchantCode:'',
}
const location = useLocation();
  const [dataa, setDataa] = React.useState([]);
  const [state, setstate] = React.useState(false);
  // const { currentLangData } = React.useContext(LangContext);
  const [valueSearch, setValueSearch] = React.useState('');
  const [value, setValue] = React.useState(initialValue);
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const toast = useToast();
  let dataOfEdit = '';
  let tempArray = [];

  React.useEffect(() => {
    if (location.status === 'create') {
      setstate(true);
    }
    if (location?.state?.val != null || location?.state?.val != undefined) {
      dataOfEdit = location?.state?.val;
      console.log('dataOfEdit', dataOfEdit);
      setValue({
        ...value,
        id: dataOfEdit?._id,
        gatewayId:dataOfEdit?.gatewayId,
        gatewayName: dataOfEdit?.gatewayName,
        merchantCode: dataOfEdit?.merchantCode,
      });
    }
    getALLItems(dataOfEdit?.gatewayId,dataOfEdit?.merchantCode);
  },[location])

  // React.useEffect(() => {
  //   getALLItems();
  // }, [value]);

  const getALLItems = (gatewayId,merchantCode) => {
    setLoader(false);
    console.log('getALLItems=>', value);
    const body = {
      gatewayId: String(gatewayId),
      merchantCode: merchantCode,
    };
    console.log('body=',body)
    apimanager
      .post('getGatewayActions',body)
      .then(res => {
        console.log('getGatewayActions==', res);
        if (res.message === 11006) {
          res?.actions?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.records);
          setLoader(true);
        }
        if (res.message === 8499) {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed.Response Not saved',
          });
        }
        if (res.message === 7000) {
          toast({
            title: 'User Not Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `User Not Found`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
 
  const convertingDate = date => {
    let newDate = new Date(date);
    return newDate.toLocaleString();
  };

  // const searchItems = (value, limit, page) => {
  //   setLoader(false);
  //   const body = {
  //     searchPhrase: value,
  //     numRecords: limit,
  //     page: page,
  //   };
  //   apimanager
  //     .post('searchStore', body)
  //     .then(res => {
  //       if (res.message === 8512) {
  //         res?.record?.docs.map((value, index) => {
  //           tempArray.push(value);
  //         });
  //         setDataa(tempArray);
  //         setpaginate(res?.record);
  //         setLoader(true);
  //       }
  //     })
  //     .catch(error => {
  //       setLoader(true);
  //     });
  // };
  return (
    <Flex flexDirection={'column'}>
    <Flex {...style.miniheader}>
      {MiniHeader({
        title: 'Merchant Actions',
        path: 'Dashboard',
        onclickForRoute: () => history.push('/Dashboard'),
        path2:'Merchants Actions',
      })}
    </Flex>
    <Flex {...style.box} background={'#fafdfb'} padding={10}>
      <Flex {...style.main} paddingY={10}>
        <ChecktableTwo
          datalist={dataa}
          TotDocs={paginat}
          loaderr={loader}
          removePagination={true}
          headerNames={colMerchantActions}
          renderItem={({ item, index }) => {
            // console.log('RenderItem=',item)
            return (
              <Tr key={index} width={'100%'}>
                <Td>{convertingDate(item?.createdAt)}</Td>
                <Td>{item?.actionName}</Td>
                <Td>{item?.gatewayName}</Td>
                <Td>{item?.merchantCode}</Td>
                <Td>{item?.merchantKey}</Td>
              </Tr>
            );
          }}
        />
      </Flex>
    </Flex>
  </Flex>
  )
}

export default MerchantActions