import React from 'react';
import {
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Text,
  Table,
  Tbody,
  Thead,
  Divider,
  Heading,
  useToast,
  SimpleGrid,
  TableContainer,
} from '@chakra-ui/react';
import {
  customResetBtn,
  customBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import '../App.css';
import { BsCheckCircle } from 'react-icons/bs';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import Loading from '../components/common/Loading';
import { useLocation, useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';

const IAM = () => {
  let initialValue = {
    email: '',
    userId: '',
    name: '',
    country: '',
    profileId: '',
    address: '',
    MSISDN: '',
    ageGroup: '',
    isXoxUser: '',
    ssoMigrated: '',
    emailVerified: '',
    maritalStatus: '',
  };
  let apiManager = ApiManager.getInstance();
  const [error, setError] = React.useState(null);
  const [ssUser, setSsoUser] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [value, setValue] = React.useState(initialValue);
  const [middlewareUser, setMiddleUser] = React.useState([]);
  const [loadingApiCall, setLoadingApiCall] = React.useState(false);

  let location = useLocation();
  let history = useHistory();
  let toast = useToast();

  React.useEffect(() => {
    if (location.status === 'create') {
    }
    if (location.state?.val) {
      let data = location?.state?.val;
      setValue({
        ...value,
        email: data?.email,
        userId: data?.userId,
        country: data?.country,
        profileId: data?.profileId,
        address: data?.address,
        MSISDN: data?.MSISDN,
        ageGroup: data?.ageGroup,
        isXoxUser: data?.isXoxUser,
        maritalStatus: data?.maritalStatus,
        emailVerified: data?.emailVerified,
        ssoMigrated: data?.ssoMigrated,
        name: data?.firstName + ' ' + data?.lastName,
      });
      getUserDetailsForCMSApi(data?.userId);
      console.log('data?.userId', data?.userId);
    }
    console.log('pathdata', location?.state?.val);
  }, [location]);

  console.log('UserDetailValue>>', value);

  const getUserDetailsForCMSApi = userId => {
    console.log('UserIDD=>', userId);
    setLoadingApiCall(true);
    let tempArray = [];
    let body = {
      userId: userId,
    };
    apiManager
      .post('getUserDetailsForCMS', body)
      .then(res => {
        console.log('getUserDetailsForCMS=>RES', res);
        if (res.message === 7079) {
          console.log('Get getUserDetailsForCMS', res);
          // pageCount = res.results.totalDocs;
          setSsoUser(res?.result?.ssoUser);
          setMiddleUser(res?.result?.middlewareUser);

          if (res?.result?.ssoUser) {
            setSsoUser(res?.result?.ssoUser);
          } else if (res?.result?.result) {
            setSsoUser(res?.result?.result);
          }

          setLoadingApiCall(false);
        }
      })
      .catch(error => {
        setLoadingApiCall(false);
        console.log('error', error);
      });
  };

  const onClickEmailVerification = () => {
    setLoadingApiCall(true);
    const body = {
      userId: value?.userId,
    };
    apiManager
      .post('verifyUserEmail', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 4030) {
          setLoadingApiCall(false);

          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            duration: 3000,
            isClosable: true,
            status: 'success',
            description: `Recorded, User Is successfully Varified`,
          });
        }
        getUserDetailsForCMSApi(value?.userId);
      })
      .catch(error => {
        console.log(error);
        setLoadingApiCall(true);
      });
  };

  const noName = tempName => {
    console.log('tempName', tempName);
    if (
      tempName === 'undefined undefined' ||
      tempName === '' ||
      tempName === ' '
    ) {
      return 'No-Name';
    } else {
      return tempName;
    }
  };

  const goToBackRefreshPage = () => {
    history.push('/');
  };

  const deleteRecord = data => {
    setLoading(true);

    console.log('deleteRecord', data);
    // deleteS3(data.companyLogo);
    let body = {
      userId: data,
    };
    apiManager
      .delete('deleteUserFromCMS', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 4031) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
          // getUserDetailsForCMSApi(value?.userId);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          // getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
        setError('Something went wrong');
        toast({
          title: error,
          duration: 2000,
          status: 'error',
          // isClosable: true,
          description: `Failed! Please try agian later!`,
        });
      });
  };

  const deleteFromSelfcareRecord = data => {
    setLoading(true);

    console.log('deleteFromSelfcareRecord', data);
    // deleteS3(data.companyLogo);
    let body = {
      profileId: data,
    };
    apiManager
      .post('deleteSelfcareProfile', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 2004) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted Selfcare Profile!`,
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
          // getUserDetailsForCMSApi(value?.userId);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Deleted Selfcare Profile!`,
          });
          // getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'Inactive';
    } else if (value === 1) {
      return 'Active';
    }
  };

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'SSO & Middleware Detail',
          path: 'Dashboard',
          path1: 'View SSO & Middleware Detail',
        })}
      </Flex>

      <Flex {...style.box}>
        {!loadingApiCall ? (
          <Flex
            flexDirection={'row'}
            justifyContent={'space-around'}
            background={'white'}
          >
            <Flex
              padding={'2%'}
              marginRight={'5%'}
              background={'white'}
              flexDirection={'column'}
              width={'50%'}
              justifyContent={'space-around'}
            >
              <Heading
                textAlign={'center'}
                marginY={'5%'}
                className={'headingMiddleware'}
              >
                SSO
              </Heading>

              <TableContainer>
                <Table size="sm">
                  <Thead marginTop={10}>
                    <Tr>
                      <Th fontSize={'lg'}>Title</Th>
                      <Th fontSize={'lg'}>Detail</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td fontWeight={'bold'}>
                        {noName(ssUser?.firstName + ' ' + ssUser?.lastName)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Email</Td>
                      <Td fontWeight={'bold'}>{ssUser?.email}</Td>
                    </Tr>
                    <Tr>
                      <Td>MSISDN</Td>
                      <Td fontWeight={'bold'}>{ssUser?.MSISDN}</Td>
                    </Tr>
                    <Tr>
                      <Td>Profile Id</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.profileId ? ssUser?.profileId : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Country</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.country ? ssUser?.country : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Address</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.address ? ssUser?.address : '--'}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Is Xox User</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.isXoxUser === false ? (
                          <Text>No</Text>
                        ) : ssUser?.isXoxUser === true ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>User ID</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.userId ? ssUser?.userId : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>SSO Migrated</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.ssoMigrated === false ? (
                          <Text>No</Text>
                        ) : ssUser?.ssoMigrated === true ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td fontWeight={'bold'}>
                        {changeStatus(ssUser?.status)}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>

            <Flex
              padding={'2%'}
              marginLeft={'5%'}
              width={'50%'}
              background={'white'}
              flexDirection={'column'}
              justifyContent={'space-around'}
            >
              <Heading
                textAlign={'center'}
                marginY={'5%'}
                className="headingMiddleware"
              >
                Middleware
              </Heading>
              <TableContainer>
                <Table
                  size="sm"
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Thead>
                    <Tr>
                      <Th>Title</Th>
                      <Th>Detail</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td fontWeight={'bold'}>
                        {noName(middlewareUser?.full_name)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Email</Td>
                      <Td fontWeight={'bold'}>{middlewareUser?.email}</Td>
                    </Tr>
                    <Tr>
                      <Td>MSISDN</Td>
                      <Td fontWeight={'bold'}>{middlewareUser?.msisdn}</Td>
                    </Tr>
                    <Tr>
                      <Td>Profile Id</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.profileId
                          ? middlewareUser?.profileId
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Name & black number</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.full_name_with_black_number
                          ? middlewareUser?.full_name_with_black_number
                          : '--'}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Has Pin</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.has_pin?.toString()
                          ? middlewareUser?.has_pin?.toString()
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Address</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.address
                          ? middlewareUser?.address
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>User ID</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.user_id
                          ? middlewareUser?.user_id
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Is Xox User</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.is_xox_user === 0 ? (
                          <Text>No</Text>
                        ) : middlewareUser?.is_xox_user === 1 ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Status</Td>
                      <Td fontWeight={'bold'}>
                        {changeStatus(middlewareUser?.status)
                          ? changeStatus(middlewareUser?.status)
                          : '--'}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
          </Flex>
        ) : (
          // <Tr>
          //   <Td></Td>
          //   <Td></Td>
          //   <Td paddingY={'15vh'}>
          <Flex
            style={{
              w: '100%',
              height: '50vh',
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loading />
          </Flex>
          //   </Td>
          //   <Td></Td>
          //   <Td></Td>
          // </Tr>
        )}

        <Flex
          padding={'2%'}
          marginTop={'10'}
          background={'white'}
          flexDirection={'column'}
        >
          <Text fontSize={'lg'} marginBottom={2}>
            Operation
          </Text>
          <Divider orientation="horizontal" />
          <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
            <Flex alignItems={'center'} paddingY={3}>
              <Text
                //   fontWeight={'black'}
                fontSize={'small'}
                color={'black'}
              >
                Email Verification :{' '}
              </Text>
              {ssUser?.emailVerified === 0 ? (
                <>
                  <Text
                    fontWeight={'bold'}
                    fontSize={'xlg'}
                    color={'green'}
                    marginLeft={2}
                  >
                    Varified
                  </Text>
                  <BsCheckCircle
                    style={{
                      fontWeight: 'bold',
                      marginLeft: 2,
                      color: 'green',
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </>
              ) : ssUser?.emailVerified === 1 ? (
                <Text
                  fontWeight={'bold'}
                  fontSize={'lg'}
                  marginLeft={2}
                  color={'gray.700'}
                >
                  <Text>Unvarified</Text>
                </Text>
              ) : null}
            </Flex>
          </SimpleGrid>
          <Divider orientation="horizontal" />
          <SimpleGrid
            width={'100%'}
            columns={2}
            spacingX={'50px'}
            alignSelf={'center'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {ssUser?.emailVerified === 1 ? (
              <Box>
                {customResetBtn({
                  title: 'Verify Email',
                  onClickReset: () => onClickEmailVerification(),
                })}
              </Box>
            ) : null}

            {customBtn({
              title: 'Delete',
              backgroundColor: 'black',
              isLoading: loading,
              onClick: () => deleteRecord(value?.userId),
            })}
            {ssUser?.isXoxUser
              ? customBtn({
                  title: 'Delete From Selfcare',
                  backgroundColor: 'black',
                  isLoading: loading,
                  onClick: () => deleteFromSelfcareRecord(ssUser?.profileId),
                })
              : null}
            {customBtn({
              title: 'Update',
              backgroundColor: 'black',
              onClick: () =>
                history.push({
                  pathname: '/CreateBlackUser',
                  status: 'create',
                  state: { val: value },
                }),
            })}
            {customBtn({
              title: 'Cancel',
              backgroundColor: 'black',
              onClick: () => history.goBack(),
            })}
            {/* {customBtn({
              title: 'Suspend',
              backgroundColor: 'black',
              onClick: () => onClickEmailVerification(),
            })} */}

            {customBtn({
              title: 'Back to Dashboard',
              backgroundColor: 'black',
              onClick: () => goToBackRefreshPage(),
            })}
          </SimpleGrid>
        </Flex>
      </Flex>
      <Divider marginStart={5} orientation="horizontal" />
      {renderCopyRight()}
    </Flex>
  );
};

export default IAM;
