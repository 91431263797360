import React from 'react';
import {
  Select,
  Button,
  Text,
  Flex,
  useToast,
  Td,
  Tr,
  Spacer,
  Tooltip,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { useHistory } from 'react-router-dom';
import { colAppVersion } from '../context/data';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
import { options } from '../context/data';
import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';

const AppVersion = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [valueSearch, setValueSearch] = React.useState();
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    setValueSearch(ev);
    searchItems(ev, value, 1);
  };
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    setValue(ev);
  };
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAllAppVersions', body)
      .then(res => {
        if (res.message === 8337) {
          res?.record?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
        if (res.message === 8336) {
          setLoader(true);
          toast({
            title: 'Mongodb pagination Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
        if (res.message === 2454) {
          setLoader(true);
          toast({
            title: 'No Data Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Add Data to Display.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const searchItems = (value, limit, page) => {
    setLoader(false);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    apimanager.post('searchAppVersion', body).then(res => {
      console.log('responce', res);
      if (res.message === 8339) {
        res?.record?.docs.map((value, index) => {
          tempArray.push(value);
        });
        setDataa(tempArray);
        setpaginate(res?.record);
        setLoader(true);
      }
      if (res.message === 8338) {
        toast({
          title: 'Failed',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: `Pagination Failed`,
        });
      }
    });
  };
  const deleteItem = item => {
    console.log('Deletion done', item._id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    apimanager
      .post('daleteAppversion', body)
      .then(response => {
        if (response.message === 8333) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems(value, 1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'App Version List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'App Version Management',
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateAppVersion')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'} mx={1}>{currentLangData?.app?.search}:</Text>
              {customInputLabel({
                size: 'sm',
                onChange: e => handleOnSearch(e, 'searchPhrase'),
              })}
            </Flex>
          </Flex>

          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colAppVersion}
            onPageChange={page => {
              if (valueSearch) {
                searchItems(valueSearch, value, page);
              } else {
                getALLItems(value, page);
              }
            }}
            renderItem={({ item, index }) => {
              // console.log('renderitem', item);
              // let releaseDate = new Date(item?.relesaseDate / 1000);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.version}</Td>
                  <Td>
                    {item?.android === true ? (
                      <CheckIcon />
                    ) : (
                      <CloseIcon color={'red'} />
                    )}
                  </Td>
                  <Td>
                    {item?.ios === true ? (
                      <CheckIcon />
                    ) : (
                      <CloseIcon color={'red'} />
                    )}
                  </Td>
                  <Td>
                    {new Date(item?.releaseDate * 1000).toLocaleDateString()}
                  </Td>
                  <Td {...style.statusStyle}>
                    {item?.status === 1 ? (
                      <>{currentLangData?.app?.active}</>
                    ) : item?.status === 0 ? (
                      <>{currentLangData?.app?.inactive}</>
                    ) : null}
                  </Td>
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Tooltip label={currentLangData?.app?.edit}>
                      <Button
                        {...style.editbtn}
                        leftIcon={<EditIcon />}
                        onClick={() =>
                          history.push({
                            pathname: '/CreateAppVersion',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip label={currentLangData?.app?.delete} bg="red.600">
                      <Button
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      >
                        {/* {currentLangData?.app?.delete} */}
                      </Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default AppVersion;
