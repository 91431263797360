import theme from '../config/color';

const styles = {
  flexParentFlex: {
    height: '80vh',
    flexDirection: 'column',
    backgroundColor: '#FAFDFB',
  },
  multiSelect: {
    fontSize:'10px',
  },

  parentFlex: {
    padding: '5',
    width: '95%',
    alignSelf: 'center',
    alignItems: 'center',
    background: 'white',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  miniheader: {
    padding: '1%',
    bgColor: '#fff',
    paddingStart: 2,
    borderRadius: 0,
    boxShadow: 'sm',
    borderColor: '#f2f2f2',
    paddingBottom: '2.5%',
    borderBottomWidth: '1px',
  },
  box: {
    flexDirection: 'column',
    padding: '3%',
    height:'100%',
  },

  flexAnswerCn: {
    padding: 5,
    height: '50vh',
    width: '100%',
    borderWidth: 1,
    marginTop: '2%',
    flexDirection: 'column',
  },

  txtAnswerCn: {
    fontSize: '12px',
    marginBottom: '2',
    color: theme.customColors.blackAdminColor['100'],
  },

  txtRed: {
    color: 'red',
    marginLeft: '1',
  },

  multiSelect: {
    chips: {
      width: '90%',
      // height: '100%',
      fontSize: '10px',
      background: 'grey',
      fontSize: '12px',
      'font-size': '12px',
      size: '10px',
    },
    miniheader: {
      borderRadius: 0,
      boxShadow: 'sm',
      padding: '1%',
      bgColor: '#fff',
      paddingStart: 2,
      borderColor: '#f2f2f2',
      paddingBottom: '2.5%',
      borderBottomWidth: '1px',
    },
    multiselectContainer: {
      color: 'black',
      Width: '100%',

      border: '1px',
      hover: {
        background: '#efefef',
      },
      height: '100%',
    },
    searchBox: {
      height: '100%',
      width: '100%',
      'font-size': '8px',
      fontSize: '8px',
      'font-size': '8px',
      size: '10px',
      'min-height': ' 20px',
      'border-bottom': '1px solid gray',
      'border-radius': '5px',
      hover: {
        background: '#efefef',
      },
    },
    // option: {
    //   color: 'black',
    //   hover: {
    //     background: '#efefef',
    //   },
    // },

    optionContainer: {
      border: '1px ',
      backgroundColor: 'white',
      color: 'blue',
      fontSize: '8px',
      'font-size': '8px',
      size: '10px',
    },
    option: {
      onMouseOver: 'red',
      color: 'black',
      fontSize: '8px',
      'font-size': '8px',
      size: '10px',
      backgroundColor: '#efefef',
    },
    groupHeading: {
      color: 'yellow',
      fontSize: '8px',
      'font-size': '8px',
      size: '10px',
      hover: {
        background: '#efefef',
      },
      backgroundColor: 'purple',
      onMouseOver: 'red',
    },
  },
  redAsterisk: {
    fontSize: 'md',
    marginBottom: '1',
    color: theme.customColors.blackAdminColor['100'],
  },
};

export default styles;
