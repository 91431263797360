import React, { useState, useEffect } from 'react';
import { Flex, useToast, SimpleGrid } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import { MiniHeader } from '../components/common/customComponent';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { Bar } from 'react-chartjs-2';
import LineChart from '../components/common/LineChart';

const BlackAppDashboard = () => {
  let quickSelect = [
    'Yesterday',
    'Last 7 Days',
    'Last 28 Days',
    'This Week',
    'This Month',
    'This Year',
    'Last Week',
    'Last Month',
  ];

  let osType = ['IOS', 'Android'];

  let subscription = [
    { list: 'Subscribed', val: 1 },
    { list: 'Not Subscribed', val: 0 },
  ];

  let initialValue = {
    quickSelect: '',
    osType: '',
    subscription: '',
  };

  let history = useHistory();
  const [dataa, setDataa] = useState([]);
  const [value, setValue] = useState('10');
  const [allDate, setAllDate] = useState([]);
  const [newData, setNewData] = useState([]);
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const [sDate, setSDate] = useState('');
  const [type, setType] = useState('');
  const [bMMicroservicesReport, setBMMicroservicesReport] = useState([]);
  const [eDate, setEDate] = useState('');
  const [payload, setPayload] = useState(initialValue);
  const [loader, setLoader] = useState(false);
  let apimanager = ApiManager.getInstance();

  const [userData, setUserData] = useState({
    labels: allDate.map((dat, index) => dat),
    labels: [
      '2022-09-07',
      '2022-08-15',
      '2022-08-11',
      '2022-08-10',
      '2022-08-01',
      '2022-06-20',
      '2022-06-16',
    ],
    datasets: [
      {
        label: 'Peak Traffic Period',
        data: [1, 1, 2, 2, 2, 2, 1],
        // data: dataa.map(data => data.activeUsers),
        // data: allActiveUsers,
        backgroundColor: [
          'rgba(75,192,192,1)',
          '#ecf0f1',
          '#50AF95',
          '#f3ba2f',
          '#2a71d0',
        ],
        borderColor: 'black',
        borderWidth: 2,
      },
    ],
  });

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const toast = useToast();
  // Creating Variable //
  let tempArray = [];

  useEffect(() => {
    getALLItems();
    blackMarketMicroservicesReport();
    newUserRegisterationReport();
  }, [1]);

  const handleType = selectedName => {
    if (selectedName === 'Yesterday') {
      return 1;
    } else if (selectedName === 'Last 7 Days') {
      return 2;
    } else if (selectedName === 'Last 28 Days') {
      return 3;
    } else if (selectedName === 'This Week') {
      return 4;
    } else if (selectedName === 'This Month') {
      return 5;
    } else if (selectedName === 'This Year') {
      return 6;
    } else if (selectedName === 'Last Week') {
      return 7;
    } else if (selectedName === 'Last Month') {
      return 8;
    }
  };

  const handleDate = e => {
    console.log('Date==>', e);
    let startDate = new Date(e[0]);
    let endDate = new Date(e[1]);

    console.log('StartD =>', startDate.getTime());
    console.log('EndD =>', endDate.getTime());
    let finalStartDate = startDate.getTime();
    let finalEndDate = endDate.getTime();
    setSDate(finalStartDate);
    setEDate(finalEndDate);
    setDateRange(e);
    setType('');
    getALLItems(null, finalStartDate, finalEndDate, null);
    blackMarketMicroservicesReport(null, finalStartDate, finalEndDate, null);
    newUserRegisterationReport(null, finalStartDate, finalEndDate, null);
  };

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    let tempType = handleType(ev);
    console.log('tempType ', tempType);
    setType(tempType);
    setSDate('');
    setEDate('');

    getALLItems(tempType, null, null, null);
    blackMarketMicroservicesReport(tempType, null, null, null);
    newUserRegisterationReport(tempType, null, null, null);

    setPayload({
      ...value,
      [key]: ev,
    });
  };

  const handleonChangeOSType = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    setType('');
    setSDate('');
    setEDate('');

    getALLItems(null, null, null, ev, null);
    blackMarketMicroservicesReport(null, null, null, ev, null);
    newUserRegisterationReport(null, null, null, ev, null);
    setPayload({
      ...value,
      [key]: ev,
    });
  };

  const handleonChangeSubNonSubs = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    setType('');
    setSDate('');
    setEDate('');

    getALLItems(null, null, null, null, ev);
    blackMarketMicroservicesReport(null, null, null, null, ev);
    newUserRegisterationReport(null, null, null, null, ev);
    setPayload({
      ...value,
      [key]: ev,
    });
  };

  const newUserRegisterationReport = (
    typee,
    startD,
    endD,
    osTyp,
    subNonsubs
  ) => {
    console.log(
      'newUserRegisterationReport=>>',
      typee,
      startD,
      endD,
      osTyp,
      subNonsubs
    );

    let tempDate = [];
    let tempUser = [];

    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('newUserRegisterationReport', body)
      .then(res => {
        console.log('newUserRegisterationReport=>', res);
        if (res.message === 8814) {
          res?.data?.map((value, index) => {
            tempArray.push(value);
            // console.log('value OF Map', value);
            tempDate.push(value?.date);
            tempUser.push(value?.activeUsers);
          });
          setNewData(tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  let keysBMMicroservicesReport = [];
  let valuesBMMicroservicesReport = [];

  const blackMarketMicroservicesReport = (
    typee,
    startD,
    endD,
    osTyp,
    subNonsubs
  ) => {
    // setLoader(false);
    console.log(
      'blackMarketMicroservicesReport=>>',
      typee,
      startD,
      endD,
      osTyp,
      subNonsubs
    );

    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('blackMarketMicroservicesReport', body)
      .then(res => {
        console.log('blackMarketMicroservicesReport=>', res);
        if (res.message === 8820) {
          res?.data?.map((value, index) => {
            setBMMicroservicesReport(value);
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  if (bMMicroservicesReport !== []) {
    keysBMMicroservicesReport = Object.keys(bMMicroservicesReport);
    valuesBMMicroservicesReport = Object.values(bMMicroservicesReport);
  }

  const getALLItems = (typee, startD, endD, osTyp, subNonsubs) => {
    // setLoader(false);
    console.log('getALLItems=>>', typee, startD, endD, osTyp, subNonsubs);

    let tempDate = [];
    let tempUser = [];

    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('activeUsersReport', body)
      .then(res => {
        console.log('activeUsersReport=>', res);
        if (res.message === 8817) {
          res?.data?.map((value, index) => {
            tempArray.push(value);
            // console.log('value OF Map', value);
            tempDate.push(value?.date);
            tempUser.push(value?.activeUsers);
          });
          setAllDate(tempDate);
          setAllActiveUsers(tempUser);
          setDataa(tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Black App Dashboard',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Black App Dashboard',
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <SimpleGrid width={'100%'} columns={4} spacingX={'50px'}>
              {DatewithTimePicker({
                title: 'Date',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,

                changePicker: true,
                // onChange: e => setDateRange(e),
                onChange: e => handleDate(e),
              })}
              {dropdownCustomSvalue({
                title: 'Subscription',
                mandatory: false,
                temparray: subscription,
                placeholder: 'Select Option',
                onChange: e => handleonChangeSubNonSubs(e, 'subscription'),
              })}

              {dropdownCustom({
                title: 'Quick Select',
                mandatory: false,
                temparray: quickSelect,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'quickSelect'),
              })}
              {dropdownCustom({
                title: 'Os Type',
                mandatory: false,
                temparray: osType,
                placeholder: 'Select Option',
                onChange: e => handleonChangeOSType(e, 'osType'),
              })}
            </SimpleGrid>
          </Flex>
          <Flex {...style.optionFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              <Flex>
                <LineChart
                  chartData={{
                    labels: dataa.map((dat, index) => dat.date),
                    datasets: [
                      {
                        label: 'Active Users',
                        data: dataa.map(data => data.activeUsers),
                        backgroundColor: [
                          'rgba(75,192,192,1)',
                          '#ecf0f1',
                          '#50AF95',
                          '#f3ba2f',
                          '#2a71d0',
                        ],
                        borderColor: 'black',
                        borderWidth: 2,
                      },
                    ],
                  }}
                />
              </Flex>
              <Flex>
                <LineChart chartData={userData} />
              </Flex>
            </SimpleGrid>
          </Flex>

          <Flex {...style.optionFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              <Flex>
                <LineChart
                  chartData={{
                    labels: newData.map(data => data.date),
                    datasets: [
                      {
                        label: 'New Registeration',
                        data: newData.map(data => data.count),
                        backgroundColor: [
                          'rgba(75,192,192,1)',
                          '#ecf0f1',
                          '#50AF95',
                          '#f3ba2f',
                          '#2a71d0',
                        ],
                        borderColor: 'black',
                        borderWidth: 2,
                      },
                    ],
                  }}
                />
              </Flex>
              <Flex>
                {bMMicroservicesReport.length != 0 ? (
                  <Bar
                    style={{ marginTop: 8 }}
                    width={'20%'}
                    height={'10%'}
                    data={{
                      labels: keysBMMicroservicesReport,
                      datasets: [
                        {
                          label: 'Black Market',
                          data: valuesBMMicroservicesReport,
                          backgroundColor: 'rgba(96, 177, 245, 0.8)',
                        },
                      ],
                    }}
                  />
                ) : (
                  <Bar
                    style={{ marginTop: 8 }}
                    width={'20%'}
                    height={'10%'}
                    data={{
                      labels: [],
                      datasets: [
                        {
                          label: [],
                          data: [],
                          backgroundColor: 'rgba(96, 177, 245, 0.8)',
                        },
                      ],
                    }}
                  />
                )}
              </Flex>
            </SimpleGrid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default BlackAppDashboard;
