const styles = {
  parentFlexing: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  firstFlexInParent: {
    w: '100%',
    background: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
export default styles;
