import React from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
} from '@chakra-ui/react';
import theme from '../config/color';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import {
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';

const CreateGame = () => {
  console.log('CreateGame gg bro');
  let history = useHistory();
  return (
    <Flex
      flexDirection={'column'}
      // padding={2}
      backgroundColor={'#FAFDFB'}
      height={'80vh'}
    >
      <Flex 
      {...style.miniheader}
      marginBottom={'2%'}
      >
      {MiniHeader({
        title: 'Create Dynamic Campaign',
        path: 'Dashboard',
        onclickForRoute: () => history.push('/Dashboard'),
        path1:'CreateDynamicCompaign',
        useSlash:true,
        path2:'Create',
      })}
      </Flex>

      <Flex
        padding={'8'}
        width={'97%'}
        boxShadow={'lg'}
        alignSelf={'center'}
        alignItems={'center'}
        background={'white'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({ title: 'Name', mandatory: true })}

          <Flex flexDirection={'row'} marginTop={4}>
            <Text
              color={theme.customColors.blackAdminColor['100']}
              fontSize="12px"
              marginBottom={'2'}
            >
              Description
            </Text>
          </Flex>
          <Textarea
            //     value={value}
            //     onChange={handleInputChange}
            size="sm"
            height={'180px'}
          />
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={4} spacingX={'50px'} marginTop={6}>
          {dropdownCustom({
            title: 'Game Type',
            mandatory: 'true',
          })}
          {customInputLabel({ title: 'Apple ID', mandatory: true })}
          {customInputLabel({ title: 'Google Play ID', mandatory: false })}
          {customInputLabel({ title: 'Game URL', mandatory: true })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          {dropdownCustom({
            title: 'Status',
            mandatory: 'true',
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          {customInputLabel({
            title: 'Image',
            type: 'file',
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>

      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateGame;
