import React from 'react';
import { Flex, SimpleGrid, Divider } from '@chakra-ui/react';

import {
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';

const CreateLuckDrawReward = () => {
  console.log('CreateLuckDrawReward gg bro');
  return (
    <Flex
      flexDirection={'column'}
      padding={2}
      backgroundColor={'#FAFDFB'}
      // height={'80vh'}
    >
      {MiniHeader({
        title: 'Create Luck Draw Reward',
        path: 'Dashboard / Luck Draw Reward / Create',
      })}
      <Flex
        padding={'8'}
        justifyContent={'space-around'}
        height={'100%'}
        flexDirection={'column'}
        background={'white'}
        alignItems={'center'}
      >
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({ title: 'Name', mandatory: true })}
        </SimpleGrid>

        <Flex width={'100%'} marginY={10} flexDirection={'column'}>
          <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
            {customInputLabel({
              title: 'Merchant Name',
              mandatory: true,
            })}
            {customInputLabel({
              title: 'Merchant ID',
              mandatory: false,
            })}
          </SimpleGrid>

          <SimpleGrid
            width={'100%'}
            columns={2}
            spacingX={'50px'}
            marginTop={4}
          >
            {customInputLabel({
              title: 'Per Day Reward',
              mandatory: true,
            })}
            {customInputLabel({
              title: 'Campaign Total',
              mandatory: false,
            })}
          </SimpleGrid>

          <Flex marginTop={4}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Status',
                mandatory: true,
                //     type: 'file',
              })}
            </SimpleGrid>
          </Flex>

          {customUpdateCancelBtn({
            title1: 'Submit',
            title2: 'Cancel',
          })}
        </Flex>
      </Flex>
      <Divider orientation="horizontal" />

      {renderCopyRight()}
    </Flex>
  );
};
export default CreateLuckDrawReward;
