import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import style from '../components/common/style';
import {
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
  customInputImage,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { uploadImage } from '../config/imageUploader';

const CreateHappyHourBackground = () => {
  let initialValue = {
    id: '',
    name: '',
    startdate: '',
    enddate: '',
  };
  let mandatoryField = {
    name: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [success, setsuccess] = React.useState(null);
  const [status, setstatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [s3image, setS3Image] = React.useState('');
  const [error, setError] = React.useState(null);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [endD, setEndD] = React.useState('');
  const [startD, setStartD] = React.useState('');
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();

  React.useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val) {
      let data = location?.state?.val;
      setValue({
        ...value,
        id: data?._id,
        name: data?.name,
        startdate: data?.startDate,
        enddate: data?.endDate,
      });
      setS3Image(data?.image);
      if (data?.startDate) {
        setStartD(new Date(data?.startDate * 1000));
      }
      if (data?.endDate) {
        setEndD(new Date(data?.endDate * 1000));
      }
    }
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const upload = e => {
    setImageLoading(true);
    uploadImage(e.target.files[0])
      .then(res => {
        setS3Image(e.target.files[0].name);
        setImageLoading(false);
      })
      .catch(error => {});
  };
  const convertedDate = strDate => {
    const dt = Date.parse(strDate);
    return dt / 1000;
  };
  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter All mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter All mandatory fields',
      });
    } else if (s3image === '' || s3image === undefined) {
      setLoading(false);
      setError('Please enter Image field');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter mandatory Image field',
      });
    } else if (startD === '' || startD === undefined) {
      setLoading(false);
      setError('Start Date / End Date');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Fields Must be Filled.Failed to Submit',
      });
    } else if (endD === '' || endD === undefined) {
      setLoading(false);
      setError('Start Date / End Date');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Fields Must be Filled.Failed to Submit',
      });
    } else {
      const body = {
        name: value?.name,
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
        image: s3image,
      };
      apimanager
        .post('addHappyHourBackground', body)
        .then(response => {
          if (response.message === 8494) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (response.message === 7000) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `User Not Found`,
            });
          }
          if (response.message === 8058) {
            setLoading(false);
            setError('Something went wrong');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };
  const onClickUpdate = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        id: value?.id,
        name: value?.name,
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
        image: s3image,
      };
      apimanager
        .post('editHappyHourBackground', body)
        .then(response => {
          if (response.message === 8497) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Happy Hour Background',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'App Setup',
              useSlash: 'true',
              path2: 'Edit Happy Hour Background',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={5}
            >
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: false,
                selectedDate: startD,
                onChange: e => setStartD(e),
                value: startD,
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: false,
                selectedDate: endD,
                onChange: e => setEndD(e),
                value: endD,
              })}
            </SimpleGrid>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {!imageLoading ? (
                <Flex>
                  {customInputImage({
                    title: 'Icon',
                    mandatory: true,
                    type: 'file',
                    src: process.env.REACT_APP_S3URL + s3image,
                    onChange: e => upload(e),
                  })}
                </Flex>
              ) : (
                <Skeleton height="70px" />
              )}
            </SimpleGrid>{' '}
            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Happy Hour Background',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'App Setup',
              useSlash: 'true',
              path2: 'Create Happy Hour Background',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={5}
            >
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: false,
                selectedDate: startD,
                onChange: e => setStartD(e),
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: false,
                selectedDate: endD,
                onChange: e => setEndD(e),
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={'5'}
            >
              {!imageLoading ? (
                <Flex>
                  {customInputImage({
                    title: 'Icon',
                    mandatory: true,
                    type: 'file',
                    src: process.env.REACT_APP_S3URL + s3image,
                    onChange: e => upload(e),
                  })}
                </Flex>
              ) : (
                <Skeleton height="70px" />
              )}
            </SimpleGrid>{' '}
            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateHappyHourBackground;
