import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';
import { Roles } from '../context/data';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';

import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

const UpdateCmsUserPassword = () => {
  let initialValue = {
    userId: '',
    password: '',
    confirmPassword: '',
  };

  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [changeApi, setChangeApi] = useState(false);
  const [loading, setLoading] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const location = useLocation();
  const toast = useToast();

  let mandatoryFields = {
    password: true,
    confirmPassword: true,
  };

  let dataOfEdit = '';

  React.useEffect(() => {
    if (location?.state?.data != null || location?.state?.data != undefined) {
      dataOfEdit = location?.state?.data;

      console.log('location?.state?.data', location?.state?.data);

      setValue({
        ...value,
        userId: dataOfEdit,
      });
    }
    console.log('pathdata', location);
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    setValue({
      ...value,
      [key]: ev,
    });
    console.log('value=>', value);
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else if (value?.password === value?.confirmPassword) {
      const body = {
        userId: value?.userId,
        newPassword: value?.password,
      };
      console.log('body', body);
      apimanager
        .post('updateUserPasswordFromCMS', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 7037) {
            setSuccess('Successfully Recorded!');
            setLoading(false);
            toast({
              title: success,
              duration: 3000,
              isClosable: true,
              status: 'success',
              description: `New Password has been Updated Successfully`,
            });
            setTimeout(() => {
              history.goBack();
            }, 3000);
          } else {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Failed',
              description: `${response?.errors[0]?.msg}`,
            });
          }
          if (response.message === 4001) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Role is not defined',
              description: `Please Select Role!`,
            });
          }
          if (response.message === 7063) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Email Duplication',
              description: `Use Different Email!`,
            });
          } else {
            setLoading(false);
            setError('Please enter mandatory fields');
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setError('Password & ConfirmPassword field must be Same');
      setLoading(false);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description:
          'Failed to Submit Password & ConfirmPassword field must be Same',
      });
    }
  };

  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Update Password',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'User',
          useSlash: true,
          path2: 'Update Password',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'New Password',
            mandatory: true,
            onChange: e => handleonChange(e, 'password'),
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Confirm Password',
            mandatory: true,
            onChange: e => handleonChange(e, 'confirmPassword'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>
      <Spacer />
      <Divider
        marginStart={10}
        
        orientation="horizontal"
        marginTop={5}
        marginBottom={2}
      />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default UpdateCmsUserPassword;
