import React, { useState, useContext } from 'react';
import {
  Text,
  Button,
  Flex,
  useToast,
  Tr,
  Td,
  Select,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react';
import style from '../../components/common/style';
import { ChecktableTwo } from '../../components/common/ChecktableTwo';
import { colNotificationCompaign } from '../../context/data';
import { options } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
// import { IoMdAddCircleOutline } from 'react-icons/io';
// import { AiOutlineMinusCircle } from 'react-icons/ai';
import ApiManager from '../../config/apiManager';
import LangContext from '../../context/languageContext';
// import { useAuthState } from '../context/authContext';

const NotificationCompaigns = () => {
  let history = useHistory();
  const [dataa, setDataa] = useState([]);
  const [value, setValue] = useState('10');
  const [paginat, setpaginate] = useState();
  const [loader, setLoader] = useState(false);
//   const [success, setSuccess] = useState(null);
  const { currentLangData } = useContext(LangContext);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  let tempArray = [];

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log('evt', evt);
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value,1);
  }, []);
  console.log('value', value);
  const getALLItems = (limit,pages)  => {
    // console.log('limit',limit,'pages',pages)
    setLoader(false);
    const body = {
      numRecords: limit,
      page: pages,
    };
    // console.log('body=', body);
    apimanager
      .get('getNotificationTopics', body)
      .then(res => {
        // console.log('bodygetGatewayActions==', res);
        if (res.messages === 12011) {
          res?.data?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.data);
          setLoader(true);
        }
        if (res.message === 8499) {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed.Response Not saved',
          });
        }
        if (res.message === 7000) {
          toast({
            title: 'User Not Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `User Not Found`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  console.log('Data=', dataa);

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Notification Compaigns',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Notification Compaigns',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numRecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colNotificationCompaign}
            onPageChange={page => getALLItems(value,page)}
            renderItem={({ item, index }) => {
              console.log('RenderItem=', item);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?._id}</Td>
                  <Td>{item?.title}</Td>
                  <Td>{item?.message}</Td>
                  <Td>{item?.total}</Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NotificationCompaigns;
