import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import style from '../../components/common/style';
import { ChecktableTwo } from '../../components/common/ChecktableTwo';
import { listCities, colCities } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import ApiManager from '../../config/apiManager';
import { useHistory } from 'react-router-dom';
import { deleteItem } from './BannerTypes';
import LangContext from '../../context/languageContext';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { options } from '../../context/data';

export const Cities = () => {
  let history = useHistory();
  let toast = useToast();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  // Creating Variable //
  let tempArray = [];

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllCities', body)
      .then(res => {
        // console.log("res",res)
        if (res.message === 8070) {
          let data = res?.result?.docs;
          res?.record?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deleteCity', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8069) {
          toast({
            title: 'Deleted',
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          // getAllCategory(1);
          getALLItems();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader} boxShadow={'lg'} borderBottomWidth={'1px'}>
        {MiniHeader({
          title: 'Cities List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'App Setup',
          useSlash: 'true',
          path2: 'Cities',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateCity')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            {/* <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
              <Input {...style.searchinput}></Input>
            </Flex> */}
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colCities}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.countryName}</Td>
                  <Td>{item?.stateName}</Td>
                  <Td>{item?.name}</Td>
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Tooltip>
                      <Button
                        {...style.editbtn}
                        leftIcon={<EditIcon />}
                        onClick={() =>
                          history.push({
                            pathname: '/CreateCity',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      >
                      </Button>
                    </Tooltip>

                    <Tooltip label={currentLangData?.app?.delete} bg={'red'}>
                      <Button
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      ></Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
