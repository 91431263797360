import React, { useState, useContext } from 'react';
import {
  Text,
  Button,
  Flex,
  useToast,
  Tr,
  Td,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react';
import { AtSignIcon, LinkIcon } from '@chakra-ui/icons';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { colMerchantCode, colMerchantCodeNPG} from '../context/data';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { FaFileInvoice } from 'react-icons/fa';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
// import { useAuthState } from '../context/authContext';

const MerchantCodes = () => {
  let init = {
    paymentMethod: '',
  };
  let tempPaymentMethod = [
    { list: 'IPAY88', val: '1' },
    { list: 'NPG', val: '2' },
  ];
  let paymentMethod = ['IPAY88', 'NPG'];

  let history = useHistory();
  const [dataa, setDataa] = useState([]);
  const [value, setValue] = useState('10');
  const [paginat, setpaginate] = useState();
  const [loader, setLoader] = useState(false);
  const [payload, setPayload] = useState(init);
  const [success, setSuccess] = useState(null);
  const [paymenyID, setPaymentID] = useState('');
  const [valueSearch, setValueSearch] = useState();
  const { currentLangData } = useContext(LangContext);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  let tempArray = [];

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EV=>', ev);
    let temp = '';
    if (ev === 'IPAY88') {
      setPaymentID('1');
      getALLItems(value, 1, '1');
      // getGatewayActions('1');
    } else if (ev === 'NPG') {
      setPaymentID('2');
      getALLItems(value, 1, '2');
      // getGatewayActions('2');
    }

    // setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1, '1');
  }, [value]);

  // const getGatewayActions = gatewayID => {
  //   console.log('gatewayIDD=>', gatewayID);
  //   // setLoadingApiCall(true);
  //   let tempArray = [];
  //   let body = {
  //     gatewayId: gatewayID,
  //   };
  //   apimanager
  //     .post('getGatewayActions', body)
  //     .then(res => {
  //       console.log('getAllActions=>RES', res);
  //       if (res.message === 11006) {
  //         console.log('Get getAllActions', res);
  //         console.log('Get getAllActions', res?.actions);

  //         // setActionArray(res?.actions);
  //         // pageCount = res.results.totalDocs;
  //         // setSsoUser(res?.result?.ssoUser);
  //         // setMiddleUser(res?.result?.middlewareUser);

  //         // if (res?.result?.ssoUser) {
  //         //   setSsoUser(res?.result?.ssoUser);
  //         // } else if (res?.result?.result) {
  //         //   setSsoUser(res?.result?.result);
  //         // }
  //         // setLoadingApiCall(false);
  //       }
  //     })
  //     .catch(error => {
  //       // setLoadingApiCall(false);
  //       console.log('error', error);
  //     });
  // };

  const getALLItems = (limit, page, gateway) => {
    setLoader(false);
    console.log('getALLItems=>', limit, page, gateway);
    const body = {
      numRecords: limit,
      gatewayId: gateway.toString(),
      page: page,
      queryParam: {
        page: page,
        numRecords: limit
      }
    };
    apimanager
      .post('getAllMerchantCodes', body)
      .then(res => {
        console.log('response', res);
        if (res.message === 11008) {
          res?.records?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.records);
          setLoader(true);
        }
        if (res.message === 8499) {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed.Response Not saved',
          });
        }
        if (res.message === 7000) {
          toast({
            title: 'User Not Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `User Not Found`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/AssignMerchantCodesToActions',
      state: { data: editData, useEditApi: true },
    });
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Merchants',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Merchants',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/addMerchantCode')}
            >
              Create
            </Button>
          </Flex>
          <SimpleGrid
            marginStart={5}
            marginBottom={5}
            width={'80%'}
            columns={2}
            spacingX={'50px'}
          >
            {dropdownCustom({
              title: 'Select Payment Method',
              mandatory: false,
              temparray: paymentMethod,
              // placeholder: 'Select Option',
              onChange: e => handleOnChange(e, 'paymentMethod'),
            })}
            {/* {dropdownCustom({
              title: 'Os Type',
              mandatory: false,
              temparray: actionArray ? actionArray : '',
              placeholder: 'Select Option',
              // onChange: e => handleonChangeOSType(e, 'osType'),
            })} */}
          </SimpleGrid>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            // removePagination={true}
            headerNames={paymenyID === '2' ? colMerchantCodeNPG : colMerchantCode }
            onPageChange={page => getALLItems(value, page, paymenyID)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.gatewayName}</Td>
                  <Td>{item?.merchantName}</Td>
                  <Td>{item?.merchantCode}</Td>
                  {paymenyID === '2' ? (
                    <>
                      <Td>{item?.privateKeyLink}</Td>
                      <Td>{item?.publicKeyLink}</Td>
                    </>
                  ) : (
                    <>
                      <Td>{item?.merchantKey}</Td>
                    </>
                  )}
                  <Td flex={1} justifyContent={'center'} alignItems={'center'}>
                    <Tooltip label="Assign" placement="top">
                      <Button
                        {...style.editbtn}
                        fontSize={'xsm'}
                        // variant="outline"
                        // fontWeight={'light'}color={'gray'}
                        leftIcon={
                          <LinkIcon w={'15px'} h={'15px'} color={'gray'} />
                        }
                        onClick={() => navigateToEditPage(item)}
                      >
                        {/* Assign */}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      label={currentLangData?.app?.merchantActions}
                      placement="top"
                    >
                      <Button
                        {...style.editbtn}
                        fontWeight={'light'}
                        color={'gray'}
                        leftIcon={
                          <FaFileInvoice w={'15px'} h={'15px'} color={'gray'} />
                        }
                        onClick={() =>
                          history.push({
                            pathname: '/MerchantActions',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      ></Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MerchantCodes;
