import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';

import {
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  MiniHeader,
} from '../components/common/customComponent';

const CreateIpay88Credential = () => {
  console.log('CreateIpay88Credential gg bro');
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      {MiniHeader({
        title: 'Create Ipay88 Credential',
        path: 'Dashboard / CMS & Backend Setup / Ipay88 Credentials / Create Ipay88 Credential',
      })}

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Name',
            mandatory: true,
          })}
        </SimpleGrid>

        <SimpleGrid
          width={'100%'}
          columns={2}
          spacingX={'50px'}
          marginTop={'4%'}
        >
          {customInputLabel({
            title: 'Marchant Code',
            mandatory: true,
          })}
          {customInputLabel({
            title: 'Merchant Key',
            mandatory: true,
          })}
        </SimpleGrid>

        <SimpleGrid
          width={'100%'}
          columns={1}
          spacingX={'50px'}
          marginTop={'4%'}
        >
          {customInputLabel({
            title: 'URL',
            mandatory: true,
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateIpay88Credential;
