import React from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Textarea,
  Select,
  Input,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';

import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import { customSaveButton } from '../components/inboxMessagingComponent';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

const CreateCountry = () => {
  let initialValue = {
    isoCode: '',
    name: '',
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const location = useLocation();
  const toast = useToast();

  React.useEffect(() => {
    if (location.status === "create") {
      setstatus(true);
    }
    if (location.state?.val?.isoCode) setValue((_p) => ({ ..._p, isoCode: location.state.val.isoCode }))
    if (location.state?.val?.name) setValue((_p) => ({ ..._p, name: location.state.val.name }))
    // console.log('pathdata', location.state);
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const onClickSubmit = () => {
    setLoading(true);
    console.log('Api Run');
    const body = {
      isoCode: value?.isoCode,
      name: value?.name,
    };
    console.log('body>>>>', body);
    apimanager
      .post('addCountry', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8362) {
          setLoading(false);
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
        else if (response.message === 8361) {
          toast({
            // title: 'error',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Please Enter Mandatory Fields!',
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const onClickUpdate = () => {
    console.log('Api Run');
    const body = {
      id: location.state.val._id,
      isoCode: value?.isoCode,
      name: value?.name,
    };
    console.log('body>>>>', body);
    apimanager
      .post('updateCountry', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8364) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
   <>
   { status ? (
      <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...styles.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Edit Country',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'App Setup',
          useSlash: true,
          path2: 'Countries',
          path3: 'Edit',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
          {customInputLabel({
            title: 'isoCode',
            mandatory: true,
            pattern:'^linkedin\.com/',
            onChange: e => handleonChange(e, 'isoCode'),
            value:value['isoCode'],
          })}
          {customInputLabel({
            mandatory: true,
            title: 'Name',
            onChange: e => handleonChange(e, 'name'),
            value:value['name'],
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Update',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickUpdate(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
   ):(
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
    <Flex {...styles.miniheader} marginBottom={'2%'}>
      {MiniHeader({
        title: 'Create Country',
        path: 'Dashboard',
        onclickForRoute: () => history.push('/Dashboard'),
        path1: 'App Setup',
        useSlash: true,
        path2: 'Countries',
        path3: 'Create',
      })}
    </Flex>

    <Flex {...styles.parentFlex}>
      <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
        {customInputLabel({
          title: 'isoCode',
          mandatory: true,
          pattern:'^linkedin\.com/',
          onChange: e => handleonChange(e, 'isoCode'),
        })}
        {customInputLabel({
          mandatory: true,
          title: 'Name',
          onChange: e => handleonChange(e, 'name'),
        })}
      </SimpleGrid>

      {customUpdateCancelBtn({
        title1: 'Submit',
        title2: 'Cancel',
        isLoading: loading,
        onClickCancel: () => history.goBack(),
        onClickSubmit: () => onClickSubmit(),
      })}
    </Flex>
    <Spacer />
    <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
    <Flex position={'sticky'} marginLeft={4}>
      {renderCopyRight()}
    </Flex>
  </Flex>
   )}
   </>
  );
};

export default CreateCountry;
