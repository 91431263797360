import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Spacer,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import { ChecktableOne } from '../../components/common/ChecktableOne';
import { listSoftpins, colSoftpins } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../../config/apiManager';
import LangContext from '../../context/languageContext';
import { options } from '../../context/data';

const Softpins = () => {
  let history = useHistory();
  let toast = useToast();
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [dataa, setDataa] = React.useState();
  const [success, setSuccess] = React.useState(null);
  let tempArray = [];

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    let apimanager = ApiManager.getInstance();
    // console.log('page>>>', limit, page);
    const body = {
      numRecords: limit,
      page: page,
    };
    console.log('state Screen');
    apimanager
      .get('getAllSoftpins', body)
      .then(res => {
        if (res.message === 8219) {
          console.log('responce', res);
          let data = res?.results?.docs;
          res?.record?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
        // console.log('tempArray=>>>>>', tempArray);
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    // console.log('Deletion done', item);
    // console.log('Delete done');
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    // console.log('body>>>>', body);
    apimanager
      .post('deleteSoftpin', body)
      .then(response => {
        if (response.message === 8217) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems(value, 1);
        }
        // console.log(response, 'response');
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Softpin list',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Softpins',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateSoftpin')}
            >
              Create
            </Button>
            <Button
              {...style.bulkbutton}
              // onClick={() =>
              //   history.push({
              //     pathname: '/AddBulk',
              //     status: 'create',
              //     // state: { val: item },
              //   })
              // }
            >
              Bulk Insert
            </Button>
          </Flex>

          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            {/* <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
              <Input {...style.searchinput}></Input>
            </Flex> */}
          </Flex>

          <ChecktableOne
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            screenName="softpins"
            headerNames={colSoftpins}
            onDelete={item => {
              deleteItem(item);
            }}
            onPageChange={page => getALLItems(value, page)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Softpins;
