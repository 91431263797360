import React, { useRef } from 'react';
import {
  Flex,
  Textarea,
  Box,
  Avatar,
  Text,
  Menu,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import theme from '../../config/color';
import { FaKey } from 'react-icons/fa';

import { BiLogIn } from 'react-icons/bi';
import styles from '../../styles/aboutUsStyles';
import { useHistory } from 'react-router-dom';
import { ChevronDownIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Dashboard from '../../pages/Dashboard';
import { useAuthDispatch } from '../../context/authContext';

// const history = useHistory();
export const profileDetail = ({
  title,
  onClick,
  onClickIAM,
  onClickInformation,
  onClickPassword,
}) => {
  return (
    <Flex alignItems={'center'} marginBottom={'2'}>
      <Avatar
        h={10}
        w={10}
        src="https://www.linkpicture.com/q/black_4.png"
        // src="https://avatarfiles.alphacoders.com/946/thumb-1920-94610.jpg"
      />
      <Box ml="3">
        <Menu isLazy size={'sm'} >
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Text fontSize="14px" color={'white'}>
              {title}
            </Text>
            <MenuButton
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              // borderWidth="1px"
              opacity={100}
              _hover={{ bg: 'Transparent', opacity: 0.1 }}
              _expanded={{ bg: 'black', opacity: 0.1 }}
              _focus={{ boxShadow: 'none', opacity: 0.1 }}
              color={'white'}
              rightIcon={
                <ChevronDownIcon background={'white'} color={'white'} />
              }
            >
              <ChevronDownIcon
                background={'white'}
                color={'white'}
                h={5}
                zIndex={6}
                w={5}
              />
            </MenuButton>
            <ChevronDownIcon
              zIndex={-5}
              marginLeft={-6}
              color={'white'}
              textColor={'white'}
              colorScheme={'white'}
            />
          </Flex>
          <MenuList fontSize={'sm'} fontWeight={'normal'}>
            {/* <MenuItem color={'black'}>
              <Button
                {...styles.menuInsideBtn}
                leftIcon={<ArrowForwardIcon />}
                onClick={onClickIAM}
              >
                I A M (Identity and Access Management)
              </Button>
            </MenuItem> */}
            {/* <MenuItem color={'black'}>
              <Button 
                {...styles.menuInsideBtn}
                leftIcon={<ArrowForwardIcon />}
                onClick={onClickInformation}
              >
                Detail (SSO / Middleware)
              </Button>
            </MenuItem> */}
            <MenuItem color={'black'} onClick={onClickPassword}>
              <Button {...styles.menuInsideBtn} leftIcon={<FaKey />}>
                Change Password
              </Button>
            </MenuItem>
            <MenuItem color={'red'} onClick={onClick}>
              <Button {...styles.menuInsideBtn} leftIcon={<BiLogIn />}>
                Logout
              </Button>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export const MiniHeader = ({
  title,
  path,
  onclickForRoute,
  path1,
  onclickForRoute1,
  path2,
  onclickForRoute2,
  pointer,
  useSlash,
}) => {
  let history = useHistory();
  return (
    <Flex
      width={'100%'}
      height={'2vh'}
      // alignSelf={'flex-start'}
      // marginBottom={'10'}
      position={'sticky'}
      // padding={20}
      // boxShadow={'0px 15px 10px -15px #111'}
      marginStart={3}
      // marginTop={-2}
      // boxShadow={'3px 3px 5px 6px #ccc'}
      // boxShadow={
      //   '0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19)'
      // }
      // marginX={-2}
      // paddingY={-6}
    >
      <Flex {...styles.firstFlexInParent} paddingX={5}>
        <Flex>
          <Text fontWeight={'bold'} fontSize={'26px'} color={'#34395e'}>
            {title}
          </Text>
        </Flex>
        <Flex alignItems={'center'}>
          <Text
            color={'gray.500'}
            fontSize={'sm'}
            cursor={'pointer'}
            onClick={onclickForRoute && onclickForRoute}
          >
            {path}
          </Text>
          <Text marginX={1} color={'#838486'}>
            /
          </Text>
          <Text
            color={'gray.500'}
            fontSize={'sm'}
            cursor={'pointer'}
            onClick={onclickForRoute1 && onclickForRoute1}
          >
            {path1}
          </Text>
          {useSlash ? (
            <Text marginX={1} color={'#838486'}>
              /
            </Text>
          ) : null}
          <Text
            color={'gray.500'}
            fontSize={'sm'}
            cursor={'default'}
            onClick={() => onclick && onclickForRoute2}
          >
            {path2}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const customTextarea = ({ mandatory, title, onChange, value }) => {
  return (
    <>
      <Flex alignSelf={'flex-start'} flexDirection={'row'} marginTop={4}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="12px"
          marginBottom={'2'}
          alignSelf={'flex-start'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      <Textarea
        //     value={value}
        //     onChange={handleInputChange}
        size="sm"
        value={value}
        height={'160px'}
        onChange={onChange}
      />
    </>
  );
};

export const AlertDialogBox = ({
  ButtonName,
  // isOpen,
  leastDestructiveRef,
  // onClose,
  BoxHeading,
  AlertText,
  btn1,
  btn2,
  onClick,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <>
      <Button colorScheme="red" onClick={onOpen}>
        {ButtonName}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {BoxHeading}
            </AlertDialogHeader>

            <AlertDialogBody>{AlertText}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {btn1}
              </Button>
              <Button colorScheme="red" onClick={onClick} ml={3}>
                {btn2}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
