import React from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import ControlledEditor from '../components/textEditor/classEditor';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { useLocation } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { isMobileList } from '../context/data';
import { customSaveButton } from '../components/inboxMessagingComponent';

const CreateProduct = () => {
  let initialValue = {
    xoxProductId: '',
    name: '',
    isMobile: '',
    description: '',
    productType: '',
    valueRM: '',
    additional: '',
    startDate: '',
    endDate: '',
    status: '',
  };
  let mandatoryFields = {
    xoxProductId: true,
    name: true,
    isMobile: true,
    description: true,
    productType: true,
    valueRM: true,
    // additional: true,
    status: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [startD, setStartD] = React.useState(new Date());
  const [endD, setendD] = React.useState(new Date());
  const [success, setSuccess] = React.useState(null);
  let apiManager = ApiManager.getInstance();
  const [countries, setCountries] = React.useState([]);
  let history = useHistory();
  let location = useLocation();
  let toast = useToast();
  let producttype = ['Mobile Internet', 'Mobile Internet Add-on'];
  let statusarray = ['Inactive', 'Active'];
  React.useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val?.name)
      setValue(_p => ({ ..._p, name: location.state.val.name }));
    if (location.state?.val?.xoxProductId)
      setValue(_p => ({
        ..._p,
        xoxProductId: location.state.val.xoxProductId,
      }));
    if (location.state?.val?.description)
      setValue(_p => ({
        ..._p,
        description: location?.state?.val?.description,
      }));
    if (location.state?.val?.valueRM)
      setValue(_p => ({ ..._p, valueRM: location?.state?.val?.valueRM }));
    if (location.state?.val?.productType)
      setValue(_p => ({
        ..._p,
        productType: location?.state?.val?.productType,
      }));
    if (location.state?.val?.additional)
      setValue(_p => ({ ..._p, additional: location?.state?.val?.additional }));
    if (location.state?.val?.startDate)
      setStartD(_p => ({ ..._p, startDate: location?.state?.val?.startDate }));
    if (location.state?.val?.endDate)
      setendD(_p => ({ ..._p, endDate: location?.state?.val?.endDate }));
    if (location.state?.val?.status)
      setValue(_p => ({ ..._p, status: location?.state?.val?.status }));
    // console.log('pathdata', location.state.val);
  }, [location]);
  // ---------------------------
  console.log('value=>', value);
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('event>>', ev);
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const handleonSwitch = (evt, key) => {
    const ev = evt.target.value;
    console.log('Switch', ev, 'key', key);
    if (ev === 'Inactive') {
      let ev = '0';
      setValue({
        ...value,
        [key]: ev,
      });
    } else if (ev === 'Active') {
      let ev = '1';
      setValue({
        ...value,
        [key]: ev,
      });
    }
  };
  const handleSingleonChange = (evt, key) => {
    console.log('handleSingleonChange', evt.blocks[0].text);
    const ev = evt.blocks[0].text;
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  const onClickSubmit = () => {
    setLoading(true);
    console.log('Onsubmit-value', value);
    console.log('Onsubmit-mandatory', mandatoryFields);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    console.log('iferror', ifError);
    if (ifError) {
      // setError('Please enter mandatory fields');
      setLoading(false);
      toast({
        title: 'Please enter mandatory fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      let tempProductType = '';

      if (value.productType === 'Mobile Internet') {
        tempProductType = 0;
      } else if (value.productType === 'Mobile Internet Add-on') {
        tempProductType = 1;
      }

      let body = {
        xoxProductId: value.xoxProductId,
        name: value.name,
        isMobile: value.isMobile,
        description: value.description,
        productType: tempProductType,
        valueRM: value.valueRM,
        additional: value.additional,
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
        status: value.status,
      };
      console.log('Value==>', value, 'body=>', body);
      apiManager
        .post('addProduct', body)
        .then(res => {
          console.log('res=>', res);
          if (res.message === 8243) {
            setLoading(false);
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          } else if (res.message === 8058) {
            setLoading(false);
            console.log('check', res.err.keyValue);

            toast({
              title: 'XOX Product Id / Name',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Must Not be Duplicate.',
            });
          } else {
            setLoading(false);
            setError('Please enter mandatory fields');
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error', error);
        });
    }
  };

  const onClickUpdate = () => {
    console.log('Api Run');
    const body = {
      id: location.state.val._id,
      XOXProductID: value.xoxProductId,
      name: value.name,
      productType: value.productType,
      ValueRM: value.valueRM,
      Additional: value.additional,
      startDate: value.startDate,
      endDate: value.endDate,
      status: value.status,
    };
    console.log('body>>>>', body);
    apiManager
      .post('updateProduct', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8246) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Product',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Product Management',
              useSlash: true,
              path2: 'Edit Product',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'XOX Product ID',
                mandatory: true,
                onChange: e => handleonChange(e, 'xoxProductId'),
                value: value['xoxProductId'],
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={2}
            >
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>

            <Flex
              padding={5}
              marginTop={5}
              borderWidth={1}
              flexDirection={'column'}
              height={'50vh'}
              width={'100%'}
            >
              <Text marginX={1} marginY={2} fontSize={'sm'}>
                Description
              </Text>
              <ControlledEditor
                tempData={e => handleSingleonChange(e, 'description')}
              />
            </Flex>

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={5}
            >
              {dropdownCustom({
                title: 'Product Type',
                placeholder: 'Select Option',
                mandatory: true,
                onChange: e => handleonChange(e, 'productType'),
                value: value['productType'],
              })}
              {}
              {customInputLabel({
                title: 'Value (RM)',
                mandatory: true,
                onChange: e => handleonChange(e, 'valueRM'),
                value: value['valueRM'],
              })}
              {customInputLabel({
                title: 'Additional',
                mandatory: true,
                onChange: e => handleonChange(e, 'additional'),
                value: value['additional'],
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Start Date',
                mandatory: true,
                onChange: e => setStartD(e),
                // onChange: e => handleonChange(e, 'startDate'),
                value: new Date(value['startDate'] * 1000),
              })}
              {customInputLabel({
                title: 'End Date',
                mandatory: true,
                onChange: e => setendD(e),
                // onChange: e => handleonChange(e, 'endDate'),
                value: new Date(value['endDate'] * 1000),
              })}
              {dropdownCustom({
                title: 'Status',
                mandatory: true,
                onChange: e => handleonSwitch(e, 'status'),
                placeholder: 'Select Option',
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              onClickCancel: () => console.log('Cancel'),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Product',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Product Management',
              useSlash: true,
              path2: 'Create Product',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'XOX Product ID',
                mandatory: true,
                onChange: e => handleonChange(e, 'xoxProductId'),
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={2}
            >
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
              })}
              {dropdownCustomSvalue({
                title: 'IsMobile',
                mandatory: true,
                placeholder: 'Select Option',
                temparray: isMobileList,
                onChange: e => handleonChange(e, 'isMobile'),
              })}
            </SimpleGrid>

            <Flex
              padding={5}
              marginTop={5}
              borderWidth={1}
              flexDirection={'column'}
              height={'50vh'}
              width={'100%'}
            >
              <Text marginX={1} marginY={2} fontSize={'sm'}>
                Description
              </Text>
              <ControlledEditor
                tempData={e => handleSingleonChange(e, 'description')}
              />
            </Flex>

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={5}
            >
              {dropdownCustom({
                title: 'Product Type',
                mandatory: true,
                placeholder: 'Select Option',
                temparray: producttype,
                onChange: e => handleonChange(e, 'productType'),
              })}
              {customInputLabel({
                title: 'Value (RM)',
                mandatory: true,
                type: 'number',
                onChange: e => {
                  handleonChange(e, 'valueRM');
                },
              })}

              {customInputLabel({
                title: 'Additional',
                mandatory:
                  value.productType === 'Mobile Internet Add-on' ? true : false,
                disabled:
                  value.productType === 'Mobile Internet'
                    ? true
                    : value.productType === ''
                    ? true
                    : false,
                onChange: e => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    handleonChange(e, 'additional');
                  }
                },
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={5}
            >
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: true,
                selectedDate: startD,
                onChange: e => setStartD(e),
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: true,
                selectedDate: endD,
                onChange: e => setendD(e),
              })}
              {dropdownCustom({
                title: 'Status',
                mandatory: true,
                temparray: statusarray,
                placeholder: 'Select Option',
                onChange: e => handleonSwitch(e, 'status'),
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateProduct;
