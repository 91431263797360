import React from 'react';
import { Box, Button, Flex ,Text ,Select ,Spacer ,Input,useToast} from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import { ChecktableOne } from '../../components/common/ChecktableOne';
import { listStates, colStates } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../../config/apiManager';
import { deleteItem } from './BannerTypes';
import LangContext from '../../context/languageContext';
import { options } from '../../context/data';

export const States = () => {
  let history = useHistory();
  let toast = useToast();
  let apimanager = ApiManager.getInstance();
  const [dataa, setDataa] = React.useState();
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [success, setSuccess] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [paginat, setpaginate] = React.useState();
  let tempArray = [];
  // console.log("dataa>>>>>",dataa)
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value,1);
  }, [value]);

  const getALLItems = (limit,page) => {
    let apimanager = ApiManager.getInstance();
    setLoader(false);
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllState',body)
      .then(res => {
        if (res.message === 8224) {
          console.log('Get add country responce', res);
          let data = res?.results?.docs;
          // console.log('data>>>>>>>', data);
          res?.record?.docs?.map((value, index) => {
            // console.log("valuee",value)
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
        console.log('tempArray=>>>>>', tempArray);
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    // console.log('Deletion done', item.states._id);
    console.log('Delete done item',item);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deleteState', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8062) {
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          // getAllCategory(1);
          getALLItems();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader} boxShadow={'lg'} borderBottomWidth={'1px'}>
        {MiniHeader({
          title: 'States List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'App Setup',
          useSlash: 'true',
          path2: 'States',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateState')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            {/* <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
              <Input {...style.searchinput}></Input>
            </Flex> */}
          </Flex>
          <ChecktableOne
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            screenName="States"
            headerNames={colStates}
            onDelete={item => {
              deleteItem(item);
            }}
            onPageChange={page => getALLItems(value, page)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
