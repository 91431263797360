import { Icon, Flex, Text } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { NavLink, Link } from 'react-router-dom';

/**
 * @author
 * @function MenuItem
 **/

const MenuItem = props => {
  const { name, subMenus, iconClassName, onClick, to, exact, activity } = props;
  const [expand, setExpand] = useState(false);

  return (
    <li onClick={props.onClick}>
      <Link
        exact
        to={to}
        onClick={() => {
          setExpand(e => !e);
        }}
        className={`menu-item`}
      >
        <div className="menu-icon">
          <Icon>{iconClassName}</Icon>
        </div>
        <span>{name}</span>

        {subMenus && subMenus.length > 0 ? (
          !activity ? (
            <ChevronDownIcon position={'sticky'} left={'100%'} color={'gray'} />
          ) : null
        ) : // </div>
        null}
      </Link>

      {subMenus && subMenus.length > 0 ? (
        <ul className={`sub-menu ${expand ? 'active' : null}`}>
          {subMenus.map((menu, index) => (
            <>
              <li key={index}>
                <NavLink to={menu.to}>{menu.name}</NavLink>
              </li>
            </>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default MenuItem;
