import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Spacer,
  Input,
  Select,
  Tr,
  Th,
  Td,
  Table,
  Tbody,
  Thead,
  useToast,
} from '@chakra-ui/react';
import { options } from '../context/data';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import styles from '../components/common/style';
import { listAdmins, colAdmins } from '../context/data';
import LangContext from '../context/languageContext';
import Loading from '../components/common/Loading';
import { ChecktableTwo } from '../components/common/ChecktableOne';
import { MiniHeader } from '../components/common/customComponent';

const SearchOptimization = () => {
  let tempArray = [];

  let toast = useToast();
  let history = useHistory();

  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('10');
  const [data, setData] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = React.useState('');
  const [paginate, setpaginate] = React.useState();
  const [pageChange, setPageChange] = useState('');
  const { currentLangData } = useContext(LangContext);
  const [roleAccess, setRoleAccess] = React.useState([]);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [valueSearch, setValueSearch] = React.useState();
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);

  useEffect(() => {
    // getRoles();
    getAllCategory(page, value);
  }, []);

  // Utility functions //
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('next and previous', loading);
    setCurrentPage(selectedPage);
    getAllCategory(selectedPage + 1, value);
    console.log('clicked', selectedPage + 1);
  };

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('event', ev);
    setValue(ev);
    getAllCategory(page, ev);
  };

  let totalDatalength = paginate?.totalDocs;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  let pageCount = paginate?.totalPages;
  const pageCeilingCount = paginate?.totalPages;
  console.log(pageCeilingCount, 'PAGE COUNT', pageCount);

  useEffect(() => {}, [page]);
  useEffect(() => {}, [value]);

  const getRoles = () => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let tempRoleAccess = [];

    let body = {
      // page: page,
      // nextPage: 3,
      // numRecords: value,
    };

    apiManager
      .get('getAllCMSRoles', body)
      .then(res => {
        console.log('getAllCMSRoles=>RES', res);
        if (res.message === 4009) {
          console.log('Get getAllCMSRoles', res);

          res.records.docs.map((value, index) => {
            console.log('valuesssss', value?.roleName);
            tempRolesId.push([value?.roleName, value?.roleId]);
            tempArray.push(value?.roleName);
            tempRoleAccess.push([value?.roleAccess, value?.roleId]);
          });

          setRoleAccess(tempRoleAccess);
          setRoles(tempArray);
          setNameRoles(tempRolesId);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAllCategory = (page, value) => {
    setLoading(true);
    let tempArray = [];
    console.log('page=>', page);
    let body = {
      //   page: page,
      //   // nextPage: 3,
      //   numRecords: value,
    };
    apiManager
      .post('trendingSearchForCMS', body)
      .then(res => {
        console.log('trendingSearchForCMS=>RES', res);
        if (res.message === 8489) {
          console.log('Get trendingSearchForCMS', res);
          //   pageCount = res.records.totalDocs;
          res.result.map((value, index) => {
            // console.log('valuesssss', value);
            tempArray.push(value);
          });
          setpaginate(res.results);
          setData(tempArray);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const deleteRecord = data => {
    setLoading(true);

    console.log('deleteRecord', data);
    // deleteS3(data.companyLogo);
    let body = {
      id: data?._id,
    };
    apiManager
      .post('deleteGameReward', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 8605) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          getAllCategory(1, value);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleOnSearch = (evt, key) => {
    console.log("e.key === 'Enter==>", evt);
    const ev = evt.target.value;

    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };

  const searchItems = (value, limit, page) => {
    console.log('searchItems', value, limit, page);
    setLoading(true);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apiManager
      .post('searchGameRewards', body)
      .then(res => {
        console.log('res=>', res.message);
        if (res.message === 8614) {
          res?.record?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setData(tempArray);
          setpaginate(res?.record);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreateActions',
      state: { data: editData, useEditApi: true },
    });
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Trending Search',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Search Optimization',
          useSlash: true,
          path2: 'Trending Search',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}></Flex>

          <Flex {...style.optionFlex}>
            <Flex
              {...style.showentries}
              alignItems={'center'}
              fontSize={'sm'}
            ></Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}></Flex>
          </Flex>
          <Flex padding={'1%'} flexDirection={'column'}>
          <Flex {...style.tableFlex}>
            <Table {...styles.table} padding={'1%'}>
              <Thead {...styles.thead}>
                <Tr>
                  <Th {...styles.th}>Searched Keywords</Th>
                  <Th {...styles.th}></Th>
                  <Th {...styles.th}>Search Count</Th>
                </Tr>
              </Thead>
              <Tbody {...styles.tbody}>
                {!loading ? (
                  data &&
                  data?.map((value, index) => {
                    console.log('Data value=>', value);

                    if (index >= 0) {
                      return (
                        <>
                          <Tr key={index} {...styles.tr}>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?._id}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            ></Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.count}
                            </Td>
                          </Tr>
                        </>
                      );
                    } else {
                      return (
                        <Tr height={'40vh'}>
                          <Td></Td>
                          <Td></Td>
                          <Td>
                            <Text>NO DATA AVAILABLE</Text>
                          </Td>
                          <Td></Td>
                          <Td></Td>
                        </Tr>
                      );
                    }
                  })
                ) : (
                  <Tr>
                    <Td></Td>
                    <Td paddingY={'15vh'}>
                      <Flex
                        style={{
                          w: '100%',
                          h: '100%',
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Loading />
                      </Flex>
                    </Td>
                    <Td></Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            </Flex>
            <Flex {...style.tablefooter}>
            <Flex>
            <Text >Showing {data.length} entries</Text></Flex>
            <Flex {...style.paginatecontainer}>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              activeClassName={'active'}
            />
              </Flex></Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SearchOptimization;
