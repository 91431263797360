import React, { useState, useEffect } from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';

import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  MiniHeader,
} from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

const CreateAdmin = () => {
  let initialValue = {
    email: '',
    role: '',
    userId: '',
    oldRoleName: '',
    oldRoleId: '',
  };

  const [value, setValue] = React.useState(initialValue);
  const [currentEmail, setCurrentEmail] = useState('');
  const [status, setstatus] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [changeApi, setChangeApi] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [oldRole, setOldRole] = React.useState('');
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const location = useLocation();
  const toast = useToast();

  let mandatoryFields = {
    email: true,
    role: true,
  };

  var oldNameVariable = '';

  useEffect(() => {
    if (location?.state?.data != null || location?.state?.data != undefined) {
      console.log('PREVIOUS DATA', location);
      setChangeApi(location?.state?.useEditApi);
      let dataOfEdit = location?.state?.data;

      getAllCmsRoless(dataOfEdit?.role);
      setValue({
        ...value,
        email: dataOfEdit?.email,
        userId: dataOfEdit?._id,
        role: dataOfEdit?.role,
        oldRoleId: dataOfEdit?.role,
      });
      setCurrentEmail(dataOfEdit?.email);
      console.log('nameRoles', nameRoles);
    } else {
      getAllCmsRoless();
    }
  }, []);

  const convertToName = (previousId, roleData) => {
    console.log('IDD=>', previousId);
    console.log('roleData=>', roleData);
    let idSingle = '';

    let tempName = '';
    roleData?.map((dat, index) => {
      console.log('dat==<name and id>', dat);
      console.log('dat==<Name>', dat[0]);
      console.log('dat==<ID>', dat[1]);
      if (previousId === dat[1]) {
        console.log('dat[1]', dat);
        idSingle = dat[1];
        tempName = dat[0];
      }
    });

    setValue({
      // value,
      oldRoleName: tempName,
    });
    setOldRole(tempName);
    oldNameVariable = tempName;
    // setOldRole(oldNameVariable);

    console.log('Previous id=>', idSingle);
    console.log('Previous name=>', tempName);
    console.log('Previous oldNameVariable=>', oldNameVariable);
  };

  const getCMSUser = email => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let body = {
      email: email,
    };
    console.log('BODYY=>', body);
    apimanager
      .get('getCMSUser', email)
      .then(res => {
        console.log('getCMSUser=>RES', res);
        // if (res.message === 4009) {
        //   console.log('Get getCMSUser', res);
        //   // pageCount = res.results.totalDocs;
        //   res.records.docs.map((value, index) => {
        //     console.log('valuesssss', value?.roleName);
        //     // tempRolesId.push([value?.roleName, value?.roleId]);
        //     // tempArray.push(value?.roleName);
        //   });
        //   // setRoles(tempArray);
        //   // setNameRoles(tempRolesId);
        //   // setpaginate(res.results);
        //   // setData(tempArray);
        //   setLoading(false);
        // }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAllCmsRoless = previousID => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let body = {
      // page: page,
      // nextPage: 3,
      // numRecords: value,
    };
    apimanager
      .get('getAllCMSRoles', body)
      .then(res => {
        console.log('getAllCMSRoles=>RES', res);
        if (res.message === 4009) {
          console.log('Get getAllCMSRoles', res);
          // pageCount = res.results.totalDocs;
          res.records.docs.map((value, index) => {
            tempRolesId.push([value?.roleName, value?.roleId]);
            tempArray.push(value?.roleName);
          });
          setNameRoles(tempRolesId);
          convertToName(previousID, tempRolesId);
          setRoles(tempArray);

          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    console.log(value, 'Value');
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      console.log('nameRoles', nameRoles);
      let idSingle = '';
      let tempName = '';
      let id = nameRoles?.map((dat, index) => {
        console.log('dat==<>', dat);
        console.log('dat==<>', dat[0]);
        console.log('dat==<>', dat[1]);
        if (dat[0] === value?.role) {
          console.log('dat[1]', dat[1]);
          idSingle = dat[1];
          tempName = dat[0];
        }
      });
      console.log('IDDD==>', id);
      console.log('idSingle==>', idSingle);
      console.log('oldRoleId==>', value?.oldRoleId);

      const body = {
        email: value?.email,
        role: idSingle ? idSingle : value['oldRoleId'],
      };
      console.log('body>>>>', body);

      if (!changeApi) {
        apimanager
          .post('addCMSUser', body)
          .then(response => {
            console.log(response, 'response');
            if (response.message === 4002) {
              setSuccess('Successfully Recorded!');
              setLoading(false);
              toast({
                title: success,
                duration: 3000,
                isClosable: true,
                status: 'success',
                description: `An email has been sent with temporary Password!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 4000);
            }
            if (response.message === 4001) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Role is not defined',
                description: `Please Select Role!`,
              });
            }
            if (response.message === 7063) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Email Duplication',
                description: `Use Different Email!`,
              });
            } else {
              setLoading(false);
              setError('Please enter mandatory fields');
            }
          })
          .catch(error => {
            setLoading(false);
            console.log(error);
          });
      } else {
        const body = {
          // userId: value?.userId,
          // oldRole: oldRole,
          // newRole: value['role'],

          email: currentEmail,
          newRole: idSingle ? idSingle : value['oldRoleId'],
        };
        console.log('Update body', body);
        apimanager
          .post('changeCMSUserRole', body)
          .then(response => {
            console.log(response, 'response');
            if (response.message === 4028) {
              setSuccess('Successfully Recorded!');
              setLoading(false);
              toast({
                title: success,
                duration: 2000,
                isClosable: true,
                status: 'success',
                description: `User has been updated!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }
            if (response.message === 7057) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Error',
                description: `Database error!`,
              });
            }
            if (response.message === 4025) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Failed',
                description: `Cannot update default user!`,
              });
            }
            if (response.message === 4026) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Failed! Please select the role',
                description: `New Role Not Found!`,
              });
            }
            if (response.message === 4027) {
              setLoading(false);
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Failed',
                description: `Unable to update specific user!`,
              });
            } else {
              setLoading(false);
              console.log('Response Error=>', response?.errors[0]?.msg);
              setError(response?.errors[0]?.msg);
              toast({
                status: 'error',
                duration: 4000,
                isClosable: true,
                title: response?.errors[0]?.msg,
                description: response?.errors[1]?.msg,
              });
            }
          })
          .catch(error => {
            setLoading(false);
            console.log(error);
          });
      }
    }
  };
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: !changeApi ? 'Create User' : 'Update User',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'User',
          useSlash: true,
          path2: 'Admin',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {!changeApi
            ? customInputLabel({
                title: 'Email',
                value: value['email'],
                mandatory: true,
                onChange: e => handleonChange(e, 'email'),
              })
            : customInputLabel({
                title: 'Email',
                value: currentEmail,
                disabled: true,
                mandatory: true,
                onChange: e => setCurrentEmail(e.target.value),
              })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {dropdownCustom({
            title: 'Role',
            mandatory: true,
            temparray: roles,
            placeholder: value['oldRoleName'] ? value['oldRoleName'] : 'Select Option' ,
            onChange: e => handleonChange(e, 'role'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Update',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>
      <Spacer />
      <Divider
        marginLeft={10}
        orientation="horizontal"
        marginTop={5}
        marginBottom={2}
      />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateAdmin;
