import React, { useState } from 'react';
import {
  Flex,
  Spacer,
  Divider,
  Textarea,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
const CreateSoftpin = () => {
  let history = useHistory();
  let location = useLocation();
  let tempArray = [];

  const toast = useToast();
  let apiManager = ApiManager.getInstance();

  let initialValue = {
    category: '',
    pinNumber: '',
    serialNumber: '',
    // price: '',
    // amount: '',
    minStockQuantity: '',
    expiryDate: '',
  };

  let mandatoryFields = {
    // category: true,
    // expiryDate: true,
    // price: true,
    // amount: true,
    pinNumber: true,
    // minStockQuantity: true,
    serialNumber: true,
  };

  const [error, setError] = useState(null);
  const [pObject, setPObject] = useState([]);
  const [success, setSuccess] = useState(null);
  const [expiryD, setExpiryD] = useState(new Date());
  const [category, setCategory] = useState([]);
  const [value, setValue] = useState(initialValue);
  const [status, setstatus] = React.useState(false);

  // Handle Functions //
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };
  React.useEffect(() => {
    getAllCategory();
  }, []);
  React.useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val?.category)
      setValue(_p => ({ ..._p, category: location.state.val.category }));
    if (location.state?.val?.pinNumber)
      setValue(_p => ({ ..._p, pinNumber: location.state.val.pinNumber }));
    if (location.state?.val?.serialNumber)
      setValue(_p => ({
        ..._p,
        serialNumber: location.state.val.serialNumber,
      }));
    if (location.state?.val?.price)
      setValue(_p => ({ ..._p, price: location.state.val.price }));
    if (location.state?.val?.amount)
      setValue(_p => ({ ..._p, amount: location.state.val.amount }));
    if (location.state?.val?.minStockQuantity)
      setValue(_p => ({
        ..._p,
        minStockQuantity: location.state.val.minStockQuantity,
      }));
    if (location.state?.val?.expiryDate)
      setValue(_p => ({ ..._p, expiryDate: location.state.val.expiryDate }));

    console.log('pathdata', location.state);
  }, [location]);

  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };
  // Api calling //
  const getAllCategory = () => {
    apiManager
      .get('getAllSoftpinsCategories')
      .then(res => {
        console.log('RESgetAllSoftpinsCategories=>', res);
        if (res.message === 8208) {
          // console.log('REs', res);
          res.results.docs.map((value, index) => {
            // console.log('valuesssss', value.name);
            tempArray.push({ list: value?.name, val: value?._id });
          });
          setCategory(tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  console.log('categoryArray', category);
  console.log('category', value);
  const onClickSubmit = () => {
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      let body = {
        categoryId: value.category,
        pinNumber: value.pinNumber,
        serialNumber: value.serialNumber,
        // status: parseInt(value?.status),
        // price: value.price,
        // amount: value.amount,
        minStockQuantity: value.minStockQuantity,
        expiryDate: String(convertedDate(expiryD)),
      };
      console.log('body=>', body);
      apiManager
        .post('addSoftpin', body)
        .then(res => {
          console.log('res=> addSoftpin', res);
          if (res.message === 8210) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          } else {
            setError('Please enter mandatory fields');
          }
          if (res.message === 6176) {
            setError('Duplicate Values ');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Serial No / Pin Number Must Not Dublicte!`,
            });
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };

  // console.log('category=>', category);
  const onClickUpdate = () => {
    console.log('Api Run');
    const body = {
      id: location.state.val._id,
      category: value?.category,
      pinNumber: value?.pinNumber,
      // price: value.price,
      // amount: value.amount,
      serialNumber: value?.serialNumber,
      // minStockQuantity: value.minStockQuantity,
      expiryDate: value?.expiryDate,
    };
    console.log('body>>>>', body);
    apiManager
      .post('updateSoftpin', body)
      .then(response => {
        // console.log(response, 'response');
        if (response.message === 8216) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      {status ? (
        <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} h={'80vh'}>
          <Flex
            {...style.miniheader}
            // marginBottom={'2%'}
          >
            {MiniHeader({
              title: 'Edit Softpin',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Softpin Management',
              useSlash: true,
              path2: 'Edit Softpin',
            })}
          </Flex>

          <Flex {...style.box} paddingX={'2%'}>
            <Flex {...styles.parentFlex}>
              <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
                {dropdownCustomSvalue({
                  mandatory: true,
                  temparray: category,
                  title: 'Softpin Category',
                  onChange: e => handleonChange(e, 'category'),
                  value: value['category'],
                })}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={3}
                spacingX={'50px'}
                marginTop={4}
              >
                {customInputLabel({
                  title: 'Pin Number',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'pinNumber'),
                  value: value['pinNumber'],
                })}
                {customInputLabel({
                  title: 'Serial Number',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'serialNumber'),
                  value: value['serialNumber'],
                })}
                {customDateWithTitle({
                  title: 'Expiry Date',
                  mandatory: true,
                  selectedDate: expiryD,
                  onChange: e => setExpiryD(e),
                })}
              </SimpleGrid>

              {/* <SimpleGrid
                width={'100%'}
                columns={2}
                spacingX={'50px'}
                marginTop={4}
              > */}
              {/* {customInputLabel({
                  title: 'Price',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'price'),
                  value: value['price'],
                })}
                {customInputLabel({
                  title: 'Amount',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'amount'),
                  value: value['amount'],
                })} */}
              {/* {customInputLabel({
                  title: 'Minimum Stock Quantity',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'minStockQuantity'),
                  value: value['minStockQuantity'],
                })} */}

              {/* </SimpleGrid> */}

              {customUpdateCancelBtn({
                title1: 'Update',
                title2: 'Cancel',
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickUpdate(),
              })}
            </Flex>
          </Flex>
          <Spacer />
          <Divider
            marginStart={10}
            orientation="horizontal"
            marginTop={5}
            marginBottom={2}
          />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} h={'80vh'}>
          <Flex
            {...style.miniheader}
            // marginBottom={'2%'}
          >
            {MiniHeader({
              title: 'Create Softpin',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Softpin Management',
              useSlash: true,
              path2: 'Create Softpin',
            })}
          </Flex>

          <Flex {...style.box} paddingX={'2%'}>
            <Flex {...styles.parentFlex}>
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {dropdownCustomSvalue({
                  mandatory: true,
                  temparray: category,
                  title: 'Softpin Category',
                  placeholder:'Select Category',
                  onChange: e => handleonChange(e, 'category'),
                })}
                {customInputLabel({
                  title: 'Serial Number(In Number)',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'serialNumber'),
                })}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={2}
                spacingX={'50px'}
                marginTop={4}
              >
                {customInputLabel({
                  title: 'Pin Number(In Number)',
                  mandatory: true,
                  type: 'number',
                  onChange: e => handleonChange(e, 'pinNumber'),
                })}
                {customDateWithTitle({
                  title: 'Expiry Date',
                  mandatory: true,
                  selectedDate: expiryD,
                  onChange: e => setExpiryD(e),
                })}
              </SimpleGrid>

              {customUpdateCancelBtn({
                title1: 'Submit',
                title2: 'Cancel',
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickSubmit(),
              })}
            </Flex>
          </Flex>
          <Spacer />
          <Divider
            marginStart={10}
            orientation="horizontal"
            marginTop={5}
            marginBottom={2}
          />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateSoftpin;
