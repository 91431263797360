import React from 'react';
import {
  Flex,
  Spacer,
  Divider,
  Skeleton,
  SimpleGrid,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  DashComponent,
  TableCustom,
  RenderSoftpin,
} from '../components/dashboardComponent';
import LangContext from '../context/languageContext';
import styles from '../styles/dashboardStyles';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import ApiManager from '../config/apiManager';

const Dashboard = () => {
  const [skeltonLoad, setSkeletonLoad] = React.useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  // --------------Data States----------------------
  const [dataa, setDataa] = React.useState();
  const [pANDn, setPandNStatus] = React.useState();
  const [producttype, setproducttype] = React.useState();
  const [compaigns, setCompaigns] = React.useState();
  const [softpinTable, setSoftpinTable] = React.useState();
  // -------------total Sum States---------------------------------
  const [totalBanner, setTotalBanner] = React.useState();
  const [totalpANDn, setTotalpANDn] = React.useState();
  const [totalProduct, setTotalProduct] = React.useState();
  const [totalCompaign, setTotalCompaign] = React.useState();

  const { currentLangData } = React.useContext(LangContext);
  const [loader, setLoader] = React.useState(false);

  let apimanager = ApiManager.getInstance();
  let tempArray = [];
  let temppromotionNews = [];
  let tempproductType = [];
  let tempCompaigns = [];
  let tempSoftpinTable = [];
  let tempTotalbanner = [];
  let tempTotalpANDn = [];
  let tempTotalproduct = [];
  let tempTotalcompaign = [];
  let tempTotalsoftpin = [];

  React.useEffect(() => {
    bannerStatusReport();
    promotionAndNewsStatusReport();
    productTypeReport();
    campaignsStatusReport();
    SoftpinStockWarning();
  }, []);

  const bannerStatusReport = () => {
    setSkeletonLoad(false);
    apimanager
      .get('bannerStatusReport')
      .then(res => {
        // console.log('responce', res);
        if (res.message === 8545) {
          res?.data?.map((value, index) => {
            // console.log("valuee",value)
            tempArray.push(value);
            tempTotalbanner.push(value?.total);
          });
          setDataa(tempArray);
          let add = tempTotalbanner?.reduce((accumulator, current) => {
            return accumulator + current;
          });
          setTotalBanner(add);
          setSkeletonLoad(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setSkeletonLoad(true);
      });
  };
  const promotionAndNewsStatusReport = () => {
    setSkeletonLoad(false);
    apimanager
      .get('promotionAndNewsStatusReport')
      .then(res => {
        // console.log('responce>promotionAndNewsStatusReport', res);
        if (res.message === 8373) {
          res?.data?.map((value, index) => {
            // console.log("valuee",value)
            temppromotionNews.push(value);
            tempTotalpANDn.push(value?.total);
          });
          setPandNStatus(temppromotionNews);
        }
        let add = tempTotalpANDn?.reduce((accumulator, current) => {
          return accumulator + current;
        });
        setTotalpANDn(add);
        setSkeletonLoad(true);
      })
      .catch(error => {
        console.log('error', error);
        setSkeletonLoad(true);
      });
  };
  const productTypeReport = () => {
    setSkeletonLoad(false);
    apimanager
      .get('productTypeReport')
      .then(res => {
        // console.log('responce=>productTypeReport', res);
        if (res.message === 8546) {
          res?.data?.map((value, index) => {
            // console.log("valuee",value)
            tempproductType.push(value);
            tempTotalproduct.push(value?.total);
          });
          setproducttype(tempproductType);
          let add = tempTotalproduct?.reduce((accumulator, current) => {
            return accumulator + current;
          });
          setTotalProduct(add);
          setSkeletonLoad(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setSkeletonLoad(true);
      });
  };
  const campaignsStatusReport = () => {
    setSkeletonLoad(false);
    apimanager
      .get('campaignsStatusReport')
      .then(res => {
        // console.log('responce>campaignsStatusReport', res);
        if (res.message === 8547) {
          res?.data?.map((value, index) => {
            // console.log("valuee",value)
            tempCompaigns.push(value);
            tempTotalcompaign.push(value?.total);
          });
          setCompaigns(tempCompaigns);
          let add = tempTotalcompaign?.reduce((accumulator, current) => {
            return accumulator + current;
          });
          setTotalCompaign(add);
          setSkeletonLoad(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setSkeletonLoad(true);
      });
  };
  const SoftpinStockWarning = () => {
    setSkeletonLoad(false);
    apimanager
      .get('getSoftpinStockWarning')
      .then(res => {
        // console.log('responce>getSoftpinStockWarning', res);
        if (res.message === 8548) {
          res?.data?.map((value, index) => {
            // console.log("valuee",value)
            tempSoftpinTable.push(value);
          });
          setSoftpinTable(tempSoftpinTable);

          setSkeletonLoad(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setSkeletonLoad(true);
      });
  };

  return (
    <>
      <Flex {...styles.parentFlex}>
        {skeltonLoad ? (
          <>
            <SimpleGrid
              {...styles.simpleGridStyle}
              columns={isMobile ? 1 : 2}
              zIndex={1}
            >
              {DashComponent({
                title: 'Banner',
                dataArray: dataa,
                totalArray: totalBanner,
                image: '</FaRegImages>',
              })}
              {DashComponent({
                title: 'Promotion & News',
                dataArray: pANDn,
                totalArray: totalpANDn,
              })}
              {DashComponent({
                title: 'Products',
                dataArray: producttype,
                totalArray: totalProduct,
              })}
              {DashComponent({
                title: 'Compaigns',
                dataArray: compaigns,
                totalArray: totalCompaign,
              })}
            </SimpleGrid>

            <SimpleGrid {...styles.simpleGridSecond} columns={isMobile ? 1 : 1}>
              {RenderSoftpin()}
              <Flex>
                <TableCustom dataArray={softpinTable} />
              </Flex>
            </SimpleGrid>
          </>
        ) : (
          <Flex {...styles.checkSimpleGridfirst}>
            <SimpleGrid
              {...styles.simpleGridStyle}
              marginTop={'0'}
              columns={isMobile ? 1 : 2}
            >
              <Skeleton h={'30vh'} width={'100%'} />
              <Skeleton h={'30vh'} width={'100%'} />
              <Skeleton h={'30vh'} width={'100%'} />
              <Skeleton h={'30vh'} width={'100%'} />
            </SimpleGrid>

            <SimpleGrid
              {...styles.simpleGridStyle}
              marginTop={5}
              columns={isMobile ? 1 : 1}
            >
              <Skeleton h={'5vh'} width={'100%'} />
              <Skeleton h={'5vh'} width={'100%'} />
              <Skeleton h={'5vh'} width={'100%'} />
              <Skeleton h={'5vh'} width={'100%'} />
            </SimpleGrid>
          </Flex>
        )}

        <Spacer />
        <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
        <Flex position={'sticky'} marginLeft={4}>
          {renderCopyRight()}
        </Flex>
      </Flex>
    </>
  );
};
export default Dashboard;
