import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Box, Text } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
// import '.././../styles/styles.css';
import styles from './styles';
import theme from '../../config/color';

const CustomDateWithTitle = ({
  title,
  style,
  mandatory,
  onChange,
  selectedDate,
  value,
}) => {
  return (
    <Flex {...style} flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? null : <Text color={'#ff0000'}>*</Text>}
      </Flex>
      <Flex {...styles.iconDateContainer} borderColor={'lightgray'}>
        <CalendarIcon
          w={4}
          h={4}
          margin={'2'}
          color={'gray'}
          alignSelf={'center'}
        />
        <Flex h={'100%'} borderColor={'lightgray'} borderWidth={'0.1px'}></Flex>
        <DatePicker
          selected={selectedDate}
          value={value}
          onChange={onChange&&onChange}
          className="react-datepicker__without_border_fullWH"
        />
      </Flex>
    </Flex>
  );
};

export default CustomDateWithTitle;
