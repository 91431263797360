import React from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';
import {
Roles,
} from '../context/data';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';

import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

  const RegisterUser = () => {
    let initialValue = {
        email: '',
        role: '',
      };
      const [value, setValue] = React.useState(initialValue);
      const [status, setstatus] = React.useState(false);
      const [success, setSuccess] = React.useState(null);
      const [error, setError] = React.useState(null);
      let apimanager = ApiManager.getInstance();
      let history = useHistory();
      const location = useLocation();
      const toast = useToast();
      let token = location?.pathname;
      const usertoken = token.split('/RegisterUser/')
    
      let mandatoryFields = {
        firstName:true,
        lastName:true,
        password:true,
      };
    
      const handleonChange = (evt, key) => {
        const ev = evt.target.value;
        setValue({
          ...value,
          [key]: ev,
        });
        console.log('value=>', value);
      };
    
      const onClickSubmit = () => {
        let ifError = checkMandatory({
          value: value,
          mandatoryFields: mandatoryFields,
        });
    
        if (ifError) {
          setError('Please enter mandatory fields');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Submit.',
          });
        } else {
        const body = {
          token:usertoken[1],
          firstName: value?.firstName,
          lastName: value?.lastName,
          password:value?.password,
        };
        console.log('body>>>>', body);
        apimanager
          .post('registerCMSUser', body)
          .then(response => {
            console.log(response, 'response');
            if (response.message === 7007) {
              setSuccess('Successfully Recorded!');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Recorded, Your Name and Password is Registered!`,
              });
              setTimeout(() => {
                history.goBack();
              });
            } else {
              setError('Please enter mandatory fields');
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
      };
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
    <Flex {...style.miniheader} marginBottom={'2%'}>
         {MiniHeader({
           title: 'Registration',
           path: 'Dashboard',
        //    onclickForRoute: () => history.push('/Dashboard'),
           path1: 'Register',
        //    useSlash: true,
        //    path2: 'Admin',
         })}
       </Flex>

   <Flex {...styles.parentFlex}>
     <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
       {customInputLabel({
         title: 'First Name',
         mandatory: true,
         onChange: e => handleonChange(e, 'firstName'),
       })}
       {customInputLabel({
         title: 'Last Name',
         mandatory: true,
         onChange: e => handleonChange(e, 'lastName'),
       })}
     </SimpleGrid>
     <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
       {customInputLabel({
         title: 'Password',
         mandatory: true,
         onChange: e => handleonChange(e, 'password'),
       })}
     </SimpleGrid>

     {/* <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
       {dropdownCustom({
         title: 'Role',
         mandatory: true,
         temparray:Roles,
         onChange: e => handleonChange(e, 'role'),
       })}
     </SimpleGrid> */}
     {/* <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
       {customInputLabel({
         title: 'Profile Image',
         type: 'file',
       })}
     </SimpleGrid> */}

     {customUpdateCancelBtn({
       title1: 'Register',
       title2: 'Cancel',
       onClickCancel: () => console.log('Cancel'),
       onClickSubmit: () => onClickSubmit(),
     })}
   </Flex>
   <Spacer />
   <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
   <Flex position={'sticky'} marginLeft={4}>
     {renderCopyRight()}
   </Flex>
 </Flex>
  )
};
export default RegisterUser;
