import React, { useState } from 'react';
import {
  Flex,
  Td,
  Tr,
  useToast,
  Tooltip,
  SimpleGrid,
  Link,
  Button,
  Text,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import { dropdownCustomSvalue } from '../../components/bannerComponents/customBannerComponents';
import DatewithTimePicker from '../../components/DateComponent/datewithTImePicker';
import { ChecktableTwo } from '../../components/common/ChecktableTwo';
import style from './styles';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import { colNamesContactUs } from '../../context/data';
import ApiManager from '../../config/apiManager';
import LangContext from '../../context/languageContext';

const ContactUs = () => {
  let history = useHistory();
  let category = [
    { val: 1, list: 'Inquiries' },
    { val: 2, list: 'FeedBack/Bug Report' },
  ];
  // const [startDate, setStartDate] = useState(new Date());
  const [dataa, setDataa] = React.useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [sDate, setSDate] = useState('');
  const [eDate, setEDate] = useState('');
  const { currentLangData } = React.useContext(LangContext);
  const [categories, setCategory] = React.useState('');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', ev);
    setSDate('');
    setEDate('');
    setCategory(ev);
    getALLItems(sDate, eDate, ev,1);
  };
  // console.log(value?.Category,"<<<>>>")
  React.useEffect(() => {
    getALLItems();
  }, []);
  const handleDate = e => {
    // console.log('Date==>', e);
    let startDate = new Date(e[0]);
    let endDate = new Date(e[1]);
    // console.log('StartD =>',startDate.getTime());
    // console.log('EndD =>', endDate.getTime());
    let finalStartDate = Date.parse(startDate) / 1000;
    let finalEndDate = Date.parse(endDate) / 1000;
    setSDate(finalStartDate);
    setEDate(finalEndDate);
    setDateRange(e);
    // setType('');
    getALLItems(finalStartDate, finalEndDate, categories,1);
  };

  const getALLItems = (startDate, endDate, Category,page) => {
    // setLoader(false);
    let body = {
      startDate: startDate,
      endDate: endDate,
      category: Category,
      page:page,
    };
    // console.log('Body>>>', body);
    apimanager
      .post('getAllContactUs', body)
      .then(res => {
        // console.log('Response>>', res);
        if (res.message === 8625) {
          res?.result?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.result);
          setLoader(true);
        }
        if (res.message === 8336) {
          // setLoader(true);
          toast({
            title: 'Mongodb pagination Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
        if (res.message === 2454) {
          // setLoader(true);
          toast({
            title: 'No Data Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Add Data to Display.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        // setLoader(true);
      });
  };
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Contact Us Report',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: 'true',
          path2: 'Contact Us',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={5}>
            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={3}
            >
              {DatewithTimePicker({
                title: 'Date',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,
                changePicker: true,
                onChange: e => handleDate(e),
              })}
              {dropdownCustomSvalue({
                title: 'Category',
                mandatory: false,
                temparray: category,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'Category'),
              })}
            </SimpleGrid>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colNamesContactUs}
            onPageChange={page => getALLItems(sDate, eDate,categories, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              let releaseDate = new Date(item?.createdAt / 1000);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>
                    {new Date(item?.createdAt * 1000).toLocaleDateString()}
                  </Td>
                  <Td>{item?.name}</Td>
                  <Td>{item?.email}</Td>
                  <Td>{item?.phone}</Td>
                  <Td textAlign={'center'}>{item?.category}</Td>
                  <Td>
                    <Flex flexDirection={'row'} textAlign={'center'}>
                      <Flex mt={0.5} mr={2} noOfLines={1}>
                      <Tooltip label={item.message}>
                        {item?.message}
                        </Tooltip>
                      </Flex>
                      {item?.image ? (
                        <Link
                          color="teal.500"
                          href={item?.image}
                          paddingY={'2px'}
                          paddingX={'10px'}
                          isExternal
                        >
                          {currentLangData?.app?.seeImage}
                        </Link>
                      ) : null}
                    </Flex>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContactUs;
