import React, { useState } from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import Multiselect from 'multiselect-react-dropdown';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
  customInputImage,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import ApiManager from '../config/apiManager';
import { customSaveButton } from '../components/inboxMessagingComponent';
// import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { uploadImage } from '../config/imageUploader';

const CreatePromoCode = () => {
  const toast = useToast();
  let history = useHistory();
  let tempAlowedUser = [];
  let tempnotAllowedUser = [];
  let apiManager = ApiManager.getInstance();
  var promoType = [
    { val: 0, list: 'Discount' },
    { val: 1, list: 'Amount' },
  ];
  var assign = [
    { val: 0, list: 'Dealer' },
    { val: 1, list: 'Reseller' },
  ];
  // var showMobile = [
  //   { val: true, list: 'Yes' },
  //   { val: false, list: 'No' },
  // ];
  var Status = [
    { val: 0, list: 'Active' },
    { val: 1, list: 'Pending' },
    { val: 2, list: 'Close' },
  ];
  const location = useLocation();

  let initialValue = {
    title: '',
    description: '',
    couponCode: '',
    promoType: '',
    promoValue: '',
    discountValue: '',
    minimumAmount: '',
    quota: '',
    maximumUsePerUser: '',
    assignTo: '',
    showInMobile: '',
    status: '',
    allowedUser: '',
    notAllowedUser: '',
    promoImage: '',
  };

  let mandatoryFields = {
    title: true,
    description: true,
    couponCode: true,
    promoType: true,
    promoValue: true,
    notAllowedUser: true,
    allowedUser: true,
    // qoute: true,
    maximumUsePerUser: true,
    discountValue: true,
  };
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [status, setstatus] = useState(false);
  const [imageView, setImageView] = React.useState('');
  const [s3image, setS3Image] = useState('');
  const [showMobile, setShowMobile] = useState([
    { val: true, list: 'Yes' },
    { val: false, list: 'No' },
  ]);
  const [startD, setStartD] = useState(new Date());
  const [endD, setendD] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState([]);

  // Handle Functions //
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('valueEvent', evt.target.value);
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const onSelectedFuntion = (e, key) => {
    console.log('onSelect=>', e);
    setValue({
      ...value,
      [key]: e,
    });
  };
  console.log('value>>>>>>>>>>>>>>>value', value);
  React.useEffect(() => {
    getAllCategory();
  }, []);

  React.useEffect(() => {
    if (location?.status === 'create') {
      setstatus(true);
    }
    if (location?.state?.val?.title)
      setValue(_p => ({ ..._p, title: location?.state?.val?.title }));
    if (location?.state?.val?.description)
      setValue(_p => ({
        ..._p,
        description: location?.state?.val?.description,
      }));
    if (location?.state?.val?.couponCode)
      setValue(_p => ({ ..._p, couponCode: location?.state?.val?.couponCode }));
    if (location?.state?.val?.type == 0)
      setValue(_p => ({ ..._p, promoType: location?.state?.val?.type.toString() }));
    if (location?.state?.val?.type == 1)
      setValue(_p => ({ ..._p, promoType: location?.state?.val?.type.toString() }));
    if (location?.state?.val?.value)
      setValue(_p => ({ ..._p, promoValue: location?.state?.val?.value }));
    if (location?.state?.val?.maxValueForDiscount)
      setValue(_p => ({
        ..._p,
        discountValue: location?.state?.val?.maxValueForDiscount,
      }));
    if (location?.state?.val?.minimumAmount)
      setValue(_p => ({
        ..._p,
        minimumAmount: location?.state?.val?.minimumAmount,
      }));
    if (location?.state?.val?.startDate){
      var startDate = new Date(location?.state?.val?.startDate * 1000);
      setStartD(startDate)
      // setValue(_p => ({ ..._p, startDate: location?.state?.val?.startDate }));
    }
    if (location?.state?.val?.endDate){
      var endDate = new Date(location?.state?.val?.endDate * 1000);
      setendD(endDate)
      // setValue(_p => ({ ..._p, endDate: location?.state?.val?.endDate }));
    }
    if (location?.state?.val?.quota)
      setValue(_p => ({ ..._p, quota: location?.state?.val?.quota }));
    if (location?.state?.val?.maxUsePerUser){
      setValue(_p => ({
        ..._p,
        maximumUsePerUser: location?.state?.val?.maxUsePerUser,
      }));
    }
    if (location.state?.val?.assignTo === 0)
      setValue(_p => ({ ..._p, assignTo: location?.state?.val?.assignTo.toString()}));
    if (location.state?.val?.assignTo === 1)
      setValue(_p => ({ ..._p, assignTo: location?.state?.val?.assignTo.toString()}));
    if (location.state?.val?.showInMobile == false){
      setValue(_p => ({
        ..._p,
        showInMobile: location?.state?.val?.showInMobile.toString(),
      }));
    }
    if (location.state?.val?.showInMobile == true){
      setValue(_p => ({
        ..._p,
        showInMobile: location?.state?.val?.showInMobile.toString(),
      }));
    }

    if (location?.state?.val?.status === 0)
      setValue(_p => ({ ..._p, status: location?.state?.val?.status.toString()}));
    if (location?.state?.val?.status === 1)
      setValue(_p => ({ ..._p, status: location?.state?.val?.status.toString()}));
    if (location?.state?.val?.status === 2)
      setValue(_p => ({ ..._p, status: location?.state?.val?.status.toString()}));
    if (location?.state?.val?.allowedUsers){
      location?.state?.val?.allowedUsers.map((val, index) => {
        tempAlowedUser.push(val);
      });
    setValue(_p => ({
      ..._p,
      allowedUser: tempAlowedUser,
    }));
  }
    if (location?.state?.val?.notAllowedUsers){
      location?.state?.val?.notAllowedUsers.map((val, index) => {
        tempnotAllowedUser.push(val);
      });
    setValue(_p => ({
      ..._p,
      notAllowedUser: tempnotAllowedUser,
    }));
  }
    if (location?.state?.val?.promoImage){
      setValue(_p => ({ ..._p, promoImage: location?.state?.val?.promoImage }));
    }
    console.log('pathdata', location?.state?.val);
  }, [location]);
  console.log('Array', tempAlowedUser);
  console.log('valueee-->',value);

  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  // API CALLING //
  const getAllCategory = () => {
    // setLoading(true);
    let tempArray = [];
    // console.log('page=>', page);
    apiManager
      .get('getAllCMSUsers')
      .then(res => {
        if (res.message === 7055) {
          console.log('Get getAllCMSUsers', res);
          res.results.docs.map((value, index) => {
            // console.log("usersNames",value.firstName+value.lastName)
            tempArray.push(value?.firstName + value?.lastName);
          });
          setData(tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  console.log('DataDropdown>>', data);
  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      let body = {
        title: value?.title,
        description: value?.description,
        couponCode: value?.couponCode,
        type: value?.promoType, //type
        value: value?.promoValue,
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
        quota: value?.quota,
        maxUsePerUser: value?.maximumUsePerUser,
        maxValueForDiscount: value?.discountValue,
        minimumAmount: value?.minimumAmount,
        showInMobile: value?.showInMobile,
        promoImage: s3image,
        status: value?.status,
        assignTo: value?.assignTo, //
        allowedUsers: value?.allowedUser, //
        notAllowedUsers: value?.notAllowedUser, //
      };

      console.log('body addPromoCode-->',body);

      apiManager
        .post('addPromoCode', body)
        .then(res => {
          console.log('res=>addpromoCode', res);
          if (res.message === 8100) {
            setLoading(false);
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          } else {
            setLoading(false);
            setError('Please enter mandatory fields');
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };
  const upload = e => {
    setImageLoading(true);
    console.log('e.target=>', e.target.files[0].name);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('image uri', res.location);
        setS3Image(e.target.files[0].name);
        setImageView(res?.location);
        setImageLoading(false);
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  // -----------------------------------
  const onClickUpdate = () => {
    setLoading(true);
    console.log('Api Run');
    const body = {
      id: location.state.val._id,
      title: value.title,
      description: value.description,
      couponCode: value.couponCode,
      type: value.promoType,
      value: value.promoValue,
      maxValueForDiscount: value.discountValue,
      minimumAmount: value.minimumAmount,
      startDate: String(convertedDate(startD)),
      endDate: String(convertedDate(endD)),
      quota: Number(value.quota),
      maximumUsePerUse: value.maximumUsePerUser,
      assignTo: value.assignTo,
      showInMobile: value.showInMobile,
      status: value.status,
      allowedUsers: value.allowedUser,
      notAllowedUsers: value.notAllowedUser,
      promoImage: s3image,
    };
    console.log('body>>>>', body);
    apiManager
      .post('updatePromoCode', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8102) {
          setLoading(false);
          setSuccess('Successfully Updated!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Updated, Please processed to List!`,
          });
          setTimeout(() => {
            setLoading(false);  
            history.goBack();
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleImageSubmit = async e => {
    console.log('ImageEvent>>>>', e.target.files[0].name);
    await uploadImage(e.target.files[0])
      .then(res => {
        console.log('awsResponce>>>', res.location);
        setS3Image(e.target.files[0].name);
      })
      .catch(error => {
        console.log('error>>>>', error);
      });
  };
  const onRemoveFuntionAllowedUser = e => {
    console.log('onRemoveFuntion=>', e);
    if (e === '') {
      setValue({
        ...value,
        allowedUser: '',
      });
    } else {
      setValue({
        ...value,
        allowedUser: e,
      });
    }
  };
  const onRemoveFuntionnotAllowedUser = e => {
    console.log('onRemoveFuntion=>', e);
    if (e === '') {
      setValue({
        ...value,
        notAllowedUser: '',
      });
    } else {
      setValue({
        ...value,
        notAllowedUser: e,
      });
    }
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Promo Code',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Promo Code Management',
              useSlash: true,
              path2: 'Edit Promo Code',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Title',
                mandatory: true,
                onChange: e => handleonChange(e, 'title'),
                value: value['title'],
              })}
            </SimpleGrid>
            {customTextarea({
              title: 'Description',
              mandatory: true,
              onChange: e => handleonChange(e, 'description'),
              value: value['description'],
            })}

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={3}
            >
              {customInputLabel({
                title: 'Coupon Code',
                mandatory: true,
                value:value?.couponCode,
                onChange: e => handleonChange(e, 'couponCode'),
              })}
              {dropdownCustomSvalue({
                title: 'Promo Type',
                mandatory: true,
                placeholder:value?.promoType === '0' ? 'Discount' : 'Amount',
                temparray: promoType,
                onChange: e => handleonChange(e, 'promoType'),
              })}
              {customInputLabel({
                title: 'Promo Value',
                mandatory: true,
                value:value?.promoValue,
                onChange: e => handleonChange(e, 'promoValue'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={3}
            >
              {customInputLabel({
                title: 'MaximumValueforDiscount',
                mandatory: true,
                onChange: e => handleonChange(e, 'discountValue'),
                value: value['discountValue'],
              })}
              {customInputLabel({
                title: 'Minimum Amount',
                mandatory: true,
                onChange: e => handleonChange(e, 'minimumAmount'),
                value: value['minimumAmount'],
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={3}
            >
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: true,
                selectedDate: startD,
                // startdate.toLocaleDateString()
                // value:startD.toLocaleDateString(),
                onChange: e => setStartD(e),
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: true,
                selectedDate: endD,
                // value:endD.toLocaleDateString(),
                onChange: e => setendD(e),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={3}
            >
              {customInputLabel({
                title: 'Quote',
                mandatory: true,
                value: value['quota'],
                onChange: e => handleonChange(e, 'quota'),
                
              })}
              {customInputLabel({
                title: 'Maximum Use Per User',
                mandatory: true,
                value: value['maximumUsePerUser'],
                onChange: e => handleonChange(e, 'maximumUsePerUser'),

              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={4}
            >
              {dropdownCustomSvalue({
                title: 'Assigned To',
                temparray:assign,
                placeholder: value['assignTo'] === '0' ? 'Dealer' : 'Reseller',
                onChange: e => handleonChange(e, 'assignTo'),
              })}
              {console.log("jhhjh",value['assignTo'])}
              {dropdownCustomSvalue({
                title: 'Show in Mobile',
                mandatory: true,
                temparray:showMobile,
                placeholder: value['showInMobile'] === 'false' ? 'No' : 'Yes',
                onChange: e => handleonChange(e, 'showInMobile'),
                
              })}
              {dropdownCustomSvalue({
                title: 'Status',
                mandatory: true,
                temparray:Status,
                placeholder: value['status'] === '0' ? 'Active' : (value['status'] === '1') ? 'Pending' : 'Close',
                onChange: e => handleonChange(e, 'status'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              <Flex marginBottom={'4px'} marginTop={'4px'}>
                <Flex>
                  <Text {...styles.redAsterisk}>Allowed User</Text>
                  <Text color={'#ff0000'}>*</Text>
                </Flex>
              </Flex>
              <Multiselect
                // id={vId}
                options={data}
                isObject={false}
                singleSelect={false}
                hidePlaceholder={true}
                style={{ ...style.multiSelect }}
                selectedValues={value?.allowedUser}
                onRemove={e => onRemoveFuntionAllowedUser(e)}
                onSelect={e => onSelectedFuntion(e, 'allowedUser')}
              />
              <Flex marginBottom={'4px'} marginTop={'4px'}>
                <Flex>
                  <Text {...styles.redAsterisk}>Not Allowed User</Text>
                  <Text color={'#ff0000'}>*</Text>
                </Flex>
              </Flex>
              <Multiselect
                // id={vId}
                options={data}
                isObject={false}
                singleSelect={false}
                hidePlaceholder={true}
                style={{ ...style.multiSelect }}
                selectedValues={value?.notAllowedUser}
                onRemove={e => onRemoveFuntionnotAllowedUser(e)}
                onSelect={e => onSelectedFuntion(e, 'notAllowedUser')}
              />
              {/* {customInputLabel({
                title: 'Allowed Users',
                mandatory: true,
                onChange: e => handleonChange(e, 'allowedUser'),
                // value: value['allowedUser'],
              })}
              {customInputLabel({
                title: 'Not Allowed Users',
                mandatory: true,
                type:"file",
                onChange: e => handleonChange(e, 'notAllowedUser'),
                // value: value['notAllowedUser'],
              })} */}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={4}
            >
              {customInputImage({
                title: 'Promo Image',
                mandatory: true,
                type: 'file',
                src: process.env.REACT_APP_S3URL + value?.promoImage,
                onChange: e => upload(e),
                // value: value['promoImage'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Promo Code',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Promo Code Management',
              useSlash: true,
              path2: 'Create Promo Code',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Title',
                mandatory: true,
                onChange: e => handleonChange(e, 'title'),
              })}
            </SimpleGrid>
            {customTextarea({
              title: 'Description',
              mandatory: true,
              onChange: e => handleonChange(e, 'description'),
            })}

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={3}
            >
              {customInputLabel({
                title: 'Coupon Code',
                mandatory: true,
                onChange: e => handleonChange(e, 'couponCode'),
              })}
              {dropdownCustomSvalue({
                title: 'Promo Type',
                mandatory: true,
                temparray: promoType,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'promoType'),
              })}
              {customInputLabel({
                title: 'Promo Value',
                type: 'number',
                mandatory: true,
                onChange: e => handleonChange(e, 'promoValue'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={3}
            >
              {customInputLabel({
                title: 'MaximumValueforDiscount',
                mandatory: true,
                type: 'number',
                onChange: e => handleonChange(e, 'discountValue'),
              })}
              {customInputLabel({
                title: 'Minimum Amount',
                mandatory: true,
                type: 'number',
                onChange: e => handleonChange(e, 'minimumAmount'),
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={3}
            >
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: true,
                selectedDate: startD,
                onChange: e => setStartD(e),
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: true,
                selectedDate: endD,
                onChange: e => setendD(e),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={3}
            >
              {customInputLabel({
                title: 'Quota',
                mandatory: true,
                type: 'number',
                onChange: e => handleonChange(e, 'quota'),
              })}
              {customInputLabel({
                title: 'Maximum Use Per User',
                mandatory: true,
                type: 'number',
                onChange: e => handleonChange(e, 'maximumUsePerUser'),
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={4}
            >
              {dropdownCustomSvalue({
                title: 'Assigned To',
                temparray: assign,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'assignTo'),
              })}
              {dropdownCustomSvalue({
                title: 'Show in Mobile',
                temparray: showMobile,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'showInMobile'),
              })}
              {dropdownCustomSvalue({
                title: 'Status',
                mandatory: true,
                temparray: Status,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'status'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              <Flex marginBottom={'4px'} marginTop={'4px'}>
                <Flex>
                  <Text {...styles.redAsterisk}>Allowed User</Text>
                  <Text color={'#ff0000'}>*</Text>
                </Flex>
              </Flex>
              <Multiselect
                // id={vId}
                options={data}
                isObject={false}
                singleSelect={false}
                hidePlaceholder={true}
                style={{ ...style.multiSelect }}
                // selectedValues={dataa}
                onSelect={e => onSelectedFuntion(e, 'allowedUser')}
              />
              <Flex marginBottom={'4px'} marginTop={'4px'}>
                <Flex>
                  <Text {...styles.redAsterisk}>Not Allowed User</Text>
                  <Text color={'#ff0000'}>*</Text>
                </Flex>
              </Flex>
              <Multiselect
                // id={vId}
                options={data}
                isObject={false}
                singleSelect={false}
                hidePlaceholder={true}
                style={{ ...style.multiSelect }}
                // selectedValues={dataa}
                onSelect={e => onSelectedFuntion(e, 'notAllowedUser')}
              />
              {/* {customInputLabel({
                title: 'Allowed Users',
                mandatory: true,
                onChange: e => handleonChange(e, 'allowedUser'),
              })}
              {customInputLabel({
                title: 'Not Allowed Users',
                mandatory: true,
                onChange: e => handleonChange(e, 'notAllowedUser'),
              })} */}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={4}
            >
                 {!imageLoading ? (
                  customInputImage({
                    title: 'Promo Image',
                    mandatory: true,
                    type: 'file',
                    src: imageView,
                    onChange: e => upload(e),
                  })
                ) : (
                  <Skeleton height="70px" />
                )}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreatePromoCode;
