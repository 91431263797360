import React, { useState, useContext } from 'react';
import {
  Text,
  Button,
  Flex,
  useToast,
  Tr,
  Td,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { colActions} from '../context/data';
import { MiniHeader } from '../components/common/customComponent';
import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';

import { useHistory } from 'react-router-dom';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
import { CSVLink } from 'react-csv';

const PaymentNMerchantActions = () => {
  let init = {
    paymentMethod: '',
  };

  let tempPaymentMethod = [
    { list: 'IPAY88', val: '1' },
    { list: 'NPG', val: '2' },
  ];

  let paymentMethod = ['IPAY88', 'NPG'];

  let history = useHistory();
  const [dataa, setDataa] = useState([]);
  const [value, setValue] = useState('10');
  const [paginat, setpaginate] = useState();
  const [loader, setLoader] = useState(false);
  const [payload, setPayload] = useState(init);
  const [paymenyMethod, setPaymentMethod] = useState('1');
  const [dataCsv, setDataCsv] = React.useState([]);
  const [actionArray, setActionArray] = useState([]);
  const [valueSearch, setValueSearch] = useState();

  const { currentLangData } = useContext(LangContext);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  let tempArray = [];

  const csvReport = {
    filename: 'PaymentActions.csv',
    // Headers: colNames,
    data: dataCsv,
  };

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EV=>', ev);
    let temp = '';
    if (ev === 'IPAY88') {
      setPaymentMethod('1');
      getALLItems(value, 1, '1', valueSearch);
      //   getGatewayActions('1');
    } else if (ev === 'NPG') {
      setPaymentMethod('2');
      getALLItems(value, 1, '2', valueSearch);
      //   getGatewayActions('2');
    }

    // setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1, '1');
  }, [value]);

  const getALLItems = (limit, page, gateway, search) => {
    let tempCSV = [];

    setLoader(false);
    console.log('getALLItems=>', limit, page, gateway, search);
    const body = {
      numRecords: limit,
      gatewayId: gateway.toString(),
      page: page,
      merchantCode: search,
    };
    apimanager
      .post('getGatewayActions', body)
      .then(res => {
        console.log('getGatewayActions', res);
        if (res.message === 11006) {
          res?.actions?.map((value, index) => {
            tempArray.push(value);

            let {
              actionId,
              actionKey,
              actionName,
              gatewayId,
              gatewayName,
              merchantCode,
              merchantKey,
              serverType,
              createdAt,
            } = value;
            tempCSV.push({
              GatewayName: gatewayName,
              ActionID: actionId,
              ActionKey: actionKey,
              ActionName: actionName,
              GatewayID: gatewayId,
              ActionKey: actionKey,
              MerchantCode: merchantCode,
              MerchantKey: merchantKey,
              ServerType: serverType,
              Time: createdAt,
            });
          });
          setDataCsv(tempCSV);

          setDataa(tempArray);
          setpaginate(res?.records);
          setLoader(true);
        }
        if (res.message === 8499) {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed.Response Not saved',
          });
        }
        if (res.message === 7000) {
          toast({
            title: 'User Not Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `User Not Found`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    console.log('event', ev);
    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      // searchItems(ev, value, 1);
      getALLItems(value, 1, paymenyMethod, ev);
    }
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Payment and Merchant Actions',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Payment and Merchant Actions',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/addMerchantCode')}
            >
              Create
            </Button>
            <Spacer />
            <Flex
              justifyContent={'flex-end'}
              alignItems={'center'}
              marginLeft={'5'}
              marginRight={5}
            //   marginTop={5}
            >
              <CSVLink
                {...csvReport}
                justifyContent={'flex-end'}
                alignItems={'end'}
              >
                <Button {...style.downloadbutton}>Export Actions</Button>
              </CSVLink>
            </Flex>
          </Flex>

          <SimpleGrid
            marginStart={5}
            marginBottom={5}
            width={'100%'}
            columns={2}
            spacingX={'50px'}
          >
            {dropdownCustom({
              title: 'Select Payment Method',
              mandatory: false,
              temparray: paymentMethod,
              // placeholder: 'Select Option',
              onChange: e => handleOnChange(e, 'paymentMethod'),
            })}

            <Flex flexDirection={'column'}>
              <Flex
                {...style.showentries}
                marginTop={4}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Text fontSize={'sm'} paddingY={'5px'} mr={'4px'} mt={'2px'}>
                  {currentLangData?.app?.search}:
                </Text>
                {customInputLabel({
                  size: 'sm',
                  onKeyDown: e => handleOnSearch(e, 'searchPhrase'),
                })}
              </Flex>
            </Flex>
          </SimpleGrid>

          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            removePagination={true}
            headerNames={colActions}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              return (
                <Tr key={index} width={'100%'} >
                  <Td {...style.td}>{item?.gatewayName}</Td>
                  <Td {...style.td}>{item?.actionName}</Td>
                  <Td {...style.td}>{item?.merchantCode}</Td>
                  <Td {...style.td}>{item?.merchantKey}</Td>
                  <Td {...style.td}>{item?.actionId}</Td>
                  <Td {...style.td}>{item?.actionKey}</Td>
                  {/* <Td flex={1} justifyContent={'center'} alignItems={'center'}>
                    <Tooltip label="Assign" placement="top">
                      <Button
                        {...style.editbtn}
                        fontSize={'xsm'}
                        // variant="outline"
                        fontWeight={'light'}
                        color={'gray'}
                        rightIcon={
                          <AtSignIcon w={'15px'} h={'15px'} color={'gray'} />
                        }
                        onClick={() => navigateToEditPage(item)}
                      >
                        Assign
                      </Button>
                    </Tooltip>
                  </Td> */}
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PaymentNMerchantActions;
