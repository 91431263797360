import theme from '../config/color';

export const styles = {
  mainFlex: {
    height: '100vh',
    background: 'white.100',
    justifyContent: 'center',
    backgroundColor: '#FAFDFB',
  },
  secondMainFlex: {
    width: '25%',
    boxShadow: 'lg',
    borderRadius: 5,
    marginTop: '7%',
    borderTopWidth: 2,
    borderColor: 'black',
    flexDirection: 'column',
    borderBottomWidth: 2,
    backgroundColor: 'white',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    size: '18px',
    color: theme.customColors.blackAdminColor[200],
    marginStart: 5,
    as: 'h6',
    marginTop: 5,
  },
  socialFlex: {
    padding: '10%',
    marginBottom: 5,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  successMsg: {
    color: 'green',
    fontSize: 'sm',
    textAlign: 'center',
    fontWeight: 'bold',
  },
};
