import { BiCopyright } from 'react-icons/bi';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Flex,
  Button,
  Text,
  Heading,
  Link,
  Tab,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Skeleton,
} from '@chakra-ui/react';
import styles from './styles';

export const customButton = ({ title, onClick }) => {
  return (
    <Flex background={'white'}>
      <Button onClick={onClick} {...styles.editButton}>
        {title}
      </Button>
    </Flex>
  );
};

export const customPreviewHtmlBtn = ({ title }) => {
  return (
    <>
      <Text marginX={1}></Text>
      <Button {...styles.previewHtmlBtn}>
        <Text fontSize={'12px'} fontWeight={'light'}>
          {title}
        </Text>
      </Button>
    </>
  );
};

export const enComponent = (dataa, loader) => {
  // console.log("?????",dataa?.[0]?.ENG)
  const IFame = dat => {
    // let data = dataa?.[0]?.ENG;
    let data = dat;
    console.log('location data=>', data);
    return {
      __html: data,
    };
  };
  const dataManipulation = () => {
    let dat = IFame(dataa?.[0]?.ENG);
    return {
      __html: dat,
    };
  };
  // let da = dataManipulation();
  // console.log('da', IFame());

  // };

  return (
    <>
      <Heading as="h3" size="2xl" marginY={5}>
        What Makes a Solid ‘About Us’ Page?
      </Heading>
      {loader === true ? (
        <div dangerouslySetInnerHTML={dataManipulation().__html}></div>
      ) : (
        <Skeleton height="300px" width="100%" />
      )}
    </>
  );
};

export const myComponent = (dataa, loader) => {
  const IFame = dat => {
    // let data = dataa?.[0]?.ENG;
    let data = dat;
    console.log('location data=>', data);
    return {
      __html: data,
    };
  };
  const dataManipulation = () => {
    let dat = IFame(dataa?.[0]?.MY);
    return {
      __html: dat,
    };
  };
  return (
    <>
      {loader === true ? (
        <div dangerouslySetInnerHTML={dataManipulation().__html}></div>
      ) : (
        <Skeleton height="300px" width="100%" />
      )}
    </>
  );
};

export const cnComponent = (dataa, loader) => {
  const IFame = dat => {
    // let data = dataa?.[0]?.ENG;
    let data = dat;
    console.log('location data=>', data);
    return {
      __html: data,
    };
  };
  const dataManipulation = () => {
    let dat = IFame(dataa?.[0]?.CN);
    return {
      __html: dat,
    };
  };
  return (
    <>
      {loader === true ? (
        <div dangerouslySetInnerHTML={dataManipulation().__html}></div>
      ) : (
        <Skeleton height="300px" width="100%" />
      )}
    </>
  );
};

export const renderCopyRight = () => {
  return (
    <>
      <Flex
        flexDirection={'row'}
        alignItems={'center'}
        marginBottom={'2%'}
        color={'gray.400'}
        marginStart={'2%'}
      >
        <Text>Copyright </Text>
        <Link marginX={1}>{<BiCopyright />} </Link>
        <Text> 2022</Text>
      </Flex>
    </>
  );
};
export const renderCopyRightBlack = () => {
  return (
    <>
      <Flex
        flexDirection={'row'}
        alignItems={'center'}
        // alignSelf={'end'}
        // justifyContent={''}
        fontSize={'14px'}
        color={'gray.400'}
      >
        <Text>Copyright </Text>
        <Link marginX={1}>{<BiCopyright />} </Link>
        <Text>BlackAdmin 2022</Text>
      </Flex>
    </>
  );
};

export const tabView = ({ tabP1, tabP2, tabP3 }) => {
  return (
    <Tabs variant="enclosed" background={'white'}>
      <TabList>
        <Tab>EN</Tab>
        <Tab>MY</Tab>
        <Tab>Cn</Tab>
      </TabList>
      <TabPanels height={'100%'}>
        <TabPanel>{tabP1}</TabPanel>
        <TabPanel>{tabP2}</TabPanel>
        <TabPanel>{tabP3}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const tabViewAppUserReport = ({ tabP1, tabP2 }) => {
  return (
    <Tabs variant="enclosed" background={'white'} marginLeft={2}>
      <TabList>
        <Tab>Graph</Tab>
        <Tab>Export</Tab>
      </TabList>
      <TabPanels height={'80vh'}>
        <TabPanel>{tabP1}</TabPanel>
        <TabPanel>{tabP2}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};
