import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Input,
  Tr,
  Th,
  Td,
  Table,
  Tbody,
  Thead,
  useToast,
} from '@chakra-ui/react';
import { options } from '../context/data';
import { Tooltip } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import Multiselect from 'multiselect-react-dropdown';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import styles from '../components/common/style';
import { listAdmins, colAdmins } from '../context/data';
import LangContext from '../context/languageContext';
import Loading from '../components/common/Loading';
import { DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import { ChecktableTwo } from '../components/common/ChecktableOne';
import { MiniHeader } from '../components/common/customComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { FaDyalog } from 'react-icons/fa';
import { customSwtich } from '../components/createAppVersionComponent/createAppVersionComponent';
const Admins = () => {
  let tempArray = [];

  let toast = useToast();
  let history = useHistory();

  let tempActions = ['Active', 'InActive'];

  let iniValue = {
    action: '',
  };

  const [page, setPage] = useState(1);
  const [changeActions, setChangeActions] = useState(iniValue);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('10');
  const [aName, setAName] = useState('');
  const [data, setData] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = React.useState('');
  const [paginate, setpaginate] = React.useState();
  const [currentUserId, setCurrentUserId] = useState('');
  const [pageChange, setPageChange] = useState('');
  const { currentLangData } = useContext(LangContext);
  const [roleAccess, setRoleAccess] = React.useState([]);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [valueSearch, setValueSearch] = React.useState();
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [actions, setActions] = React.useState(tempActions);

  useEffect(() => {
    getRoles();
    getAllCategory(page, value);
  }, []);

  // Utility functions //
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('next and previous', loading);
    setCurrentPage(selectedPage);
    getAllCategory(selectedPage + 1, value);
    console.log('clicked', selectedPage + 1);
  };

  const handleonChange = (evt, key) => {
    console.log('EVT', evt);
    const ev = evt.target.value;
    console.log('EVT=>', ev);

    selectPayment(evt.target.value);
    setChangeActions({
      // ...value,
      [key]: ev,
    });
  };

  const handleonSwitch = (evt, gateway, key) => {
    const ev = evt;
    console.log('Switch', ev, 'gateway=>', gateway, 'key', key);

    selectPayment(gateway);

    // setValue({
    //   ...value,
    //   [key]: ev,
    // });
  };

  // true on => 1 //
  // false on => 2 //

  const selectPayment = gatewayStatus => {
    console.log('gatewayStatus=>', gatewayStatus);
    // let nam = '';
    // if (gatewayStatus === 'Active') {
    //   nam = '1';
    // } else if (gatewayStatus === 'InActive') {
    //   nam = '2';
    // }

    // if (gatewayStatus === true) {
    //   nam = '1';
    //   console.log('gatewayStatus True');
    // } else if (gatewayStatus === false) {
    //   nam = '2';
    //   console.log('gatewayStatus False');
    // }

    // console.log('naaam', nam);
    const body = {
      gatewayId: gatewayStatus,
    };
    console.log('Update body', body);
    apiManager
      .post('selectPaymentGateway', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 11002) {
          setSuccess('Successfully Recorded!');
          setLoading(false);
          toast({
            title: success,
            duration: 2000,
            isClosable: true,
            status: 'success',
            description: `Gateway Updation Success!`,
          });
          getAllCategory(page, value);
        }
        if (response.message === 11001) {
          setLoading(false);
          toast({
            status: 'error',
            duration: 3000,
            isClosable: true,
            title: 'Error',
            description: `Gateway Updation Failed!`,
          });
        }

        if (response.message === 11000) {
          setLoading(false);
          toast({
            status: 'error',
            duration: 3000,
            isClosable: true,
            title: 'Failed',
            description: `Gateway Already Selected!`,
          });
        } else {
          setLoading(false);
          console.log('Response Error=>', response?.errors[0]?.msg);
          setError(response?.errors[0]?.msg);
          toast({
            status: 'error',
            duration: 4000,
            isClosable: true,
            title: response?.errors[0]?.msg,
            description: response?.errors[1]?.msg,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  console.log('Change Actions=>', changeActions);
  let totalDatalength = paginate?.totalDocs;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  let pageCount = paginate?.totalPages;
  const pageCeilingCount = paginate?.totalPages;
  // console.log(pageCeilingCount, 'PAGE COUNT', pageCount);

  useEffect(() => {}, [page]);
  useEffect(() => {}, [value]);

  const getRoles = () => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let tempRoleAccess = [];

    let body = {
      // page: page,
      // nextPage: 3,
      // numRecords: value,
    };

    apiManager
      .get('getAllCMSRoles', body)
      .then(res => {
        console.log('getAllCMSRoles=>RES', res);
        if (res.message === 4009) {
          console.log('Get getAllCMSRoles', res);

          res.records.docs.map((value, index) => {
            // console.log('valuesssss', value?.roleName);
            tempRolesId.push([value?.roleName, value?.roleId]);
            tempArray.push(value?.roleName);
            tempRoleAccess.push([value?.roleAccess, value?.roleId]);
          });

          setRoleAccess(tempRoleAccess);
          setRoles(tempArray);
          setNameRoles(tempRolesId);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAllCategory = (page, value) => {
    setLoading(true);
    let tempArray = [];
    console.log('page=>', page);
    let body = {
      page: page,
      // nextPage: 3,
      numRecords: value,
    };
    apiManager
      .get('getPaymentGateways', body)
      .then(res => {
        console.log('getPaymentGateways=>RES', res);
        if (res.message === 11004) {
          console.log('Get getPaymentGateways', res);
          pageCount = res.gateways.length;
          res.gateways.map((value, index) => {
            // console.log('valuesssss', value);
            tempArray.push(value);
          });
          setpaginate(res.results);
          setData(tempArray);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getUserDetailsForCMSApi = email => {
    console.log('emailD=>', email);
    setLoading(true);
    let tempArray = [];
    let body = {
      userId: email,
    };
    apiManager
      .post('getUserDetailsForCMS', body)
      .then(res => {
        console.log('getUserDetailsForCMS=>RES', res);
        if (res.message === 7079) {
          console.log('Get getUserDetailsForCMS', res);
          console.log(
            'Get getUserDetailsForCMS userId=>',
            res?.results?.docs?.userId
          );
          setCurrentUserId(res?.results?.docs?.userId);
          // pageCount = res.results.totalDocs;
          // setSsoUser(res?.result?.ssoUser);
          // setMiddleUser(res?.result?.middlewareUser);

          // setLoadingApiCall(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const deleteRecord = data => {
    setLoading(true);

    console.log('deleteUserFromCMS', data);
    // deleteS3(data.companyLogo);
    getUserDetailsForCMSApi(data?._id);
    let body = {
      userId: data?._id,
    };
    apiManager
      .delete('deleteUserFromCMS', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 4031) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          getAllCategory(1, value);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          // getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleOnSearch = (evt, key) => {
    console.log("e.key === 'Enter==>", evt);
    const ev = evt.target.value;

    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };

  const searchItems = (value, limit, page) => {
    console.log('searchItems', value, limit, page);
    setLoading(true);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apiManager
      .post('searchGameRewards', body)
      .then(res => {
        console.log('res=>', res.message);
        if (res.message === 8614) {
          res?.record?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setData(tempArray);
          setpaginate(res?.record);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/PaymentGatewayViewActions',
      state: { data: editData, useEditApi: true },
    });
  };

  const suspendUser = data => {
    setLoading(true);
    console.log('suspend', data);
    // deleteS3(data.companyLogo);
    let body = {
      email: data?.email,
    };
    console.log('body of suspend', body);
    apiManager
      .post('suspendUser', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 4004) {
          setLoading(false);
          setSuccess('SuspendUser');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Suspend User!`,
          });
          getAllCategory(1, value);
        }
        if (res.message === 4003) {
          setLoading(false);
          setError(' User Already Suspended');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Something went wrong!User Already Suspended`,
          });
          getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const unsuspendUser = data => {
    setLoading(true);

    console.log('suspend', data);
    // deleteS3(data.companyLogo);
    let body = {
      email: data?.email,
    };
    console.log('body of suspend', body);
    apiManager
      .post('unsuspendUser', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 4006) {
          setLoading(false);
          setSuccess('suspendUser');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Unsuspend User!`,
          });
          getAllCategory(1, value);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! User Already Unspended!`,
          });
          // getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'Inactive';
    } else if (value === 1) {
      return 'Active';
    }
  };

  const changeActionsData = data => {
    console.log('changeActionsData=>', data);
    let tempName = '';

    if (data === '1') {
      return 'Active';
    } else if (data === '2') {
      return 'InActive';
    }
    // return tempName;
  };

  console.log('aName=>', aName);

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Payment',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Payment',
          useSlash: true,
          path2: 'Payment Gateway',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main} padding={8}>
          <Flex {...style.buttoncontainer}></Flex>
          <Flex {...style.optionFlex}></Flex>
          <Flex {...style.tableFlex}>  
          <Table {...styles.table} padding={'1%'}>
            <Thead {...styles.thead}>
              <Tr>
                <Th {...styles.th}>Name</Th>
                <Th {...styles.th}>Status</Th>
                <Th
                  {...styles.th}
                  alignItems={'center'}
                  justifyContent={'space-around'}
                >
                  Selected-Gateway
                </Th>
                <Th {...styles.th}>Detail</Th>
              </Tr>
            </Thead>
            <Tbody {...styles.tbody}>
              {!loading ? (
                data &&
                data?.map((value, index) => {
                  console.log('value==>', value);

                  let tempNam = changeActionsData(value?.gatewayId);
                  console.log('tempNamtempNam=>', tempNam);

                  if (index >= 0) {
                    return (
                      <>
                        <Tr key={index} {...styles.tr}>
                          <Td
                            flex={1}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            {value?.name}
                          </Td>
                          <Td
                            flex={1}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            {value?.isSelected.toString()}
                          </Td>

                          {/* <Td
                            flex={1}
                            justifyContent={'center'}
                            alignItems={'center'}
                            color={'green'}
                            fontSize={'xs'}
                            fontWeight={'bold'}
                          >
                            {changeStatus(value?.status)}
                          </Td> */}

                          <Td
                            // width={"50%"}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-around'}
                          >
                            {/* {dropdownCustom({
                              temparray: actions,
                              width: '50%',
                              placeholder: value?.isSelected,
                              onChange: e => handleonChange(e, 'action'),
                            })} */}

                            <Flex>
                              {customSwtich({
                                // title: tempNam,
                                // disabled: value?.isSelected,
                                // label: tempNam,
                                checked: value?.isSelected,

                                check: e =>
                                  // handleonSwitch(e.target.checked, 'all'),
                                  handleonSwitch(
                                    e.target.checked,
                                    value?.gatewayId,
                                    'all'
                                  ),
                              })}
                            </Flex>

                            {/* <Select
                              placeholder={'select option'}
                              width={'50%'}
                              onChange={(e) => handleonChange(e)}
                            >
                              <option value="option1">1</option>
                              <option value="option2">2</option>
                            </Select> */}

                            {/* <Multiselect
                              // id={vId}
                              options={actions}
                              isObject={false}
                              singleSelect={true}
                              hidePlaceholder={true}
                              style={{ ...style.multiSelect }}
                              selectedValues={value?.gatewaysId}
                              onSelect={e => handleonChange(e, 'action')}
                              // onRemove={e => onSelectedFuntion(e, 'permisions')}
                            /> */}

                            {/* <Tooltip label="Edit" placement="top"> */}
                            {/* <Select
                                  placeholder={
                                    value?.gatewaysId === '1'
                                      ? 'Active'
                                      : 'Inactive'
                                  }
                                width={'50%'}
                              >
                                <option value="option1">Active</option>
                                <option value="option2">Inactive</option>
                              </Select> */}
                            {/* {value?.gatewayId ? ( */}
                            {/* <Button
                              {...style.editbtn}
                              width={'30%'}
                              fontSize={'sm'}
                              variant="outline"
                              color={value?.isSelected ? 'red' : 'Green'}
                              // isDisabled={!value?.isSelected ? true : false}
                              fontWeight={'light'}
                              leftIcon={
                                <EditIcon
                                // color={!value?.isSelected ? 'red' : 'black'}
                                />
                              }
                              onClick={() =>
                                selectPayment(
                                  value?.isSelected ? 'InActive' : 'Active'
                                )
                              }
                            >
                              {value?.isSelected ? 'InActive' : 'Active'}
                            </Button> */}
                            {/* ) : (
                              <Button
                                {...style.editbtn}
                                fontSize={'sm'}
                                // variant="outline"
                                fontWeight={'light'}
                                // color={"red"}
                                // isDisabled={value?.isSelected ? true : false}

                                // rightIcon={<EditIcon />}
                                onClick={() => selectPayment('Active')}
                              >
                                Active
                              </Button> */}
                            {/* )} */}
                            {/* </Tooltip> */}

                            {/* {value?.isSuspended === false ? (
                                <Button
                                  {...style.Delbtn}
                                  fontSize={'sm'}
                                  fontWeight={'light'}
                                  onClick={() => suspendUser(value)}
                                >
                                  Suspended
                                </Button>
                            ) : (
                                <Button
                                  {...style.Delbtn}
                                  color={'green'}
                                  fontSize={'sm'}
                                  // onClick={() => unsuspendUser(value)}
                                  // onClick={() => deleteRecord(value)}
                                >
                                  Unsuspended
                                </Button>
                            )} */}

                            {/* <Button
                              {...style.Delbtn}
                              fontSize={'sm'}
                              // variant="outline"
                              fontWeight={'light'}
                              rightIcon={<DeleteIcon />}
                              onClick={() => deleteRecord(value)}
                            >
                              Delete
                            </Button> */}
                          </Td>
                          <Td
                            flex={1}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <Tooltip label="View" placement="top">
                              <Button
                                {...style.editbtn}
                                fontSize={'sm'}
                                // variant="outline"
                                fontWeight={'light'}
                                rightIcon={
                                  <ViewIcon
                                    w={'15px'}
                                    h={'15px'}
                                    color={'gray'}
                                  />
                                }
                                onClick={() =>
                                  navigateToEditPage(value?.gatewayId)
                                }
                              >
                                {/* Edit */}
                              </Button>
                            </Tooltip>
                          </Td>
                        </Tr>
                      </>
                    );
                  } else {
                    return (
                      <Tr height={'40vh'}>
                        <Td></Td>
                        <Td>
                          <Text>NO DATA AVAILABLE</Text>
                        </Td>
                        <Td></Td>
                      </Tr>
                    );
                  }
                })
              ) : (
                <Tr>
                  <Td></Td>
                  <Td paddingY={'15vh'}>
                    <Flex
                      style={{
                        w: '100%',
                        h: '100%',
                        d: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Loading />
                    </Flex>
                  </Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
            
          </Table>
          </Flex>
          <Flex {...style.tablefooter}>
            <Flex mt={'2px'}>
              <Text>Showing {data.length} Results</Text>
            </Flex>
            <Flex {...style.paginatecontainer}>
              {/* <ReactPaginate
                nextLabel={'Next'}
                pageCount={pageCount}
                previousLabel={'Previous'}
                activeClassName={'active'}
                nextClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                containerClassName={'pagination'}
                onPageChange={handlePageClick}
                previousLinkClassName={'page-link'}
              /> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Admins;
