import theme from '../../config/color';
const style = {
  uniqueKeyOuterContainer: {
    width: '90%',
    flexDirection: 'column',
    marginLeft: '2%',
    marginRight: '2%',
  },
  uniqueKeyContainer: {
    // width: '80%',
    height: 10,
    borderWidth: 1,
    alignItems: 'center',
    // paddingX: 4,
    // borderRadius: 4,
    borderColor: theme.customColors.gray[200],
  },
  subMenuFlex: {
    paddingX: 16,
    paddingY: 1,
  },
};
export default style;
