import React, { useState } from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import {
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { CustomSelect } from '../components/bannerComponents/customDropDowns';
import Multiselect from 'multiselect-react-dropdown';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import style from '../components/common/style';
import ApiManager from '../config/apiManager';

const CreateRole = () => {
  let initialValue = {
    id: '',
    name: '',
    permisions: {},
  };
  let mandatoryField = {
    name: true,
    permisions: true,
  };
  const [dataa, setDataa] = React.useState([]);
  const [paginate, setPaginate] = React.useState('');
  const [value, setValue] = React.useState(initialValue);
  const [removedVal, setRemoveVal] = React.useState([]);
  const [arr, setArr] = useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [success, setsuccess] = React.useState(null);
  const [status, setstatus] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  // const storeTypelist = ['SELFCARE', 'AD/MD'];
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();
  let tempArray = [];
  let tempMultiselect = [];
  let tempPreSelected = [];
  let alreadySelected = [];
  // let temponRemoveOptions = [{label:'Not Allow TO ADD Permissions',value:'Not '}];

  const handleonChange = (evt, key) => {
    // console.log('event', evt);
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const handleDropdownChange = (evt, key) => {
    console.log(key, 'BodyEvent=', evt);

    if (key === 'permisions') {
      evt?.map((val, i) => {
        // console.log("VAL------",val)
        tempPreSelected.push(val);
        tempMultiselect.push(val?.value);
      });
      setSelectedOption(tempPreSelected);
      setValue({
        ...value,
        [key]: tempMultiselect,
      });
    }
    if (key === 'pageNumber') {
      getAllItems(evt?.value);
    }
  };

  const handleDropdownChangeRemoval = (evt, key) => {
    console.log(key, 'BodyEvent=', evt);
    console.log('selectedOption', selectedOption);

    let difference = selectedOption.filter(x => !evt.includes(x));
    console.log('Removed from list: ', difference);

    let tempRemovedVal = [];
    // tempRemovedVal.push(difference[0].value)
    difference.map((val, key) => {
      console.log(key, 'Map removed val.label', val.label);
      console.log(key, 'Map removed val.value', val.value);
      tempRemovedVal.push(val.value);
    });
    console.log('Removed array', tempRemovedVal);
    setRemoveVal(tempRemovedVal.concat(removedVal));

    // let finalData = tem

    if (key === 'permisions') {
      evt?.map((val, i) => {
        console.log('VAL------', val);
        tempPreSelected.push(val);
        tempMultiselect.push(val?.value);
      });
      setSelectedOption(tempPreSelected);
      setValue({
        ...value,
        [key]: tempMultiselect,
      });
    }
    if (key === 'pageNumber') {
      getAllItems(evt?.value);
    }
  };

  console.log('Remove valued for senting to api', removedVal);

  // const onSelectedFuntion = (e, key) => {
  //   console.log('onSelect=>', e);
  //   setValue({
  //     ...value,
  //     [key]: e,
  //   });
  // };
  React.useEffect(() => {
    getAllItems(1);
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.status === 'Addaction') {
      setAdd(true);
    }
    if (location.state?.val) {
      let data = location?.state?.val;
      data?.roleAccess?.map((values, index) => {
        console.log('values==', values);
        alreadySelected.push({
          label: values,
          value: values,
        });
      });
      // console.log("tempPreSelected",tempPreSelected)
      setSelectedOption(alreadySelected);
      setValue({
        ...value,
        id: data?.roleId,
        name: data?.roleName,
        permisions: data?.roleAccess,
      });
    }
    console.log('pathdata', location?.state?.val);
  }, []);

  console.log('VAL===', value);
  const getAllItems = pages => {
    let body = {
      page: pages,
    };
    apimanager.get('getAllActions', body).then(res => {
      console.log('responseAllAction', res);
      if (res.message === 4008) {
        res?.records?.docs?.map((value, index) => {
          // console.log('value==', value);
          // let { actionName, actionApi } = value;
          tempArray.push({
            label: value?.actionApi,
            value: value?.actionApi,
          });
        });
        setDataa(tempArray);
        setPaginate(res?.records);
      }
    });
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter All mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter All mandatory fields',
      });
    } else {
      const body = {
        name: value?.name,
        roleAccess: value?.permisions,
      };
      console.log('create body>>>>', body);
      apimanager
        .post('createRole', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 4010) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
          if (response.message === 7000) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `User Not Found`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const onClickUpdate = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter All mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter All mandatory fields',
      });
    } else {
      const body = {
        roleId: value?.id,
        name: value?.name,
        actions: removedVal,
      };
      console.log('update body>>>>', body);
      apimanager
        .post('removeActions', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 4013) {
            setLoading(false);
            setsuccess('Successfully Update');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
          if (response.message === 4019) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `CANNOT_CHANGE_SYSTEM_DEFINED_ROLES`,
            });
          }
          if (response.message === 7000) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `User Not Found`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const onClickAdd = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter All mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter All mandatory fields',
      });
    } else {
      // let selectedPermissions = [];
      // console.log('==apiaction==', actionapi);
      // actionapi.map((val, i) => {
      //   // console.log("act>>",val)
      //   // console.log(val[0],"<<<>>>",value?.permisions[0])
      //   console.log('actionApiValue', val[0]);
      //   console.log('value?.permisions', value?.permisions);
      //   value.permisions.map(item => {
      //     console.log('item', item);
      //     if (item === val[0]) {
      //       console.log('value1 inside', val[1]);
      //       //let yes = [...selectedPermissions]
      //       //selectedPermissions = yes.push(val[1])
      //       selectedPermissions.push(val[1]);
      //     }
      //     return item;
      //   });
      //   // if (val[0] === value?.permisions[0]){
      //   //   console.log("actionApiValue1",val[1]);
      //   //   let yes = [...selectedPermissions]
      //   //   selectedPermissions = yes.push(val[1])
      //   // }

      //   return val;
      // });

      // console.log('selectedPermissions', selectedPermissions);

      const body = {
        roleId: value?.id,
        // name: value?.name,
        actions: value?.permisions, //apiaction,
      };
      console.log('Add body>>>>', body);
      apimanager
        .post('addActions', body)
        .then(response => {
          console.log(response, 'responseAdd');
          if (response.message === 4012) {
            setLoading(false);
            setsuccess('Successfully Update');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
          if (response.message === 4011) {
            setLoading(false);
            setError('Failed, Records Not Valid');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `NOT ALL RECORDS VALID`,
            });
          }
          if (response.message === 4019) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `CANNOT CHANGE SYSTEM DEFINED ROLES`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  // console.log(dataa, 'selectedOption===', selectedOption);
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Role',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Role',
              useSlash: true,
              path2: 'Edit Role',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={'4%'}
            >
              <Flex marginBottom={'4px'} marginTop={'4px'}>
                <Flex>
                  <Text {...styles.redAsterisk}>Permissions</Text>
                  <Text color={'#ff0000'}>*</Text>
                </Flex>
              </Flex>
              {CustomSelect({
                isMulti: true,
                options: null,
                isClearable: true,
                // isDisabled:true,
                value: selectedOption,
                paginate: paginate,
                pagechange: e => getAllItems(e?.value),
                onChange: e => handleDropdownChangeRemoval(e, 'permisions'),
              })}
              {/* <Multiselect
                // id={vId}
                options={dataa}
                isObject={false}
                singleSelect={false}
                hidePlaceholder={true}
                style={{ ...style.multiSelect }}
                selectedValues={value?.permisions}
                onSelect={e => onSelectedFuntion(e, 'permisions')}
                onRemove={e => onSelectedFuntion(e, 'permisions')}
              /> */}
            </SimpleGrid>
            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Role',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Role',
              useSlash: true,
              path2: 'Create Role',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={'4%'}
            >
              <Flex marginBottom={'4px'} marginTop={'4px'}>
                <Flex>
                  <Text {...styles.redAsterisk}>Permissions</Text>
                  <Text color={'#ff0000'} marginLeft={1}>
                    *
                  </Text>
                </Flex>
              </Flex>
              {add ? (
                <>
                  {CustomSelect({
                    isMulti: true,
                    options: dataa,
                    isClearable: true,
                    value: selectedOption,
                    paginate: paginate,
                    // isDisabled:true,
                    styles: {
                      multiValueRemove: base => ({
                        ...base,
                        border: `1px dotted red`,
                        height: '100%',
                        display: 'none',
                      }),
                    },
                    onSelect: selectedOption,

                    pagechange: e => getAllItems(e?.value),
                    onChange: e => handleDropdownChange(e, 'permisions'),
                  })}
                </>
              ) : (
                <>
                  {CustomSelect({
                    options: dataa,
                    placeholder: 'Select Option',
                    // defaultValue:dataa,
                    paginate: paginate,
                    // value: selectedOption,
                    // isOptionSelected:selectedOption,
                    // defaultValue:selectedOption,
                    isMulti: true,
                    pagechange: e => handleDropdownChange(e, 'pageNumber'),
                    onChange: e => handleDropdownChange(e, 'permisions'),
                  })}
                </>
              )}
            </SimpleGrid>
            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => {
                add ? onClickAdd() : onClickSubmit();
              },
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};
export default CreateRole;
