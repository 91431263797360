import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const YetToBeImplemented = () => {
  return (
    <Flex
      width={'100%'}
      height={'90vh'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Flex padding={'100px'} background={"CaptionText"} borderRadius={"10"}>
        <Text textAlign={'center'} fontSize="5xl" color={'white'}>
          Yet To Be Implemented
        </Text>
      </Flex>
    </Flex>
  );
};
export default YetToBeImplemented;
