import React from 'react';
import {
  Text,
  Button,
  Flex,
  Image,
  useToast,
  Td,
  Tr,
  Spacer,
  Select,
  Circle,
  Tooltip,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { colRecommendation } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';
import LangContext from '../context/languageContext';
import ApiManager from '../config/apiManager';
import { options } from '../context/data';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const ProductRecomendations = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [valueSearch, setValueSearch] = React.useState();
  const [error, setError] = React.useState(null);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    setValue(ev);
  };
  React.useEffect(() => {
    // testing //
    // let num =[1,2,2,3]
    // let a = [...new Set(num)];
    // console.log("NewArray",a);
    getALLItems(value, 1);
  }, []);
  // const handleonSwitch = (evt, key, id) => {
  //   const ev = evt;
  //   console.log('Switch', ev, 'key', key, 'id', id);
  //   setsuggest(ev);
  //   // setValue({
  //   //   ...value,
  //   //   [key]: ev,
  //   // });
  // };

  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    if (evt.key === 'Enter') {
      // console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAdminRecommendations', body)
      .then(res => {
        // console.log('res>>', res);
        if (res.message === 8702) {
          res?.list?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.list);
          setLoader(true);
        } else {
          setError('Failed');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Something Went Wrong`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const searchItems = (value, limit, page) => {
    // console.log(limit,"limit,page",page)
    setLoader(false);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    apimanager
      .post('searchRecommendations', body)
      .then(res => {
        if (res.message === 8719) {
          res?.record?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.result);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const deleteItem = item => {
    // console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    apimanager
      .post('deleteRecommendations', body)
      .then(response => {
        // console.log("res>",response)
        if (response.message === 8706) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems(value, 1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const isValidURL = (str) => {
    // console.log("StringTofunction=",String(str));
    const pattern = new RegExp(
      "^(http|https)://", "i"
    );
      // console.log("return=",!!pattern.test(String(str)))
    return !!pattern.test(str);
  }
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Product Recommendation',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Product Recommendation',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/AddProductRecomendation')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex
              {...style.showentries}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Text fontSize={'sm'} paddingY={'5px'} mr={'4px'} mt={'2px'}>
                {currentLangData?.app?.search} :
              </Text>
              {customInputLabel({
                size: 'sm',
                onKeyDown: e => handleOnSearch(e, 'searchPhrase'),
              })}
            </Flex>
          </Flex>

          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colRecommendation}
            onPageChange={page => {
              if (valueSearch) {
                searchItems(valueSearch, value, page);
              } else {
                getALLItems(value, page);
              }
            }}
            renderItem={({ item, index }) => {
              // console.log('renderitem', item);
           
              let startdate = new Date(item?.displayPeriodStart * 1000);
              let enddate = new Date(item?.displayPeriodEnd * 1000);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{startdate.toLocaleDateString()}</Td>
                  <Td>{enddate.toLocaleDateString()}</Td>
                  <Td>
                    <Tooltip label={item?.tileName} aria-label="A tooltip">
                      <Text fontSize={'sm'} noOfLines={1}>
                        {' '}
                        {item?.tileName}
                      </Text>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip
                      label={item?.descriptiveText}
                      aria-label="A tooltip"
                    >
                      <Text fontSize={'sm'} noOfLines={1}>
                        {' '}
                        {item?.descriptiveText}
                      </Text>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Image
                      borderRadius="full"
                      boxSize="40px"
                      src={
                        item?.type === 3 ? (
                          isValidURL(item?.tileImage) === true
                          ? (item?.tileImage)
                          : (process.env.REACT_APP_S3URL+item?.tileImage) 
                        ):(
                           item?.tileImage
                        )
                      }
                    ></Image>
                  </Td>
                  <Td>{item?.amount}</Td>
                  {item?.displayStatus === 0 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="black"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.inactive}
                        </Text>
                      </Circle>
                    </Td>
                  ) : item?.displayStatus === 1 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.active}
                        </Text>
                      </Circle>
                    </Td>
                  ) : (
                    <Td>--</Td>
                  )}

                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                    paddingY={'15px'}
                  >
                    <Tooltip label={currentLangData?.app?.edit}>
                      <Button
                        {...style.editbtn}
                        leftIcon={<EditIcon />}
                        onClick={() =>
                          history.push({
                            pathname: '/AddProductRecomendation',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip label={currentLangData?.app?.delete} bg={'red'}>
                      <Button
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      ></Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductRecomendations;
