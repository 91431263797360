import React from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import {
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import Multiselect from 'multiselect-react-dropdown';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import style from '../components/common/style';
import ApiManager from '../config/apiManager';

const CreateRole = () => {
  let initialValue = {
    id: '',
    name: '',
    permisions: '',
    oldRoleName: '',
  };
  let mandatoryField = {
    name: true,
  };
  const [dataa, setDataa] = React.useState([]);
  const [actionapi, setActionApi] = React.useState([]);
  const [value, setValue] = React.useState(initialValue);
  const [success, setsuccess] = React.useState(null);
  const [status, setstatus] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [updateName, setUpdateName] = React.useState(false);
  const storeTypelist = ['SELFCARE', 'AD/MD'];
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();
  let tempArray = [];
  let ActionApiArray = [];

  const handleonChange = (evt, key) => {
    // console.log('event', evt);
    const ev = evt.target.value;
    // console.log('event ev', ev);
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const onSelectedFuntion = (e, key) => {
    console.log('onSelect=>', e);
    setValue({
      ...value,
      [key]: e,
    });
  };
  React.useEffect(() => {
    if (location.status === 'updateName') {
      setUpdateName(true);
    }
    if (location.state?.val) {
      let data = location?.state?.val;
      setValue({
        ...value,
        name: data?.roleName,
        oldRoleName: data?.roleName,
      });
    }
    console.log('pathdata', location?.state?.val);
  }, []);

  const onClickAdd = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter All mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter All mandatory fields',
      });
    } else {
      const payload = {
        oldRole: value?.oldRoleName,
        newRole: value?.name,
      };
      console.log('body>>>>', payload);
      apimanager
        .post('updateRoleName', payload)
        .then(response => {
          console.log(response, 'responseAdd');
          if (response.message === 4023) {
            setLoading(false);
            setsuccess('Successfully Update');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (response.message === 4011) {
            setLoading(false);
            setError('Failed, Records Not Valid');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `NOT ALL RECORDS VALID`,
            });
          }
          if (response.message === 4019) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Cannot Change System Defined Roles!`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Update Role Name',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Role',
          useSlash: true,
          path2: 'Update Role Name',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Name',
            mandatory: true,
            value: value['name'],
            onChange: e => handleonChange(e, 'name'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickAdd(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};
export default CreateRole;
