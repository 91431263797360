import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import {
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { useLocation } from 'react-router-dom';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  MiniHeader,
} from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
const CreatePage = () => {
  let quickSelect = [
    'Black Market',
    'XOX Mobile Line Management',
    'ADX',
    'EKYC',
    'EPP',
    'SuperApp',
    'other',
  ];
  let initialValue = {
    // pageName: '',
  };
  let mandatoryField = {
    pageName: true,
    pageType: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [success, setsuccess] = React.useState(null);
  const [status, setstatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  // const storeTypelist = ['SELFCARE', 'AD/MD'];
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();

  var pageTypeName = '';
  React.useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    console.log('location', location.state?.val);
    if (location.state?.val) {
      let data = location?.state?.val;
      // setValue({
      //   ...value,
      //   id: data?.pageId,
      //   pageName: data?.pageName,
      //   pageType: data?.pageType,
      // });
      pageTypeName = handleChangePageTypeName(data?.pageType);
      setValue({
        ...value,
        pageTypeNAME: pageTypeName,
        id: data?.pageId,
        pageName: data?.pageName,
        pageType: data?.pageType,
      });
      console.log('pageTypeName', pageTypeName);
    }
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleChangePageTypeName = Name => {
    if (Name == 1) {
      return 'Black Market';
    }
    if (Name == 2) {
      return 'XOX Mobile Line Management';
    }
    if (Name == 3) {
      return 'ADX';
    }
    if (Name == 4) {
      return 'EKYC';
    }
    if (Name == 5) {
      return 'EPP';
    }
    if (Name == 6) {
      return 'SuperApp';
    }
    if (Name == 7) {
      return 'other';
    }
  };

  const handleonChangePageType = (evt, key) => {
    const ev = evt.target.value;
    console.log('EV', ev);
    console.log('EV is', typeof ev);
    console.log('Key', key);
    if (ev == 'Black Market') {
      setValue({
        ...value,
        [key]: 1,
      });
    }
    if (ev == 'XOX Mobile Line Management') {
      setValue({
        ...value,
        [key]: 2,
      });
    }
    if (ev == 'ADX') {
      setValue({
        ...value,
        [key]: 3,
      });
    }
    if (ev == 'EKYC') {
      setValue({
        ...value,
        [key]: 4,
      });
    }
    if (ev == 'EPP') {
      setValue({
        ...value,
        [key]: 5,
      });
    }
    if (ev == 'SuperApp') {
      setValue({
        ...value,
        [key]: 6,
      });
    }
    if (ev == 'other') {
      setValue({
        ...value,
        [key]: 7,
      });
    }
  };
  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setLoading(false);
      setError('Please enter All mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter All mandatory fields',
      });
    } else {
      const body = {
        pageName: value?.PageName,
        pageType: value?.pageType,
      };
      console.log("Body",body)
      apimanager
        .post('addPage', body)
        .then(response => {
          console.log('response', response);
          if (response.message === 8800) {
            setLoading(false);
            setsuccess('Successfully Added');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [2000]);
          }
          if (response.message === 8858) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Something Went Wrong`,
            });
          }
          if (response.message === 8801) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Page Not Found`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };
  const onClickUpdate = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField,
    });
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        pageId: value?.id,
        pageName: value?.pageName,
        pageType: value?.pageType,
      };
      apimanager
        .post('updatePage', body)
        .then(response => {
          if (response.message === 8803) {
            setLoading(false);
            setsuccess('Successfully Updated!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [2000]);
          }
          if (response.message === 8801) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Page Not Found`,
            });
          }
          if (response.message === 8802) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Something Went Wrong`,
            });
          }
        })
        .catch(error => {});
    }
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Page',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Pages',
              useSlash: true,
              path2: 'Edit Page',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Page Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'pageName'),
                value: value['pageName'],
              })}
              {dropdownCustom({
                title: 'Page Type',
                mandatory: true,
                temparray: quickSelect,
                placeholder: value['pageTypeNAME'],
                onChange: e => handleonChangePageType(e, 'pageType'),
                // value: value['pageTypeNAME'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Page',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Pages',
              useSlash: true,
              path2: 'Create Pages',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {customInputLabel({
                title: 'Page Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'PageName'),
              })}
              {dropdownCustom({
                title: 'Page Type',
                mandatory: true,
                temparray: quickSelect,
                placeholder: 'Select Page Type',
                onChange: e => handleonChangePageType(e, 'pageType'),
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreatePage;
