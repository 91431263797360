import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ControlledEditorSelected = callback => {
  // console.log('Value of controll selected', callback);
  let value = callback?.value;
  console.log('valuevalue=>', value);
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(value)
    // EditorState.createEmpty()
    )
    )
  );

  const onEditorStateChange = editorData => {
    let stringText =
      editorData &&
      (convertToRaw(editorData.getCurrentContent()));

    // console.log(stringText, 'Editor');
    // console.log('State', editorData);

    setEditorState(editorData);
    console.log('callback', callback?.tempData(stringText));
    // console.log('callback', callback);
    // console.log('callback', callback);
    // callback?.tempData(stringText)
    // callback:()=>
  };

  return (
    <Editor
      editorState={editorState}
      editorClassName="demo-editor"
      //     wrapperClassName="demo-wrapper"
      // editorClassName="demo-editor"
      wrapperClassName="demo-wrapper"
      onEditorStateChange={e => onEditorStateChange(e)}
    />
  );
};

export default ControlledEditorSelected;
