import React from 'react';
import {
  Text,
  Flex,
  SimpleGrid,
  useToast,
  Divider,
  Spacer,
  Circle,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import ApiManager from '../config/apiManager';
import styles from '../styles/mobileBannerStyles';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import LangContext from '../context/languageContext';
import * as XLSX from 'xlsx';

export const AddBulk = () => {
  let initialValue = {
    bulk: '',
  };
  const [success, setsuccess] = React.useState(null);
  const { currentLangData } = React.useContext(LangContext);
  const [error, setError] = React.useState(null);
  const [status, setStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  let location = useLocation();
  let toast = useToast();
  let tempArray = [];

  React.useEffect(() => {
    if (location.status === 'productbulk') {
      setStatus(true);
    }
  }, [location]);

  const readExcel = file => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = e => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
    promise.then(d => {
      console.log('data from excel', d);
      // setData(d);
      d?.map((value, index) => {
        tempArray.push(value);
      });
      setData(tempArray);
    });
  };
  // console.log("data>>>>>",data)
  const onClickSubmit = () => {
    setLoading(true);
    const body = {
      records: data,
    };
    console.log('body>>>>', body);
    apimanager
      .post('addBulkStores', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8520) {
          setLoading(false);
          setsuccess('Successfully Submitted');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Submitted, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
        response?.errors?.map((value, index) => {
          console.log('Error Response', value?.msg);
          if (value?.msg === 8513) {
            setLoading(false);
            setError('Store_Type is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8514) {
            setLoading(false);
            setError('branch_name is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8515) {
            setLoading(false);
            setError('contact is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8516) {
            setLoading(false);
            setError('full_address is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8517) {
            setLoading(false);
            setError('longitude is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8518) {
            setLoading(false);
            setError('latitude is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8519) {
            setLoading(false);
            setError('Records Array is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
        });
        if (response.message === 8058) {
          setLoading(false);
          setError('Something went wrong on Server  ');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Failed to add data`,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    // }
  };
  const onProductBulk = () => {
    setLoading(true);
    const body = {
      records: data,
    };
    console.log('body>>>>', body);
    apimanager
      .post('addBulkProducts', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8520) {
          setLoading(false);
          setsuccess('Successfully Submitted');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Submitted, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
        response?.errors?.map((value, index) => {
          console.log('Error Response', value?.msg);
          if (value?.msg === 8201) {
            setLoading(false);
            setError('Name is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8880) {
            setLoading(false);
            setError('description is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8251) {
            setLoading(false);
            setError('xox product id is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8516) {
            setLoading(false);
            setError('value RM is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8091) {
            setLoading(false);
            setError('startDate is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8092) {
            setLoading(false);
            setError('endDate is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
          if (value?.msg === 8253) {
            setLoading(false);
            setError('product type is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
          }
        });
        if (response.message === 8058) {
          setLoading(false);
          setError('Failed to add data');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `xoxProductId And Name must be unique`,
            });
        }
        if (response.message === 8254) {
          setLoading(false);
          setError('additional is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
        }
        if (response.message === 8097) {
          setLoading(false);
          setError('status is Required');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
        }
        if (response.message === 8519) {
          setLoading(false);
          setError('Records must be string');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed to add data`,
            });
        }
      })
      .catch(error => {
        console.log(error);
      });
    // }
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: status ? 'Add Bulk Products' : 'Add Bulk',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'sidebar.selfCareCenterManagement',
          useSlash: true,
          path2: 'Add Bulk',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main} padding={5}>
          <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
            {customInputLabel({
              title: status
                ? currentLangData?.app?.productTitle
                : currentLangData?.app?.storeTitle,
              type: 'file',
              accept: '.xlsv,.xls,.csv,.xlsx',
              onChange: e => {
                const file = e.target.files[0];
                readExcel(file);
              },
            })}
          </SimpleGrid>
          <Flex fontSize={'xs'} flexDirection={'column'} mt={'15px'}>
            <Text fontSize={'md'} fontWeight={'bold'}>
              {status
                ? currentLangData?.app?.bulkInstructionProduct
                : currentLangData?.app?.bulkInstructionTitle}
            </Text>
            {status ? (
              <Flex flexDirection={'column'} ml={'15px'}>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.xoxId}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.name}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.description}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.aditional}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.value}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.productType}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.startDate}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.endDate}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.status}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <Flex flexDirection={'column'} ml={'15px'}>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.store_Type}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.branch_name}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.full_address}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.contact_number}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.latitude}
                  </Text>
                </Flex>
                <Flex flexDirection={'row'} alignItems={'Center'}>
                  <Circle size={'8px'} bg={'gray'}></Circle>
                  <Text fontWeight={'bold'} ml={'5px'} color={'gray'}>
                    {currentLangData?.app?.longitude}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
          {customUpdateCancelBtn({
            title1: 'Submit',
            title2: 'Cancel',
            onClickCancel: () => history.goBack(),
            onClickSubmit: () => {status ? onProductBulk():onClickSubmit()},
          })}
        </Flex>
        <Spacer />
        <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
        <Flex position={'sticky'} marginLeft={4}>
          {renderCopyRight()}
        </Flex>
      </Flex>
    </Flex>
  );
};
