import React from 'react';
import { Flex, Text, SimpleGrid, Divider, Spacer } from '@chakra-ui/react';
import styles from '../styles/aboutUsStyles';
import theme from '../config/color';

import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';

const CreateQuotes = () => {
  console.log('CreateQuotes gg bro');
  return (
    <Flex
      flexDirection={'column'}
      // padding={2}
      backgroundColor={'#FAFDFB'}
      height={'80vh'}
    >
      {MiniHeader({
        title: 'Create Quotes',
        path: 'Dashboard / Motivational Quotes / Create',
      })}

      <Flex
        padding={'8'}
        width={'97%'}
        boxShadow={'lg'}
        alignSelf={'center'}
        alignItems={'center'}
        background={'white'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({ title: 'First Name', mandatory: true })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" />
      <Flex position={'sticky'}>{renderCopyRight()}</Flex>
    </Flex>
  );
};

export default CreateQuotes;
