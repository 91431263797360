import React from 'react';
import { Flex, Text, Divider, useToast,Button } from '@chakra-ui/react';
import {
  tabView,
  customButton,
  cnComponent,
  enComponent,
  myComponent,
  renderCopyRight,
  customPreviewHtmlBtn,
} from '../components/aboutUsComponent/customCompoent';
import styles from '../styles/aboutUsStyles';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';

const AboutUsManagement = () => {
  const [dataa, setDataa] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  let tempArray = [];
  let toast = useToast();

  React.useEffect(() => {
    getALLItems();
  }, []);
  // console.log('dataaaa',dataa)
  const getALLItems = () => {
    setLoader(false);
    apimanager
      .get('getAboutUs')
      .then(res => {
        // console.log("res",res)
        if (res.message === 8235) {
          let data = res.data;
          setDataa(data);
          setLoader(true);
        } else if (res.message === 8619) {
          setError('Access Denied');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Not Allowed To Perform This Operation',
          });
        } else if (res.message === 8234) {
          setError('Not Found');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'About Us Not Found',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(false);
        setError('Something Went Wrong');
        toast({
          title: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: 'Wait For Response...',
        });
      });
  };

  return (
    <Flex {...styles.parentFlex}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'About Us Management',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'About Us Management',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...styles.btnFlex} paddingStart={'2%'}>
          <Flex marginY={5}>
            {customButton({
              title: 'Edit',
              onClick: () =>
                history.push({
                  pathname: '/editAboutUs',
                  status: 'create',
                  state: { val: dataa },
                }),
            })}
            {/* {customPreviewHtmlBtn({
              title: 'Preview HTML Page',
              onClick:history.push('/AboutUs'),
            })} */}
            <Button
              {...style.previewbutton}
              // onClick={() => history.push('/AboutUs')}
            >
              Preview Html Page
            </Button>
          </Flex>
          <Divider
            orientation="horizontal"
            marginBottom={5}
            color={'lightgray'}
          />

          {tabView({
            tabP1: enComponent(dataa, loader),
            tabP2: myComponent(dataa, loader),
            tabP3: cnComponent(dataa, loader),
          })}
        </Flex>
      </Flex>
      <Divider marginY={10} width={'97%'} marginX={5} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};
export default AboutUsManagement;
