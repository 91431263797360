import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
import { Flex, Text } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import style from '../common/style';
import './custumStyle.css';

const Menu = props => {
  const handlePageClick = ({ selected: selectedPage }) => {
    props.onPageChange(selectedPage + 1);
  };
  return (
    <Fragment>
      <components.Menu {...props}>
        <Flex flexDirection={'column'}>
          {props?.selectProps?.fetchingData ? (
            <span className="fetching">Fetching data...</span>
          ) : (
            <Flex>
              <Text fontSize="lg">{props.children}</Text>
            </Flex>
          )}
          {props?.selectProps?.paginate?.pages === 1 ||
          props?.selectProps?.paginate?.totalPages === 1 ? null : (
            <Flex>
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                forcePage={Math.ceil(props?.selectProps?.paginate?.page - 1)}
                pageCount={
                  props?.selectProps?.paginate?.pages ||
                  props?.selectProps?.paginate?.totalPages
                }
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </Flex>
          )}
        </Flex>
      </components.Menu>
    </Fragment>
  );
};

export const CustomSelect = ({
  options,
  // fetchingData,
  onSelect,
  isMulti,
  value,
  menuIsOpen,
  isDisabled,
  defaultInputValue,
  isClearable,
  defaultValue,
  placeholder,
  paginate,
  onChange,
  pagechange,
  defaultValueSelect,
  isOptionSelected,
  styles
}) => {

  return (
    <div>
      <Select
        isMulti={isMulti}
        isClearable={false}
        // className={"button"}

        styles={styles}
        menuIsOpen={menuIsOpen}
        options={options}
        onChange={onChange}
        value={value}
        hideSelectedOptions={true}
        isOptionSelected={isOptionSelected}
        defaultValue={defaultValueSelect}
        isDisabled={isDisabled}
        // defaultValue={defaultValue}
        placeholder={placeholder}
        components={{
          Menu: props => (
            <Menu
              {...props}
              onPageChange={v => {
                if (pagechange) return pagechange({ value: v });
              }}
            />
          ),
        }}
        // fetchingData={fetchingData}
        paginate={paginate}
      />
    </div>
  );
};
export const MultiSelect = ({
  options,
  // fetchingData,
  onSelect,
  isMulti,
  value,
  menuIsOpen,
  isDisabled,
  defaultInputValue,
  isClearable,
  defaultValue,
  placeholder,
  onChange,
  defaultValueSelect,
  isOptionSelected,
  styles
}) => {

  return (
    <div>
      <Select
        isMulti={isMulti}
        isClearable={false}
        // className={"button"}

        styles={styles}
        menuIsOpen={menuIsOpen}
        options={options}
        onChange={onChange}
        value={value}
        hideSelectedOptions={true}
        isOptionSelected={isOptionSelected}
        defaultValue={defaultValueSelect}
        isDisabled={isDisabled}
        // defaultValue={defaultValue}
        placeholder={placeholder}
     
      />
    </div>
  );
};

