import React, { useState, useLayoutEffect } from 'react';

const LangContext = React.createContext({
  lang: '',
  currentLangData: {},
  switchLang: () => {},
});

export default LangContext;

export function LangProvider(props) {
  const [lang, setLang] = useState(
    window.localStorage.getItem('appUILang') || window.navigator.language
  );

  useLayoutEffect(() => {
    const selectedLang = window.localStorage?.getItem('appUILang');
    console.log('selectedlang', selectedLang);
    if (selectedLang) {
      setLang(selectedLang);
    } else {
      setLang('en-US');
    }
  }, [lang]);

  const switchLang = ln => {
    setLang(ln);
    window.localStorage.setItem('appUILang', ln);
  };

  return (
    <LangContext.Provider
      value={{
        lang,
        switchLang,
        currentLangData: langData[lang],
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
}

const langData = {
  'en-US': {
    common: {
      title: 'Title',
      genre: 'Genre',
      link: 'Read more',
      date: 'Release date',
      description: 'Description',
      specification: 'Specification',
      weWillBackShortly: 'We will get back to you shortly',
      submit: 'Submit',
    },
    apis: {
      getUser: 'Get User',
      getDepartment: 'getDepartment',
      getStaffLocations: 'Get Staff Locations',
      getAllProducts: 'getAllProducts',
      getComments: 'Get Comments',
      updatePhase: 'Update Phase',
      addPhase: 'Add Phase',
      getClient: 'getClient',
      getAllSubscription: 'getAllSubscription',
      getMerchants: 'getMerchants',
      getAllMerchant: 'getAllMerchant',
      getManufacturedIn: 'getManufacturedIn',
      deleteTracePhase: 'Delete Trace Phase',
      phaseProgress: 'Phase Progress',
      productPhaseProgress: 'Product Phase Progress',
      allTracePhases: 'All Trace Phases',
      getAllTracePhases: 'Get All Trace Phases',
      updateMerchant: 'UpdateMerchant',
      updateClient: 'updateClient',
      getIndustry: 'getIndustry',
      getPackedIn: 'getPackedIn',
      getAllClients: 'getAllClients',
      getMerchantDetail: 'getMerchantDetail',
      getAdminNotifications: 'getAdminNotifications',
      getSaleBaseIndustries: 'getSaleBaseIndustries',
      topPerformingIndustries: 'topPerformingIndustries',
    },
    constants: {
      procress: 'Procress',
    },
    app: {
      AntChain: 'ANTCHAIN',
      create: 'Create',
      download: 'Download',
      show: 'Show',
      entries: 'entries',
      search: 'Search',
      varified: 'Varified',
      unvarified: 'Not Varified',
      active: 'Active',
      MSISDNError: 'MSISDN Not Found',
      resetpassword: 'Reset Password',
      inactive: 'Inactive',
      edit: 'Edit',
      delete: 'Delete',
      view: 'View',
      showing: 'Showing',
      to: 'to',
      of: 'of',
      back: 'Back',
      login: 'Login',
      batch: 'Batch',
      phase: 'Phase',
      email: 'Email',
      text: 'Text',
      submit: 'Submit',
      signOut: 'Sign out',
      preview: 'Preview',
      save: 'Save',
      login: 'Login',
      back: 'Back',
      sales: 'Sales',
      back: 'Back',
      revoke:'Revoke',
      sales: 'Sales',
      preview: 'Preview',
      author: 'Author',
      image: 'Image',
      image: 'Image',
      caption: 'Caption',
      addContent: 'Add Page Content',
      addParagraph: 'Add Paragarph',
      cataegoryTag: 'Category Tag',
      postDate: 'Post Date',
      antchain: 'ANTCHAIN',
      Username: 'Username',
      password: 'Password',
      antchain: 'ANTCHAIN',
      continue: 'Continue',
      subAdmin: 'Sub Admin',
      datedJoin: 'Dated Join',
      deparment: 'Department',
      remember: 'Remember me',
      productId: 'Product ID',
      poweredby: 'Powered by',
      antchain: 'ANTCHAIN',
      login: 'Login',
      signOut: 'Sign out',
      more: 'More',
      success: 'success',
      error: 'error',
      return: 'Return',
      industry: 'Industry',
      seeAll: 'See All',
      export: 'Export',
      qrCode: ' Qr Code',
      qty: 'Qty',
      seeAll: 'See All',
      seeImage:'See Image',
      phases: 'Phases',
      industry: 'Industry',
      factory: 'Factory',
      halal: 'Halal',
      yes: 'Yes',
      no:'No',
      subscribed:'Subscribed',
      notSubscribed:'Not SUbscribed',
      search: 'Search',
      client: 'Client',
      traxPlan: 'Trax Plan',
      unitSold: 'Unit Sold',
      sales: 'Sales',
      startDate: 'Start Date',
      endDate: 'End Date',
      success: 'Success',
      storeTitle:'Please select an Excel file that contains the Store data',
      productTitle:'Please select an Excel file that contains the product data',
      bulkInstructionTitle:
        'For adding stores in bulk, the Excel file should have the following columns:',
        bulkInstructionProduct:
        'For adding bulk Products, the Excel file should have the following columns:',
      store_Type: 'storeType 1 => SELFCARE, 2 => AD/MD',
      branch_name: 'branch',
      full_address: 'fullAddress',
      contact_number: 'contact',
      latitude: 'latitude',
      longitude: 'longitude',
      productmanagementbulk: 'For adding bulk products, the Excel file should have the following columns:',
      suspend:'Suspend',
      unsuspend:'Unsuspend',
      restore:'Restore',
      AddAction:'Add Action',
      RemoveAction:'Remove Action',
      xoxId:'xoxProductId',
      name:'name',
      description:'description',
      aditional:'additional - If the product type is 1 (Mobile Internet), this must be blank. If the product type is 2 (Mobile Internet Add-on), this column must have a value.',
      value:'valueRM',
      productType:'productType - Available value is either 1 or 2. 1 for Mobile Internet and 2 for Mobile Internet Add-on',
      startDate:'startDate- Format should be dd-mm-yyyy hh:MM:ss',
      endDate:'endDate- Format should be dd-mm-yyyy hh:MM:ss',
      status:'status - Available value is either 0 or 1. 0 for Inactive and 1 for Active.',
      viewDetail:'View Detail',
      AddAction:'Add',
      RemoveAction:'Remove',
      update:"Update",
      manage:'Manage',
      softpinWarning:'Softpin Stock Warning',
      merchantActions:'Merchant Actions',
    },
  },

  ml: {
    common: { weWillBackShortly: 'We will get back to you shortly' },
    app: {
      productId: 'Product ID',
    },
  },
};
