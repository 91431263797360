import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import styles from '../../styles/mobileBannerStyles';
import style from '../../components/common/style';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ApiManager from '../../config/apiManager';
import { dropdownCustomSvalue } from '../../components/bannerComponents/customBannerComponents';
import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../../components/createAppVersionComponent/createAppVersionComponent';

import { renderCopyRight } from '../../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../../components/common/customComponent';
import { DATABUTTONS, NotificationType } from '../../context/data';

const NotifiyAllUsers = () => {
  let initialValue = {
    title: '',
    appLink: '',
    message: '',
    notificationType: '',
  };
  const [value, setValue] = React.useState(initialValue);
  const [page, setPage] = React.useState();
  const [error, setError] = React.useState(null);
  const [success, setsuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoader] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  // let location = useLocation();
  let toast = useToast();
  let tempArray = [];
  let mandatoryFields = {
    title: true,
    message: true,
  };
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };
  console.log('value=>', value);
  React.useEffect(() => {
    getALLItems();
  }, []);

  const getALLItems = () => {
    setPageLoader(false);
    apimanager
      .get('getPages')
      .then(res => {
        // console.log("Response",res)
        if (res.message === 8804) {
          res?.result?.docs?.map((value, index) => {
            tempArray.push(value?.pageName);
          });
          setPage(tempArray);
          // setpaginate(res?.result);
          setPageLoader(true);
        }
        if (res.message === 8801) {
          setPageLoader(true);
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Page not found',
          });
        } else if (!res.message) {
          setPageLoader(true);
          toast({
            title: 'Something Went Wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Something Went Wrong`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setPageLoader(true);
      });
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    console.log(value, 'Value');
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Mandatory Field must be filled',
      });
    } else {
      const body = {
        title: value?.title,
        deepLink: value?.appLink,
        message: value?.message,
        notificationType: value?.notificationType,
      };
      console.log('body>>>>', body);
      apimanager
        .post('sendNotificationToAllUsers', body)
        .then(response => {
          if (response.message === 12002) {
            setLoading(false);
            setsuccess('Successfully Sent To All Users');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Sent`,
            });
            setTimeout(() => {
              history.push('/users/NotificationCompaigns');
            });
          }
          if (response.message === 12001) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Client Data is not valid`,
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'100%'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Notification To All Users',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Notification',
          useSlash: true,
          path2: 'Notification To All Users',
        })}
      </Flex>
      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Enter Title :',
            mandatory: true,
            onChange: e => handleonChange(e, 'title'),
          })}
          {dropdownCustomSvalue({
            title: 'App linking :',
            placeholder: 'Select Option',
            isLoading: pageLoading,
            temparray: DATABUTTONS,
            onChange: e => handleonChange(e, 'appLink'),
          })}

          {/* {dropdownCustomSvalue({
            title: 'Notification Type :',
            placeholder: 'Select Option',
            isLoading: pageLoading,
            temparray: NotificationType,
            onChange: e => handleonChange(e, 'notificationType'),
          })} */}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customTextarea({
            title: 'Enter Message :',
            mandatory: true,
            onChange: e => handleonChange(e, 'message'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default NotifiyAllUsers;
