import React from 'react';
import {
  Text,
  Button,
  Flex,
  Image,
  useToast,
  Td,
  Tr,
  Spacer,
  Input,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import {
  listHappyHourBackgrounds,
  colHappyHourBackgrounds,
} from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { ChecktableTwo } from '../../components/common/ChecktableTwo';
import { useHistory } from 'react-router-dom';
import LangContext from '../../context/languageContext';
import ApiManager from '../../config/apiManager';
import { options } from '../../context/data';
import config from '../../config/config';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

export const HappyHourBackgrounds = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    setValue(ev);
  };
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  // const deleteImagefile = e => {
  //   // setShow(true);
  //   console.log('e.target=>', e);
  //   deleteImage(e)
  //     .then(res => {
  //       console.log('image uri', res);
  //       // setS3Image(e.target.files[0].name);
  //       // setShow(false);
  //     })
  //     .catch(error => {
  //       console.log('error', error);
  //     });
  // };

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAllHappyHourBackgrounds', body)
      .then(res => {
        // console.log("res",res)
        if (res.message === 8502) {
          res?.record?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
        if (res.message === 8499) {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed.Response Not saved',
          });
        }
        if (res.message === 7000) {
          toast({
            title: 'User Not Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `User Not Found`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deleteHappyHourBackground', body)
      .then(response => {
        if (response.message === 8498) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems(value, 1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Happy Hour Backgrounds',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: true,
          path2: 'HappyHourBackgrounds',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateHappyHourBackground')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}></Flex>
          </Flex>

          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colHappyHourBackgrounds}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              let startdate = new Date(item?.startDate * 1000);
              let enddate = new Date(item?.endDate * 1000);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{startdate.toLocaleDateString()}</Td>
                  <Td>{enddate.toLocaleDateString()}</Td>
                  <Td>{item?.name}</Td>
                  <Td>
                    <Image
                      borderRadius="full"
                      boxSize="40px"
                      src={process.env.REACT_APP_S3URL + item?.image}
                    ></Image>
                  </Td>
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Tooltip label={currentLangData?.app?.edit}>
                      <Button
                        {...style.editbtn}
                        leftIcon={<EditIcon />}
                        onClick={() =>
                          history.push({
                            pathname: '/CreateHappyHourBackground',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip
                      label={currentLangData?.app?.delete}
                      bg={'red'}
                    >
                      <Button
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      ></Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
