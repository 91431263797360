import React from 'react';
import ReactPaginate from 'react-paginate';
import { CheckIcon, UpDownIcon } from '@chakra-ui/icons';
import { MdImportExport } from 'react-icons/md';
import { options } from '../../context/data';
import LangContext from '../../context/languageContext';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Circle,
} from '@chakra-ui/react';
import style from './style';
import { useHistory } from 'react-router-dom';
import Loading from './Loading';
export const ChecktableTwo = ({
  datalist,
  screenType,
  headerNames,
  onPageChange,
  screenName,
  onDelete,
  TotDocs,
  renderItem,
  loaderr,
  removePagination,
}) => {
  let history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [EditDelButton, setEditDelButton] = React.useState(false);
  const [viewdownloadbutton, setviewdownloadbutton] = React.useState(false);
  const { currentLangData } = React.useContext(LangContext);

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('clicked', selectedPage);
    setCurrentPage(selectedPage);
    onPageChange(selectedPage + 1);
  };
  // console.log('datalist>>>>>>>', datalist);
  let totalDatalength = Math.ceil(TotDocs?.totalDocs);
  // console.log('list', datalist?.length);
  const PER_PAGE = selectvalue;
  // console.log('No of data show in a single page>>', PER_PAGE);
  const offset = currentPage * PER_PAGE;
  // console.log('offset', offset);
  const pageCount = Math.ceil(TotDocs?.totalPages);
  // console.log("PageCount=",pageCount)

  React.useEffect(() => {
    if (screenType === 'buttons') {
      setEditDelButton(true);
    } else if (screenType === 'LuckDrawList') {
      setviewdownloadbutton(true);
    }
  }, []);
  return (
    <Flex width={'100%'} flexDirection={'column'} paddingX={5}>
      <Flex {...style.tableFlex}>
        <Table {...style.table}>
          <Thead {...style.thead}>
            <Tr>
              {headerNames.map((headerItem, index) => (
                <Th {...style.th} key={index}>
                  <Flex>
                    <MdImportExport />
                    {headerItem}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody {...style.tbody}>
            {loaderr === true ? (
              datalist?.length > 0 ? (
                datalist?.map((value, index) => {
                  // console.log("valueeee>>>>>",value)
                  if (renderItem) return renderItem({ item: value, index });
                })
              ) : (
                <Tr>
                  <Td paddingY={'15vh'}>
                    <Flex {...style.elseflex}>
                      <Text fontSize={'xl'}>oops! Data bucket is Empty</Text>
                    </Flex>
                  </Td>
                </Tr>
              )
            ) : (
              <Tr>
                <Td paddingY={'15vh'}>
                  <Flex {...style.elseflex}>
                    <Loading />
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
      {!removePagination ? (
        <Flex {...style.tablefooter}>
          <Flex marginTop={1}>
            <Text>
              {currentLangData?.app?.showing} {1} {currentLangData?.app?.to}{' '}
              {TotDocs?.limit <= totalDatalength ? TotDocs?.limit : totalDatalength} {currentLangData?.app?.of} {totalDatalength}{' '}
              {currentLangData?.app?.entries}
            </Text>
          </Flex>
          <Flex {...style.paginatecontainer}>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};
