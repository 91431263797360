import { ArrowForwardIcon } from '@chakra-ui/icons';
import {RiPencilFill , RiDirectionFill} from "react-icons/ri"
const style = {
  editButton: {
    size: 'sm',
    leftIcon: (
      <RiPencilFill  color={'white'} />
    ),
    color: 'white',
    bgColor: 'black',
    borderRadius: '30px',
    _hover: { 
      bg: '#777777',
      transform: 'translateY(-2px)',
    },
    boxShadow: '0 2px 6px #1d1d1d',
    fontWeight: '600',
    fontsize: '12px',
  },
  previewHtmlBtn: {
    size: 'sm',
    leftIcon: (
      <RiDirectionFill size={'18px'}/>
    ),
    color: '#191d21',
    bgColor: '#e3eaef',
    borderRadius: '30px',
    _hover: { bg: '#c3d2dc' },
    boxShadow: '0 2px 6px #e6ecf1',
    fontWeight: '600',
    fontsize: '12px',
    mx: '10px',
  },
};
export default style;
