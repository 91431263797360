import React from 'react';
import { useHistory } from 'react-router-dom';
import { profileDetail } from './customComponent';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useAuthDispatch, useAuthState } from '../../context/authContext';

const Header = props => {
  const authState = useAuthState();
  // console.log('authState in Dashboard', authState);
  let history = useHistory();
  const dispatch = useAuthDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [flag, setFlag] = React.useState(false);
  const handleToggle = () => (isOpen ? onClose() : onOpen());
  const [heighting, setHeighting] = React.useState(0);

  const onLogout = async () => {
    await localStorage.removeItem('currentUser');
    await localStorage.removeItem('user');
    console.log('pressed Logging out');
    //localStorage.removeItem("mdgSession");
    dispatch('LOGOUT');
    history.push('/Login');
    window?.location?.reload();
  };

  const gotToIAM = () => {
    history.push('/IAM');
    // setFlag(true)
    props.hideSidebar(flag);
  };

  const goToChangePassword = (email) => {
    let savedUser = localStorage.getItem('user');
    let userID = localStorage.getItem('user_id');
    // console.log('savedUser=>', savedUser);
    // console.log('userID=>', userID);
    // console.log('email=>', email);

    history.push({
      pathname: '/ChangePassword',
      status: 'create',
      state: { val: userID },
    });
  };

  return (
    <Flex
      as="nav"
      top="0"
      align="flex-start"
      justify="flex-end"
      height={'90px'}
      bg="black"
      color="white"
      {...props}
    >
      <Box
        display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
        mt={{ base: 2, md: 0 }}
        padding={5}
        zIndex={3}
      >
        {profileDetail({
          title: `${authState.user.loginEmail}`,
          onClick: () => onLogout(),
          onClickIAM: () => gotToIAM(),
          onClickPassword: () => goToChangePassword(authState.user.loginEmail),
          // onClickInformation:()=>history.push('/SsoMiddlewareInfo')
        })}
      </Box>
    </Flex>
  );
};

export default Header;
