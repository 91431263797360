import React from 'react';
import { FaRegImages, FaRegNewspaper } from 'react-icons/fa';
import { AiFillTags } from 'react-icons/ai';
import { HiSpeakerphone } from 'react-icons/hi';
import LangContext from '../context/languageContext';
import { StarIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Flex,
  Text,
  Box,
  Icon,
  Image,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';
import style from './common/style';
import { useHistory } from 'react-router-dom';

export const DashComponent = ({ title, dataArray, totalArray, image }) => {
  const { currentLangData } = React.useContext(LangContext);
  let history = useHistory();
  return (
    <Box {...style.boxMain}>
      <Flex p="5" flexDirection={'column'}>
        <Flex {...style.boxHeader}>
          <Text>{title}</Text>
          {title === 'Banner' ? (
            <Button
              {...style.manageButton}
              onClick={() => history.push('/BannerManagement')}
            >
              {currentLangData?.app?.manage}
            </Button>
          ) : title === 'Promotion & News' ? (
            <Button
              {...style.manageButton}
              onClick={() => history.push('/%20%20PromotionAndNewsManagement')}
            >
              {currentLangData?.app?.manage}
            </Button>
          ) : title === 'Products' ? (
            <Button
              {...style.manageButton}
              onClick={() => history.push('/ProductManagement')}
            >
              {currentLangData?.app?.manage}
            </Button>
          ) : title === 'Compaigns' ? (
            <Button
              {...style.manageButton}
              onClick={() =>
                history.push('/CNYCampaignManagement/DynamicCampaign')
              }
            >
              {currentLangData?.app?.manage}
            </Button>
          ) : null}
        </Flex>
        <Flex {...style.boxBody}>
          {dataArray?.map((value, index) => {
            return (
              <Flex
                flexDirection={'column'}
                color={'gray'}
                alignItems={'center'}
              >
                <Text fontWeight={'bold'} fontSize={'xl'}>
                  {value?.total}
                </Text>
                {parseInt(value?._id) === 0 ? (
                  <Text fontSize={'sm'}>Suspended</Text>
                ) : parseInt(value?._id) === 1 ? (
                  <Text fontSize={'sm'}>Approved</Text>
                ) : parseInt(value?._id) === 2 ? (
                  <Text fontSize={'sm'}>Pending Reviewed</Text>
                ) : parseInt(value?._id) === 3 ? (
                  <Text fontSize={'sm'}>Active</Text>
                ) : parseInt(value?._id) === 4 ? (
                  <Text fontSize={'sm'}>Pending</Text>
                ) : (
                  <Text fontSize={'sm'}>{value?._id}</Text>
                )}
              </Flex>
            );
          })}
        </Flex>
        <Flex>
          <Flex marginTop={5} alignItems={'center'} justifyContent={'center'}>
            <Flex {...style.boxFooter}>
              <Icon
                boxSize={'25px'}
                color={'white'}
                as={
                  title === 'Banner'
                    ? FaRegImages
                    : title === 'Promotion & News'
                    ? FaRegNewspaper
                    : title === 'Products'
                    ? AiFillTags
                    : title === 'Compaigns'
                    ? HiSpeakerphone
                    : null
                }
              />
            </Flex>
            <Flex {...style.totalCalculation}>
              <Text fontSize={'md'} color={'gray'}>
                Total
              </Text>
              <Text fontWeight={'bold'} fontSize={'3xl'}>
                {totalArray}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export const TableCustom = ({ dataArray }) => {
  const { currentLangData } = React.useContext(LangContext);
  return (
    <Table variant="striped" colorScheme="blackAlpha" color={'gray.600'}>
      <Thead>
        <Tr>
          <Th>Softpin Category</Th>
          <Th></Th>
          <Th></Th>
          <Th>Quantity</Th>
          <Th isNumeric>Minimum Qty</Th>
        </Tr>
      </Thead>
      <Tbody {...style.tbody}>
        {dataArray?.length > 0 ? (
          dataArray?.map((value, index) => {
            return (
              <Tr>
                <Td alignItems={'center'}>{value?.name}</Td>
                <Td></Td>
                <Td></Td>
                <Td alignItems={'center'}>{value?.totalQuantity}</Td>
                <Td isNumeric>{value?.minStockQuantity}</Td>
              </Tr>
            );
          })
        ) : (
          <Tr>
            <Td></Td>
            <Td></Td>
            <Td paddingY={'15vh'} >
              <Flex {...style.elseflex}>
                <Text fontSize={'xl'}>oops! Data bucket is Empty</Text>
              </Flex>
            </Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export const RenderSoftpin = () => {
  const { currentLangData } = React.useContext(LangContext);
  let history = useHistory();
  return (
    <Flex>
      <Flex {...style.tableHead}>
        {currentLangData?.app?.softpinWarning}
        <Button
          {...style.tableManagebtn}
          onClick={() => history.push('/SoftpinManagement/SoftpinStock')}
        >
          {currentLangData?.app?.manage}
        </Button>
      </Flex>
    </Flex>
  );
};
