const styles = {
  mainFlex: {
    background: '#fafdfb',
    flexDirection: 'column',
  },
  parentFlex: {
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    backgroundColor: '#FAFDFB',
  },
  firstFlexInParent: {
    w: '100%',
    background: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnFlex: {
    // marginTop: 5,
    borderWidth: '1px',
    borderColor: '#f2f2f2',
    background: 'white',
    flexDirection: 'column',
  },
  miniheader: {
    borderRadius: 0,
    boxShadow: 'sm',
    padding: '1%',
    bgColor: '#fff',
    paddingStart: 2,
    borderColor: '#f2f2f2',
    paddingBottom: '2.5%',
    borderBottomWidth: '1px',
  },
  menuInsideBtn: {
    fontSize: 'sm',
    variant: 'outline',
    borderWidth: 0,
    fontWeight: 'normal',
  },
  fieldsFlex: {
    padding: '1%',
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  simplegridStyle: {
    columns: 2,
    width: '100%',
    spacingX: '50px',
  },
  changeLogTxt: {
    width: '100%',
    marginTop: '10',
    flexDirection: 'column',
  },
  txtChangelog: {
    fontSize: '12px',
    marginBottom: '2',
  },
  secondFlex: {
    marginY: 10,
    width: '100%',
    flexDirection: 'column',
  },
};
export default styles;
