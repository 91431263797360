import React, { useState, useContext, useEffect } from 'react';
import {
  Flex,
  Link,
  Center,
  Text,
  Input,
  Stack,
  Button,
  HStack,
  Spacer,
  Divider,
  Heading,
  useToast,
  Checkbox,
  FormLabel,
  InputGroup,
  FormControl,
  useMediaQuery,
  InputRightElement,
} from '@chakra-ui/react';
import {
  ExternalLinkIcon,
  SunIcon,
  ViewIcon,
  ViewOffIcon,
} from '@chakra-ui/icons';
import theme from '../config/color';
import styles from '../styles/loginStyles';
import { useHistory } from 'react-router-dom';
import { emailRegex } from '../constants/regex';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
import { useAuthDispatch, useAuthState } from '../context/authContext';
import { renderCopyRightBlack } from '../components/aboutUsComponent/customCompoent';

const Register = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  // Handling all the states //
  const toast = useToast();
  const [err, setErr] = useState(null);
  const dispatch = useAuthDispatch();
  const [loading, setload] = useState(false);
  const handleClick = () => setShow(!show);
  const [firstName, setFirstName] = useState(undefined);
  const [show, setShow] = React.useState(false);
  const [password, setPassword] = useState(undefined);
  const { currentLangData } = useContext(LangContext);

  const [error, setError] = useState({
    emailError: '',
    passError: '',
  });

  let apimanager = ApiManager.getInstance();

  let history = useHistory();

  useEffect(() => {}, [err]);

  const submitForm = async () => {
    console.log(firstName, 'FIELDS', password);

    const isValid = validate();
    if (isValid) {
      console.log('valid');

      setload(true);
      console.log('payload', {
        firstName,
        password,
        // role: 1,
      });
      apimanager
        .post('login', {
          firstName,
          password,
        })
        .then(response => {
          console.log('response..', response);
          //     if (response.message === 7051) {
          //       toast({
          //         title: `Successfully Signed in`,
          //         description: `Welcome ${firstName} to Black Admin CMS.`,
          //         status: 'success',
          //         duration: 3000,
          //         isClosable: true,
          //       });
          //       let user = {
          //         token: response.token,
          //         email: email,
          //       };
          //       localStorage.setItem('user', JSON.stringify(user));
          //       apimanager._setToken(email, response.token);
          //       dispatch('AUTHENTICATED', user);
          //       dispatch('STOP_LOADING');

          //       // getUserDetails(user);
          //       setload(false);

          //       setTimeout(() => {
          //         console.log('====Navigate to next===');
          //         history.push('/');
          //         window?.location?.reload();
          //       }, 300);
          //     }

          if (response.message === 7009) {
            setErr('Password is incorrect');
            setload(false);
          }

          if (response.message === 7000) {
            setErr('User Not Found');
            setload(false);
          }
        })
        .catch(e => {
          console.log('error', e);
          setload(false);
        });
    } else {
      toast({
        title: err,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to logged-in.',
      });
      setload(false);
    }
  };

  const getUserDetails = user => {
    console.log('USER==>', user);
    apimanager
      .get('getUser', user)
      .then(response =>
        console
          .log('response', response)
          .catch(error => console.log(error, 'error'))
      );
  };

  const validate = () => {
    if (firstName === '' && password === '') {
      // setError(currentLangData.app.allRequired);
      setErr('All Fields are required');

      return false;
    }
    if (firstName === '' && password !== '') {
      setErr('You missed a spot! Dont forget to add your email.');
      return false;
    }
    if (firstName !== '' && password === '') {
      setErr('Please enter password');
      return false;
    }
    
    if (password?.length < 8) {
      setErr(
        'Password should be valid 1 uppercase 1 special character and minimum 8 length'
      );
      return false;
    }
    // setErr(null);
    return true;
  };

  return (
    <Flex {...styles.mainFlex}>
      <Flex {...styles.loginCard}>
        <Flex {...styles.headingFlex}>
          <Heading {...styles.heading}>Register</Heading>
        </Flex>
        <Divider
          {...styles.divider}
          borderColor={theme.customColors.gray[900]}
        />

        <Stack {...styles.stackOfForm}>
          <FormControl id="firstName">
            <FormLabel {...styles.labelpass}>First Name</FormLabel>
            <Input
              //   type="email"
              onChange={event => setFirstName(event.target.value)}
            />
          </FormControl>

          <FormControl id="lastName">
            <FormLabel {...styles.labelpass}>Last Name</FormLabel>
            <Input
              //   type="email"
              onChange={event => setFirstName(event.target.value)}
            />
          </FormControl>

          <FormControl id="password">
            <FormLabel {...styles.labelpass}>Password</FormLabel>
            <InputGroup size="sm">
              <Input
                type={show ? 'text' : 'password'}
                onChange={event => setPassword(event.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {!show ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <Stack spacing={10}>
            <Stack
              {...styles.stackOfRememberForget}
              direction={!isMobile ? 'row' : 'column'}
            >
              {/* <Checkbox {...styles.checkbox}> */}
              <Text fontSize={'xs'}></Text>
              {/* </Checkbox> */}
              <Link {...styles.link}></Link>
            </Stack>

            <Button
              {...styles.loginBtn}
              onClick={() => submitForm()}
              isLoading={loading}
            >
              <Text {...styles.btnTxtLogin}>Register</Text>
            </Button>
            {/* <Flex>
              <HStack>
                <Link
                  onClick={() => history.push('/SocialLogin')}
                  {...styles.socalLinkTxt}
                  marginBottom={0}
                  fontSize={isMobile ? 'xs' : 'sm'}
                >
                  Login with Social Media <ExternalLinkIcon mx="2px" />
                </Link>
              </HStack>
            </Flex> */}
          </Stack>
        </Stack>
      </Flex>

      <Flex
        position={'sticky'}
        alignSelf={'center'}
        alignItems={'center'}
        justifyItems={'center'}
        marginTop={10}
      >
        {renderCopyRightBlack()}
      </Flex>
    </Flex>
  );
};
export default Register;
