import React from 'react';
import { Flex,Image } from '@chakra-ui/react'
import notAccess from '../../Images/notAccess.jpg';


const NotAllowed = () => {
  return (
    <Flex
      justifyContent={'center'}
    >
        {/* <Image src={"https://s3-ap-southeast-1.amazonaws.com/ppy-profile-imgs-xox/products/a6c33bae4043a65f0efbaaac2d4f2555337a9a75.jpg.webp"}></Image> */}
        <Image src={notAccess}></Image>
    </Flex>
  )
}

export default NotAllowed;