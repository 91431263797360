import React from 'react';
import { Button, Flex, useToast, Tr, Td } from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { colSuspendUser } from '../context/data';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
const SuspendUser = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];

  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .post('getCmsSuspendedUsers', body)
      .then(res => {
        if (res.message === 3001) {
          res?.record?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        } else {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Failed!`,
          });
        }
        if (res.message === 8499) {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed. Response not saved',
          });
        }
        if (res.message === 7000) {
          toast({
            title: 'User Not Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `User Not Found`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  
  const UnSuspendItem = item => {
    // console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      email: item.email,
    };
    // console.log('body>>>>', body);
    apimanager
      .post('unsuspendCMSUser', body)
      .then(response => {
        console.log('response', response);
        if (response.message === 3005) {
          setSuccess('User Successfully Unsuspended!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Unsuspended, Please processed to List!`,
          });
          getALLItems(value, 1);
        }else{
          setSuccess('Something went wrong!');
          toast({
            title: success,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Failed!`,
          });
          getALLItems(value, 1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Suspended Users',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Suspended Users',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateRole')}
            >
              Create
            </Button>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colSuspendUser}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.email}</Td>
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Button
                      {...style.Delbtn}
                      onClick={() => UnSuspendItem(item)}
                      variant={'outline'}
                    >
                      {currentLangData?.app?.unsuspend}
                    </Button>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SuspendUser;
