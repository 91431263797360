import React, { useState } from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  Circle,
  Text,
  useToast,
  Select,
  Td,
  Tr,
  Button,
} from '@chakra-ui/react';
import LangContext from '../../context/languageContext';
import styles from '../../styles/mobileBannerStyles';
import { useHistory } from 'react-router-dom';
import { options } from '../../context/data';
import DatewithTimePicker from '../../components/DateComponent/datewithTImePicker';
import { ChecktableTwo } from '../../components/common/ChecktableTwo';
// import style from '../../components/common/style';
import style from '../Reports/styles';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../../components/aboutUsComponent/customCompoent';
import { CSVLink } from 'react-csv';
import {
  customTextarea,
  MiniHeader,
} from '../../components/common/customComponent';
import { useLocation } from 'react-router-dom';
import ApiManager from '../../config/apiManager';
import { isMobileList } from '../../context/data';
import { set } from 'draft-js/lib/DefaultDraftBlockRenderMap';

const BlackAppNewRegistration = () => {
  let quickSelect = [
    { list: 'Yesterday', val: 1 },
    { list: 'Last 7 Days', val: 2 },
    { list: 'Last 28 Days', val: 3 },
    { list: 'This Week', val: 4 },
    { list: 'This Month', val: 5 },
    { list: 'This Year', val: 6 },
    { list: 'Last Week', val: 7 },
    { list: 'Last Year', val: 8 },
  ];
  let initialValue = {
    quickSelect: '',
    osType: '',
    subscription: '',
  };
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [sDate, setSDate] = useState('');
  const [eDate, setEDate] = useState('');
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState(initialValue);
  const [limit, setLimit] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [dataCsv, setDataCsv] = React.useState([]);
  const [pag, setPag] = useState(1);
  let apimanager = ApiManager.getInstance();
  let tempArray = [];
  let tempCsv = [];
  const [dataa, setDataa] = React.useState([]);
  const [paginat, setpaginate] = React.useState();
  const toast = useToast();
  let osType = ['IOS', 'Android'];
  let subscription = [
    { list: 'Subscribed', val: 1 },
    { list: 'Not Subscribed', val: 0 },
  ];
  let history = useHistory();
  let columsOfData = [
    'UserId',
    'Name',
    'Email',
    'Msisdn',
    'Login Type',
    'Is XOX',
    'RegistrationSource',
    'Date Visited',
  ];

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    setLimit(ev);
    // console.log('EVT=>', evt.target.value);
    // // let type = handleType(ev);
    // setValue({
    //   ...value,
    //   [key]: ev,
    // });
    // console.log('startDate, endDate', sDate, eDate);
    // getALLItems(ev, pag, sDate, eDate);
  };
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  // React.useEffect(() => {
  //   getALLItems(limit,value, 1);
  // }, []);
  React.useEffect(() => {
    getALLItems(limit,value, pag,sDate, eDate);
  }, [value,limit,pag]);

  const handleDate = e => {
    console.log('Date==>', e);
    if (e[1] !== null) {
      let startDate = new Date(e[0]);
      let endDate = new Date(e[1]);

      console.log('StartD =>', startDate);
      console.log('EndD =>', endDate);
      let finalStartDate = startDate.getTime();
      let finalEndDate = endDate.getTime();
      setSDate(finalStartDate);
      setEDate(finalEndDate);
      setDateRange(e);

      // getALLItems(null, null, finalStartDate, finalEndDate);
      getALLItems(limit, value,pag, finalStartDate, finalEndDate);
    }
    setDateRange(e);
  };
  const convertRegistrationType = registrationType => {
    if (registrationType == 1) return 'Subscribed';
    else if (registrationType == 0) return 'NotSubscribed';
  };
  const convertisXoxUser = isXoxUser => {
    if (isXoxUser == true) return 'Yes';
    else return 'No';
  };
  const convertcreatedAt = createdAt => {
    return new Date(createdAt * 1000);
  };
  // React.useEffect(() => {
  //   getALLItems();
  // }, [1]);
  console.log('setvlues', value);
  const getALLItems = (limit,value, page, startD, endD) => {
    console.log('value>>>', limit);
    setLoader(false);
    const body = {
      limit: parseInt(limit),
      type: parseInt(value?.quickSelect),
      OSType: value?.osType,
      subscriptionLevel: parseInt(value?.subscription),
      startDate: endD,
      endDate: startD,
      page: page,
    };
    console.log('body=>', body);
    apimanager
      .post('newRegisterationReport', body)
      .then(res => {
        // console.log('RESNewRegistration=>', res);
        console.log('res newRegisterationReport=>', res);

        if (res.message === 8814) {
          res?.data?.docs?.map((value, index) => {
            tempArray?.push(value);
            let {
              email,
              MSISDN,
              createdAt,
              userId,
              registrationType,
              loginType,
              isXoxUser,
            } = value;
            let convertedRegistrationType =
              convertRegistrationType(registrationType);
            let convertedisXoxUser = convertisXoxUser(isXoxUser);
            let convertedCreatedAt = convertcreatedAt(createdAt);
            tempCsv.push({
              userId: userId,
              Email: email,
              MSISDN: MSISDN,
              loginType: loginType,
              registrationType: convertedRegistrationType,
              isXoxUser: convertedisXoxUser,
              Time: convertedCreatedAt.toLocaleDateString(),
            });
          });
          setpaginate(res?.data);
          setDataa(tempArray);
          setDataCsv(tempCsv);
          setLoader(true);
        }
        if (res.message === 8336) {
          setLoader(true);
          toast({
            title: 'Mongodb pagination Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
        if (res.message === 2454) {
          setLoader(true);
          toast({
            title: 'No Data Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Add Data to Display.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const csvReport = {
    filename: 'blackAppNewRegistrationReport.csv',
    // Headers: colNames,
    data: dataCsv,
  };

  const checktableTwoOnChange = (val, e) => {
    console.log('e', e);  //value,page
    setPag(e);
    // getALLItems(val, e);
  };

  console.log('dataState>>', dataa);
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Black App New Registration',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: true,
          path2: 'Black App New Registration',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex
            {...style.datesContainer}
            padding={5}
            width={'80%'}
            flexDirection={'column'}
          >
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {DatewithTimePicker({
                title: 'Select Date Range',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,

                changePicker: true,
                // onChange: e => setDateRange(e),
                onChange: e => handleDate(e),
              })}

              {dropdownCustomSvalue({
                title: 'Quick Select Date',
                mandatory: false,
                temparray: quickSelect,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'quickSelect'),
              })}
            </SimpleGrid>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} mt={5}>
              {dropdownCustom({
                title: 'Os Type',
                mandatory: false,
                temparray: osType,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'osType'),
              })}
              {dropdownCustomSvalue({
                title: 'Subscription',
                mandatory: false,
                temparray: subscription,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'subscription'),
              })}
            </SimpleGrid>
            <SimpleGrid
              justifyContent={'space-between'}
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              mt={5}
            >
              <Flex
                // {...style.showentries}
                alignItems={'center'}
                fontSize={'sm'}
              >
                <Text>{currentLangData?.app?.show}</Text>
                <Select
                  {...style.select}
                  onChange={e => handleOnChange(e, 'limit')}
                >
                  {options.map(option => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Select>
                <Text>{currentLangData?.app?.entries}</Text>
              </Flex>
              <Flex {...style.btnContainer}>
                <Spacer />
                <Flex>
                  <CSVLink {...csvReport}>
                    <Button {...style.downloadbutton}>
                      {currentLangData?.app?.download}
                    </Button>
                  </CSVLink>
                </Flex>
              </Flex>
            </SimpleGrid>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={columsOfData}
            onPageChange={page => checktableTwoOnChange(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              console.log('createdAt', item?.createdAt);
              let date = new Date(item?.createdAt * 1000);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.userId}</Td>
                  <Td>{item?.firstName}</Td>
                  <Td>{item?.email}</Td>
                  <Td>{item?.MSISDN}</Td>
                  <Td>{item?.loginType}</Td>
                  {item?.isXoxUser === true ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="black"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.yes}
                        </Text>
                      </Circle>
                    </Td>
                  ) : item?.isXoxUser === false ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.no}
                        </Text>
                      </Circle>
                    </Td>
                  ) : null}
                  <Td {...style.statusStyle}>
                    {item?.registrationType === 1 ? (
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="black"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.subscribed}
                        </Text>
                      </Circle>
                    ) : item?.registrationType === 0 ? (
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.notSubscribed}
                        </Text>
                      </Circle>
                    ) : null}
                  </Td>

                  <Td>{date.toLocaleDateString()}</Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BlackAppNewRegistration;
