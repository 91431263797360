import React from 'react';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import styles from '../components/common/style';
import Loading from '../components/common/Loading';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import LangContext from '../context/languageContext';

import {
  Tr,
  Th,
  Td,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Button,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
// import { TableComp } from '../components/common/table';
import { MdImportExport } from 'react-icons/md';
import ApiManager from '../config/apiManager';
import { MiniHeader } from '../components/common/customComponent';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const FaqManagement = () => {
  let toast = useToast();
  let history = useHistory();
  let apiManager = ApiManager.getInstance();
  const { currentLangData } = React.useContext(LangContext);
  const [data, setData] = React.useState([]);
  const [success, setSuccess] = React.useState('');
  console.log('FaqManagement gg bro');

  React.useEffect(() => {
    getAllCategory();
    // console.log('hhhhhh')
  }, []);

  // Utility functions /

  const moveUp = id => {
    let index = data.findIndex(e => e._id == id);
    console.log('index', index, 'ID', id);
    if (index > 0) {
      let el = data[index];
      data[index] = data[index - 1];
      data[index - 1] = el;
      console.log('EL=>', el);
      // setData((_p) => ..._p,el)
      setData([...data]);
    }
    // getAllCategory()
  };

  console.log('newdata', data);

  const moveDown = id => {
    console.log('DATA==>', data);
    let index = data.findIndex(e => e._id == id);
    console.log('index', index, 'ID', id);

    if (index !== -1 && index < data.length - 1) {
      let el = data[index];
      data[index] = data[index + 1];
      data[index + 1] = el;
    }

    setData([...data]);
    // getAllCategory();
  };

  const moveItem = (from, to) => {
    console.log(from, 'DIFF', to);
    // remove `from` item and store it
    var f = data.splice(from, 1)[0];
    // insert stored item into position `to`
    data.splice(to, 0, f);
  };

  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreateFrequentlyAskedQuestion',
      state: { data: editData, useEditApi: true },
    });
  };

  // Api implementation //

  const deleteRecord = data => {
    console.log('deleteRecord', data);
    let body = {
      id: data?._id,
    };
    apiManager
      .post('deleteFAQ', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 8168) {
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          getAllCategory();
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getAllCategory = () => {
    let tempArray = [];
    apiManager
      .get('getAllFAQS')
      .then(res => {
        console.log('getAllFAQS=>RES', res);
        if (res.message === 8170) {
          console.log('Get getAllFAQS', res);
          res.result.docs.map((value, index) => {
            // console.log('valuesssss', value);
            tempArray.push(value);
          });
          setData(tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Frequently Asked Question List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'FAQ Management',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main} padding={'1%'}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateFrequentlyAskedQuestion')}
            >
              Create
            </Button>
            <Button {...style.previewbutton} fontWeight={'normal'}
              onClick={() => history.push('/FaqUnauth')}
            >
              Preview Html Page
            </Button>
          </Flex>

          <Table {...styles.table} padding={'1%'}>
            <Thead {...styles.thead}>
              <Tr>
                <Th {...styles.th}>Question</Th>
                <Th {...styles.th}>
                  <Flex>
                    <MdImportExport />
                    Recorder
                  </Flex>
                </Th>
                <Th {...styles.th}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody {...styles.tbody}>
              {data?.length > 0 ? (
                data &&
                data?.map((value, index) => {
                  // console.log(index, 'Data=>', value);
                  if (value.length !== 0) {
                    return (
                      <>
                        <Tr key={index} {...styles.tr}>
                          <Td key={index}>
                            <Tooltip
                              label={value?.questionENG}
                              aria-label="A tooltip"
                            >
                              <Text fontSize={'sm'} noOfLines={1}>
                                {' '}
                                {value?.questionENG}
                              </Text>
                            </Tooltip>
                            {/* {value?.questionENG} */}
                          </Td>
                          <Td
                            flex={1}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <ArrowUpIcon
                              {...styles.iconStyling}
                              onClick={() => moveUp(value?._id)}
                            />
                            <ArrowDownIcon
                              {...styles.iconStyling}
                              onClick={() => moveDown(value?._id)}
                              // onClick={() => moveDown(value?._id)}
                              // onClick={() => moveItem(0,1)}
                            />
                          </Td>
                          <Td
                            width={'15%'}
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                          >
                            <Tooltip label={currentLangData?.app?.edit}>
                              <Button
                                {...style.editbtn}
                                leftIcon={<EditIcon />}
                                onClick={() => navigateToEditPage(value)}
                              ></Button>
                            </Tooltip>
                            <Tooltip
                              label={currentLangData?.app?.delete}
                              bg={'red'}
                            >
                              <Button
                                {...style.Delbtn}
                                leftIcon={<DeleteIcon />}
                                onClick={() => deleteRecord(value)}
                              ></Button>
                            </Tooltip>
                          </Td>
                        </Tr>
                      </>
                    );
                  } else {
                    return (
                      <Tr height={'40vh'}>
                        <Td></Td>
                        <Td>
                          <Text>NO DATA AVAILABLE</Text>
                        </Td>
                        <Td></Td>
                      </Tr>
                    );
                  }
                })
              ) : (
                <Tr height={'40vh'}>
                  <Td></Td>
                  <Td>
                    <Loading />
                  </Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default FaqManagement;
