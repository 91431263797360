import React, { useState, useEffect } from 'react';
import {
  Flex,
  useToast,
  SimpleGrid,
  Divider,
  Spacer,
  Skeleton,
  Button,
} from '@chakra-ui/react';

import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  MiniHeader,
  customTextarea,
} from '../components/common/customComponent';
import {
  checkMandatory,
  customInputImage,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import ApiManager from '../config/apiManager';
import { uploadImage, deleteImage } from '../config/imageUploader';
import style from '../components/common/style';
import stylesP from '../styles/createLoyaltyPointStyles';
import { useHistory, useLocation } from 'react-router-dom';
import {
  statusDrop,
  rewardItem,
  rewardTypeDrop,
  rewardTypeValueDrop,
} from '../constants/data';

const CreateGameReward = () => {
  let dataOfEdit = [];
  let initialState = {
    id: '',
    name: '',
    image: '',
    resultValue: '',
    description: '',
    status: statusDrop[0],
    rewardItem: rewardItem[0],
    rewardType: rewardTypeDrop[0],
    rewardTypeValue: rewardTypeValueDrop[0],
  };

  let mandatoryFields = {
    name: true,
    rewardType: true,
    rewardItem: true,
    status: true,
    image: true,
  };
  const toast = useToast();
  let history = useHistory();
  let location = useLocation();
  let apimanager = ApiManager.getInstance();

  const [image, setImage] = useState('');
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialState);
  const [changeApi, setChangeApi] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (location?.state?.data != null || location?.state?.data != undefined) {
      dataOfEdit = location?.state?.data;
      let aray = [];
      setValue({
        ...value,
        name: dataOfEdit?.name,
        image: dataOfEdit?.image,
        resultValue: dataOfEdit?.resultValue,
        description: dataOfEdit?.description,
        status: dataOfEdit?.status ? dataOfEdit?.status : statusDrop[0],
        rewardItem: dataOfEdit?.rewardType
          ? dataOfEdit?.rewardType
          : rewardItem[0],
        rewardType: dataOfEdit?.type ? dataOfEdit?.type : rewardTypeDrop[0],
        rewardTypeValue: dataOfEdit?.rewardTypeValue
          ? dataOfEdit?.rewardTypeValue
          : rewardTypeValueDrop[0],
      });
      // getVouchers(dataOfEdit?.vouchers);
      // console.log('platform temp array', aray);

      // let tempStatus = changeStatusEdit(dataOfEdit?.status);
      // console.log('tempStatus=>', tempStatus);

      // let tempHalal = changeHalalEdit(dataOfEdit?.nonHalal);
      // console.log('tempHalal=>', tempHalal);

      setImage(dataOfEdit?.image);

      console.log('dataOfEdit', dataOfEdit);
      let changeApi = location?.state?.useEditApi;
      setChangeApi(changeApi);
    }
    setEditData(dataOfEdit);
  }, []);

  // Utility Functions //
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const uploadI = async e => {
    // setShow(true);
    setLoading(true);
    setImageLoading(true);
    // console.log('e.target=>', e);
    console.log('e.target=> name', e.target.files[0].name);
    await uploadImage(e.target.files[0])
      .then(res => {
        setLoading(false);

        setImageLoading(false);
        console.log('image Response', res);
        console.log('image uri', res.location);
        setImage(e.target.files[0].name);
        setValue({
          ...value,
          image: res.location,
        });
        // setShow(false);
      })
      .catch(error => {
        setLoading(false);
        setImageLoading(false);
        console.log('error', error);
      });
  };

  const deleteFromS3 = async image => {
    console.log('Image Name = >', image);
    console.log('URL FOR DELETING IMAGE', process.env.REACT_APP_S3URL + image);
    // await deleteImage(image)
    await deleteImage(image)
      .then(res => {
        console.log('Response of Delete', res);
      })
      .catch(err => {
        console.log('Err=>', err);  
      });
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 'Inactive') {
      return 0;
    } else if (value === 'Active') {
      return 1;
    }
  };

  const changeRewardType = value => {
    console.log('value=>', value);
    if (value === 'Competition') {
      return 0;
    } else if (value === 'Lucky Draw') {
      return 1;
    } else if (value === 'Min Score') {
      return 2;
    } else if (value === 'Min Score for Lucky Draw') {
      return 3;
    }
  };

  const changeRewardItem = value => {
    console.log('changeRewardItem=>', value);
    if (value === 'Data') {
      return 0;
    } else if (value === 'Voice') {
      return 1;
    } else if (value === 'SMS') {
      return 2;
    } else if (value === 'Quotes') {
      return 3;
    }
  };

  // Api Implementation //
  const onClickSubmit = () => {
    setLoading(true);
    console.log('onClickSubmit', value);

    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      let tempStatus = changeStatus(value['status']);
      console.log('tempStatus=>', tempStatus);

      let tempReward = changeRewardType(value['rewardType']);
      console.log('tempReward', tempReward);

      let tempRewardItem = changeRewardItem(value['rewardItem']);
      console.log('tempReward', tempRewardItem);

      const body = {
        name: value['name'],
        description: value['description'],
        type: tempReward,
        status: tempStatus,
        image: image,
        resultValue: value['resultValue'],
        rewardType: tempRewardItem,
        rewardTypeValue: value['rewardTypeValue'],
        // type: 2,
        // resultValue: 30,
        // rewardType: 2,
        // rewardTypeValue: '20',
        // image: 'hhdddh.jpg',
      };
      console.log('body>>>>', body);

      if (!changeApi) {
        apimanager
          .post('addGameReward', body)
          .then(response => {
            console.log(response, 'response');
            console.log('response.message', response.message);
            if (response.message === 8601) {
              setLoading(loading);
              setSuccess('Successfully Added Reward');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Added Reward!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 4000);
            }
            if (response.errors != undefined || response.errors != null) {
              if (response.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[0]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[1]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      } else {
        console.log('IDDD=>', dataOfEdit);
        let payload = {
          id: editData?._id,
          name: value['name'],
          description: value['description'],
          type: tempReward,
          status: tempStatus,
          image: image,
          resultValue: value['resultValue'],
          rewardType: tempRewardItem,
          rewardTypeValue: value['rewardTypeValue'],
        };
        console.log('update payload', payload);
        apimanager
          .post('updateGameReward', payload)
          .then(response => {
            console.log(response, 'response');
            console.log('response.message', response.message);
            if (response.message === 8603) {
              setLoading(loading);
              setSuccess('Successfully Updated Reward');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Updated Reward!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }
            if (response.errors !== undefined || response.errors !== null) {
              if (response.message === 5001) {
                setLoading(loading);
                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[1]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      }
    }
  };

  return (
    <Flex {...stylesP.parent} height={'100%'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: !changeApi ? 'Create Reward' : 'Update Reward',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: !changeApi ? 'Create Reward' : 'Update Reward',
          useSlash: true,
          path2: 'Create',
        })}
      </Flex>

      <Flex
        padding={'8'}
        width={'97%'}
        boxShadow={'lg'}
        alignSelf={'center'}
        alignItems={'center'}
        background={'white'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {/* {customInputLabel({ title: 'Name', mandatory: true })} */}
          {customInputLabel({
            value: value['name'],
            title: 'Name',
            mandatory: true,
            onChange: e => handleonChange(e, 'name'),
          })}

          {customTextarea({
            title: 'Description',
            value: value['description'],
            onChange: e => handleonChange(e, 'description'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={6}>
          {dropdownCustom({
            title: 'Reward Type',
            mandatory: true,
            temparray: rewardTypeDrop,
            placeholder: value['rewardType'],
            onChange: e => handleonChange(e, 'rewardType'),
          })}
          {customInputLabel({
            title: 'Result Value',
            mandatory: false,
            value: value['resultValue'],
            onChange: e => handleonChange(e, 'resultValue'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={4}>
          {dropdownCustom({
            temparray: rewardItem,
            placeholder: value['rewardItem'],
            title: 'Reward Item',
            mandatory: true,
            onChange: e => handleonChange(e, 'rewardItem'),
          })}
          {dropdownCustom({
            temparray: rewardTypeValueDrop,
            title: 'Reward Type Value',
            placeholder: value['rewardTypeValue'],
            mandatory: true,
            onChange: e => handleonChange(e, 'rewardTypeValue'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          {dropdownCustom({
            title: 'Status',
            // placeholder:value["status"],
            mandatory: true,
            temparray: statusDrop,
            onChange: e => {
              handleonChange(e, 'status');
            },
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          {!imageLoading ? (
            <Flex>
              {customInputImage({
                title: 'Image',
                mandatory: true,
                type: 'file',
                // value: value['image'],
                src: process.env.REACT_APP_S3URL + image,
                // onClick: e => console.log('E==>', e),
                onChange: e => uploadI(e),
              })}
              {/* <Button onClick={() => deleteFromS3(image)}>
                Delete From S3
              </Button> */}
            </Flex>
          ) : (
            <Skeleton height="20px" />
          )}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Update',
          title2: 'Cancel',
          isLoading: loading,
          onClickSubmit: () => onClickSubmit(),
          onClickCancel: () => history.goBack(),
        })}
      </Flex>

      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateGameReward;
