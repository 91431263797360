import React, { useState, useEffect, useContext } from 'react';
import {
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Table,
  Tbody,
  Select,
  Button,
  Spacer,
  Thead,
  useToast,
  Divider,
  Tooltip,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { options } from '../../context/data';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import style from '../../components/common/style';
import styles from '../../components/common/style';
import ApiManager from '../../config/apiManager';
import LangContext from '../../context/languageContext';
import Loading from '../../components/common/Loading';
import { MiniHeader } from '../../components/common/customComponent';
import { customInputLabel } from '../../components/createAppVersionComponent/createAppVersionComponent';
import { deleteFile } from 'react-s3';
import { renderCopyRight } from '../../components/aboutUsComponent/customCompoent';

const AdxRewardManagement = () => {
  let tempArray = [];

  let toast = useToast();
  let history = useHistory();

  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('10');
  const [data, setData] = React.useState([]);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = React.useState('');
  const [paginate, setpaginate] = React.useState();
  const [pageChange, setPageChange] = useState('');
  const { currentLangData } = useContext(LangContext);
  const [valueSearch, setValueSearch] = React.useState();
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);

  useEffect(() => {
    getAllCategory(page, value);
  }, []);

  // Utility functions //
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('next and previous', loading);
    setCurrentPage(selectedPage);
    getAllCategory(selectedPage + 1, value);
    console.log('clicked', selectedPage + 1);
  };

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('event', ev);
    setValue(ev);
    getAllCategory(page, ev);
  };

  let totalDatalength = paginate?.totalDocs;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  let pageCount = paginate?.totalPages;
  const pageCeilingCount = paginate?.totalPages;
  console.log(pageCeilingCount, 'PAGE COUNT', pageCount);

  useEffect(() => {}, [page]);
  useEffect(() => {}, [value]);

  const deleteRecord = data => {
    setLoading(true);

    console.log('deleteRecord', data);
    // deleteS3(data.companyLogo);
    let body = {
      id: data?._id,
    };
    apiManager
      .post('deleteGameReward', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 8605) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          getAllCategory(1, value);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const deleteS3 = uri => {
    console.log('e.target=>', uri);
    deleteFile(uri)
      .then(res => {
        console.log(res, 'RES');
        // console.log('image uri', res.location);
        // setS3Image(res.location);
        // setValue({
        //   ...value,
        //   componyLogo: res.location,
        // });
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getAllCategory = (page, value) => {
    setLoading(true);
    let tempArray = [];
    console.log('page=>', page);
    let body = {
      page: page,
      // nextPage: 3,
      numRecords: value,
    };
    apiManager
      .get('getGameRewardsList', body)
      .then(res => {
        if (res.message === 8607) {
          console.log('Get GetGameRewardsList', res);
          pageCount = res.record.totalDocs;
          res.record.docs.map((value, index) => {
            // console.log('valuesssss', value);
            tempArray.push(value);
          });
          setpaginate(res.record);
          setData(tempArray);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleOnSearch = (evt, key) => {
    console.log("e.key === 'Enter==>", evt);
    const ev = evt.target.value;

    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };

  const searchItems = (value, limit, page) => {
    console.log('searchItems', value, limit, page);
    setLoading(true);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apiManager
      .post('searchGameRewards', body)
      .then(res => {
        console.log('res=>', res.message);
        if (res.message === 8614) {
          res?.record?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setData(tempArray);
          setpaginate(res?.record);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreateGameReward',
      state: { data: editData, useEditApi: true },
    });
  };

  const convertingDate = date => {
    let newDate = new Date(date);
    return newDate.toLocaleString();
  };

  const changeRewardItem = value => {
    console.log('changeRewardItem=>', value);
    if (value === 0) {
      return 'Data';
    } else if (value === 1) {
      return 'Voice';
    } else if (value === 2) {
      return 'SMS';
    } else if (value === 3) {
      return 'Quotes';
    }
  };

  const changeRewardType = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'Competition';
    } else if (value === 1) {
      return 'Lucky Draw';
    } else if (value === 2) {
      return 'Min Score';
    } else if (value === 3) {
      return 'Min Score for Lucky Draw';
    }
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Reward List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Reward',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main} padding={5}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateGameReward')}
            >
              Create
            </Button>
          </Flex>

          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingX={'5px'}>{currentLangData?.app?.search}: </Text>
              {customInputLabel({
                size: 'sm',
                onKeyDown: e => handleOnSearch(e),
                // onChange: e => handleOnSearch(e),
              })}
            </Flex>
          </Flex>

          <Table {...styles.table} padding={'1%'}>
            <Thead {...styles.thead}>
              <Tr>
                <Th {...styles.th}>Name</Th>
                <Th {...styles.th}>Reward Type</Th>
                <Th {...styles.th}>Reward item</Th>
                <Th {...styles.th}>Reward Type Value</Th>
                <Th {...styles.th}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody {...styles.tbody}>
              {console.log('Array is empty', data.length)}
              {data?.length > 0 ? (
                !loading ? (
                  data &&
                  data?.map((value, index) => {
                    console.log(index, 'value', value);
                    console.log('Running Reward Data');
                    console.log(index, 'value.length', value.length);

                    let tempRewardItem = changeRewardItem(value?.rewardType);
                    console.log('tempReward', tempRewardItem);

                    let tempReward = changeRewardType(value?.type);
                    console.log('tempReward', tempReward);
                    if (index >= 0) {
                      return (
                        <>
                          <Tr key={index} {...styles.tr}>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.name}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {tempReward}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {tempRewardItem}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.rewardTypeValue}
                            </Td>
                            <Td
                              width={'20%'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'flex-end'}
                            >
                              <Tooltip label={currentLangData?.app?.edit}>
                                <Button
                                  {...style.editbtn}
                                  leftIcon={<EditIcon />}
                                  onClick={() => navigateToEditPage(value)}
                                ></Button>
                              </Tooltip>
                              <Tooltip
                                label={currentLangData?.app?.delete}
                                bg={'red'}
                              >
                                <Button
                                  {...style.Delbtn}
                                  leftIcon={<DeleteIcon />}
                                  onClick={() => deleteRecord(value)}
                                ></Button>
                              </Tooltip>
                            </Td>
                          </Tr>
                        </>
                      );
                    } else {
                      return (
                        <Tr height={'40vh'}>
                          <Td></Td>
                          <Td></Td>
                          <Td>
                            <Text>NO DATA AVAILABLE</Text>
                          </Td>
                          <Td></Td>
                          <Td></Td>
                        </Tr>
                      );
                    }
                  })
                ) : (
                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td paddingY={'15vh'}>
                      <Flex
                        style={{
                          w: '100%',
                          h: '100%',
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Loading />
                      </Flex>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                )
              ) : (
                <Tr>
                  <Td paddingY={'15vh'}>
                    <Flex {...style.elseflex}>
                      <Text fontSize={'xl'}>Oops! Data bucket is Empty</Text>
                    </Flex>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />

          <Flex {...style.paginatecontainer} justifyContent={'space-between'}>
            <Text>
              Showing {data.length} to {PER_PAGE} of {totalDatalength} entries
            </Text>
            <ReactPaginate
              nextLabel={'Next'}
              pageCount={pageCount}
              previousLabel={'Previous'}
              activeClassName={'active'}
              nextClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              containerClassName={'pagination'}
              onPageChange={handlePageClick}
              previousLinkClassName={'page-link'}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default AdxRewardManagement;

// import React from 'react';
// import { Box, Button, Flex, Text } from '@chakra-ui/react';
// import style from '../../components/common/style';
// import { TableComp } from '../../components/common/table';
// import { listRewards, colRewards } from '../../context/data';
// import { useHistory } from 'react-router-dom';
// import { MiniHeader } from '../../components/common/customComponent';

// const Rewards = () => {
//   let history = useHistory();
//   console.log('Rewards gg bro');
//   return (
//     <Flex flexDirection={'column'}>
//       <Flex {...style.miniheader}>
//         {MiniHeader({
//           title: 'Game Reward List',
//           path: 'Dashboard',
//           onclickForRoute: () => history.push('/Dashboard'),
//           path1: 'Game Reward Management',
//           useSlash:true,
//           path2:'Reward',
//         })}
//       </Flex>
//       <Flex {...style.box} background={'#fafdfb'} padding={10}>
//         <Flex {...style.main}>
//           <Flex {...style.buttoncontainer}>
//             <Button
//               {...style.createbutton}
//               onClick={() => history.push('/CreateGameReward')}
//             >
//               Create
//             </Button>
//           </Flex>
//           <TableComp
//             list={listRewards}
//             screenName="LuckDrawList"
//             colNames={colRewards}
//           />
//         </Flex>
//       </Flex>
//     </Flex>
//   );
// };
// export default Rewards;
