import React from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';

import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';

const CreateUpsellCompaign = () => {
  console.log('CreateUpsellCompaign gg bro');
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      {MiniHeader({
        title: 'Create Terms & Conditions',
        path: 'Dashboard / Term and Conditions / Create Terms & Conditions',
      })}

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Title',
            mandatory: true,
            // type: 'file',
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customTextarea({
            title: 'Description',
            mandatory: true,
          })}
          {customTextarea({
            title: 'Success message',
            mandatory: true,
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Start Date',
            mandatory: true,
          })}
          {customInputLabel({
            title: 'End Date',
            mandatory: true,
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Transaction Type',
            mandatory: true,
          })}
          <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
            {customInputLabel({
              title: 'Min Amount',
              mandatory: true,
            })}
            {customInputLabel({
              title: 'Status',
              mandatory: true,
            })}
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Image',
            mandatory: true,
            type:"file"
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateUpsellCompaign;
