import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  Input,
  useToast,
} from '@chakra-ui/react';
import style from '../../components/common/style';
import { ChecktableOne } from '../../components/common/ChecktableOne';
import { colBannerTypes } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../../config/apiManager';
import LangContext from '../../context/languageContext';
import { options } from '../../context/data';

// const deleteItem = (item) => {
//   console.log('Item to delete', item._id)
// }

export const BannerTypes = () => {
  let toast = useToast();
  let history = useHistory();
  let apimanager = ApiManager.getInstance();
  const [dataa, setDataa] = React.useState();
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const { currentLangData } = React.useContext(LangContext);
  let tempArray = [];

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    let apimanager = ApiManager.getInstance();
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllBannerTypes', body)
      .then(res => {
        if (res.message === 8129) {
          console.log('Get add country responce', res);
          let data = res?.result?.docs;
          console.log('data>>>>>>>data', data);
          res?.result?.docs?.map((value, index) => {
            // console.log("valuee",value)
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.result);
          setLoader(true);
        }
        console.log('tempArray=>>>>>', tempArray);
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const deleteItem = item => {
    console.log('Deletion done', item._id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deleteBannerType', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8128) {
          toast({
            title: 'Successfully Deleted',
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          getALLItems(value, 1);
        } else {
          toast({
            title: 'Failed to Delete',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Something went wrong!`,
          });
          getALLItems(value, 1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader} boxShadow={'lg'} borderBottomWidth={'1px'}>
        {MiniHeader({
          title: 'Banner Types List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: 'true',
          path2: 'Banner Types',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateBannerType')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            {/* <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
              <Input {...style.searchinput}></Input>
            </Flex> */}
          </Flex>
          <ChecktableOne
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            screenName="BannerTypes"
            screenType="buttons"
            Editbutton="CreateBannerType"
            headerNames={colBannerTypes}
            onDelete={item => {
              deleteItem(item);
            }}
            onPageChange={page => getALLItems(value, page)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
