const styles = {
  smallHeading: {
    display: 'block',
    fontSize: 'md',
    marginBottom: 2,
  },
  editbtn: {
    size: 'sm',
    variant: 'ghost',
    color: 'black',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  Delbtn: {
    size: 'sm',
    variant: 'ghost',
    color: 'red',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
};
export default styles;
