import React from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { CheckIcon, DeleteIcon, EditIcon, UpDownIcon } from '@chakra-ui/icons';
import { MdImportExport } from 'react-icons/md';
import { options } from '../../context/data';
import LangContext from '../../context/languageContext';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  Input,
  Table,
  Thead,
  Tooltip,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Circle,
} from '@chakra-ui/react';
import style from '../common/style';
import Loading from './Loading';
import CreateBannerType from '../../pages/CreateBannerTypes';

export const ChecktableOne = ({
  datalist,
  TotDocs,
  screenType,
  headerNames,
  dataState,
  screenName,
  onDelete,
  Editbutton,
  onPageChange,
  renderItem,
  loaderr,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [screenStatusbanner, setscreenStatusbanner] = React.useState(false);
  const [softpins, setsoftpins] = React.useState(false);
  const [Bannermanagement, setBannermanagement] = React.useState(false);
  const [PromoCodeManagement, setPromoCodeManagement] = React.useState(false);
  const [otplogs, setOtplogs] = React.useState(false);
  const [screenState, setScreenState] = React.useState(false);
  const [screenBanner, setScreenBanner] = React.useState(false);
  const [countries, setcountries] = React.useState(false);
  const [FAQCategories, setFAQCategories] = React.useState(false);
  const [smsMasterLibrary, setSmsMasterLibrary] = React.useState(false);
  const { currentLangData } = React.useContext(LangContext);

  const [editSoftpinCategory, setEditSoftpinCategory] = React.useState(false);

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('clicked', selectedPage);
    setCurrentPage(selectedPage);
    onPageChange(selectedPage + 1);
  };
  let history = useHistory();
  // console.log('datalist>>>>>>>', datalist);
  let totalDatalength = TotDocs?.totalDocs;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  // const pageCount = Math.ceil(totalDatalength / PER_PAGE);
  const pageCount = TotDocs?.totalPages;

  React.useEffect(() => {
    if (screenName === 'States') {
      setScreenState(true);
    } else if (screenName === 'BannerTypes') {
      setScreenBanner(true);
    } else if (screenName === 'StatusForBanners') {
      setscreenStatusbanner(true);
    } else if (screenName === 'softpins') {
      setsoftpins(true);
    } else if (screenName === 'Bannermanagement') {
      setBannermanagement(true);
    } else if (screenName === 'PromoCodeManagement') {
      setPromoCodeManagement(true);
    } else if (screenName === 'Otplogs') {
      setOtplogs(true);
    } else if (screenName === 'Countries') {
      setcountries(true);
    } else if (screenName === 'FAQCategories') {
      setFAQCategories(true);
    } else if (screenName === 'SmsMasterLibrary') {
      setSmsMasterLibrary(true);
    } else if (Editbutton === 'CreateSoftpinCategory') {
      setEditSoftpinCategory(true);
    }
    console.log('screenbanner>>>>', Editbutton);
  }, []);
  return (
    <Flex width={'100%'} flexDirection={'column'} paddingX={5}>
      <Flex {...style.tableFlex}>
        <Table {...style.table}>
          <Thead {...style.thead}>
            <Tr>
              {headerNames.map((headerItem, index) => (
                <Th {...style.th} key={index}>
                  <Flex>
                    <MdImportExport />
                    {headerItem}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody {...style.tbody}>
            {loaderr === true ? (
              datalist?.length > 0 ? (
                datalist?.map((value, index) => {
                  // console.log('ScreenValue=',value)
                  let startdate = new Date(value?.startDate * 1000);
                  let enddate = new Date(value?.endDate * 1000);
                  let expirydate = new Date(value?.expiryDate * 1000);
                  let createddate = new Date(value?.createdAt * 1000);
                  let lastSentOtp = new Date(value?.lastSentOtp * 1000);

                  if (renderItem) return renderItem({ item: value, index });
                  return (
                    <Tr key={index} width={'100%'} {...style.tr}>
                      {/* ------------------screens modification-------------- */}
                      {screenState ? (
                        <>
                          <Td>{value?.countryName}</Td>
                          <Td>
                            {value?.name}
                            {/* {value?.states?.map((dat, index) => {
                            return dat?.name;
                          })} */}
                          </Td>
                        </>
                      ) : screenBanner ? (
                        <Td>{value?.name}</Td>
                      ) : screenStatusbanner ? (
                        <Td>{value}</Td>
                      ) : softpins ? (
                        <>
                          <Td>{value?.categoryName}</Td>
                          <Td>{value?.pinNumber}</Td>
                          <Td>{value?.price}</Td>
                          <Td>{value?.amount}</Td>

                          {/* <Td>
                            {value?.softpinsCategories?.map((dat, index) => {
                              return dat.price;
                            })}
                          </Td>
                          <Td>
                            {value?.softpinsCategories?.map((dat, index) => {
                              return dat.amount;
                            })}
                          </Td> */}
                          <Td>{expirydate.toLocaleDateString()}</Td>
                        </>
                      ) : Bannermanagement ? (
                        <>
                          <Td>{startdate.toLocaleDateString()}</Td>
                          <Td>{enddate.toLocaleDateString()}</Td>
                          <Td>{value?.name}</Td>
                          <Td>{value?.bannerType}</Td>
                          <Td>{value?.status}</Td>
                        </>
                      ) : PromoCodeManagement ? (
                        <>
                          <Td>{startdate.toLocaleDateString()}</Td>
                          <Td>{enddate.toLocaleDateString()}</Td>
                          <Td>{value?.couponCode}</Td>
                          <Td>{value?.quota}</Td>
                          <Td>{value?.status}</Td>
                        </>
                      ) : otplogs ? (
                        <>
                          <Td></Td>
                          <Td>{value?.phoneNumber}</Td>
                          <Td>{value?.otp}</Td>
                          <Td>{value?.description}</Td>
                          <Td>{lastSentOtp.toLocaleString()}</Td>
                          <Td>
                            <Circle
                              marginStart={2}
                              fontSize={'sm'}
                              size="100%"
                              bg="black"
                              color="white"
                            >
                              {currentLangData?.app?.success}
                            </Circle>
                          </Td>
                        </>
                      ) : countries ? (
                        <>
                          <Td>{value?.isoCode}</Td>
                          <Td>{value?.name}</Td>
                        </>
                      ) : FAQCategories ? (
                        <>
                          <Td>{value?.name}</Td>
                        </>
                      ) : smsMasterLibrary ? (
                        <>
                          <Td>{value?.smsCategory}</Td>
                          <Td>{value?.smsId}</Td>
                          <Td>{value?.contentENG}</Td>
                        </>
                      ) : null}
                      {/* ----------------------------------buttons----------------------------------------- */}
                      <Td
                        position={'relative'}
                        display={'inline-flex'}
                        verticalAlign={'middle'}
                        justifyContent={'flex-end'}
                      >
                        {screenBanner ? (
                          <Tooltip label={currentLangData?.app?.edit}>
                            <Button
                              {...style.editbtn}
                              leftIcon={<EditIcon />}
                              onClick={() =>
                                history.push({
                                  pathname: '/CreateBannerType',
                                  status: 'create',
                                  state: { val: value },
                                })
                              }
                            ></Button>
                          </Tooltip>
                        ) : softpins ? (
                          <Tooltip label={currentLangData?.app?.edit}>
                          <Button
                            {...style.editbtn}
                            leftIcon={<EditIcon />}
                            onClick={() =>
                              history.push({
                                pathname: '/CreateSoftpin',
                                status: 'create',
                                state: { val: value },
                              })
                            }
                          >
                          </Button>
                          </Tooltip>
                        ) : countries ? (
                          <Button
                            {...style.editbtn}
                            onClick={() =>
                              history.push({
                                pathname: '/CreateCountry',
                                status: 'create',
                                state: { val: value },
                              })
                            }
                          >
                            {currentLangData?.app?.edit}
                          </Button>
                        ) : screenState ? (
                          <Tooltip label={currentLangData?.app?.edit}>
                            <Button
                              {...style.editbtn}
                              leftIcon={<EditIcon />}
                              onClick={() =>
                                history.push({
                                  pathname: '/CreateState',
                                  status: 'create',
                                  state: { val: value },
                                })
                              }
                            ></Button>
                          </Tooltip>
                        ) : PromoCodeManagement ? (
                          <Tooltip label={currentLangData?.app?.edit}>
                          <Button
                            {...style.editbtn}
                            leftIcon={<EditIcon />}
                            onClick={() =>
                              history.push({
                                pathname: '/CreatePromoCode',
                                status: 'create',
                                state: { val: value },
                              })
                            }
                          >
                          </Button>
                          </Tooltip>
                        ) : FAQCategories ? (
                          <Tooltip label={currentLangData?.app?.edit}>
                            <Button
                              {...style.editbtn}
                              leftIcon={<EditIcon />}
                              onClick={() =>
                                history.push({
                                  pathname: '/CreateFaqCategory',
                                  status: 'create',
                                  state: { val: value },
                                })
                              }
                            ></Button>
                          </Tooltip>
                        ) : smsMasterLibrary ? (
                          <Tooltip label={currentLangData?.app?.edit}>
                            <Button
                              {...style.editbtn}
                              leftIcon={<EditIcon />}

                              onClick={() =>
                                history.push({
                                  pathname: '/CreateSmsMasterLibrary',
                                  status: 'create',
                                  state: { val: value },
                                })
                              }
                            >
                              {/* {currentLangData?.app?.edit} */}
                            </Button>
                          </Tooltip>
                        ) : otplogs ? null : (
                          <Button {...style.editbtn}>
                            {currentLangData?.app?.edit}
                          </Button>
                        )}
                        {otplogs ? null : (
                          <>
                            <Tooltip
                              label={currentLangData?.app?.delete}
                              bg="red.600"
                            >
                              <Button
                                {...style.Delbtn}
                                leftIcon={<DeleteIcon />}
                                onClick={() => onDelete(value)}
                              ></Button>
                            </Tooltip>
                          </>
                        )}
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td paddingY={'15vh'}>
                    <Flex {...style.elseflex}>
                      {/* <Loading /> */}
                      <Text fontSize={'xl'}>oops! Data bucket is Empty</Text>
                    </Flex>
                  </Td>
                </Tr>
              )
            ) : (
              <Tr>
                <Td paddingY={'15vh'}>
                  <Flex {...style.elseflex}>
                    <Loading />
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
      <Flex {...style.tablefooter}>
        <Flex>
          <Text>
            {currentLangData?.app?.showing} {offset} {currentLangData?.app?.to}
            {PER_PAGE} {currentLangData?.app?.of} {totalDatalength}{' '}
            {currentLangData?.app?.entries}
          </Text>
        </Flex>
        <Flex {...style.paginatecontainer}>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
