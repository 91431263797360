const styles = {
  parentFlex: {
    flex: 1,
    h: '100%',
    marginStart: 8,
    background: '#fafdfb',
    flexDirection: 'column',
  },
  manageButton: {
    size: 'sm',
    bg: 'white',
    //     rounded: 'lg',
    color: 'white',
    variant: 'outline',
    boxShadow: 'lg',
    background: 'black',
    borderRadius: '40px',
  },
  simpleGridStyle: {
    spacingX: '30px',
    spacingY: '20px',
    alignItems: 'center',
    marginTop: '-45px',
    justifyContent: 'center',
  },
  simpleGridSecond: {
    spacingX: '30px',
    spacingY: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkSimpleGridfirst: {
    width: '100%',
    marginBottom: 5,
    flexDirection: 'column',
  },
};
export default styles;
