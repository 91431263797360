// import style from './style';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import './editor.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ControlledEditor = callback => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const onEditorStateChange = editorData => {
    let stringText =
      editorData &&
      (convertToRaw(editorData.getCurrentContent()));

    console.log(stringText, 'Editor State', editorData);

    setEditorState(editorData);
    // var converted = convertFromRaw(stringText);
    console.log('callback', callback?.tempData(stringText));

    // console.log('convertFromRaw', converted);
    // console.log('callback', callback);
    // convertCommentFromJSONToHTML(stringText);
    // callback.tempData()
    // callback:()=>
  };

  return (
    <Editor
      editorState={editorState}
      editorClassName="demo-editor"
      //     wrapperClassName="demo-wrapper"
      // editorClassName="demo-editor"
      wrapperClassName="demo-wrapper"
      // localization={{
      //   locale: 'ko',
      // }}
      onEditorStateChange={e => onEditorStateChange(e)}
    />
  );
};

export default ControlledEditor;

// import React, { Component } from 'react';
// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// // import draftToHtml from 'draftjs-to-html';
// import draftToMarkdown from 'draftjs-to-markdown';
// export class ControlledEditor extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editorState: EditorState.createEmpty(),
//     };
//   }

//   onEditorStateChange = editorState => {
//     let stringText =
//       editorState &&
//       draftToMarkdown(convertToRaw(editorState.getCurrentContent()));

//     console.log('stringText=>', stringText);
//     this.setState({
//       editorState,
//     });
//     // this.props.currentText = stringText;
//   };

//   render() {
//     const { editorState } = this.state;
//     console.log(editorState);
//     return (
//       <Editor
//         editorState={editorState}
//         wrapperClassName="demo-wrapper"
//         editorClassName="demo-editor"
//         onEditorStateChange={this.onEditorStateChange}
//       />
//     );
//   }
// }
