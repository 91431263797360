import React, { useEffect } from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  Text,
  Select,
  Textarea,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import style from '../components/common/style';
import { useHistory } from 'react-router-dom';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
  customInputImage,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import Multiselect from 'multiselect-react-dropdown';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import { uploadImage } from '../config/imageUploader';
import { DATABUTTON } from '../context/data';

const CreateAskQuestion = () => {
  let initialValue = {
    title: '',
    description: '',
    transactionType: [],
    paymentType: [],
    minimumSpend: '',
    rewardType: '',
    status: '',
    campaignType: '',
  };

  let mandatoryFields = {
    title: true,
    description: true,
    transactionType: true,
    paymentType: true,
    minimumSpend: true,
    rewardType: true,
  };

  let referralMandatoryFields = {
    title: true,
    description: true,

    rewardType: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [type, setType] = React.useState(false);
  const [singleItem, setSingleItem] = React.useState(false);
  const [rewardVoucher, setRewardVoucher] = React.useState(false);
  const [rewardNonVoucher, setrewardNonVoucher] = React.useState(false);
  const [success, setsuccess] = React.useState(null);
  const [endD, setEndD] = React.useState('');
  const [voucherEndD, setVoucherEndD] = React.useState('');
  const [startD, setStartD] = React.useState('');
  const [title, setTitle] = React.useState([]);
  const [merchantList, setMerchantList] = React.useState([]);
  const [merchantAllList, setMerchantAllList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [route, setRoute] = React.useState([]);
  const [s3image, setS3Image] = React.useState('');
  const [error, setError] = React.useState(null);
  const [imageLoading, setImageLoading] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();
  let temptitle = [];
  let tempRoute = [];
  let TransactionType = [
    'Pay Bill',
    'Purchase Season Pass(Black)',
    'Purchase Season Pass(Prepaid)',
    'Top UP',
    'SoftpinTopUp',
  ];
  let TypeData = ['Dynamic Campaign', 'Referral Reward'];
  let paymentType = ['AirTime', 'Ewallet', 'Ipay88'];
  let rewardType = ['Voucher', 'Non-Voucher'];

  const changeCompaignEdit = value => {
    if (value === 0) {
      setType(false);
      return 'Dynamic Campaign';
    } else if (value === 1) {
      setType(true);
      return 'Referral Reward';
    }
  };

  useEffect(() => {
    getmerchantList();
    let compaign = changeCompaignEdit(location.state?.val?.campaignType);
    console.log('compaign', compaign);
    console.log('location', location);

    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val?.campaignType != null) {
      console.log('running compaign check');
      setValue(_p => ({
        ..._p,
        campaignType: compaign,
      }));
    }

    if (location.state?.val?.title)
      setValue(_p => ({ ..._p, title: location.state?.val?.title }));
    if (location.state?.val?.startDate) {
      let startdate = new Date(location.state?.val?.startDate * 1000);
      setStartD(startdate);
    }
    if (location.state?.val?.endDate) {
      let eddate = new Date(location.state?.val?.endDate * 1000);
      setEndD(eddate);
    }
    if (location?.state?.val?.rewardType != null) {
      if (location?.state?.val?.rewardType == 1) {
        setValue(_p => ({ ..._p, rewardType: 'Non-Voucher' }));
        setrewardNonVoucher(true);
        setRewardVoucher(false);
      }
      if (location?.state?.val?.rewardType == 0) {
        setValue(_p => ({ ..._p, rewardType: 'Voucher' }));
        setRewardVoucher(true);
        setrewardNonVoucher(false);
      }
      if (location.state?.val?.rewardType == 2) {
        setValue(_p => ({ ..._p, rewardType: 'Form' }));
        setrewardNonVoucher(false);
        setRewardVoucher(false);
      }
    }

    if (location.state?.val?.description)
      setValue(_p => ({
        ..._p,
        description: location?.state?.val?.description,
      }));
    if (location.state?.val?.minimumSpend)
      setValue(_p => ({
        ..._p,
        minimumSpend: location?.state?.val?.minimumSpend,
      }));
    if (location.state?.val?.image) setS3Image(location?.state?.val?.image);
    if (location.state?.val?.status)
      setValue(_p => ({ ..._p, status: location?.state?.val?.status }));
    if (location.state?.val?.transactionType)
      setValue(_p => ({
        ..._p,
        transactionType: location?.state?.val?.transactionType,
      }));
    if (location.state?.val?.paymentType)
      setValue(_p => ({
        ..._p,
        paymentType: location?.state?.val?.paymentType,
      }));
    if (location.state?.val?.details?.voucherPromoCode)
      setValue(_p => ({
        ..._p,
        voucherPromoCode: location?.state?.val?.details?.voucherPromoCode,
      }));
    if (location.state?.val?.details?.voucherQty)
      setValue(_p => ({
        ..._p,
        voucherQty: location?.state?.val?.details?.voucherQty,
      }));
    if (location.state?.val?.details?.voucherExpiryDate) {
      let voucherEDate = new Date(
        location.state?.val?.details?.voucherExpiryDate * 1000
      );
      setVoucherEndD(voucherEDate);
    }
    if (location.state?.val?.details?.data)
      setValue(_p => ({ ..._p, data: location?.state?.val?.details?.data }));
    if (location.state?.val?.details?.sms)
      setValue(_p => ({ ..._p, sms: location?.state?.val?.details?.sms }));
    if (location.state?.val?.details?.voice)
      setValue(_p => ({ ..._p, voice: location?.state?.val?.details?.voice }));
    console.log('pathdata', location?.state?.val);
  }, [location]);

  useEffect(() => {
    DATABUTTON.map((value, index) => {
      console.log(value.title, 'Check', index);
      temptitle.push(value.title);
      tempRoute.push(value.route);
    });
    setTitle(temptitle);
    setRoute(tempRoute);
  }, []);

  const getSingleMerchant = () => {
    let b = merchantAllList?.find((item, idx) => {
      if (item.idmerchant == location?.state?.val?.voucherMerchantId) {
        console.log('what is single merchant', item);
        return item?.merchantname + '-' + item?.voucherValue;
      }
    });
    return b;
  };

  useEffect(() => {
    if (merchantAllList) {
      let aa = getSingleMerchant();
      setValue(_p => ({
        ..._p,
        voucherMerchantId: aa?.merchantname + '-' + aa?.voucherValue,
      }));
    }
  }, [merchantAllList]);

  useEffect(() => {}, [value]);

  console.log('value', value);
  let tempArray = [];
  const getmerchantList = () => {
    apimanager
      .get('loyaltyPoints/merchantListForCMS')
      .then(res => {
        console.log('merchantListForCMS', res);
        if (res.message === 9061) {
          res?.data?.map((value, index) => {
            tempArray.push(value.merchantname + '-' + value.voucherValue);
          });
          setMerchantList(tempArray);
          setMerchantAllList(res?.data);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoading(true);
      });
  };
  const handleonSwitch = (evt, key) => {
    const ev = evt;
    console.log('Switch', ev, 'key', key);
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleonChange = (evt, key) => {
    console.log('key', key);
    console.log('event', evt);
    const ev = evt.target.value;
    console.log('event ev', ev);
    setValue({
      ...value,
      [key]: ev,
    });
    if (ev == 'Referral Reward') {
      setType(true);
    }
    if (ev == 'Dynamic Campaign') {
      setType(false);
    }
    if (ev == 'Voucher') {
      setRewardVoucher(true);
      setrewardNonVoucher(false);
    }
    if (ev == 'Non-Voucher') {
      setrewardNonVoucher(true);
      setRewardVoucher(false);
    }
    if (ev == 'Form') {
      setrewardNonVoucher(false);
      setRewardVoucher(false);
    }
  };
  console.log('value', value);

  const upload = e => {
    setImageLoading(true);
    console.log('e.target=>', e.target.files[0].name);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('image uri', res.location);
        setS3Image(e.target.files[0].name);
        setImageLoading(false);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };
  const checkID = currentID => {
    console.log('voucherMerchantID==>', currentID);
    let idd = merchantAllList.find(merchant => {
      if (merchant.merchantname + '-' + merchant.voucherValue === currentID) {
        console.log(
          merchant.idmerchant,
          'merchant.idmerchantmerchant.idmerchant'
        );
        return merchant.idmerchant;
      } else {
        return false;
      }
    });
    console.log('IDDDD==>', idd.idmerchant);
    return idd.idmerchant;
  };

  const onClickSubmit = () => {
    console.log('value submit---', value);
    let reward = null;

    if (value?.rewardType == 'Voucher') {
      reward = 0;
    }
    if (value?.rewardType == 'Non-Voucher') {
      reward = 1;
    }
    if (value?.rewardType == 'Form') {
      reward = 2;
    }

    let findMerchantId = null;
    if (value?.voucherMerchantId != 'undefined-undefined') {
      findMerchantId = checkID(value?.voucherMerchantId);
      console.log('findMerchantId', findMerchantId);
    }

    setLoading(true);

    let ifError = null;
    if (value?.campaignType === 'Referral Reward') {
      ifError = checkMandatory({
        value: value,
        mandatoryFields: referralMandatoryFields,
      });
    }
    if (value?.campaignType === 'Dynamic Campaign') {
      ifError = checkMandatory({
        value: value,
        mandatoryFields: mandatoryFields,
      });
    }
    console.log('ifError', ifError);
    if (ifError) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter mandatory fields',
      });
    } else if (s3image === '' || s3image === undefined) {
      setLoading(false);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Please enter mandatory fields',
      });
    } else {
      console.log(value, 'VALUE');

      const body = {
        title: value?.title,
        description: value?.description,
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
        rewardType: reward,
        transactionType: value?.transactionType,
        paymentType: value?.paymentType,
        minimumSpend: value?.minimumSpend == '' ? 0 : value?.minimumSpend,
        image: s3image,
        status: value?.status == true ? 1 : 0,
        campaignType: value?.campaignType == 'Referral Reward' ? 1 : 0,
        data: value?.data ? parseInt(value?.data) : '',
        sms: value?.sms ? parseInt(value?.sms) : '',
        voice: value?.voice ? parseInt(value?.voice) : '',
        voucherMerchantId: findMerchantId ? findMerchantId.toString() : '',
        voucherPromoCode: value?.voucherPromoCode
          ? parseInt(value?.voucherPromoCode)
          : '',
        voucherQty: value?.voucherQty ? parseInt(value?.voucherQty) : '',
        voucherExpiryDate:
          voucherEndD.length === 0 ? '' : String(convertedDate(voucherEndD)),
      };
      console.log('body>>>>', body);
      apimanager
        .post('addCampaign', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8632) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Submitted, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          } else if (response.message === 8636) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Please enter data`,
            });
          } else if (response.message === 8637) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Please Enter SMS`,
            });
          } else if (response.message === 8637) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Please Enter Voice`,
            });
          } else if (response.message === 8633) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Please enter voucher promocode`,
            });
          } else if (response.message === 8634) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `please enter voucher QTY`,
            });
          } else if (response.message === 8635) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `please enter voucher expiry date`,
            });
          } else {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `failed to add campaign`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const onClickUpdate = () => {
    let reward = null;

    if (value?.rewardType == 'Voucher') {
      reward = 0;
    }
    if (value?.rewardType == 'Non-Voucher') {
      reward = 1;
    }
    if (value?.rewardType == 'Form') {
      reward = 2;
    }

    let findMerchantId = null;
    if (value?.voucherMerchantId != 'undefined-undefined') {
      findMerchantId = checkID(value?.voucherMerchantId);
      console.log('findMerchantId', findMerchantId);
    }

  
    setLoading(true);

    let ifError = null;
    if (value?.campaignType === 'Referral Reward') {
      ifError = checkMandatory({
        value: value,
        mandatoryFields: referralMandatoryFields,
      });
    }
    if (value?.campaignType === 'Dynamic Campaign') {
      ifError = checkMandatory({
        value: value,
        mandatoryFields: mandatoryFields,
      });
    }
    console.log('ifError', ifError);
  
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    }
  
    else {
      console.log('value-- update', value);
      const body = {
        id: location.state.val._id,
        title: value?.title,
        description: value?.description,
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
        rewardType: reward,
        transactionType: value?.transactionType,
        paymentType: value?.paymentType,
        minimumSpend: value?.minimumSpend,
        image: s3image,
        status: value?.status == true ? 1 : 0,
        campaignType: value?.campaignType == 'Referral Reward' ? 1 : 0,
        data: value?.data ? parseInt(value?.data) : '',
        sms: value?.sms ? parseInt(value?.sms) : '',
        voice: value?.voice ? parseInt(value?.voice) : '',
        voucherMerchantId: findMerchantId ? findMerchantId.toString() : '',
        voucherPromoCode: value?.voucherPromoCode
          ? parseInt(value?.voucherPromoCode)
          : '',
        voucherQty: value?.voucherQty ? parseInt(value?.voucherQty) : '',
        voucherExpiryDate:
          voucherEndD.length === 0 ? '' : String(convertedDate(voucherEndD)),
      };
      console.log('body>>>>', body);
      apimanager
        .post('updateCampaign', body)
        .then(response => {
          console.log('response update campaign ', response);
          if (response.message === 8630) {
            setLoading(false);
            setsuccess('Successfully Submitted');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Updated, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          } else {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `failed to update campaign`,
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const onSelectedFuntion = (e, key) => {
    console.log('onSelect=>', e);
    setValue({
      ...value,
      [key]: e,
    });
  };
  return (
    <Flex
      flexDirection={'column'}
      // padding={2}
      backgroundColor={'#FAFDFB'}
      height={'100%'}
    >
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {status == false
          ? MiniHeader({
              title: 'Create Compaign',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'CreateDynamicCompaign',
              useSlash: true,
              path2: 'Create',
            })
          : MiniHeader({
              title: 'Edit Compaign',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'CreateDynamicCompaign',
              useSlash: true,
              path2: 'Create',
            })}
      </Flex>{' '}
      <Flex {...styles.box}>
        <Flex
          padding={'2%'}
          background={'white'}
          flexDirection={'column'}
          justifyContent={'space-around'}
        >
          <SimpleGrid
            width={'100%'}
            columns={1}
            spacingX={'50px'}
            marginTop={4}
          >
            {dropdownCustom({
              title: 'Campaign Type',
              mandatory: 'true',
              placeholder: value['campaignType']
                ? value['campaignType']
                : 'select option',
              temparray: TypeData,
              // placeholder: value['campaignType'],
              onChange: e => handleonChange(e, 'campaignType'),
              // value: value['campaignType'],
            })}
          </SimpleGrid>
          <SimpleGrid
            width={'100%'}
            columns={1}
            spacingX={'50px'}
            marginTop={4}
          >
            {customInputLabel({
              title: 'Title',
              mandatory: true,
              onChange: e => handleonChange(e, 'title'),
              value: value['title'],
              //location.state.val.name,
            })}
          </SimpleGrid>
          <SimpleGrid
            width={'100%'}
            columns={1}
            spacingX={'50px'}
            marginTop={5}
          >
            {customTextarea({
              title: 'Description',
              mandatory: true,
              onChange: e => handleonChange(e, 'description'),
              value: value['description'],
            })}
          </SimpleGrid>
          <SimpleGrid
            width={'100%'}
            columns={2}
            spacingX={'50px'}
            marginTop={4}
          >
            {customDateWithTitle({
              title: 'Start Date',
              mandatory: false,
              selectedDate: startD,
              // onChange: e => handleonChange(e, 'startDate'),
              onChange: e => setStartD(e),
              value: startD,
            })}
            {customDateWithTitle({
              title: 'End Date',
              mandatory: false,
              selectedDate: endD,
              // onChange: e => handleonDateChange(e, 'endDate'),
              onChange: e => setEndD(e),
              value: endD,
              // value: value['endDate'],
            })}
          </SimpleGrid>
          {type == false ? (
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={4}
            >
              <Flex marginBottom={'4px'} marginTop={'4px'}>
                <Flex>
                  <Text {...styles.redAsterisk}>Transaction Type</Text>
                  <Text color={'#ff0000'}>*</Text>
                </Flex>
              </Flex>

              <Multiselect
                // id={vId}
                displayValue={value['transactionType']}
                options={TransactionType}
                isObject={false}
                singleSelect={false}
                hidePlaceholder={true}
                style={{ ...style.multiSelect }}
                selectedValues={value.transactionType}
                onSelect={e => onSelectedFuntion(e, 'transactionType')}
              />
            </SimpleGrid>
          ) : null}
          {type == false ? (
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={4}
            >
              <Flex
                flexDirection={'column'}
                marginBottom={'4px'}
                marginTop={'4px'}
              >
                <Flex>
                  <Text {...styles.redAsterisk}>Payment Type</Text>
                  <Text color={'#ff0000'}>*</Text>
                </Flex>
                <Multiselect
                  // id={vId}
                  options={paymentType}
                  isObject={false}
                  singleSelect={false}
                  hidePlaceholder={true}
                  style={{ ...style.multiSelect }}
                  selectedValues={value.paymentType}
                  onSelect={e => onSelectedFuntion(e, 'paymentType')}
                />
              </Flex>

              {customInputLabel({
                title: 'Minimum Spend(In Number)',
                mandatory: true,
                type: 'number',
                onChange: e => handleonChange(e, 'minimumSpend'),
                value: value['minimumSpend'],
              })}
            </SimpleGrid>
          ) : null}

          <SimpleGrid
            width={'100%'}
            columns={1}
            spacingX={'50px'}
            marginTop={4}
          >
            {dropdownCustom({
              title: 'Reward Type',
              mandatory: 'true',
              placeholder: value['rewardType']
                ? value['rewardType']
                : 'select option',
              temparray: rewardType,
              onChange: e => handleonChange(e, 'rewardType'),
            })}
          </SimpleGrid>

          {rewardVoucher == true ? (
            <SimpleGrid
              flexDirection={'column'}
              width={'100%'}
              columns={4}
              spacingX={'50px'}
              marginTop={4}
            >
              {dropdownCustom({
                title: 'Voucher Merchant Id',
                mandatory: 'true',
                temparray: merchantList,
                placeholder:
                  value?.voucherMerchantId == 'undefined-undefined'
                    ? 'select option'
                    : value?.voucherMerchantId,
                onChange: e => handleonChange(e, 'voucherMerchantId'),
                // value: singleItem,
              })}
              {customInputLabel({
                title: 'Voucher Promo Code',
                mandatory: true,
                onChange: e => handleonChange(e, 'voucherPromoCode'),
                value: value['voucherPromoCode'],
                //location.state.val.name,
              })}
              {customInputLabel({
                title: 'Voucher Qty',
                mandatory: true,
                onChange: e => handleonChange(e, 'voucherQty'),
                value: value['voucherQty'],
                //location.state.val.name,
              })}
              {customDateWithTitle({
                title: 'Voucher Expiry Date',
                mandatory: false,
                selectedDate: voucherEndD,
                onChange: e => setVoucherEndD(e),
                value: voucherEndD,
              })}
            </SimpleGrid>
          ) : null}

          {rewardNonVoucher == true ? (
            <SimpleGrid
              flexDirection={'column'}
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={4}
            >
              {customInputLabel({
                title: 'Data',
                mandatory: true,
                onChange: e => handleonChange(e, 'data'),
                value: value['data'],
                //location.state.val.name,
              })}
              {customInputLabel({
                title: 'Voice',
                mandatory: true,
                onChange: e => handleonChange(e, 'voice'),
                value: value['voice'],
                //location.state.val.name,
              })}
              {customInputLabel({
                title: 'SMS',
                mandatory: true,
                onChange: e => handleonChange(e, 'sms'),
                value: value['sms'],
                //location.state.val.name,
              })}
            </SimpleGrid>
          ) : null}
          <SimpleGrid
            width={'100%'}
            columns={1}
            spacingX={'50px'}
            marginTop={4}
          >
            {customInputImage({
              title: 'Image',
              mandatory: true,
              type: 'file',
              src: process.env.REACT_APP_S3URL + s3image,
              // src: value['image'],
              placeholder: process.env.REACT_APP_S3URL + s3image,
              onChange: e => upload(e),
              // value: value['image'],
            })}
          </SimpleGrid>
          <SimpleGrid
            width={'100%'}
            columns={1}
            spacingX={'50px'}
            marginTop={4}
          >
            {customSwtich({
              title: 'Active',
              label: 'Status',
              checked: value['status'],
              check: e => handleonSwitch(e.target.checked, 'status'),
            })}
          </SimpleGrid>

          {status == false
            ? customUpdateCancelBtn({
                title1: 'Submit',
                title2: 'Cancel',
                isLoading: loading,
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickSubmit(),
              })
            : customUpdateCancelBtn({
                title1: 'Update',
                title2: 'Cancel',
                isLoading: loading,
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickUpdate(),
              })}
        </Flex>
      </Flex>
      <Spacer />
      <Divider
        orientation="horizontal"
        marginTop={5}
        marginBottom={2}
        width={'95%'}
        ml={7}
      />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateAskQuestion;
