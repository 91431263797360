import React, { useState, useEffect } from 'react';
import { Flex, Spacer, Divider, useToast, SimpleGrid } from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';

const CreateFaqCategory = () => {
  const toast = useToast();
  let history = useHistory();
  const location = useLocation();
  let apimanager = ApiManager.getInstance();

  let initialValue = {
    name: '',
  };
  let mandatoryFields = {
    name: true,
  };

  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [status, setstatus] = React.useState(false);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val?.name)
      setValue(_p => ({ ..._p, name: location.state.val.name }));
    // console.log('pathdata', location.state);
  }, [location]);

  const onClickSubmit = () => {
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      console.log('Resolved');
      let body = {
        name: value?.name,
      };
      console.log('body=>', body);
      apimanager
        .post('addFAQCategory', body)
        .then(res => {
          console.log('res=>', res);
          if (res.message === 8150) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          } else {
            setError('Please enter mandatory fields');
          }
          if (res.message === 8204) {
            setError('Error in Minimum Stock Quantity');
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };
  const onClickUpdate = () => {
    console.log('Api Run');
    const body = {
      id: location.state.val._id,
      name: value?.name,
    };
    console.log('body>>>>', body);
    apimanager
      .post('updateFAQCategory', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8152) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
           <Flex {...styles.miniheader} marginBottom={'2%'}>
          {MiniHeader({
            title: 'Edit FAQ Category',
            path: 'Dashboard',
            onclickForRoute: () => history.push('/Dashboard'),
            path1: 'App Setup',
            useSlash: true,
            path2: 'FAQ CAtegories List',
            useSlash: true,
            path3: 'Edit FAQ Category',
          })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                pattern: '^linkedin.com/',
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
           <Flex {...styles.miniheader} marginBottom={'2%'}>
          {MiniHeader({
            title: 'Create FAQ Category',
            path: 'Dashboard',
            onclickForRoute: () => history.push('/Dashboard'),
            path1: 'App Setup',
            useSlash: true,
            path2: 'FAQ CAtegories List',
            useSlash: true,
            path3: 'Create FAQ Category',
          })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                pattern: '^linkedin.com/',
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateFaqCategory;
