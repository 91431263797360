import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Divider,
  Textarea,
  Skeleton,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import theme from '../config/color';
import {
  customSwtich,
  customInputImage,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import ApiManager from '../config/apiManager';
import config from '../config/config';
import style from '../components/common/style';
import { uploadImage } from '../config/imageUploader';
import { useHistory, useLocation } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { DATABUTTONS } from '../context/data';

const CreateAppVersion = () => {
  let campaign = ['none', 'asdf'];
  let status = ['Active', 'Inactive'];
  let mobileBanner = ['Yes', 'No'];
  let position = ['Right', 'Down', 'Left', 'Up'];
  let deeplink = [
    { list: 'Paybill Screen', val: '1' },
    { list: 'Season Pass Screen', val: '2' },
    { list: 'Top up Screen', val: '3' },
    { list: 'Top up with Softpin Screen', val: '4' },
    { list: 'Share Season Pass Screen', val: '5' },
    { list: 'One Time Deal Screen', val: '6' },
    { list: 'XOX Black Market Screen', val: '7' },
    { list: 'ADX', val: '8' },
    { list: 'Product', val: '9' },
  ];

  const toast = useToast();
  let history = useHistory();
  let location = useLocation();

  let tempArray = [];
  let dataOfEdit = '';

  let apiManager = ApiManager.getInstance();

  let initialValue = {
    SelectLink:'',
    url: '',
    name: '',
    status: '',
    position: '',
    deeplink: '',
    campaign: '',
    description: '',
    bannerType: '',
    mobileBanner: '',
    endDate: new Date(),
    startDate: new Date(),
  };

  let mandatoryFields = {
    name: true,
    status: true,
    position: true,
    endDate: true,
    startDate: true,
    bannerType: true,
  };

  const [endD, setEndD] = useState('');
  const [startD, setStartD] = useState('');
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState([]);
  const [s3image, setS3Image] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [imageView, setImageView] = useState('');
  const [urlLink, setUrlLink] = useState('');
  const [urlDeepLink, setUrlDeepLink] = useState('');
  const [changeApi, setChangeApi] = useState(false);
  const [bannerTypes, setBannerTypes] = useState([]);
  const [campaignList, setcampaignList] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [disableCampaign, setDisableCampaign] = useState(false);

  // Handle Functions //
  const handleonChange = (evt, key) => {
    console.log('EVENT=>', evt.target.value);
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  // Handle Functions //
  const handleonChangeBannerTypes = (evt, key) => {
    // console.log('EVENT=>', evt);
    const ev = evt.target.value;
    if (ev === 'Promocode Banner') {
      setDisableCampaign(true);
    } else if (ev === 'Campaign Banner') {
      setDisableCampaign(false);
    }
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleonSwitch = (evt, key) => {
    const ev = evt;
    console.log('Switch', ev, 'key', key);
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const convertedDeepLink =(url)=>{
    if(url == 'SuperAppDashBoardScreen') return 'XOX Mobile';
    else if(url == 'SuperAppStackxoxMobile') return 'Non XOX Mobile';
    else if(url == 'SuperAppBlackMarket') return 'Black Market';
    else if(url == 'SuperAppEppMarket') return 'EPP';
    else if(url == 'AdxHome') return 'ADX';
  }

  useEffect(() => {
    getAllCategory();
    getAllCampaigns(100, 1);
    // getAllPromocodes();

    console.log('location=>', location);

    if (location?.state?.data != null || location?.state?.data != undefined) {
      dataOfEdit = location?.state?.data;
      let aray = [];

      // let tempStatus = changeStatusEdit(dataOfEdit?.status);
      // console.log('tempStatus=>', tempStatus);

      // let tempHalal = changeHalalEdit(dataOfEdit?.nonHalal);
      // console.log('tempHalal=>', tempHalal);

      var startDate = new Date(dataOfEdit?.startDate * 1000);
      var endDate = new Date(dataOfEdit?.endDate * 1000);

      setStartD(startDate);
      setEndD(endDate);

      console.log('startDate ', startDate);

      console.log('useEffect ', location?.state);

      console.log('endDate ', endDate);
      if(dataOfEdit?.isDeeplink == false){
        setUrlLink(dataOfEdit?.link);
      }
      else{
        let convertDeepLink=convertedDeepLink(dataOfEdit?.link);
        setUrlDeepLink(convertDeepLink);
      }

      setValue({
        ...value,
        url: dataOfEdit?.link,
        deepLink:dataOfEdit?.deepLink,
        name: dataOfEdit?.bannerTypeName,
        status: dataOfEdit?.status,
        position: dataOfEdit?.position,
        mobileBanner: dataOfEdit?.isMobile,
        endDate: endDate,
        startDate: startDate,
        deeplink: dataOfEdit?.deeplink,
        SelectLink:dataOfEdit?.isDeeplink,
        // campaign: dataOfEdit?.campaign,
        campaign: dataOfEdit?.campaign,
        description: dataOfEdit?.description,
        bannerType: dataOfEdit?.bannerType,
      });
      setS3Image(dataOfEdit?.image);

      console.log('dataOfEdit', dataOfEdit);
      let changeApi = location?.state?.useEditApi;
      setChangeApi(changeApi);
    }
    setEditData(dataOfEdit);
  }, []);
  // console.log(imageView, 's3', process.env.REACT_APP_S3URL + s3image);

  const getAllCategory = () => {
    apiManager
      .get('getAllBannerTypes')
      .then(res => {
        console.log('res', res);
        console.log('getAllSoftpinsCategories=>RES', res);
        if (res.message === 8129) {
          console.log('Get add country responce', res.results);
          res.result.docs.map((value, index) => {
            console.log('valuesssss', value.name);
            tempArray.push(value.name);
          });
          setBannerTypes(tempArray);
        } else {
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getAllCampaigns = (limit, page) => {
    const body = {
      numRecords: limit,
      page: page,
    };

    apiManager
      .get('getAllCampaigns', body)
      .then(res => {
        console.log('getAllcampaigns', res);
        if (res.message == 8627) {
          let tempcampaign = [];
          res?.results?.docs?.map((value, index) => {
            tempcampaign.push(value?.title);
            console.log('Value', value);
          });
          setcampaignList(tempcampaign);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getAllPromocodes = (limit, page) => {
    const body = {
      // numRecords: limit,
      // page: page,
    };

    apiManager
      .post('get_promotions_for_admin', body)
      .then(res => {
        console.log('get_promotions_for_admin', res);
        // if (res.message == 8627) {
        //   let tempPromo = [];
        //   res?.results?.docs?.map((value, index) => {
        //     tempPromo.push(value?.title);
        //     console.log('Value', value);
        //   });
        //   setcampaignList(tempPromo);
        // }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };
  console.log('value', value);

  // API CALLING //
  const onClickSubmit = () => {
    setLoading(true);
    console.log('Value', value);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      setLoading(false);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      console.log('value[mobileBanner]', value['mobileBanner']);
      let mobileBanner = changeMobileBanner(value['mobileBanner']);
      console.log('mobileBanner=>', mobileBanner);
      var URL='';
      if(value?.SelectLink == false){
        URL = urlLink;
      }
      else{
        URL = value?.url;
      }

      let body = {
        bannerType: value?.bannerType,
        name: value?.name,
        bannerTypeName: value?.name,
        campaign: value?.campaign,
        // link: value.url?value.url: urlLink,
        link:URL,
        isMobile: mobileBanner,
        image: s3image,
        isDeeplink:value['SelectLink'] == "" ? false : value['SelectLink'],
        description: value.description,
        // deeplink: value['deeplink'],
        startDate: String(convertedDate(startD)),
        endDate: String(convertedDate(endD)),
        status: value?.status,
        position: value?.position,
      };
      console.log('Value==>', value, 'body=>', body);

      if (!changeApi) {
        apiManager
          .post('addBanner', body)
          .then(res => {
            console.log('res=>', res);
            if (res.message === 8080) {
              setLoading(false);
              setSuccess('Successfully Recorded!');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Recorded, Please processed to List!`,
              });
              setTimeout(() => {
                history.goBack();
              });
            }
            if (res.message === 8078) {
              setLoading(false);
              setSuccess('Failed Recorded!');
              toast({
                title: success,
                status: 'error',
                duration: 4000,
                isClosable: true,
                description: `Enter Mandatory Link OR DeepLink `,
              });
            }
            if (res.errors !== undefined || res.errors !== null) {
              if (res.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                console.log('res.messag', res.message);
                console.log('res.messag', res.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${res.errors[1]?.msg} !`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log('error', error);
          });
      } else {
        let payload = {
          id: editData?._id,
          bannerType: value.bannerType,
          name: value.name,
          bannerTypeName: value.name,
          campaign: value.campaign,
          // link: value.url?value.url: urlLink,
          link:URL,
          isMobile: mobileBanner,
          image: s3image,
          isDeeplink: value['SelectLink'] == "" ? false : value['SelectLink'],
          description: value.description,
          startDate: String(convertedDate(startD)),
          endDate: String(convertedDate(endD)),
          status: value.status,
          position: value.position,
        };
        console.log('Value==>', value, 'Update payload=>', payload);

        apiManager
          .post('updateBanner', payload)
          .then(res => {
            console.log('updateBanner res=>', res);
            if (res.message === 8082) {
              setLoading(false);
              setSuccess('Successfully Recorded!');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Recorded, Please processed to List!`,
              });
              setTimeout(() => {
                history.goBack();
              }, [3000]);
            }
            if (res.errors !== undefined || res.errors !== null) {
              if (res.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                console.log('res.messag', res.message);
                console.log('res.messag', res.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${res.errors[1]?.msg} !`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log('error', error);
          });
      }
    }
  };

  const changeMobileBanner = value => {
    console.log('value=>', value);
    if (value === 'No') {
      return 0;
    } else if (value === 'Yes') {
      return 1;
    } else if (value.toString() === 'false') {
      return 0;
    } else if (value.toString() === 'true') {
      return 1;
    }
  };

  const upload = e => {
    setImageLoading(true);
    console.log('e.target=>', e.target.files[0].name);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('s3ImageResponseUri', res.location);
        setS3Image(e.target.files[0].name);
        setImageView(res?.location);
        setImageLoading(false);
      })
      .catch(error => {
        setImageLoading(false);
        console.log('error', error);
      });
  };

  return (
    <Flex flexDirection={'column'} bgColor={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: !changeApi ? 'Create Banner' : 'Update Banner',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Banner Management',
          useSlash: 'true',
          path2: !changeApi ? 'Create' : 'Update',
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex
          padding={'1%'}
          background={'white'}
          flexDirection={'column'}
          justifyContent={'space-around'}
        >
          <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
            {dropdownCustom({
              title: 'Banner Type',
              value: value['bannerType'],
              mandatory: true,
              placeholder: value['bannerType']
                ? value['bannerType']
                : 'Select Option',
              temparray: bannerTypes ? bannerTypes : bannerTypes[0],
              onChange: e => handleonChange(e, 'bannerType'),
            })}
            {customInputLabel({
              title: 'Name',
              value: value['name'],
              mandatory: true,
              onChange: e => handleonChange(e, 'name'),
            })}
            {dropdownCustom({
              title: 'Campaign',
              mandatory: true,
              // isDisabled: disableCampaign,
              value: value['campaign'],
              placeholder: value['campaign'] ? value['campaign'] : 'Select Option',
              temparray: campaignList,
              onChange: e => handleonChange(e, 'campaign'),
            })}
          </SimpleGrid>
          <SimpleGrid
            width={'100%'}
            columns={3}
            spacingX={'50px'}
            marginTop={'5'}
          >
            <Flex>
              {customSwtich({
                title: 'URL/DeepLink',
                label: 'Select Link Type',
                checked: value?.SelectLink,
                check: e => handleonSwitch(e.target.checked, 'SelectLink'),
              })}
            </Flex>
            {customInputLabel({
              title: 'URL',
              value: value?.SelectLink===false ? urlLink : null,
              placeholder:  'Enter url',
              // mandatory: true,
              disabled:value?.SelectLink === true ? true : value?.SelectLink === false ? false : false,
              onChange: e => setUrlLink(e.target.value),
            })}
            {dropdownCustomSvalue({
              title: 'Deeplink',
              // placeholder: 'Select Option',
              placeholder: ((value?.SelectLink===true) && (urlDeepLink != '')) ? urlDeepLink : 'Select Option',

              isDisabled:value?.SelectLink === true ? false : value?.SelectLink === false ? true : true,
              temparray: DATABUTTONS,
              onChange: e => handleonChange(e, 'url'),
            })}
          </SimpleGrid>
          <Flex flexDirection={'column'} width={'100%'} marginTop={'10'}>
            <Flex flexDirection={'row'}>
              <Text
                color={theme.customColors.blackAdminColor['100']}
                fontSize="12px"
                marginBottom={'2'}
              >
                Description
              </Text>
              <Text color={'red'} marginLeft={'1'}>
                *
              </Text>
            </Flex>
            <Textarea
              size="sm"
              height={'180px'}
              value={value['description']}
              onChange={e => handleonChange(e, 'description')}
            />
          </Flex>
          <Flex width={'100%'} marginY={10} flexDirection={'column'}>
            <SimpleGrid width={'100%'} columns={4} spacingX={'50px'}>
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: false,
                selectedDate: startD,
                // onChange: e => handleonChange(e, 'startDate'),
                onChange: e => setStartD(e),
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: false,
                selectedDate: endD,
                onChange: e => setEndD(e),
              })}

              {dropdownCustom({
                title: 'Status',
                mandatory: true,
                placeholder: value['status']
                  ? value['status']
                  : 'Select Status',
                selectValue: 'Yes',
                temparray: status,
                onChange: e => handleonChange(e, 'status'),
              })}
              {dropdownCustom({
                title: 'Mobile banner',
                temparray: mobileBanner,
                placeholder: value?.mobileBanner.toString()
                  ? value?.mobileBanner.toString()
                  : 'Select Option',
                mandatory: true,
                onChange: e => handleonChange(e, 'mobileBanner'),
              })}
              <Flex marginTop={'4'} width={'100%'}>
                {dropdownCustom({
                  title: 'Position',
                  mandatory: true,
                  temparray: position,
                  placeholder: value['position']
                    ? value['position']
                    : 'Select Option',
                  onChange: e => handleonChange(e, 'position'),
                })}
              </Flex>
            </SimpleGrid>
            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={'10'}
            >
              <Flex flexDirection={'column'}>
                <Flex flexDirection={'row'}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Image
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                {!imageLoading ? (
                  <Flex>
                    {customInputImage({
                      title: '',
                      mandatory: false,
                      type: 'file',
                      src: imageView
                        ? imageView
                        : process.env.REACT_APP_S3URL + s3image,
                      onChange: e => upload(e),
                    })}
                  </Flex>
                ) : (
                  <Skeleton height="20px" />
                )}
              </Flex>
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: !changeApi ? 'Submit' : 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
        </Flex>
      </Flex>
      <Divider marginStart={10} orientation="horizontal" />
      {renderCopyRight()}
    </Flex>
  );
};
export default CreateAppVersion;
