import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Flex,
  Box,
  Button,
  Text,
  SimpleGrid,
  Input,
  Tr,
  Td,
  Image,
  useToast,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/styles.css';
import DateWithTitle from '../components/DateComponent/DateWithTitle';
import LangContext from '../context/languageContext';
import style from './Reports/styles';
import ApiManager from '../config/apiManager';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import DropDownWithTitle from '../components/DateComponent/DropDownWithTitle';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import { TableComp } from '../components/common/table';
import {
  listPaymentTransactions,
  colPaymentTransactions,
} from '../context/data';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';

const DemographicReport = () => {
  let quickSelect = [
    'Yesterday',
    'Last 7 Days',
    'Last 28 Days',
    'This Week',
    'This Month',
    'This Year',
    'Last Week',
    'Last month',
  ];
  let initialValue = {
    quickSelect: '',
  };
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const { currentLangData } = React.useContext(LangContext);
  const [payload, setPayload] = React.useState(initialValue);
  const [value, setValue] = React.useState('10');
  const [totalPage, setTotalPage] = useState('');
  const [type, setType] = useState();
  const [loader, setLoader] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let tempArray = [];
  const [dataa, setDataa] = React.useState([]);
  const [paginat, setpaginate] = React.useState();
  const [sDate, setSDate] = useState('');
  const [eDate, setEDate] = useState('');
  const toast = useToast();

  let history = useHistory();
  let columsOfData = [
    'ID',
    'Msisdn',
    'XOX User',
    'Device Name',
    'Date Visited',
  ];

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    let tempType = handleType(ev);
    setType(tempType);
    setSDate('');
    setEDate('');
    getALLItems(tempType, 1, null, null);
    setPayload({
      ...value,
      [key]: ev,
    });
  };

  const handleType = selectedName => {
    if (selectedName === 'Yesterday') {
      return 1;
    } else if (selectedName === 'Last 7 Days') {
      return 2;
    } else if (selectedName === 'Last 28 Days') {
      return 3;
    } else if (selectedName === 'This Week') {
      return 4;
    } else if (selectedName === 'This Month') {
      return 5;
    } else if (selectedName === 'This Year') {
      return 6;
    } else if (selectedName === 'Last Week') {
      return 7;
    } else if (selectedName === 'Last Month') {
      return 8;
    }
  };

  const handleDate = e => {
    let startDate = new Date(e[0]);
    let endDate = new Date(e[1]);
    let finalStartDate = startDate.getTime();
    let finalEndDate = endDate.getTime();
    setSDate(finalStartDate);
    setEDate(finalEndDate);
    setDateRange(e);
    setType();
    getALLItems(undefined, 1, finalStartDate, finalEndDate);
  };

  React.useEffect(() => {
    getALLItems();
  }, [1]);

  const getALLItems = (limit, page, startD, endD) => {
    console.log('getAllItem', limit, page, startD, endD);
    setLoader(false);
    const body = {
      type: limit,
      startDate: endD,
      endDate: startD,
      page: page,
    };

    console.log('body demo', body);
    apimanager
      .post('userDemographicReport', body)
      .then(res => {
        console.log('userDemographicReport', res);
        if (res.message === 8813) {
          res?.data?.docs?.map((value, index) => {
            tempArray?.push(value);
          });
          setTotalPage(res?.data?.totalPages);
          setpaginate(res?.data);
          setDataa(tempArray);
          setLoader(true);
        }
        if (res.message === 8058) {
          setLoader(true);
          toast({
            title: 'Error',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'something went wrong',
          });
        }
      })
      .catch(error => {
        setLoader(true);
      });
  };

  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Demographic Report',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: true,
          path2: 'DemographicReport',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={5} width={'80%'}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {DatewithTimePicker({
                title: 'Select Date Range',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,

                changePicker: true,
                // onChange: e => setDateRange(e),
                onChange: e => handleDate(e),
              })}

              {dropdownCustom({
                title: 'Quick Select Date',
                mandatory: false,
                temparray: quickSelect,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'quickSelect'),
              })}
            </SimpleGrid>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={columsOfData}
            onPageChange={page => getALLItems(type, page, sDate, eDate)}
            renderItem={({ item, index }) => {
              let date = item?.createdAt;
              let newDate = new Date(date);
              let finalDate = newDate.toDateString();
              let finalTime = newDate.toLocaleTimeString();
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.userId ? item?.userId : '--'}</Td>
                  <Td>{item?.loginMSISDN ? item?.loginMSISDN : '--'}</Td>
                  <Td>{item?.XoxUser == false ? 'No' : 'Yes'}</Td>
                  <Td>
                    <Tooltip hasArrow label={item?.deviceName}>
                      <Text noOfLines={1}>{item?.deviceName}</Text>
                    </Tooltip>
                  </Td>
                  <Td>{finalDate}</Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DemographicReport;
