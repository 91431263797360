import React from 'react';

const options = {
  series: [
    {
      name: 'TEAM A',
      type: 'column',
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
    },
    {
      name: 'TEAM B',
      type: 'area',
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    },
    {
      name: 'TEAM C',
      type: 'line',
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    },
  ],
  chart: {
    height: 350,
    type: 'line',
    stacked: false,
  },
  stroke: {
    width: [0, 2, 5],
    curve: 'smooth',
  },
  plotOptions: {
    bar: {
      columnWidth: '50%',
    },
  },

  fill: {
    opacity: [0.85, 0.25, 1],
    gradient: {
      inverseColors: false,
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100],
    },
  },
  labels: [
    '01/01/2003',
    '02/01/2003',
    '03/01/2003',
    '04/01/2003',
    '05/01/2003',
    '06/01/2003',
    '07/01/2003',
    '08/01/2003',
    '09/01/2003',
    '10/01/2003',
    '11/01/2003',
  ],
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    title: {
      text: 'Points',
    },
    min: 0,
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (y) {
        if (typeof y !== 'undefined') {
          return y.toFixed(0) + ' points';
        }
        return y;
      },
    },
  },
};

const Tabs = ['Performance', 'Procress', 'Profile'];


const formoptions = ['Content', 'Meta', 'Seo'];

const statusDrop = ['Active', 'Inactive'];
const rewardItem = ['Data', 'Voice', 'SMS', 'Quotes'];
const rewardTypeValueDrop = [
  '10',
  '20',
  '30',
  '40',
  '50',
  '100',
  '150',
  '200',
  '250',
  '500',
  '1000',
];
const rewardTypeDrop = [
  'Min Score for Lucky Draw',
  'Competition',
  'Lucky Draw',
  'Min Score',
];

export {
  Tabs,
  statusDrop,
  rewardItem,
  rewardTypeValueDrop,
  rewardTypeDrop,
  options,
  formoptions,
};
