import React from 'react';
import { uploadFile } from 'react-s3';
// import { uploadFile, deleteFile } from 'react-s3';
import S3FileUpload from 'react-s3';
import { Buffer } from 'buffer';
import config from '../config/config';

Buffer.from(uploadFile, 'base64');
window.Buffer = window.Buffer || require('buffer').Buffer;

const options = {
  bucketName: process.env.REACT_APP_BUCKETNAMECSV,
  region: process.env?.REACT_APP_REGIONCSV,
  accessKeyId: process.env.REACT_APP_ACCESSKEYIDCSV,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEYCSV,
  dirName:process.env.REACT_APP_DIRCSV,
};

export const csvUploader = uri => {
  // console.log('url_Hit>>>>', uri);
  // console.log('Credentials>>', options);
  return new Promise((resolve, reject) => {
    uploadFile(uri, options)
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

// export default csvUploader;
