import React from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/aboutUsStyles';
import theme from '../config/color';

import UncontrolledEditor from '../components/textEditor/textEditor';

import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';

const CreateAskQuestion = () => {
  console.log('CreateAskQuestion gg bro');
  return (
    <Flex
      flexDirection={'column'}
      // padding={2}
      backgroundColor={'#FAFDFB'}
      height={'80vh'}
    >
      {MiniHeader({
        title: 'Create Frequently Asked Question',
        path: 'Dashboard / CreateFrequentlyAskedQuestion / Create',
      })}

      <Flex
        padding={'8'}
        width={'97%'}
        boxShadow={'lg'}
        alignSelf={'center'}
        alignItems={'center'}
        background={'white'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {dropdownCustom({
            title: 'FAQ Category',
            mandatory: 'true',
          })}

          <Flex flexDirection={'row'} marginTop={4}>
            <Text
              color={theme.customColors.blackAdminColor['100']}
              fontSize="12px"
              marginBottom={'2'}
            >
              Question EN
            </Text>
            <Text color={'red'} marginLeft={'1'}>
              *
            </Text>
          </Flex>
          <Textarea
            //     value={value}
            //     onChange={handleInputChange}
            size="sm"
            height={'180px'}
          />
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          <Flex
            padding={5}
            borderWidth={1}
            flexDirection={'column'}
            height={'50vh'}
            width={'100%'}
            //   background={'red.100'}
          >
            <Flex flexDirection={'row'} marginTop={4}>
              <Text
                color={theme.customColors.blackAdminColor['100']}
                fontSize="12px"
                marginBottom={'2'}
              >
                Answer EN
              </Text>
              <Text color={'red'} marginLeft={'1'}>
                *
              </Text>
            </Flex>
            <UncontrolledEditor />
          </Flex>
          <Divider marginY={10} />

          <Flex flexDirection={'row'} marginTop={4}>
            <Text
              color={theme.customColors.blackAdminColor['100']}
              fontSize="12px"
              marginBottom={'2'}
            >
              Question CN
            </Text>
            <Text color={'red'} marginLeft={'1'}>
              *
            </Text>
          </Flex>
          <Textarea
            //     value={value}
            //     onChange={handleInputChange}
            size="sm"
            height={'180px'}
          />
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={4}>
          <Flex
            padding={5}
            borderWidth={1}
            flexDirection={'column'}
            height={'50vh'}
            width={'100%'}
            //   background={'red.100'}
          >
            <Flex flexDirection={'row'} marginTop={4}>
              <Text
                color={theme.customColors.blackAdminColor['100']}
                fontSize="12px"
                marginBottom={'2'}
              >
                Answer CN
              </Text>
              <Text color={'red'} marginLeft={'1'}>
                *
              </Text>
            </Flex>
            <UncontrolledEditor />
          </Flex>
          <Divider marginY={10} />

          <Flex flexDirection={'row'} marginTop={4}>
            <Text
              color={theme.customColors.blackAdminColor['100']}
              fontSize="12px"
              marginBottom={'2'}
            >
              Question MY
            </Text>
            <Text color={'red'} marginLeft={'1'}>
              *
            </Text>
          </Flex>
          <Textarea
            //     value={value}
            //     onChange={handleInputChange}
            size="sm"
            height={'180px'}
          />
        </SimpleGrid>

        <Flex
            padding={5}
            borderWidth={1}
            flexDirection={'column'}
            height={'50vh'}
            width={'100%'}
            //   background={'red.100'}
          >
            <Flex flexDirection={'row'} marginTop={4}>
              <Text
                color={theme.customColors.blackAdminColor['100']}
                fontSize="12px"
                marginBottom={'2'}
              >
                Answer MY
              </Text>
              <Text color={'red'} marginLeft={'1'}>
                *
              </Text>
            </Flex>
            <UncontrolledEditor />
          </Flex>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>

      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateAskQuestion;
