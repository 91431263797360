import React from 'react';
import {
  Heading,
  Flex,
  useMediaQuery,
  Divider,
  TabPanel,
  TabPanels,
  Tabs,
  Tab,
  Text,
  Skeleton,
  useToast,
  TabList,
} from '@chakra-ui/react';
import {
  renderCopyRight,
} from '../components/aboutUsComponent/customCompoent';
import styles from '../styles/aboutUsStyles';
import style from '../components/common/style';
import ApiManager from '../config/apiManager';

const AboutUsManagement = () => {
  const [dataa, setDataa] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  let apimanager = ApiManager.getInstance();
  let toast = useToast();

  React.useEffect(() => {
    getALLItems();
  }, []);

  const getALLItems = () => {
    setLoader(false);
    apimanager
      .get('getAboutUs')
      .then(res => {
        console.log('res', res);
        if (res.message === 8235) {
          let data = res.data;
          setDataa(data);
          setLoader(true);
        } else if (res.message === 8619) {
          setError('Access Denied');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Not Allowed To Perform This Operation',
          });
        } else if (res.message === 8234) {
          setError('Not Found');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'About Us Not Found',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(false);
      });
  };

  return (
    <Flex {...styles.parentFlex}>
      <Flex {...style.miniheader}></Flex>
      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...styles.btnFlex} paddingStart={'2%'}>
          <Flex marginY={5}>
            <Heading fontSize={isMobile ? 18 : 28}>About Us</Heading>
          </Flex>
          <Divider
            orientation="horizontal"
            marginBottom={5}
            color={'lightgray'}
          />

          <Tabs
            variant="enclosed"
            background={'white'}
            width={isMobile ? '80%' : '100%'}
          >
            <TabList>
              <Tab>EN</Tab>
              <Tab>MY</Tab>
              <Tab>Cn</Tab>
            </TabList>
            <TabPanels height={'100%'}>
              <TabPanel>
                <>
                  <Heading
                    as="h3"
                    size="2xl"
                    marginY={5}
                    fontSize={isMobile ? 18 : 28}
                  >
                    What Makes a Solid ‘About Us’ Page?
                  </Heading>
                  {loader === true ? (
                    <Text width={'80%'} fontSize={isMobile ? '16px' : '20px'}>
                      {dataa?.[0]?.ENG}
                    </Text>
                  ) : (
                    <Skeleton height="300px" width="100%" />
                  )}
                </>
              </TabPanel>
              <TabPanel>
                <>
                  {loader === true ? (
                    <Text width={'80%'} fontSize={isMobile ? '16px' : '20px'}>
                      {dataa?.[0]?.MY}
                    </Text>
                  ) : (
                    <Skeleton height="300px" width="100%" />
                  )}
                </>
              </TabPanel>
              <TabPanel>
                <>
                  {loader === true ? (
                    <Text width={'80%'} fontSize={isMobile ? '16px' : '20px'}>
                      {dataa?.[0]?.CN}
                    </Text>
                  ) : (
                    <Skeleton height="300px" width="100%" />
                  )}
                </>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/* {tabView({
            tabP1: enComponent(dataa, loader),
            tabP2: myComponent(dataa, loader),
            tabP3: cnComponent(dataa, loader),
          })} */}
        </Flex>
      </Flex>
      {/* <Flex {...style.footer}></Flex> */}
      <Divider marginY={10} width={'80%'} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};
export default AboutUsManagement;