import React, { useState, useEffect } from 'react';
import { Flex, Spacer, Divider, useToast, SimpleGrid } from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import style from '../components/common/style';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';

const CreateSoftpinCategory = () => {
  const toast = useToast();
  let history = useHistory();
  const location = useLocation();
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = React.useState(false);
  let statusArray = [
    { list: 'Enable', val: 0 },
    { list: 'Revoked', val: 1 },
  ];

  let initialValue = {
    name: '',
    price: '',
    amount: '',
    minimumStock: '',
    status: '',
  };
  let mandatoryFields = {
    name: true,
    price: true,
    amount: true,
    minimumStock: true,
    status: true,
  };

  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [status, setstatus] = React.useState(false);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };
  // console.log('value=>', value);
  // console.log("location>>>",location)

  useEffect(() => {
    getAllCategory();
  }, []);
  useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val?.name)
      setValue(_p => ({ ..._p, name: location.state.val.name }));
    if (location.state?.val?.price)
      setValue(_p => ({ ..._p, price: location.state.val.price }));
    if (location.state?.val?.amount)
      setValue(_p => ({ ..._p, amount: location.state.val.amount }));
    if (location.state?.val?.minStockQuantity)
      setValue(_p => ({
        ..._p,
        minimumStock: location.state.val.minStockQuantity,
      }));

    console.log('pathdata', location.state);
  }, [location]);

  // Handling Events //
  const onClickSubmit = () => {
    setLoading(!loading);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
      setLoading(loading);
    } else {
      console.log('Resolved');
      let body = {
        name: value?.name,
        price: value?.price,
        amount: value?.amount,
        minStockQuantity: value?.minimumStock,
        status: parseInt(value?.status),
      };
      console.log('body=>', body);
      apiManager
        .post('addSoftpinCategory', body)
        .then(res => {
          setLoading(loading);
          console.log('res=>', res);
          if (res.message === 8200) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setLoading(loading);
            setTimeout(() => {
              history.goBack();
            });
          } else {
            setError('Please enter mandatory fields');
            setLoading(loading);
          }
          if (res.message === 8204) {
            setLoading(loading);
            setError('Error in Minimum Stock Quantity');
          }
          if (res.message === 8649) {
            setError('Duplicate Values ');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Name Must be Unique!`,
            });
          }
        })
        .catch(error => {
          setLoading(loading);
          console.log('error', error);
        });
    }
  };

  // Getting Methods //

  const getAllCategory = () => {
    apiManager
      .get('getAllSoftpinsCategories')
      .then(res => {
        console.log('getAllSoftpinsCategories=>RES', res);
        // if (res.message === 8056) {
        //   console.log('Get add country responce', res);
        //   res.result.docs.map((value, index) => {
        //     console.log('valuesssss', value?.name);
        //     tempArray.push(value?.name);
        //   });
        //   setCountries(tempArray);
        // }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const onClickUpdate = () => {
    console.log('Api Run');
    const body = {
      id: location.state.val._id,
      name: value?.name,
      price: value?.price,
      amount: value?.amount,
      minStockQuantity: value?.minimumStock,
    };
    console.log('body>>>>', body);
    apiManager
      .post('updateSoftpinCategory', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8206) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <>
      {status ? (
        <Flex {...style.mainFlexb}>
          <Flex {...style.miniheader}>
            {MiniHeader({
              title: 'Edit Softpin Category',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Softpin Categories',
              useSlash: true,
              path2: 'Edit',
            })}
          </Flex>
          <Flex {...style.box} h={'100vh'}>
            <Flex {...styles.parentFlex}>
              <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Name',
                  mandatory: true,
                  pattern: '^linkedin.com/',
                  onChange: e => handleonChange(e, 'name'),
                  value: value['name'],
                })}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={3}
                spacingX={'50px'}
                marginTop={4}
              >
                {customInputLabel({
                  title: 'Price',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'price'),
                  value: value['price'],
                })}
                {customInputLabel({
                  title: 'Amount',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'amount'),
                  disabled: true,
                  value: value['amount'],
                })}
                {customInputLabel({
                  title: 'Minimum Stock Qty',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'minimumStock'),
                  value: value['minimumStock'],
                })}
              </SimpleGrid>

              {customUpdateCancelBtn({
                title1: 'Update',
                title2: 'Cancel',
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickUpdate(),
              })}
            </Flex>
            <Spacer />
          </Flex>

          <Divider
            w={'100%'}
            marginStart={10}
            marginTop={5}
            marginBottom={2}
            orientation="horizontal"
          />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex {...style.mainFlexb}>
          <Flex {...style.miniheader}>
            {MiniHeader({
              title: 'Create Softpin Category',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Softpin Management',
              useSlash: true,
              path2: 'Create ',
            })}
          </Flex>
          <Flex {...style.box} h={'100vh'}>
            <Flex {...styles.parentFlex}>
              <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
                {customInputLabel({
                  title: 'Name',
                  mandatory: true,
                  pattern: '^linkedin.com/',
                  onChange: e => handleonChange(e, 'name'),
                })}
                {customInputLabel({
                  title: 'Minimum Stock Qty(In Number)',
                  mandatory: true,
                  type: 'number',
                  onChange: e => handleonChange(e, 'minimumStock'),
                })}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={3}
                spacingX={'50px'}
                marginTop={4}
              >
                {customInputLabel({
                  title: 'Price(In Number)',
                  mandatory: true,
                  type: 'number',
                  onChange: e => handleonChange(e, 'price'),
                })}
                {customInputLabel({
                  title: 'Amount(In Number)',
                  mandatory: true,
                  type: 'number',
                  onChange: e => handleonChange(e, 'amount'),
                })}
                {dropdownCustomSvalue({
                  title: 'Status',
                  mandatory: true,
                  temparray: statusArray,
                  onChange: e => handleonChange(e, 'status'),
                })}
              </SimpleGrid>

              {customUpdateCancelBtn({
                title1: 'Submit',
                title2: 'Cancel',
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => onClickSubmit(),
              })}
            </Flex>
            <Spacer />
          </Flex>

          <Divider
            w={'100%'}
            marginStart={10}
            marginTop={5}
            marginBottom={2}
            orientation="horizontal"
          />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateSoftpinCategory;
