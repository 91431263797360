import React from 'react';
import {
  TableContainer,
  SimpleGrid,
  Button,
  Flex,
  Box,
  Divider,
  Table,
  Tr,
  Thead,
  Tbody,
  Tfoot,
  Th,
  Td,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  customSwtich,
  customResetBtn,
  customInputLabel,
  customUpdateCancelBtn,
  customDeactivateResetBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import style from '../components/common/style';
import ApiManager from '../config/apiManager';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const ViewBlackUserDetail = () => {
  let initialValue = {
    email: '',
    MSISDN: '',
    country: '',
    emailVerified: '',
    name: '',
    maritalStatus: '',
    ageGroup: '',
    address: '',
    userId: '',
    isXoxUser: '',
    profileId: '',
    ssoMigrated: '',
  };
  const [value, setValue] = React.useState(initialValue);
  const [success, setSuccess] = React.useState(null);
  let location = useLocation();
  let history = useHistory();
  let apimanager = ApiManager.getInstance();
  let toast = useToast();

  React.useEffect(() => {
    if (location.status === 'create') {
    }
    if (location.state?.val) {
      let data = location?.state?.val;
      setValue({
        ...value,
        email: data?.email,
        MSISDN: data?.MSISDN,
        country: data?.country,
        emailVerified: data?.emailVerified,
        name: data?.firstName + ' ' + data?.lastName,
        maritalStatus: data?.maritalStatus,
        ageGroup: data?.ageGroup,
        address: data?.address,
        userId: data?.userId,
        isXoxUser: data?.isXoxUser,
        profileId: data?.profileId,
        ssoMigrated: data?.ssoMigrated,
      });
    }
    console.log('pathdata', location?.state?.val);
  }, [location]);

  console.log('UserDetailValue>>', value);

  const onClickEmailVerification = () => {
    const body = {
      userId: value?.userId,
    };
    apimanager
      .post('verifyUserEmail', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 7069) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, User Is successfully Varified`,
          });
          // setTimeout(() => {
          //   history.goBack();
          // });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const noName = tempName => {
    console.log(tempName);
    if (tempName === 'undefined undefined' || tempName === '') {
      return 'No-Name';
    }
  };

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'View Black User Detail',
          path: 'Dashboard',
          path1: 'View Black User Detail',
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex
          padding={'2%'}
          background={'white'}
          flexDirection={'column'}
          justifyContent={'space-around'}
        >
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Title</Th>
                  <Th>Detail</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Name</Td>
                  <Td>{noName(value?.name)}</Td>
                </Tr>
                <Tr>
                  <Td>Email</Td>
                  <Td>{value?.email}</Td>
                </Tr>
                <Tr>
                  <Td>MSISDN</Td>
                  <Td>{value?.MSISDN}</Td>
                </Tr>
                <Tr>
                  <Td>Profile Id</Td>
                  <Td>{value?.profileId}</Td>
                </Tr>
                <Tr>
                  <Td>Country</Td>
                  <Td>{value?.country}</Td>
                </Tr>
                <Tr>
                  <Td>Address</Td>
                  <Td>{value?.address}</Td>
                </Tr>
                <Tr>
                  <Td>Age</Td>
                  <Td>{value?.ageGroup}</Td>
                </Tr>
                <Tr>
                  <Td>Is Xox User</Td>
                  <Td>
                    {value?.isXoxUser === false ? (
                      <Text>No</Text>
                    ) : value?.isXoxUser === true ? (
                      <Text>Yes</Text>
                    ) : null}
                  </Td>
                </Tr>
                <Tr>
                  <Td>SSO Migrated</Td>
                  <Td>
                    {value?.ssoMigrated === false ? (
                      <Text>No</Text>
                    ) : value?.ssoMigrated === true ? (
                      <Text>Yes</Text>
                    ) : null}
                  </Td>
                </Tr>
                <Tr>
                  <Td>status</Td>
                  <Td>{value?.status}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
        <Flex
          padding={'2%'}
          background={'white'}
          flexDirection={'column'}
          marginTop={'10'}
        >
          <Text fontSize={'lg'} marginBottom={2}>
            Operation
          </Text>
          <Divider orientation="horizontal" />
          <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
            <Flex alignItems={'center'} paddingY={3}>
              <Text>Email Verification : </Text>
              {value?.emailVerified === 0 ? (
                <Text fontSize={'lg'}color={"green"} marginLeft={2}>Varified</Text>
              ) : value?.emailVerified === 1 ? (
                <Text fontSize={'lg'}marginLeft={2} color={"gray.700"} >
                  <Text>Unvarified</Text>
                </Text>
              ) : null}
            </Flex>
            {value?.emailVerified === 1 ? (
              <Box>
                {customResetBtn({
                  title: 'Verify Email',
                  onClickReset: () => onClickEmailVerification(),
                })}
              </Box>
            ) : null}
          </SimpleGrid>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" />
      {renderCopyRight()}
    </Flex>
  );
};
