import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Spacer,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import { listSmsMasterlibrary, colSmsMasterlibrary } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import { ChecktableOne } from '../components/common/ChecktableOne';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
import { options } from '../context/data';

const SmsMasterlibrary = () => {
  let history = useHistory();
  const { currentLangData } = React.useContext(LangContext);
  const [success, setSuccess] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [dataa, setDataa] = React.useState();
  let tempArray = [];
  const toast = useToast();

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    let apimanager = ApiManager.getInstance();
    setLoader(false);
    // console.log('page>>>', limit, page);
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllSMSMasterLibrary',body)
      .then(res => {
        if (res.message === 8182) {
          console.log('checkresponce', res);
          res?.record?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
    setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
    setLoader(true);
      });
  };
  const deleteItem = (item) => {
    console.log('Deletion done',item);
    let apimanager = ApiManager.getInstance();
    const body = {
      id:item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deleteSMSMasterLibrary', body)
      .then(response => {
        // console.log(response, 'response');
        if (response.message === 8180) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems(value,1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'SMS Master Library list',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Sms Master Library',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateSmsMasterLibrary')}
            >
              Create
            </Button>
          </Flex>

          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
              <Input {...style.searchinput}></Input>
            </Flex>
          </Flex>

          <ChecktableOne
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            screenName="SmsMasterLibrary"
            headerNames={colSmsMasterlibrary}
            onDelete={item => {
              deleteItem(item);
            }}
            onPageChange={page => getALLItems(value, page)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default SmsMasterlibrary;
