import React from "react";
import { Center, Container, CircularProgress } from "@chakra-ui/react";

const Loading = ({ size }) => {
  return (
    <Center>
      <Container>
        <CircularProgress
          size={size}
          isIndeterminate
          color={'black'}
        />
      </Container>
    </Center>
  );
};
export default Loading;