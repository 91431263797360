import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Flex,
  Text,
  Tr,
  Th,
  Td,
  Table,
  Tbody,
  Thead,
  useToast,
} from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import styles from '../components/common/style';
import LangContext from '../context/languageContext';
import Loading from '../components/common/Loading';
import { EditIcon} from '@chakra-ui/icons';
import { MiniHeader } from '../components/common/customComponent';
import { FaDyalog,FaUnlockAlt } from 'react-icons/fa';

const Admins = () => {
  let tempArray = [];

  let toast = useToast();
  let history = useHistory();

  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('10');
  const [data, setData] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = React.useState('');
  const [paginate, setpaginate] = React.useState();
  const { currentLangData } = useContext(LangContext);
  const [roleAccess, setRoleAccess] = React.useState([]);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);

  useEffect(() => {
    getRoles();
    getAllCategory(page, value);
  }, []);

  // Utility functions //
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('next and previous', loading);
    setCurrentPage(selectedPage);
    getAllCategory(selectedPage + 1, value);
    console.log('clicked', selectedPage + 1);
  };

  let totalDatalength = paginate?.totalDocs;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  let pageCount = paginate?.totalPages;
  const pageCeilingCount = paginate?.totalPages;
  console.log(pageCeilingCount, 'PAGE COUNT', pageCount);

  useEffect(() => {}, [page]);
  useEffect(() => {}, [value]);

  const getRoles = () => {
    setLoading(true);
    let tempArray = [];
    let tempRolesId = [];
    let tempRoleAccess = [];

    let body = {
      // page: page,
      // nextPage: 3,
      // numRecords: value,
    };

    apiManager
      .get('getAllCMSRoles', body)
      .then(res => {
        console.log('getAllCMSRoles=>RES', res);
        if (res.message === 4009) {
          console.log('Get getAllCMSRoles', res);

          res.records.docs.map((value, index) => {
            tempRolesId.push([value?.roleName, value?.roleId]);
            tempArray.push(value?.roleName);
            tempRoleAccess.push([value?.roleAccess, value?.roleId]);
          });

          setRoleAccess(tempRoleAccess);
          setRoles(tempArray);
          setNameRoles(tempRolesId);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAllCategory = (page, value) => {
    setLoading(true);
    let tempArray = [];
    console.log('page=>', page);
    let body = {
      page: page,
      // nextPage: 3,
      numRecords: value,
    };
    apiManager
      .get('getAllCMSUsers', body)
      .then(res => {
        console.log('getAllCMSUsers=>RES', res);
        if (res.message === 7055) {
          console.log('Get getAllCMSUsers', res);
          pageCount = res.results.totalDocs;
          res.results.docs.map((value, index) => {
            tempArray.push(value);
          });
          setpaginate(res.results);
          setData(tempArray);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreateAdmin',
      state: { data: editData, useEditApi: true },
    });
  };

  const navigateToChangePassword = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/ChangePassword',
      state: { data: editData, useEditApi: true },
    });
  };

  const suspendUser = data => {
    setLoading(true);
    console.log('suspend', data);
    // deleteS3(data.companyLogo);
    let body = {
      email: data?.email,
    };
    console.log('body of suspend', body);
    apiManager
      .post('suspendCMSUser', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 3003) {
          setLoading(false);
          setSuccess('SuspendUser');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Suspend User!`,
          });
          getAllCategory(1, value);
        }
        if (res.message === 3002) {
          setLoading(false);
          setError(' User Already Suspended');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Something went wrong!User Already Suspended`,
          });
          getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const unsuspendUser = data => {
    setLoading(true);

    console.log('suspend', data);
    let body = {
      email: data?.email,
    };
    console.log('body of suspend', body);
    apiManager
      .post('unsuspendUser', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 4006) {
          setLoading(false);
          setSuccess('suspendUser');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Unsuspend User!`,
          });
          getAllCategory(1, value);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            description: `Failed! User Already Unspended!`,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'Inactive';
    } else if (value === 1) {
      return 'Active';
    }
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Admins',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Users',
          useSlash: true,
          path2: 'Admins',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main} padding={8}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateAdmin')}
            >
              Create
            </Button>
          </Flex>

          {/* <Flex {...style.optionFlex}></Flex> */}
          <Flex {...style.tableFlex}>
            <Table {...styles.table} padding={'1%'}>
              <Thead {...styles.thead}>
                <Tr>
                  <Th {...styles.th}>Email</Th>
                  <Th {...styles.th}>Role</Th>
                  <Th {...styles.th}>Status</Th>
                  <Th
                    {...styles.th}
                    alignItems={'center'}
                    justifyContent={'space-around'}
                  >
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody {...styles.tbody}>
                {!loading ? (
                  data &&
                  data?.map((value, index) => {
                    let tempName = '';
                    let tempId = '';
                    let nam = nameRoles.map(val => {
                      if (value?.role === val[1]) {
                        tempName = val[0];
                        tempId = val[1];
                        return val[0];
                      }
                    });

                    if (index >= 0) {
                      return (
                        <>
                          <Tr key={index} {...styles.tr}>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.email}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {nam}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                              color={'green'}
                              fontSize={'xs'}
                              fontWeight={'bold'}
                            >
                              {changeStatus(value?.status)}
                            </Td>

                            <Td
                              // width={'%'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'space-around'}
                            >
                              <Tooltip label="Change Password" placement="top">
                                <Button
                                  {...style.editbtn}
                                  fontSize={'sm'}
                                  // variant="outline"
                                  fontWeight={'light'}
                                  rightIcon={<FaUnlockAlt />}
                                  onClick={() => navigateToChangePassword(value)}
                                >
                                  {/* Edit */}
                                </Button>
                              </Tooltip>
                              <Tooltip label="Edit" placement="top">
                                <Button
                                  {...style.editbtn}
                                  fontSize={'sm'}
                                  // variant="outline"
                                  fontWeight={'light'}
                                  rightIcon={<EditIcon />}
                                  onClick={() => navigateToEditPage(value)}
                                >
                                  {/* Edit */}
                                </Button>
                              </Tooltip>
                              <Tooltip hasArrow label="Suspend" bg="red.600">
                                <Button
                                  {...style.Delbtn}
                                  fontSize={'sm'}
                                  variant="outline"
                                  fontWeight={'light'}
                                  rightIcon={<FaDyalog />}
                                  onClick={() => suspendUser(value)}
                                >
                                  {/* Suspend */}
                                </Button>
                              </Tooltip>
                            </Td>
                          </Tr>
                        </>
                      );
                    } else {
                      return (
                        <Tr height={'40vh'}>
                          <Td></Td>
                          <Td></Td>
                          <Td>
                            <Text>NO DATA AVAILABLE</Text>
                          </Td>
                          <Td></Td>
                          <Td></Td>
                        </Tr>
                      );
                    }
                  })
                ) : (
                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td paddingY={'15vh'}>
                      <Flex
                        style={{
                          w: '100%',
                          h: '100%',
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Loading />
                      </Flex>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Flex>
          <Flex {...style.tablefooter}>
            <Flex mt={'2px'}>
              <Text>
                Showing {data.length} to {PER_PAGE} of {totalDatalength} entries
              </Text>
            </Flex>
            <Flex {...style.paginatecontainer}>
              <ReactPaginate
                nextLabel={'Next'}
                pageCount={pageCount}
                previousLabel={'Previous'}
                activeClassName={'active'}
                nextClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                containerClassName={'pagination'}
                onPageChange={handlePageClick}
                previousLinkClassName={'page-link'}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Admins;
