import React, { useState, useEffect } from 'react';
import { Flex, Divider, Spacer, useToast, SimpleGrid } from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import style from '../components/common/style';

import {
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import { useHistory, useLocation } from 'react-router-dom';

const CreateBannerStatus = () => {
  let initialValue = {
    key: '',
    name: '',
  };
  let location = useLocation();

  const toast = useToast();
  let history = useHistory();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  let apimanager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [changeApi, setChangeApi] = useState(false);

  useEffect(() => {
    console.log('Edit data=>', location);
    if (location) {
      if (location?.state?.useEditApi === true) {
        let useEditApi = location?.state?.data;
        console.log('useEditApi', useEditApi);
        setChangeApi(useEditApi);
        setValue({
          ...value,
          key: useEditApi[1],
          name: useEditApi[0],
        });
      }
    }
  }, []);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
    // console.log('value=>>>>>', value);
  };

  const onClickSubmit = () => {
    console.log('Api Run');
    setLoading(true);
    if(value?.name==''){
      setLoading(false);
      return toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: `Please Enter Mandatory Fields!`,
      });
    }
    const body = {
      list: value?.name,
      modelName: 'privacy_policy_categories',
    };
    console.log('body>>>>', body);
    if (!changeApi) {
      apimanager
        .post('addCategory', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8186) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (response.errors !== undefined || response.errors !== null) {
            if (response.message === 5001) {
              setLoading(loading);
              console.log('ERROR to 5001');
              console.log('response.messag', response.message);
              console.log('response.messag', response.errors[1]?.msg);
              toast({
                title: error,
                status: 'error',
                duration: 3000,
                isClosable: true,
                description: `Failed,${response.errors[1]?.msg} !`,
              });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      const payload = {
        key: value?.key,
        list: value?.name,
        modelName: 'privacy_policy_categories',
      };
      console.log('payload', payload);
      apimanager
        .post('updateCategory', payload)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 8191) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (response.errors !== undefined || response.errors !== null) {
            if (response.message === 5001) {
              setLoading(loading);
              console.log('ERROR to 5001');
              console.log('response.messag', response.message);
              console.log('response.messag', response.errors[1]?.msg);
              toast({
                title: error,
                status: 'error',
                duration: 3000,
                isClosable: true,
                description: `Failed,${response.errors[1]?.msg} !`,
              });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <Flex {...styles.flexParentFlex}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: !changeApi
            ? 'Create Privacy Policy Category'
            : 'Update Privacy Policy Category',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Privacy Policy Category',
          useSlash: true,
          path2: !changeApi
            ? 'Create Privacy Policy Category'
            : 'Update Privacy Policy Category',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Name',
            mandatory: true,
            value: value['name'],
            onChange: e => handleonChange(e, 'name'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Update',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateBannerStatus;
