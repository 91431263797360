import theme from '../../config/color';
import { Text } from '@chakra-ui/react';
import { SmallAddIcon, UpDownIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { ImUpload3 } from 'react-icons/im';
import { RiDirectionFill } from 'react-icons/ri';
import { MdImportExport } from 'react-icons/md';
const style = {
  mainContainer: {
    paddingY: 0,
    marginTop: 3,
    width: '100%',
    // background: "#539194",
    color: theme.customColors.common[100],
  },
  footer: {
    marginTop: '40px',
    color: '#98a6ad',
    borderTop: '1px solid #e3eaef',
    display: 'inline-block',
    width: '100%',
    marginBottom: '2',
  },
  subMenuFlex: {
    paddingX: 16,
    paddingY: 1,
  },
  itemMain: {
    flexDirection: 'column',
    background: '#fafdfb',
    padding: 10,
  },
  subTabMenu: {
    noOfLines: 1,
    fontSize: 'md',
    width: '150px',
    marginLeft: 2,
    color: 'white',
    fontWeight: 'semibold',
  },
  mainFlex: {
    flex: 1,
    as: 'nav',
    height: '100vh',
    flexDirection: 'row',
  },
  mainFlexb: {
    background: '#fafdfb',
    flexDirection: 'column',
  },
  innerContainer: {
    flex: 2,
    // paddingY: 6,
    marginTop: 1,
    backgroundColor: '#629093',
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    // width: '80%',
    height: '60px',
  },
  tabsContainer: {
    paddingY: 4,
    marginTop: 3,
    width: '100%',
    background: '#539194',
    color: theme.customColors.common[100],
  },
  bottomFlex: {
    flex: 8,
    padding: 2,
    backgroundColor: '#f6f6fa',
  },
  rightreservedlabel: {
    opacity: 10,
    fontSize: 'lg',
    color: 'rgba(255, 255, 255, 0.6)',
    color: theme.customColors.common[0],
  },
  tabContainer: {
    color: 'white',
    paddingX: 8,
    paddingY: 2,
  },
  backgroundColorContianer: {
    marginTop: 1,
  },
  tabText: {
    w: '130px',
    marginLeft: 15,
    fontSize: 'lg',
    paddingRight: 3,
    fontWeight: 'bold',
    color: 'white',
  },
  antChainIcon: {
    fontSize: 'sm',
    fontSize: '18px',
    marginLeft: '-3',
    fontWeight: 'bold',
    color: theme.customColors.common[0],
  },
  iconContainer: {
    width: '110px',
    justifyContent: 'flex-end',
  },
  antchainImage: {
    marginX: '5',
    height: '30px',
    objectFit: 'fix',
  },
  signOutLabel: {
    color: 'white',
    paddingY: 1,
    marginLeft: 1,
    fontSize: 'lg',
    fontWeight: 'bold',
  },
  signoutButton: {
    size: 25,
    marginTop: 1,
  },
  sigoutContainer: {
    marginX: 8,
    marginTop: 6,
    paddingBottom: 50,
    flexDirection: 'row',
  },
  pwLabel: {
    fontSize: 'md',
    color: theme.customColors.common[0],
  },
  logoutTopContainer: {},
  emptyMidContainer: {
    margin: 4,
    borderTopWidth: 2,
  },
  mdContainer1: {
    marginTop: '5',
  },
  mdContainer2: {
    marginY: 2,
    marginRight: 2,
  },
  nav: {
    top: 0,
    as: 'nav',
    zIndex: 1,
    paddingX: 5,
    opacity: 0.9,
    paddingY: 2,

    margin: 'auto',
    bg: '#2A3042',
    wrap: 'wrap',
    width: '100%',
    align: 'center',
    color: 'gray.600',
    overflow: 'hidden',
    justify: 'space-between',
  },
  navUnauthantication: {
    top: 0,
    as: 'nav',
    zIndex: 1,
    paddingX: 5,
    bg: 'black',
    opacity: 0.9,
    paddingY: 5,
    wrap: 'wrap',
    margin: 'auto',
    width: '100%',
    align: 'center',
    color: 'gray.600',
    position: 'sticky',
    overflow: 'hidden',
    justify: 'space-between',
  },
  menuLinks: {
    spacing: 5,
    align: 'center',
  },
  searchInput: {
    paddingx: 5,
    borderRadius: 20,
    backgroundColor: theme.customColors.white[100],
  },
  icon: {
    size: 30,
    color: theme.customColors.white[100],
  },
  logoImage: {
    width: '130px',
    height: '30px',
  },
  logoImageInside: {
    width: '30px',
    height: '35px',
    marginTop: '-5px',
    marginLeft: '2px',
  },
  txtLogo: {
    fontSize: '3xl',
    fontWeight: 'bold',
    color: '#65bcc1',
  },
  logoContainer: {
    flexDirection: 'row',
  },
  switchButtonConatiner: {
    padding: 3,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonInnerCOntainer: {
    paddingY: 3,
    width: '40px',
    height: '40px',
    boxShadow: 'dark-lg',
    borderRadius: '20%',
    backgroundColor: theme.customColors.white[100],
  },
  drwawer: {
    size: 'full',
    placement: 'left',
  },
  logoMidContainer: {
    flexDirection: 'row',
  },

  powerByLabel: {
    paddingX: 6,
    color: theme.customColors.white[100],
    marginLeft: 10,
    fontSize: 'xs',
    color: 'white',
    marginRight: 3,
    fontWeight: '400',
  },
  antchainImage: {
    objectFit: 'contain',
  },
  antChainContainer: {
    paddingLeft: 2,
    fontWeight: 'semibold',
    fontSize: 'lg',
    color: theme.customColors.white[100],
  },
  hamBurgerMenu: {
    color: theme.customColors.white[100],
  },
  logoMainFlex: {
    zIndex: 10,
    paddingRight: 2,
    marginTop: ['0', '1', '2'],
    marginLeft: [-10, -5, 0, 0],
  },
  txtFlex: {
    p: '1',
    marginTop: -1,
    marginStart: 10,
    flexDirection: 'row',
  },
  txtTra: {
    color: '#70c5c4',
    marginTop: '3px',
    fontWeight: 'bold',
    marginRight: '2px',
    fontSize: { base: '24px', md: '24px', lg: '32px' },
  },
  imgX: {
    marginBottom: [4, 2, 2],
    height: ['30px', '20px', '30px', '40px'],
  },
  // -------------App Version List--------
  box: {
    flexDirection: 'column',
    // backgroundColor: '#fafdfb',
    // marginLeft: '-3%',
    // marginRight: '-3%',
    padding: '3%',
  },
  fieldsFlex: {
    padding: '1%',
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    boxShadow: 'sm',
  },
  buttoncontainer: {
    maxW: '100%',
    padding: '20px 20px ',
    borderBottom: '1px solid #f9f9f9',
  },
  btnContainer: {
    maxW: '100%',
    padding: '20px 20px ',
    // borderBottom: '1px solid #f9f9f9',
  },
  optionFlex: {
    padding: 5,
    justifyContent: 'spaceBetween',
    verticalAlign: 'middle',
  },
  searchinput: {
    bgColor: '#fdfdff',
    borderColor: '#dadada',
    height: 'calc(1.8125rem + 2px)',
    padding: '0.25rem 0.5rem',
    fontSize: '.875rem',
    lineHeight: '1.5',
    borderRadius: '0.2rem',
    margin: '5px',
  },
  select: {
    display: 'inlineBlock',
    backgroundColor: '#fdfdff',
    borderColor: '#dadada',
    fontSize: '14px',
    paddingX: '5px',
    width: 'auto',
    color: '#495057',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    icon: (
      <UpDownIcon
        boxSize={'1em'}
        fontSize={'12px'}
        marginLeft={'-30px'}
        w={0}
        h={0}
      />
    ),
    size: 'sm',
  },
  firstFlexInParent: {
    display: 'flex',
    w: '100%',
    background: '#fff',
    justifyContent: 'space-between',
    borderRadius: 0,
  },
  datepickers: {
    flexDirection: 'row',
    justifyContent: 'spaceBetween',
    marginRight: '-15px',
    marginLeft: '-15px',
    w: '100%',
  },
  dateStyles: {
    flex: '0 0 25%',
    maxWidth: '25%',
    height: 'fitContent',
    paddingY: '-35px',
    height: '40px',
  },
  datepickertext: {
    fontWeight: '600',
    color: '#34395e',
    fontSize: '12px',
    letterSpacing: '0.5px',
    alignSelf: 'flexStart',
  },
  createbutton: {
    size: 'sm',
    leftIcon: (
      <SmallAddIcon bgColor={'#fff'} borderRadius={'50%'} color={'black'} />
    ),
    color: 'white',
    bgColor: 'black',
    borderRadius: '30px',
    _hover: {
      bg: '#777777',
      transform: 'translateY(-2px)',
    },
    boxShadow: '0 2px 6px #1d1d1d',
    fontWeight: '600',
    fontsize: '12px',
  },
  previewbutton: {
    size: 'sm',
    fontWeight: 'normal',
    leftIcon: <RiDirectionFill size={'18px'} />,
    color: '#191d21',
    bgColor: '#e3eaef',
    borderRadius: '30px',
    _hover: { bg: '#c3d2dc' },
    boxShadow: '0 2px 6px #e6ecf1',
    fontWeight: '600',
    fontsize: '12px',
    mx: '10px',
  },
  bulkbutton: {
    size: 'sm',
    leftIcon: (
      <ImUpload3 bgColor={'white'} borderRadius={'50%'} color={'white'} />
    ),
    color: '#FFFFFF',
    bgColor: '#3ABAF4',
    borderRadius: '30px',
    _hover: { bg: '#3ABAF4' },
    boxShadow: '0 2px 6px #3ABAF4',
    fontWeight: '600',
    fontSize: '10px',
    mx: '10px',
  },

  downloadbutton: {
    boxShadow: '0 2px 6px #e6ecf1',
    bgColor: '#e3eaef',
    borderColor: '#e3eaef',
    color: '#191d21',
    borderRadius: '15px',
    size: 'sm',
    _hover: {
      transform: 'translateY(-2px)',
    },
  },
  showentries: {
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.customColors.blackAdminColor['100'],
  },
  datesContainer: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxW: '50%',
  },
  dateComponentStyles: {
    marginX: 2,
    flexDirection: 'column',
    width: '100%',
  },
  // ----------------TableComp-----------
  tableFlex: {
    borderBottom: '1px solid #ddd',
    overflow: 'scroll',
  },
  table: {
    variant: 'unstyled',
    size: 'sm',
  },
  thead: {
    bgColor: 'rgba(0, 0, 0, 0.04)',
    color: '#666',
    paddingTop: '0.9375em',
    paddingBottom: '0.9375em',
    borderBottom: '1px solid #f9f9f9',
    textAlign: 'center',
  },
  tbody: {
    position: 'relative',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    borderBottom: '1px solid #ddd',
    leftIcon: <MdImportExport w={5} h={5} />,
    // icon: <Icon as={SmallAddIcon} w={6} h={6} color={'black'} />
  },
  tr: {
    _hover: { bg: '#ececec' },
  },
  td: {
    textAlign: 'center',
  },
  statusStyle: {
    verticalAlign: 'middle',
    padding: '7px 12px',
    fontWeight: '600',
    letterSpacing: '0.3px',
    borderRadius: '30px',
    fontSize: '12px',
  },
  editbtn: {
    size: 'xs',
    variant: 'ghost',
    color: 'black',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  Delbtn: {
    size: 'xs',
    marginLeft: 1,
    variant: 'ghost',
    color: 'red',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
  },
  activebtn: {
    size: 'xs',
    marginLeft: 1,
    variant: 'ghost',
    color: 'Green',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
  },
  tablefooter: {
    paddingTop: '0.85em',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
  },
  elseflex: {
    position: 'absolute',
    w: '100%',
    h: '100%',
    d: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    left: '0',
  },
  // -----------------------Pagination------------ //
  paginatecontainer: {
    justifyContent: 'right',
    borderRadius: '10',
    marginBottom: '10',
    width: '100%',
  },
  // -------------------------InboxMessaging---------- //
  upperbutton: {
    size: 'sm',
    color: 'black',
    padding: '2%',
    bgColor: '#fff',
    fontSize: '12px',
    fontWeight: '600',
    borderRadius: '0px',
    marginBottom: '2%',
    boxShadow: 'sm',
  },
  solidbutton: {
    variant: 'solid',
    bgColor: 'black',
    color: 'white',
  },
  ghostbutton: {
    variant: 'ghost',
  },
  numbers: {
    bgColor: 'white',
    borderRadius: '30%',
    color: 'black',
    ml: '5px',
  },
  number: {
    W: '120px',
    bgColor: 'black',
    borderRadius: '30%',
    color: 'white',
    ml: '5px',
  },
  Upsellbtn: {
    color: 'white',
    variant: 'solid',
    size: 'sm',
    marginY: '25px',
    _hover: { bg: '#777777' },
  },
  UpsellExportbtn: {
    color: 'white',
    variant: 'solid',
    size: 'sm',
    marginY: '25px',
    _hover: { bg: '#bfc6cd' },
  },
  UpsellChangebtn: {
    color: 'white',
    variant: 'solid',
    size: 'sm',
    marginY: '25px',
    _hover: { bg: '#fb160a' },
  },
  // -----------------------------miniheader------------------ //
  miniheader: {
    padding: '1%',
    bgColor: '#fff',
    paddingStart: 2,
    borderRadius: 0,
    boxShadow: 'sm',
    borderColor: '#f2f2f2',
    paddingBottom: '2.5%',
    borderBottomWidth: '1px',
  },
  tableContainer: {},

  iconStyling: {
    w: 6,
    h: 6,
    color: '#ffa426 ',
    cursor: 'pointer',
  },
  divider: {
    width: '90%',
    marginTop: 5,
    marginBottom: 2,
    marginStart: '3%',
    orientation: 'horizontal',
  },
  multiSelect: {
    fontSize: '10px',
  },
  loadingFlex: {
    w: '100%',
    h: '100%',
    d: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    left: '0',
  },
  // ---------------Dashboard Css--------------------
  boxMain: {
    width: '100%',
    height: '95%',
    borderWidth: 0,
    color: 'gray.400',
    background: 'white',
    borderRadius: 'lg',
    boxShadow: 'lg',
    overflow: 'hidden',
    boxDecorationBreak: 'clone',
  },
  boxHeader: {
    flex: 1,
    display: 'flex',
    justify: 'space-between',
    alignItems: 'space-between',
  },
  manageButton: {
    px: 3,
    fontSize: '12px',
    rounded: 'full',
    rightIcon: <ArrowForwardIcon />,
    bg: 'black',
    color: 'white',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    _hover: {
      bg: 'white.500',
      transform: 'translateY(-2px)',
      // boxShadow: 'lg',
    },
    _focus: {
      bg: 'gray',
    },
  },
  tableHead:{
    bg:"#FFA535",
    fontWeight:'bold',
    fontSize:'18px',
    w:"100%",
    p:'4',
    color:"white",
    marginY:'5',
    marginBottom:"2",
    justifyContent:'space-between',
  },
  tableManagebtn:{
    size:'sm',
    rounded:"md",
    variant:"outline",
    borderRadius:'10%',
    rightIcon: <ArrowForwardIcon />,
    bg: 'black',
    color: 'white',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    _hover: {
      bg: 'white.500',
      transform: 'translateY(-2px)',
      // boxShadow: 'lg',
    },
    _focus: {
      bg: 'gray',
    },
  },
  boxBody: {
    flex: 1,
    marginTop: 5,
    display: 'flex',
    justifyContent: 'space-around',
  },
  boxFooter: {
    bg: 'white',
    rounded: 'md',
    boxSize: '50px',
    alignItems: 'center',
    borderRadius: '5px',
    justifyContent: 'center',
    backgroundColor: 'black',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    _hover: {
      bg: 'white.500',
      transform: 'translateY(-2px)',
      // boxShadow: 'lg',
    },
    _focus: {
      bg: 'gray',
    },
  },
  totalCalculation: {
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  tbody: {
    color: '#838486',
    position: 'relative',
  },
};
export default style;
