import React from 'react';
import {
  Input,
  Button,
  Flex,
  Text,
  useToast,
  Td,
  Tr,
  Select,
  Spacer,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { listPrivacyPolicyList, colPrivacyPolicyList } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import LangContext from '../context/languageContext';
import ApiManager from '../config/apiManager';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';
import { options } from '../context/data';
// import { Tooltip } from 'chart.js';

const PrivacyPolicyList = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };
  // console.log("dataaa>>>",dataa)
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const ArrowUp = ID => {
    let index = dataa.findIndex(e => e._id == ID); //find id from Api
    // console.log("Index",index,"id",ID)
    if (index > 0) {
      let el = dataa[index];
      dataa[index] = dataa[index - 1];
      dataa[index - 1] = el;
      // console.log("EL>>",el,"Up>>>",dataa[index])
      setDataa([...dataa]);
    }
  };

  const ArrowDown = ID => {
    let index = dataa.findIndex(e => e._id == ID); //find id from Api
    // console.log("Index",index,"id",ID)
    if (index > 0) {
      let el = dataa[index];
      dataa[index] = dataa[index + 1];
      dataa[index + 1] = el;
      // console.log("EL>>",el,"Up>>>",dataa[index])
      setDataa([...dataa]);
    }
  };

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllPrivacyPolicies', body)
      .then(res => {
        // console.log("res",res)
        if (res.message === 8242) {
          res?.record?.docs?.map((value, index) => {
            // console.log('check>>>', value);
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    // console.log('body>>>>', body);
    apimanager
      .post('deletePrivacyPolicy', body)
      .then(response => {
        if (response.message === 8240) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Privacy Policies List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Privacy Policies Management',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreatePrivacyPolicies')}
            >
              Create
            </Button>
            <Button
              {...style.previewbutton}
              onClick={() => history.push('/PrivacyPoliciesUnauth')}
            >
              Preview Html Page
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
              <Input {...style.searchinput}></Input>
            </Flex>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colPrivacyPolicyList}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.privacyCategory}</Td>
                  <Td>{item?.name}</Td>
                  <Td
                    display={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <ArrowUpIcon
                      w={5}
                      h={5}
                      color={'#ffa426 '}
                      cursor={'pointer'}
                      onClick={() => {
                        ArrowUp(item._id);
                      }}
                    />
                    <ArrowDownIcon
                      w={5}
                      h={5}
                      color={'#ffa426 '}
                      cursor={'pointer'}
                      onClick={() => {
                        ArrowDown(item._id);
                      }}
                    />
                  </Td>
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    {/* <Tooltip label={"Edit"}> */}
                    <Button
                      {...style.editbtn}
                      leftIcon={<EditIcon />}
                      onClick={() =>
                        history.push({
                          pathname: '/CreatePrivacyPolicies',
                          status: 'create',
                          state: { val: item },
                        })
                      }
                    ></Button>
                    {/* </Tooltip> */}
                    <>
                      {/* <Tooltip label={"Delete"}> */}
                      <Button
                        label={'Delete'}
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      ></Button>
                      {/* </Tooltip> */}
                    </>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default PrivacyPolicyList;
