import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Skeleton,
  Heading,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import { useHistory } from 'react-router-dom';
import {
  customInputLabel,
  customInputLabelButton,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import {
  dropdownCustomSvalue,
  dropdownCustom,
} from '../components/bannerComponents/customBannerComponents';
import { CustomSelect } from '../components/bannerComponents/customDropDowns';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
const AddProductRecomendation = () => {
  let initialValue = {
    productSearch: '',
    id: '',
    productType: '',
    product: '',
    url: '',
    description: '',
    status: '',
    position: '',
    slug: '',
    tileImage: '',
    tileName: '',
    brandName: '',
    amount: '',
  };
  let mandatoryFields = {
    product: true,
    // url: true,
    // startD:true,
    // endD:true,
    description: true,
    status: true,
    // position: true,
  };
  const [category, setCategory] = React.useState('');
  const [categoryNum, setCategoryNum] = React.useState('');
  const [value, setValue] = React.useState(initialValue);
  const [dataa, setDataa] = React.useState('');
  const [singleProduct, setSingleProduct] = React.useState({});
  const [detailProd, setDetailProd] = React.useState({});
  const [state, setstate] = React.useState(false);
  const [startD, setStartD] = React.useState(new Date());
  const [endD, setEndD] = React.useState(new Date());
  const [success, setSuccess] = React.useState(null);
  const [categoryLoading, setCategoryLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchProductLoad, setSearchProductLoad] = React.useState(false);
  const [paginateDrop, setPaginateDrop] = React.useState('');
  const [adxPaginate, setAdxPaginate] = React.useState('');
  const [displaySearch, setDisplaySearch] = React.useState(false);
  const [displayADX, setDisplayADX] = React.useState(false);
  const [productsdrop, setproductsdrop] = React.useState(false);
  const [detailDrop, setDetaildrop] = React.useState(false);
  const [error, setError] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const location = useLocation();
  let history = useHistory();
  let toast = useToast();
  let tempCategory = [];
  let tempArray = [];
  let tempArrayADX = [];
  let status = [
    { list: 'Active', val: 1 },
    { list: 'Suspended', val: 0 },
  ];
  // let position = [
  //   { list: 'Up', val: 0 },
  //   { list: 'Down', val: 1 },
  //   { list: 'Left', val: 2 },
  //   { list: 'Right', val: 3 },
  // ];
  let Active = 'Active';
  let Suspended = 'Suspended';
  // let Up = 'Up';
  // let Down = 'Down';
  // let Left = 'Left';
  // let Right = 'Right';

  React.useEffect(() => {
    getALLServices();
  }, []);
  let dataOfEdit = '';
  React.useEffect(() => {
    if (location.status === 'create') {
      setstate(true);
    }
    if (location?.state?.val != null || location?.state?.val != undefined) {
      dataOfEdit = location?.state?.val;
      console.log('dataOfEdit', dataOfEdit);
      setValue({
        ...value,
        id: dataOfEdit?._id,
        type: dataOfEdit?.type,
        tileName: dataOfEdit?.tileName,
        startTime: dataOfEdit?.displayPeriodStart,
        endTime: dataOfEdit?.displayPeriodEnd,
        tileImage: dataOfEdit.tileImage,
        product: dataOfEdit?.tileName,
        amount: dataOfEdit?.amount,
        slug: dataOfEdit?.productDetails?.slug,
        brandName: dataOfEdit?.productDetails?.brandName,
        url: dataOfEdit?.url,
        description: dataOfEdit?.descriptiveText,
        status: dataOfEdit?.displayStatus,
        position: dataOfEdit?.tilePosition,
        productDetails: dataOfEdit?.productDetails,
      });
      setStartD(new Date(dataOfEdit?.displayPeriodStart * 1000));
      setEndD(new Date(dataOfEdit?.displayPeriodEnd * 1000));
    }
  }, [location]);
  console.log('dataEdit', value);

  const handleonChange = (evt, key) => {
    // console.log('Event=', evt);
    const ev = evt.target.value;
    // console.log('event', ev);

    switch (ev) {
      case 'Black Market':
        setDisplaySearch(true);
        setCategoryNum('1');
        break;
      case 'EPP':
        setDisplaySearch(true);
        setCategoryNum('2');
        break;
      case 'ADX':
        setDisplaySearch(false);
        setCategoryNum('3');
        setDisplayADX(true);
        break;
      case 'XOX Mobile':
        setDisplaySearch(false);
        setDisplayADX(false);
        setError('Oops!');
        toast({
          title: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: 'Service Not Available',
        });
        break;
      default:
        console.log('Enter Correct Product Category');
        break;
    }
    // ------------------------
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleDropdownChange = (evt, key) => {
    let ev = evt?.value;
    // console.log(key, 'BodyEvent=', ev);
    setValue({
      ...value,
      [key]: ev,
    });
    if (key === 'product') {
      getSingleProduct(ev);
    }
    if (key === 'pageNumber') {
      getProducts(value?.productSearch, ev);
    }
  };

  const handleonChangeADX = (evt, key) => {
    console.log(key,'Event=',evt?.target.value)
    const ev = evt?.target?.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handledropdownADX = (evt, key) => {
    console.log(key,'Event===',evt)
    const ev = evt?.value;
    setValue({
      ...value,
      [key]: ev,
    });
    if (key === 'product') {
      setDetaildrop(true);
      const e = JSON.parse(evt.value);
      console.log('ADXSelectedProductData=', e);
      const productDetail = {
        CompanyLogo: e?.companyLogo,
        description: e?.description,
        detailDescription: e?.detailDescription,
        detailTitle: e?.detailTitle,
        detailTnc: e?.detailTnc,
        endDate: e?.endDate,
        Link: e?.image,
        Name: e?.name,
        nonHalal: e?.nonHalal,
        PointsRequired: e?.pointsRequired,
        startDate: e?.startDate,
        status: e?.status,
        stocks: e?.stocks,
        validity: e?.validity,
        vouchers: e?.vouchers,
        id: e?._id,
      };
      setDetailProd(productDetail);
    }
    if (key === 'pageNumber'){
      getProductsADX(value?.productSearch,ev)
    }
  };

  // ----------------------------------API's---------------------------------- //
  const getALLServices = (limit, page) => {
    // setLoading(false);
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAllBAPages')
      .then(res => {
        // console.log('res', res);
        if (res.message === 8272) {
          res?.results?.docs?.map((value, index) => {
            tempCategory.push(value?.title);
          });
          setCategory(tempCategory);
          setCategoryLoading(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setError('Failed To fetch from Server');
        toast({
          title: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: 'Server Not Responding',
        });
        setCategoryLoading(false);
      });
  };

  const getProducts = (productName, pages) => {
    setSearchProductLoad(true);
    setproductsdrop(false);
    const body = {
      searchPhrase: productName,
      page: parseInt(pages),
    };
    console.log('BodyAllProduct', body);
    apimanager
      .post('search_products', body)
      .then(res => {
        console.log('ResSearch_Products', res);
        if (res.message === '1175') {
          res?.result?.docs?.map((value, index) => {
            tempArray.push({ label: value?.name, value: value?.slug });
            // tempArray.push({ list: value?.name, val: JSON.stringify(value) });
          });
          setPaginateDrop(res?.result);
          setDataa(tempArray);
          setproductsdrop(true);
          setSearchProductLoad(false);
          // console.log('BodyPaginateDrop=', res?.result);
        }

        if (res?.result?.total === 0) {
          setError('Please enter Correct Product Name');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Enter Proper KeyWord to Search Product',
          });
          setproductsdrop(false);
          setSearchProductLoad(false);
        }
        if (res?.message === '1174') {
          setError('Something went wrong');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Server is Not Responding',
          });
          setproductsdrop(false);
          setSearchProductLoad(false);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getSingleProduct = productSlug => {
    const body = {
      slug: productSlug,
    };
    console.log('BodySingleProduct=', body);
    apimanager
      .post('get_product_detail', body)
      .then(res => {
        // console.log('ResSingleProduct', res);
        if (res.message === '1171') {
          // console.log("ResSingleProduct",res?.product)
          setproductsdrop(false);
          setSingleProduct(res?.product);
          setDetaildrop(true);
        }
        if (res?.result?.docs?.length === 0) {
          setError('Please enter Correct Product Name');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Something Went Wrong.Enter Correct Product Name',
          });
          setDetaildrop(false);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getProductsADX = (productName,pages) => {
    setSearchProductLoad(true);
    setproductsdrop(false);
    const body = {
      searchPhrase: String(productName),
      page:parseInt(pages),
    };
    console.log('BodySearchProduct>>', body);
    apimanager
      .post('searchRewards', body)
      .then(res => {
        console.log('Res_ADX', res);
        if (res.message === 8298) {
          res?.result?.docs?.map((value, index) => {
            tempArrayADX.push({
              label: value?.name,
              value: JSON.stringify(value),
            });
          });
          setDataa(tempArrayADX);
          setAdxPaginate(res?.result);
          setproductsdrop(true);
          setSearchProductLoad(false);
        }
        if (res?.results?.docs?.length === 0) {
          setError('Please enter Correct Product Name');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Enter Proper Keywords to Search Product',
          });
          setproductsdrop(false);
          setSearchProductLoad(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setSearchProductLoad(false)
      });
  };

  const convertedDate = strDate => {
    // console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  const discountedAmount = (price,percentage) => {
      let discountPercent = percentage / 100;
      // console.log("discountPercent=",discountPercent);
      let discountedPrice = price - (price * discountPercent);
      // console.log("discountedPrice=",discountedPrice);
    return discountedPrice;
  };

  console.log('Date=', startD);
  // console.log('detailProd=', detailProd);
  // console.log('category=', categoryNum);

  const AddRecomendation = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        type: categoryNum,
        url: value?.url,
        descriptiveText: value?.description,
        displayPeriodStart: String(convertedDate(startD)),
        displayPeriodEnd: String(convertedDate(endD)),
        displayStatus: value?.status,
        tilePosition: value?.position,
        name: singleProduct?.name,
        tileName: singleProduct?.name,
        tileImage: singleProduct?.colors[0]?.icon?.link,
        slug: singleProduct?.slug,
        brandName: singleProduct?.productBrand?.brand,
        requireInstallation: singleProduct?.requiresInstallation,
        amount: String(discountedAmount(singleProduct?.specifications[0]?.price[0]?.figure,singleProduct?.specifications[0]?.price[0]?.discountPercent)),
        // amount: String(singleProduct?.specifications[0]?.price[0]?.figure),
      };
      console.log('Body>>', body);
      apimanager
        .post('addRecommendations', body)
        .then(res => {
          console.log('ResBlackMrket=', res);
          if (res.message === 8700) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setLoading(false);
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (res?.message === 5068) {
            setError('Something went wrong');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Server is Not Responding',
            });
            setLoading(false);
          }
        })
        .catch(error => {
          console.log('error', error);
          setLoading(false);
        });
    }
  };

  const AddADXRecomendation = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const bodyAdx = {
        type: parseInt(categoryNum),
        // url: value?.url,
        descriptiveText: value?.description,
        displayPeriodStart: String(convertedDate(startD)),
        displayPeriodEnd: String(convertedDate(endD)),
        displayStatus: parseInt(value?.status),
        // tilePosition: parseInt(value?.position),
        name: detailProd?.Name,
        image: detailProd?.Link,
        tileName: detailProd?.Name,
        tileImage: detailProd?.Link,
        description: detailProd?.description,
        pointsRequired: detailProd?.PointsRequired,
        detailDescription: detailProd?.detailDescription,
        detailTitle: detailProd?.detailTitle,
        detailTnc: detailProd?.detailTnc,
        endDate: detailProd?.endDate,
        nonHalal: detailProd?.nonHalal,
        startDate: detailProd?.startDate,
        status: detailProd?.status,
        stocks: detailProd?.stocks,
        validity: detailProd?.validity,
        vouchers: detailProd?.vouchers,
        id: detailProd?.id,
        companyLogo: detailProd?.CompanyLogo,
      };
      console.log('Body>>', bodyAdx);
      apimanager
        .post('addRecommendations', bodyAdx)
        .then(res => {
          console.log('ResponseADX=', res);
          if (res.message === 8700) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setLoading(false);
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (res?.message === 8058) {
            setError('Something went wrong');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'DataBase Server is Not Responding',
            });
            setLoading(false);
          }
        })
        .catch(error => {
          console.log('error', error);
          setLoading(false);
        });
    }
  };

  const updateRecomendation = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        id: value?.id,
        type: value?.type,
        tileName: value?.tileName,
        tileImage: value?.tileImage,
        slug: value?.slug,
        brandName: value.brandName,
        url: value?.url,
        descriptiveText: value?.description,
        displayPeriodStart: String(convertedDate(startD)),
        displayPeriodEnd: String(convertedDate(endD)),
        displayStatus: value?.status,
        tilePosition: value?.position,
        amount: value?.amount,
        // --------------type 3---------------3
        name: value?.productDetails?.name,
        image: value?.productDetails?.image,
        description: value?.productDetails?.description,
        requiresInstallation: value?.productDetails?.requiresInstallation,
        detailDescription: value?.productDetails?.detailDescription,
        detailTitle: value?.productDetails?.detailTitle,
        detailTnc: value?.productDetails?.detailTnc,
        endDate: value?.productDetails?.endDate,
        nonHalal: value?.productDetails?.nonHalal,
        startDate: value?.productDetails?.startDate,
        status: value?.productDetails?.status,
        stocks: value?.productDetails?.stocks,
        validity: value?.productDetails?.validity,
        vouchers: value?.productDetails?.vouchers,
        companyLogo: value?.productDetails?.companyLogo,
        pointsRequired: value?.productDetails?.pointsRequired,
      };
      console.log('Body>>Update', body);
      apimanager
        .post('updateRecommendations', body)
        .then(res => {
          if (res.message === 8705) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setLoading(false);
            setTimeout(() => {
              history.goBack();
            });
          }
          if (res.errors[0].param === "displayPeriodStart") {
            setError('Update Start Display Period!');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Please Update Start Display Period!`,
            });
            setLoading(false);
          }
          if (res.errors[0].param === "displayPeriodEnd") {
            setError('Update End Date!');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Please Update End Display Period!`,
            });
            setLoading(false);
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };
  return (
    <>
      {state ? (
        <Flex flexDirection={'column'} background={'#fafdfb'} height={'100%'}>
          <Flex {...styles.miniheader}>
            {MiniHeader({
              title: 'Edit Product Recommendation',
              path: 'Dashboard',
              path1: 'Edit Product Recommendation',
            })}
          </Flex>
          <Flex {...styles.box}>
            <Flex
              padding={'2%'}
              background={'white'}
              flexDirection={'column'}
              justifyContent={'space-around'}
            >
              <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} mt={8}>
                {customInputLabel({
                  title: 'Product',
                  mandatory: true,
                  disabled:true,
                  onKeyDown: e => handleonChange(e, 'productSearch'),
                  placeholder: value['product'],
                })}
                {/* {productsdrop
                  ? dropdownCustomSvalue({
                      temparray: dataa,
                      onChange: e => handleonChange(e, 'product'),
                    })
                  : null} */}
              </SimpleGrid>
              <SimpleGrid
                width={'100%'}
                columns={1}
                spacingX={'50px'}
                marginTop={5}
              >
                {customTextarea({
                  title: 'Description',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'description'),
                  value: value['description'],
                })}
              </SimpleGrid>
              <SimpleGrid width={'100%'} columns={3} spacingX={'50px'} mt={5}>
                {DatewithTimePicker({
                  title: 'Start Date',
                  mandatory: false,
                  selectedDate: startD,
                  onChange: e => setStartD(e),
                })}
                {DatewithTimePicker({
                  title: 'End Date',
                  mandatory: false,
                  selectedDate: endD,
                  onChange: e => setEndD(e),
                })}
                {dropdownCustomSvalue({
                  title: 'Status',
                  mandatory: true,
                  temparray: status,
                  onChange: e => handleonChange(e, 'status'),
                  placeholder: value['status'] === 1 ? Active : Suspended,
                })}
              </SimpleGrid>
              {customUpdateCancelBtn({
                title1: 'Update',
                title2: 'Cancel',
                isLoading: loading,
                onClickCancel: () => history.goBack(),
                onClickSubmit: () => updateRecomendation(),
              })}
            </Flex>
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection={'column'} background={'#fafdfb'} height={'100%'}>
          <Flex {...styles.miniheader}>
            {MiniHeader({
              title: 'Product Recommendation',
              path: 'Dashboard',
              path1: 'AddProductRecommendation',
            })}
          </Flex>
          <Flex {...styles.box} height={'100%'}>
            <Flex
              padding={'2%'}
              background={'white'}
              flexDirection={'column'}
              justifyContent={'space-around'}
            >
              <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
                {categoryLoading === true ? (
                  <>
                    {dropdownCustom({
                      title: 'Product Category',
                      mandatory: true,
                      temparray: category,
                      placeholder: 'Select...',
                      onChange: e => handleonChange(e, 'productServices'),
                    })}
                  </>
                ) : (
                  <Skeleton height={'70px'} />
                )}
              </SimpleGrid>
              <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} mt={8}>
                {displaySearch === true ? (
                  <>
                    {customInputLabelButton({
                      title: 'Products',
                      mandatory: true,
                      placeholder: 'Search Product Here',
                      onChange: e => handleonChange(e, 'productSearch'),
                      isLoading: searchProductLoad,
                      onClickSearch: () => getProducts(value?.productSearch, 1),
                    })}
                    {productsdrop === true
                      ? CustomSelect({
                          options: dataa,
                          // fetchingData: detailDrop,
                          placeholder:'Select Option',
                          paginate: paginateDrop,
                          pagechange: e =>
                            handleDropdownChange(e, 'pageNumber'),
                          onChange: e => handleDropdownChange(e, 'product'),
                        })
                      : null}
                  </>
                ) : displayADX === true ? (
                  <>
                    {customInputLabelButton({
                      title: 'Search ADX Product',
                      mandatory: true,
                      placeholder: 'Search Product Here',
                      onChange: e => handleonChangeADX(e, 'productSearch'),
                      onClickSearch: () => getProductsADX(value?.productSearch,1),
                      isLoading: searchProductLoad,
                    })}
                    {productsdrop
                      ? CustomSelect({
                        options: dataa,
                        // fetchingData: detailDrop,
                        placeholder:'Select Option',
                        paginate: adxPaginate,
                        pagechange: e =>
                        handledropdownADX(e, 'pageNumber'),
                        onChange: e => handledropdownADX(e, 'product'),
                      })
                      : null}
                  </>
                ) : null}
              </SimpleGrid>
              {detailDrop ? (
                <>
                  <Flex my={5}>
                    <Heading
                      w={'100%'}
                      background={'#fafdfb'}
                      textAlign={'center'}
                    >
                      {singleProduct?.name
                        ? singleProduct?.name
                        : detailProd?.Name}
                    </Heading>
                  </Flex>

                  <SimpleGrid
                    width={'100%'}
                    columns={1}
                    spacingX={'50px'}
                    marginTop={3}
                  >
                    {customTextarea({
                      title: 'Description',
                      mandatory: true,
                      onChange: e => handleonChange(e, 'description'),
                    })}
                  </SimpleGrid>
                  <SimpleGrid
                    width={'100%'}
                    columns={3}
                    spacingX={'50px'}
                    mt={5}
                  >
                    {DatewithTimePicker({
                      title: 'Start Date',
                      mandatory: false,
                      selectedDate: startD,
                      onChange: e => setStartD(e),
                    })}
                    {DatewithTimePicker({
                      title: 'End Date',
                      mandatory: false,
                      selectedDate: endD,
                      onChange: e => setEndD(e),
                    })}
                    {dropdownCustomSvalue({
                      title: 'Status',
                      mandatory: true,
                      temparray: status,
                      placeholder: 'Select...',
                      onChange: e => handleonChange(e, 'status'),
                    })}
                  </SimpleGrid>
                  {/* <SimpleGrid
                    width={'100%'}
                    columns={2}
                    spacingX={'50px'}
                    mt={5}
                  >
                    {customInputLabel({
                      title: 'URL',
                      mandatory: true,
                      onChange: e => handleonChange(e, 'url'),
                    })}
                    {dropdownCustomSvalue({
                      title: 'Position',
                      mandatory: true,
                      temparray: position,
                      placeholder: 'Select...',
                      onChange: e => handleonChange(e, 'position'),
                    })}
                  </SimpleGrid> */}
                  {customUpdateCancelBtn({
                    title1: 'Submit',
                    title2: 'Cancel',
                    isLoading: loading,
                    onClickCancel: () => history.goBack(),
                    onClickSubmit: () =>
                      categoryNum === '3'
                        ? AddADXRecomendation()
                        : AddRecomendation(),
                  })}
                </>
              ) : null}
            </Flex>
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};
export default AddProductRecomendation;
