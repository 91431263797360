import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Divider,
  Spacer,
  Skeleton,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import theme from '../config/color';
import style from '../components/common/style';
import Multiselect from 'multiselect-react-dropdown';
import styles from '../styles/createLoyaltyPointStyles';
import { uploadImage } from '../config/imageUploader';
import {
  checkMandatory,
  customInputImage,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';

import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import { useHistory, useLocation } from 'react-router-dom';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';

const CreateLoyaltyPoint = () => {
  let dataOfEdit = '';
  let tempVoucher = [];
  let tempName = [];
  let tempMId = [];
  let iddd = [];
  let initialState = {
    tnc: '',
    title: '',
    point: '',
    name: '',
    status: '',
    stocks: '',
    image: '',
    validity: '',
    vouchers: '',
    voucherName: '',
    voucherID: '',
    halalPopup: '',
    componyLogo: '',
    descriptionDetail: '',
    descriptionReward: '',
  };

  let mandatoryFields = {
    title: true,
    tnc: true,
    point: true,
    name: true,
    status: true,
    stocks: true,
    // voucherID: true,
    halalPopup: true,
    componyLogo: true,
    descriptionDetail: true,
    descriptionReward: true,
  };

  let halaDropArray = ['Yes', 'No'];
  let statusDrop = ['Active', 'Inactive'];

  const toast = useToast();
  let history = useHistory();
  let location = useLocation();
  let apimanager = ApiManager.getInstance();

  const [v, setV] = useState([]);
  const [vId, setVId] = useState([]);
  const [option, setOption] = useState();
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState([]);
  const [s3Image, setS3Image] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialState);
  const [endD, setEndD] = useState(new Date());
  const [startD, setStartD] = useState(new Date());
  const [s3loading, setS3Loading] = useState(false);
  const [concantinate, setConcatinate] = useState([]);
  const [changeApi, setChangeApi] = useState(false);
  const [voucherName, setVoucherName] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [voucherLoading, setVoucherLoading] = useState(false);

  useEffect(() => {
    getVouchers();

    if (location?.state?.data != null || location?.state?.data != undefined) {
      dataOfEdit = location?.state?.data;
      let aray = [];

      getVouchers(dataOfEdit?.vouchers);

      let tempStatus = changeStatusEdit(dataOfEdit?.status);
      console.log('tempStatus=>', tempStatus);

      let tempHalal = changeHalalEdit(dataOfEdit?.nonHalal);
      console.log('tempHalal=>', tempHalal);

      setValue({
        ...value,
        status: tempStatus,
        halalPopup: tempHalal,
        name: dataOfEdit?.name,
        tnc: dataOfEdit?.detailTnc,
        image: dataOfEdit?.image,
        stocks: dataOfEdit?.stocks,
        title: dataOfEdit?.detailTitle,
        validity: dataOfEdit?.validity,
        voucherID: dataOfEdit?.vouchers,
        point: dataOfEdit?.pointsRequired,
        componyLogo: dataOfEdit?.companyLogo,
        descriptionReward: dataOfEdit?.description,
        descriptionDetail: dataOfEdit?.detailDescription,
      });
      setImage(dataOfEdit?.image);
      setS3Image(dataOfEdit?.companyLogo);

      var startDate = new Date(dataOfEdit?.startDate);
      var endDate = new Date(dataOfEdit?.endDate);
      setStartD(startDate);
      setEndD(endDate);

      console.log('dataOfEdit', dataOfEdit);
      let changeApi = location?.state?.useEditApi;
      setChangeApi(changeApi);

      !changeApi
        ? setOption(['Black App', 'Selfcare', 'Online-Store'])
        : setOption(aray);
    }
    setEditData(dataOfEdit);
  }, []);


  const getVouchers = vouch => {
    setVoucherLoading(true);
    apimanager
      .get('rewards/merchantList', {})
      .then(res => {
        // console.log('getAllRewards=>Response', res);
        if (res.status === true) {
          setVoucherLoading(false);
          res.data.map((value, index) => {
            tempVoucher.push(value);
            iddd.push(value?.idmerchant);
            tempName.push(value?.merchantname);
            tempMId.push([value?.idmerchant, value?.merchantname]);
          });

          setVId(iddd);
          setVoucherName(tempName);
          setV(tempMId);
          setLoading(false);

          console.log('res.data123=>', res.data, vouch);
          let data = getProjectId(res.data, vouch);
          console.log('Returned data=>', data);
          let dat = [];
          data.map(da => {
            console.log('da id', da.idmerchant);
            console.log('da data', da.merchantname);
            dat.push([da.idmerchant, da.merchantname]);
          });
          console.log('Dattt==>', dat);

          setConcatinate(dat);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getProjectId = (data, value) => {
    console.log(data, 'getvalues', value);
    let selectData = [];
    data.map((dataVouch, index) => {
      // console.log('value ', val);
      // console.log('dataVouch.idmerchant ', dataVouch.idmerchant);

      if (value.includes(dataVouch.idmerchant)) {
        console.log('yesIncludds', dataVouch);
        selectData.push(dataVouch);
      }
    });
    console.log('selectData', selectData);
    return selectData;
  };

  // Utility Functions //
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const onSelectedFuntion = e => {
    console.log('onSelect=>', e);
    let tempId = [];
    let tempVoucher = [];
    e.map((value, index) => {
      console.log(index, 'value', value[0]);

      tempId.push(value[0]);
      tempVoucher.push(value[1]);
    });
    console.log(tempVoucher, 'tempId', tempId);
    setValue({
      ...value,
      vouchers: tempId,
      voucherName: tempVoucher,
    });
  };
  console.log('bodyValue', value);

  const upload = e => {
    setS3Loading(true);
    console.log('e.target=>', e.target.files[0].name);
    uploadImage(e.target.files[0])
      .then(res => {
        setS3Loading(false);

        console.log('image uri', res.location);
        setS3Image(e.target.files[0].name);
        setValue({
          ...value,
          componyLogo: res.location,
        });
      })
      .catch(error => {
        setS3Loading(false);
        console.log('error', error);
      });
  };

  const uploadI = async e => {
    // setShow(true);
    setImageLoading(true);
    console.log('e.target=>', e);
    console.log('e.target=> name', e.target.files[0].name);
    await uploadImage(e.target.files[0])
      .then(res => {
        setImageLoading(false);
        console.log('image uri', res.location);
        setImage(e.target.files[0].name);
        setValue({
          ...value,
          image: res.location,
        });
        // setShow(false);
      })
      .catch(error => {
        setImageLoading(false);
        console.log('error', error);
      });
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 'Inactive') {
      return 0;
    } else if (value === 'Active') {
      return 1;
    }
  };

  const changeStatusEdit = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'Inactive';
    } else if (value === 1) {
      return 'Active';
    }
  };

  const changeHalal = value => {
    console.log('value=>', value);
    if (value === 'No') {
      return 0;
    } else if (value === 'Yes') {
      return 1;
    }
  };
  const changeHalalEdit = value => {
    console.log('value=>', value);
    if (value === false) {
      return 'No';
    } else if (value === true) {
      return 'Yes';
    }
  };

  const convertedDateAndTime = _callback => {
    let date =
      startD.getFullYear() +
      '-' +
      (startD.getMonth() + 1) +
      '-' +
      startD.getDate();

    let time =
      startD.getHours() + ':' + startD.getMinutes() + ':' + startD.getSeconds();

    var StartdateTime = date + ' ' + time;
    console.log('StartdateTime=>', StartdateTime);

    let endDate =
      endD.getFullYear() + '-' + (endD.getMonth() + 1) + '-' + endD.getDate();

    let endTime =
      endD.getHours() + ':' + endD.getMinutes() + ':' + endD.getSeconds();

    var endDateTime = endDate + ' ' + endTime;
    console.log('endDateTime=>', endDateTime);

    _callback([StartdateTime, endDateTime]);
  };

  // Api Implementation //
  const onClickSubmit = () => {
    setLoading(true);
    console.log('onClickSubmit', value);
    console.log('Api Run message', value['message']);

    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    }
  
    else {
      let startDate = '';
      let endDate = '';
      convertedDateAndTime(callback => {
        startDate = callback[0];
        endDate = callback[1];
      });
      console.log('startDate =', startDate, 'endDate = ', endDate);
      let newStartDate = new Date(startDate);
      let startTimestamp = newStartDate.getTime();

      console.log('timestamp', startTimestamp);

      let newEndDate = new Date(endDate);
      let endTimestamp = newEndDate.getTime();

      console.log('End timestamp', endTimestamp);

      let tempStatus = changeStatus(value['status']);
      console.log('tempStatus=>', tempStatus);

      let tempHalal = changeHalal(value['halalPopup']);
      console.log('tempHalal=>', tempHalal);

      const body = {
        status: tempStatus,
        name: value['name'],
        nonHalal: tempHalal,
        detailTnc: value['tnc'],
        stocks: value['stocks'],
        detailTitle: value['title'],
        validity: value['validity'],
        vouchers: value['vouchers'],
        // voucherID: value['voucherID'],
        image: image,
        endDate: endTimestamp,
        pointsRequired: value['point'],
        startDate: startTimestamp,
        companyLogo: s3Image,
        description: value['descriptionReward'],
        detailDescription: value['descriptionDetail'],
      };
      console.log('body>>>>', body);

      if (!changeApi) {
        apimanager
          .post('addRewards', body)
          .then(response => {
            console.log(response, 'response');
            console.log('response.message', response.message);
            if (response.message === 8370) {
              setLoading(loading);
              setSuccess('Successfully Added Reward');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Added Reward!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }
            if (response.errors !== undefined || response.errors !== null) {
              if (response.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[1]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      } else {
        // console.log('IDDD=>', dataOfEdit);
        let payload = {
          image: image,
          id: editData?._id,
          status: tempStatus,
          name: value['name'],
          nonHalal: tempHalal,
          detailTnc: value['tnc'],
          stocks: value['stocks'],
          detailTitle: value['title'],
          validity: value['validity'],
          companyLogo: s3Image,
          endDate: endTimestamp,
          startDate: startTimestamp,
          pointsRequired: value['point'],
          // vouchers: value['voucherID'],
          vouchers: value['vouchers'],
          description: value['descriptionReward'],
          detailDescription: value['descriptionDetail'],
        };
        console.log('update payload', payload);
        apimanager
          .post('updateRewards', payload)
          .then(response => {
            // console.log(response, 'response');
            console.log('response.message', response.message);
            if (response.message === 8277) {
              setLoading(loading);
              setSuccess('Successfully Updated Reward');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Updated Reward!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }
            if (response.errors !== undefined || response.errors !== null) {
              if (response.message === 5001) {
                setLoading(loading);

                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[1]?.msg);

                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[1]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      }
    }
  };



  return (
    <Flex {...styles.parent}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: !changeApi
            ? 'Create Loyalty Point Reward'
            : 'Update Loyalty Point Reward',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: !changeApi
            ? 'Create Loyalty Point Reward'
            : 'Update Loyalty Point Reward',
          useSlash: true,
          path2: 'Create',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <Flex alignSelf={'flex-start'}>
          <Text
            fontSize={'16px'}
            color={theme.customColors.blackAdminColor[200]}
            fontWeight="bold"
          >
            Reward Setting
          </Text>
        </Flex>

        <Divider marginY={10} />

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          <Flex marginBottom={'4px'} marginTop={'4px'}>
            <Flex>
              <Text {...styles.redAsterisk}>Vouchers</Text>
              <Text color={'#ff0000'}>*</Text>
            </Flex>
          </Flex>
          {!voucherLoading ? (
            <Multiselect
              id={vId}
              options={v}
              isObject={false}
              singleSelect={false}
              hidePlaceholder={true}
              style={{ ...styles.multiSelect }}
              selectedValues={concantinate}
              onSelect={e => onSelectedFuntion(e)}
            />
          ) : (
            <Skeleton height="20px" />
          )}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={2}>
          {customInputLabel({
            value: value['name'],
            title: 'Name',
            mandatory: true,
            onChange: e => handleonChange(e, 'name'),
          })}
        </SimpleGrid>

        {customTextarea({
          mandatory: true,
          title: 'Description',
          value: value['descriptionReward'],
          onChange: e => handleonChange(e, 'descriptionReward'),
        })}

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={2}>
          {customInputLabel({
            mandatory: true,
            type: 'number',
            value: value['point'],
            title: 'Points Required (In numbers)',
            onChange: e => handleonChange(e, 'point'),
          })}
          {customInputLabel({
            title: 'Stocks (In numbers)',
            type: 'number',
            mandatory: true,
            value: value['stocks'],
            onChange: e => handleonChange(e, 'stocks'),
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={2}>
          {DatewithTimePicker({
            title: 'Start Date',
            mandatory: false,
            selectedDate: startD,
            // onChange: e => handleonChange(e, 'startDate'),
            onChange: e => setStartD(e),
          })}

          {DatewithTimePicker({
            title: 'End Date',
            mandatory: false,
            selectedDate: endD,
            onChange: e => setEndD(e),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {!imageLoading ? (
            customInputImage({
              title: 'Image',
              mandatory: true,
              type: 'file',
              // value: value['image'],
              src: process.env.REACT_APP_S3URL + image,
              onChange: e => uploadI(e),
            })
          ) : (
            <Skeleton height="20px" />
          )}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          <div width={'100%'} background={'violet'}>
            <div>
              {!s3loading ? (
                <>
                  {customInputImage({
                    title: 'Company Logo',
                    mandatory: true,
                    type: 'file',
                    src: process.env.REACT_APP_S3URL + s3Image,
                    onChange: e => upload(e),
                  })}
                  <Text
                    fontSize={'xs'}
                    color={
                      theme.customColors.blackAdminColor['defaultFontColor']
                    }
                  >
                    Minimum Height = 500px, Minimum Width = 500px, Ratio = 1 /
                    Square
                  </Text>
                </>
              ) : (
                <Skeleton height="20px" />
              )}
            </div>
          </div>
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
       
          {dropdownCustom({
            title: 'No Halal Popup',
            mandatory: true,
            temparray: halaDropArray,
            placeholder: value['halalPopup']
              ? value['halalPopup']
              : 'Select Option',
            onChange: e => {
              handleonChange(e, 'halalPopup');
            },
          })}{' '}
          {dropdownCustom({
            title: 'Status',
            mandatory: true,
            temparray: statusDrop,
            placeholder: value['status'] ? value['status'] : 'Select Option',
            onChange: e => {
              handleonChange(e, 'status');
            },
          })}
       
        </SimpleGrid>
      </Flex>

      <Flex {...styles.parentFlex}>
        <Flex alignSelf={'flex-start'}>
          <Text
            fontSize={'16px'}
            color={theme.customColors.blackAdminColor[200]}
            fontWeight="bold"
          >
            Detail Popup Setting
          </Text>
        </Flex>

        <Divider marginY={10} />

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Title',
            mandatory: true,
            value: value['title'],
            onChange: e => handleonChange(e, 'title'),
          })}
        </SimpleGrid>
        {customTextarea({
          title: 'Description',
          mandatory: true,
          value: value['descriptionDetail'],
          onChange: e => handleonChange(e, 'descriptionDetail'),
        })}
        {customTextarea({
          title: 'Terms and Condition',
          mandatory: true,
          value: value['tnc'],
          onChange: e => handleonChange(e, 'tnc'),
        })}
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Validity',
            value: value['validity'],
            mandatory: true,
            onChange: e => handleonChange(e, 'validity'),
          })}
        </SimpleGrid>
        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Update',
          title2: 'Cancel',
          isLoading: loading,
          onClickSubmit: () => onClickSubmit(),
          onClickCancel: () => history.goBack(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateLoyaltyPoint;
