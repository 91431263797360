import React from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';
import ApiManager from '../config/apiManager';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const CreateState = () => {
  let initialValue = {
    country: '',
    name: '',
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [countries, setCountries] = React.useState([]);
  const [countriesId, setCountriesId] = React.useState([]);

  const [obj, setObj] = React.useState([]);
  const location = useLocation();
  const toast = useToast();
  let apimanager = ApiManager.getInstance();
  let history = useHistory();

  // const [Ids, setId] = React.useState([]);
  // console.log("id>>>>>>>>id>>>>>>>id",Ids)

  React.useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }

    if (location?.state != null || location?.state != undefined){
      setValue({
        ...value,
        country:location?.state?.val?.countryName,
        name: location?.state?.val?.name
      });
      console.log('name', location?.state?.val?.name)
    }
    
    // if (location.state?.val?.countryName)
    //   setValue(_p => ({ ..._p, country: location?.state?.val?.countryName }));
    // if (location.state?.val?.states?.name)
    //   setValue(_p => ({ ..._p, name: location?.state?.val?.name }));
    console.log('pathdata', location?.state?.val);
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
    // console.log('value=>', value);
  };
  console.log('value=>', value);
  const onClickSubmit = () => {
    setLoading(true);
    console.log('Country data', countries);
    console.log('Country Data', obj);
    console.log('Country ID', countriesId);
    let tempSelectedID = '';
    countriesId.map((val, index) => {
      console.log('ValueOfCountries ID', val[1]);
      console.log('ValueOfCountries Name', value['country']);
      if (val[1] === value['country']) {
        console.log('selected value', val[0]);
        tempSelectedID = val[0];
      }
    });

    // if(value?.country===)

    const body = {
      countryId: tempSelectedID,
      name: value?.name,
      countryName: value['country'],
    };
    console.log('body>>>>', body);
    apimanager
      .post('addState', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8366) {
          setLoading(false);
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        }
        else if (response.message === 8058) {
          setLoading(false);
          toast({
            title: 'Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Name required`,
          });
        }
        else if (response.message === 8371) {
          setLoading(false);
          toast({
            title: 'Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Country required`,
          });
        }
        else {
          setLoading(false);
          toast({
            title: 'Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `oops! Something Went Wrong`,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  // -------------Countries Name Call-------
  let tempArray = [];
  let tempArrayy = [];

  React.useEffect(() => {
    getALLItems();
  }, []);

  const getALLItems = () => {
    let tempId = [];
    console.log('countries Screen');
    apimanager
      .get('getAllCountries')
      .then(res => {
        if (res.message === 8372) {
          setObj(res.result.docs);
          console.log('Get add country responce', res);
          res.result.docs.map((value, index) => {
            console.log('valuevalue=>', value);
            tempArray.push(value?.name);
            tempId.push([value?._id, value?.name]);
          });
          console.log('tempId', tempId);
          setCountries(tempArray);
          setCountriesId(tempId);
          // setId(tempArrayy);
        }
        
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  // console.log('tempArray=>>>>>', tempArray);
  console.log('OBJ=>>>>>', obj.name);

  const onClickUpdate = () => {
    setLoading(true);
    console.log('Api Run');
    const body = {
      id: location?.state?.val?._id,
      countryName: value?.country,
      name: value?.name,
    };
    console.log('body>>>>', body);
    apimanager
      .post('updateState', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8064) {
          setLoading(false);
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...styles.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit State',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'App Setup',
              useSlash: true,
              path2: 'State',
              path3: 'Edit',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Country',
                mandatory: true,
                placeholder: value['country'],
                temparray: countries,
                onChange: e => handleonChange(e, 'country'),
              })}
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                placeholder: value['name'],
                 value:value['name'],
                onChange: e => handleonChange(e, 'name'),
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...styles.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create State',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'App Setup',
              useSlash: true,
              path2: 'State',
              path3: 'Create',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Country',
                mandatory: true,
                placeholder:'Select Country',
                onChange: e => handleonChange(e, 'country'),
                temparray: countries,
              })}
              {customInputLabel({
                title: 'Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateState;
