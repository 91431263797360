import React from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';

import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { customSaveButton } from '../components/inboxMessagingComponent';

const CreateProductCategory = () => {
  console.log('Create Privacy Policy Category gg bro');
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      {MiniHeader({
        title: 'Create Product Category',
        path: 'Dashboard / Application Setup / Create Product Category',
      })}

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Name',
            mandatory: true,
            // type: 'file',
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateProductCategory;
