import React from 'react';
import ReactPaginate from 'react-paginate';
import { CheckIcon, UpDownIcon, ViewIcon } from '@chakra-ui/icons';
import { MdImportExport, BsViewList } from 'react-icons/md';
import { options } from '../../context/data';
import LangContext from '../../context/languageContext';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Circle,
} from '@chakra-ui/react';
import style from '../common/style';
import Loading from './Loading';
import { useHistory } from 'react-router-dom';
export const Tablcomp = ({
  datalist,
  screenType,
  headerNames,
  TotDocs,
  onDelete,
  onPageChange,
  loaderr,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [EditDelButton, setEditDelButton] = React.useState(false);
  const [viewdownloadbutton, setviewdownloadbutton] = React.useState(false);
  const { currentLangData } = React.useContext(LangContext);
  let history = useHistory();

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('clicked', selectedPage + 1);
    setCurrentPage(selectedPage);
    onPageChange(selectedPage + 1);
  };
  let totalDatalength = TotDocs?.totalDocs;
  console.log('datalist>>>>', TotDocs);
  const PER_PAGE = selectvalue;
  // console.log('page>>>>>',PER_PAGE)
  // console.log('No of data show in a single page>>', PER_PAGE);
  const offset = currentPage * PER_PAGE;
  // console.log('offset', offset);
  // const pageCount = Math.ceil(totalDatalength / PER_PAGE);
  const pageCount = TotDocs?.totalPages;
  // console.log("pagecount",pageCount)
  React.useEffect(() => {
    // console.log('Check props', screenType);
    if (screenType === 'buttons') {
      setEditDelButton(true);
    } else if (screenType === 'LuckDrawList') {
      setviewdownloadbutton(true);
    }
  }, []);
  return (
    <Flex width={'100%'} flexDirection={'column'} paddingX={5}>
      <Flex {...style.tableFlex}>
        <Table {...style.table}>
          <Thead {...style.thead}>
            <Tr>
              {headerNames.map((headerItem, index) => (
                <Th {...style.th} key={index}>
                  <Flex>
                    <MdImportExport />
                    {headerItem}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody {...style.tbody}>
            {loaderr === true ? (
              datalist.length > 0 ? (
                datalist.map((value, index) => {
                  // let date = new Date(value.createdAt / 1000);
                  let currentDateTime = new Date(value.createdAt * 1000);
                  return (
                    <Tr key={index} width={'100%'}>
                      <Td>{value.email}</Td>
                      <Td>{value.MSISDN}</Td>
                      {value?.firstName ? (
                      <Td>{value.firstName}</Td>
                      ):(
                        <Text textAlign={'center'}>--</Text>
                      )}
                      <Td>
                        {value?.lastName ? (<Td>{value?.lastName}</Td>):(<Text textAlign={'center'} mr={6}>--</Text>)}</Td>
                      <Td {...style.td}>
                        {value.otps.map((dat, index) => {
                          return dat.otpCount;
                        })}
                      </Td>
                      {value?.emailVerified === 0 ? (
                        <Td {...style.statusStyle}>
                          <Circle
                            paddingY={'4px'}
                            paddingX={'10px'}
                            bg="black"
                            color="white"
                          >
                            <Text fontSize={'12px'}>
                              {currentLangData?.app?.varified}
                            </Text>
                          </Circle>
                        </Td>
                      ) : value?.emailVerified === 1 ? (
                        <Td {...style.statusStyle}>
                          <Circle
                            paddingY={'4px'}
                            paddingX={'10px'}
                            bg="#34395e"
                            color="white"
                          >
                            <Text fontSize={'12px'}>
                              {currentLangData?.app?.unvarified}
                            </Text>
                          </Circle>
                        </Td>
                      ) : null}
                      <Td>{currentDateTime.toLocaleString()}</Td>
                      {value?.resetPassword === false ? (
                        <Td {...style.statusStyle}>
                          <Circle
                            paddingY={'4px'}
                            paddingX={'10px'}
                            size="150%"
                            bg="#34395e"
                            color="white"
                          >
                            <Text fontSize={'12px'}>
                              {currentLangData?.app?.resetpassword}
                            </Text>
                          </Circle>
                        </Td>
                      ) : value?.isSuspended === true ? (
                        <Td {...style.statusStyle}>
                          <Circle
                            bg="#34395e"
                            color="white"
                            width="auto"
                            paddingY={'4px'}
                            paddingX={'10px'}
                          >
                            <Text fontSize={'12px'}>
                              {currentLangData?.app?.inactive}
                            </Text>
                          </Circle>
                        </Td>
                      ) : value?.isSuspended === false ? (
                        <Td {...style.statusStyle}>
                          <Circle
                            paddingY={'4px'}
                            paddingX={'10px'}
                            bg="black"
                            color="white"
                          >
                            <Text fontSize={'12px'}>
                              {currentLangData?.app?.active}
                            </Text>
                          </Circle>
                        </Td>
                      ) : null}
                      {EditDelButton ? (
                        <Td
                          position={'relative'}
                          display={'inline-flex'}
                          verticalAlign={'middle'}
                          marginLeft={'3'}
                        >
                      
                          <Icon
                            {...style.Delbtn}
                            color={'blackAlpha.500'}
                            variant="outline"
                            as={ViewIcon}
                            w={6}
                            h={6}
                            onClick={() =>
                              history.push({
                                pathname: '/SsoMiddlewareInfo',
                                status: 'create',
                                state: { val: value },
                              })
                            }
                          ></Icon>
                        </Td>
                      ) : // <Td {...style.td}>
                      //   <Button
                      //     {...style.editbtn}
                      //     onClick={() =>
                      //       history.push({
                      //         pathname: '/CreateBlackUser',
                      //         status: 'create',
                      //         state: { val: value },
                      //       })
                      //     }
                      //   >
                      //     {currentLangData?.app?.edit}
                      //   </Button>
                      //   <Button
                      //     {...style.editbtn}
                      //     // onClick={() =>
                      //     //   history.push({
                      //     //     // pathname: '/CreateBlackUser',
                      //     //     status: 'create',
                      //     //     state: { val: value },
                      //     //   })
                      //     // }
                      //   >
                      //     {currentLangData?.app?.view}
                      //   </Button>
                      // </Td>
                      viewdownloadbutton ? (
                        <Td>
                          <Button {...style.editbtn}>
                            {currentLangData?.app?.view}
                          </Button>
                          <Button {...style.Delbtn}>
                            {currentLangData?.app?.download}
                          </Button>
                        </Td>
                      ) : null}
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td paddingY={'15vh'}>
                    <Flex {...style.elseflex}>
                      {/* <Loading /> */}
                      <Text fontSize={'xl'}>oops! Data bucket is Empty</Text>
                    </Flex>
                  </Td>
                </Tr>
              )
            ) : (
              <Tr>
                <Td paddingY={'15vh'}>
                  <Flex {...style.elseflex}>
                    <Loading />
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
      <Flex {...style.tablefooter}>
        <Flex>
          <Text>
            {currentLangData?.app?.showing} {0} {currentLangData?.app?.to}{' '}
            {TotDocs?.limit} {currentLangData?.app?.of} {totalDatalength}{' '}
            {currentLangData?.app?.entries}
          </Text>
        </Flex>
        <Flex {...style.paginatecontainer}>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
            // page={page}
            // onChange={handleChange}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
