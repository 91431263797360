import React, { useState, version } from 'react';
import {
  Flex,
  Text,
  Spacer,
  Divider,
  Textarea,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import styles from '../styles/aboutUsStyles';
import style from '../components/common/style';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { MiniHeader } from '../components/common/customComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import {
  customSwtich,
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';

const CreateAppVersion = () => {
  let initialValue = {
    version: '',
    iosDlink: '',
    changeLog: '',
    releaseDate: '',
    androidDlink: '',
    remarks: '',
    android: false,
    ios: false,
    forceUpdate: false,
    status: false,
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [startD, setStartD] = useState(new Date());
  const [loading,setloading] = useState(false);
  let apimanager = ApiManager.getInstance();
  const [success, setSuccess] = React.useState();
  const [error, setError] = useState('');
  let history = useHistory();
  let location = useLocation();
  const toast = useToast();

  let mandatoryFields = {
    version: true,
    // releaseDate: true,
  };
  React.useEffect(() => {
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location?.state?.val?.version)
      setValue(_p => ({ ..._p, version: location?.state?.val?.version }));
    if (location?.state?.val?.releaseDate)
      setStartD(new Date(location?.state?.val?.releaseDate * 1000));
    if (location?.state?.val?.changeLog)
      setValue(_p => ({ ..._p, changeLog: location?.state?.val?.changeLog }));
    if (location?.state?.val?.ios)
      setValue(_p => ({ ..._p, ios: location?.state?.val?.ios }));
      if (location?.state?.val?.forceUpdate)
      setValue(_p => ({ ..._p, forceUpdate: location?.state?.val?.forceUpdate }));
    if (location?.state?.val?.android)
      setValue(_p => ({ ..._p, android: location?.state?.val?.android }));
    if (location?.state?.val?.status)
      setValue(_p => ({ ..._p, status: location?.state?.val?.status }));
      if (location?.state?.val?.iosDownlink)
      setValue(_p => ({ ..._p, iosDlink: location?.state?.val?.iosDownlink      }));
      if (location?.state?.val?.androidDownlink)
      setValue(_p => ({ ..._p, androidDlink: location?.state?.val?.androidDownlink }));
    // console.log('pathdata', location?.state);
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleonSwitch = (evt, key) => {
    const ev = evt;
    console.log('Switch', ev, 'key', key);
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const convertedDate = strDate => {
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  const onClickSubmit = () => {
    setloading(false);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    console.log('iferror', ifError);
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      let body = {
        version: value?.version,
        releaseDate: String(convertedDate(startD)),
        changeLog: value?.changeLog,
        android: value?.android,
        ios: value?.ios,
        remarks: value['remarks'],
        forceUpdate: value?.forceUpdate,
        status: value?.status,
        androidDownlink: value?.androidDlink,
        iosDownlink: value?.iosDlink,
      };
      console.log( '<==body=>', body);
      apimanager
        .post('addAppVersion', body)
        .then(res => {
          // console.log('res=>', res);
          if (res.message === 8331) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setloading(true);
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (res.message === 8058) {
            toast({
              title: 'Something went wrong',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed.Response Not saved',
            });
          }
        })
        .catch(error => {
          console.log('error', error);
          setloading(false);
        });
    }
  };

  const onClickUpdate = () => {
    setloading(false);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      let body = {
        id: location.state.val._id,
        version: value?.version,
        releaseDate: String(convertedDate(startD)),
        changeLog: value?.changeLog,
        android: value?.android,
        remarks: value['remarks'],
        ios: value?.ios,
        forceUpdate: value?.forceUpdate,
        status: value?.status,
        androidDownlink: value?.androidDlink,
        iosDownlink: value?.iosDlink,
      };
      // console.log( '<==body=>', body);
      apimanager
        .post('editAppVersion', body)
        .then(res => {
          console.log('res=>', res);
          if (res.message === 8335) {
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setloading(true);
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
        })
        .catch(error => {
          console.log('error', error);
          setloading(false);
        });
    }
  };
  return (
    <>
      {status ? (
        <Flex {...styles.mainFlex}>
          <Flex {...style.miniheader}>
            {MiniHeader({
              title: 'Edit App Version',
              path: 'Dashboard',
              path1: 'App Version',
              useSlash: true,
              path2: 'Edit',
            })}
          </Flex>
          <Flex {...style.box}>
            <Flex {...styles.fieldsFlex}>
              <SimpleGrid {...styles.simplegridStyle} columns={3}>
                {customInputLabel({
                  title: 'Version',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'version'),
                  value: value['version'],
                })}

                {customInputLabel({
                  title: 'Remarks',
                  mandatory: false,
                  onChange: e => handleonChange(e, 'remarks'),
                  value: value['remarks'],
                })}

                {customDateWithTitle({
                  title: 'Release Date',
                  mandatory: true,
                  selectedDate: startD,
                  onChange: e => setStartD(e),
                })}
              </SimpleGrid>

              <Flex {...styles.changeLogTxt}>
                <Text {...styles.txtChangelog}>Change Log</Text>

                <Textarea
                  size="sm"
                  height={'180px'}
                  onChange={e => handleonChange(e, 'changeLog')}
                  placeholder="Here is a sample placeholder"
                  value={value['changeLog']}
                />
              </Flex>
              <Flex {...styles.secondFlex}>
                <SimpleGrid {...styles.simplegridStyle}>
                  <Flex width={'100%'}>
                    <Flex>
                      {customSwtich({
                        title: 'Android',
                        label: 'Platform',
                        checked: value['android'],
                        check: e => handleonSwitch(e.target.checked, 'android'),
                      })}

                      {customSwtich({
                        title: 'Ios',
                        label: 'Platform',
                        noLabel: true,
                        checked: value['ios'],
                        check: e => handleonSwitch(e.target.checked, 'ios'),
                      })}
                    </Flex>
                    <Spacer />

                    <Flex justifyContent={'flex-start'}>
                      {customSwtich({
                        title: 'All',
                        label: 'Force Update',
                        checked: value['forceUpdate'],
                        check: e => handleonSwitch(e.target.checked, 'forceUpdate'),
                      })}
                    </Flex>
                  </Flex>

                  <Flex flexDirection={'column'}>
                    {customSwtich({
                      title: 'Active',
                      label: 'Status',
                      checked: value['status'],
                      check: e => handleonSwitch(e.target.checked, 'status'),
                    })}
                  </Flex>
                </SimpleGrid>

                <SimpleGrid {...styles.simplegridStyle} marginTop={'10'}>
                  {customInputLabel({
                    title: 'Android Download Link',
                    mandatory: false,
                    disabled: !value?.android,
                    placeholder:value?.androidDlink? value?.androidDlink:'Enter Link',
                    onChange: e => handleonChange(e, 'androidDlink'),
                  })}
                  {customInputLabel({
                    title: 'Ios Download Link',
                    mandatory: false,
                    disabled: !value?.ios,
                    placeholder:value?.iosDlink? value?.iosDlink:'Enter Link',
                    onChange: e => handleonChange(e, 'iosDlink'),
                  })}
                </SimpleGrid>

                {customUpdateCancelBtn({
                  title1: 'Update',
                  title2: 'Cancel',
                  isLoading:loading,
                  onClickCancel: () => history.goBack(),
                  onClickSubmit: () => onClickUpdate(),
                })}
              </Flex>
            </Flex>
          </Flex>
          <Divider marginY={10} width={'97%'} marginX={5} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex {...styles.mainFlex}>
          <Flex {...style.miniheader}>
            {MiniHeader({
              title: 'Create App Version',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'App Version',
              useSlash: 'true',
              path2: 'Create',
            })}
          </Flex>
          <Flex {...style.box}>
            <Flex {...styles.fieldsFlex}>
              <SimpleGrid {...styles.simplegridStyle} columns={3}>
                {customInputLabel({
                  title: 'Version',
                  mandatory: true,
                  onChange: e => handleonChange(e, 'version'),
                })}

                {customInputLabel({
                  title: 'Remarks',
                  mandatory: false,
                  onChange: e => handleonChange(e, 'remarks'),
                  value: value['remarks'],
                })}

                {customDateWithTitle({
                  title: 'Release Date',
                  mandatory: true,
                  selectedDate: startD,
                  onChange: e => setStartD(e),
                })}
              </SimpleGrid>

              <Flex {...styles.changeLogTxt}>
                <Text {...styles.txtChangelog}>Change Log</Text>
                <Textarea
                  size="sm"
                  height={'180px'}
                  placeholder="Enter Logs Here"
                  onChange={e => handleonChange(e, 'changeLog')}
                />
              </Flex>
              <Flex {...styles.secondFlex}>
                <SimpleGrid {...styles.simplegridStyle}>
                  <Flex width={'100%'}>
                    <Flex>
                      {customSwtich({
                        title: 'Android',
                        label: 'Platform',
                        check: e => handleonSwitch(e.target.checked, 'android'),
                      })}

                      {customSwtich({
                        title: 'Ios',
                        label: 'Platform',
                        noLabel: true,
                        check: e => handleonSwitch(e.target.checked, 'ios'),
                      })}
                    </Flex>
                    <Spacer />

                    <Flex justifyContent={'flex-start'}>
                      {customSwtich({
                        title: 'All',
                        label: 'Force Update',
                        check: e => handleonSwitch(e.target.checked, 'forceUpdate'),
                      })}
                    </Flex>
                  </Flex>

                  <Flex flexDirection={'column'}>
                    {customSwtich({
                      title: 'Active',
                      label: 'Status',
                      check: e => handleonSwitch(e.target.checked, 'status'),
                    })}
                  </Flex>
                </SimpleGrid>

                <SimpleGrid {...styles.simplegridStyle} marginTop={'10'}>
                  {customInputLabel({
                    title: 'Android Download Link',
                    mandatory: false,
                    disabled: !value?.android,
                    onChange: e => handleonChange(e, 'androidDlink'),
                  })}
                  {customInputLabel({
                    title: 'Ios Download Link',
                    mandatory: false,
                    disabled: !value?.ios,
                    onChange: e => handleonChange(e, 'iosDlink'),
                  })}
                </SimpleGrid>

                {customUpdateCancelBtn({
                  title1: 'Submit',
                  title2: 'Cancel',
                  isLoading:loading,
                  onClickCancel: () => history.goBack(),
                  onClickSubmit: () => onClickSubmit(),
                })}
              </Flex>
            </Flex>
          </Flex>
          <Divider marginY={10} width={'97%'} marginX={5} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};
export default CreateAppVersion;
