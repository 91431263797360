import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  useToast,
  Input,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableOne } from '../components/common/ChecktableOne';
import { colPromoCodeManagement } from '../context/data';
import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import LangContext from '../context/languageContext';
import { options } from '../context/data';

const PromoCodeManagement = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState();
  const [valueSearch, setValueSearch] = React.useState();
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [loader, setLoader] = React.useState(false);
  const [paginat, setpaginate] = React.useState();
  let tempArray = [];
  let toast = useToast();
  let apimanager = ApiManager.getInstance();
  // console.log("dataaaaaa>>>>>",dataa)
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };

  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('getAllPromoCodes', body)
      .then(res => {
        console.log('Responce>>', res);
        if (res.message === 8107) {
          let data = res?.record?.docs;
          res?.record?.docs?.map((value, index) => {
            // console.log("valuee",value)
            tempArray.push(value);
          });
          setDataa(tempArray);
          setLoader(true);
        }
        // console.log('tempArray=>>>>>', tempArray);
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const deleteItem = item => {
    setLoader(false);
    console.log('Deletion done', item._id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deletePromoCode', body)
      .then(response => {
        if (response.message === 8103) {
          toast({
            title: 'Deleted Successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: '',
          });
          getALLItems(value, 1);
          setLoader(true);
        }
        // console.log(response, 'response');
      })
      .catch(error => {
        console.log(error);
        setLoader(true);
      });
  };
  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };
  const searchItems = (value, limit, page) => {
    console.log('value');
    setLoader(false);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apimanager
      .post('searchPromoCode', body)
      .then(res => {
        console.log('res=>Ammar', res);
        if (res.message === 8523) {
          res?.result?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.result);
          setLoader(true);
        }
        if (res.message === 8338) {
          setLoader(true);
          toast({
            title: 'Something went Wrong',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Search.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Promo Code List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Promo Code Management',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreatePromoCode')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                // onChange={e => setSelectvalue(e.target.value)}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex
              {...style.showentries}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Text fontSize={'sm'} paddingY={'5px'} mr={'4px'} mt={'2px'}>
                {currentLangData?.app?.search}:
              </Text>
              {customInputLabel({
                size: 'sm',
                onKeyDown: e => handleOnSearch(e, 'searchPhrase'),
              })}
            </Flex>
          </Flex>
          <ChecktableOne
            datalist={dataa}
            loaderr={loader}
            screenName="PromoCodeManagement"
            headerNames={colPromoCodeManagement}
            onDelete={item => {
              deleteItem(item);
            }}
            onPageChange={page => {
              if (valueSearch) {
                searchItems(valueSearch, value, page);
              } else {
                getALLItems(value, page);
              }
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default PromoCodeManagement;
