import React, { useContext, useRef } from 'react';
import {
  Button,
  Flex,
  Divider,
  Text,
  Select,
  Spacer,
  useToast,
  Table,
  Tr,
  Thead,
  Input,
  Tbody,
  Th,
  Td,
  Icon,
  Circle,
} from '@chakra-ui/react';
import { Tablcomp } from '../components/common/tablecomp';
import style from '../components/common/style';
import { list, colNames } from '../context/data';
import { useHistory } from 'react-router-dom';
import styles from '../styles/aboutUsStyles';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import { MdImportExport } from 'react-icons/md';
import { options } from '../context/data';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import LangContext from '../context/languageContext';
import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';
import { CSVLink } from 'react-csv';
const BlackUserProfile = () => {
  const InitialValue = {
    MSISDN: '',
    createdAt: '',
    email: '',
    emailVerified: '',
    firstName: '',
    isActive: '',
    isSuspended: '',
    isXoxUser: '',
    lastName: '',
    otps: '',
    status: '',
    userName: '',
  };

  let history = useHistory();
  const { currentLangData } = useContext(LangContext);
  // Handling State //
  const [dataa, setDataa] = React.useState([]);
  const [dataCsv, setDataCsv] = React.useState([]);
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [valueSearch, setValueSearch] = React.useState();
  const [value, setValue] = React.useState('10');
  let apimanager = ApiManager.getInstance();
  let tempArray = [];
  let tempCsv = [];
  let toast = useToast();

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };
  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      searchItems(ev, value, 1);
    }
  };
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    const body = {
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apimanager
      .get('getAllUsers', body)
      .then(res => {
        if (res.message === 7055) {
          console.log('Get All User Response', res?.results);
          let data = res.results?.totalDocs;
          res?.results.docs.map((value, index) => {
            tempArray.push(value);
            let {
              email,
              MSISDN,
              createdAt,
              emailVerified,
              firstName,
              isActive,
              isSuspended,
              isXoxUser,
              lastName,
              otps,
              status,
              userName,
            } = value;
            tempCsv.push({
              userName: userName,
              Email: email,
              MSISDN: MSISDN,
              Time: createdAt,
              emailVerified: emailVerified,
              firstName: firstName,
              lastName: lastName,
              Active: isActive,
              Suspended: isSuspended,
              isXoxUser: isXoxUser,
              // otps: otps,
              status: status,
            });     
          });
          setpaginate(res.results);
          setDataa(tempArray);
          setDataCsv(tempCsv);
          setLoader(true);
        }
        if (res.message === 7054) {
          setLoader(true);
          toast({
            title: 'Mongodb pagination Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const searchItems = (value, limit, page) => {
    console.log('value');
    setLoader(false);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apimanager
      .post('searchBlackUserProfile', body)
      .then(res => {
        console.log('res=>', res.message);
        if (res.message === 7106) {
          res?.record?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
console.log("final",dataCsv)
  
  // const dataToPrint = [
  //   dataa.map((values,index)=>{
  //     console.log("Values>>>>>",values,"Index>>>>",index)
  //     {values.email,values.MSISDN}
  //   })
  console.log('dataCsv', dataCsv);
  console.log('Data to Print>>', dataa);
  const csvReport = {
    filename: 'Report.csv',
    // Headers: colNames,
    data: dataCsv,
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Flex {...styles.parentFlex}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Black User Profile',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Black User Profile',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            {/* <Button
              {...style.createbutton}
              // onClick={() => history.push('/CreateBlackUser')}
            >
              {currentLangData?.app?.create}
            </Button> */}
            <Spacer />
            <Flex>
              <CSVLink {...csvReport}>
                <Button {...style.downloadbutton}>
                  {currentLangData?.app?.download}
                </Button>
              </CSVLink>
            </Flex>
          </Flex>
          <Flex ref={componentRef} flexDirection={'column'}>
            <Flex {...style.optionFlex}>
              <Flex
                {...style.showentries}
                alignItems={'center'}
                fontSize={'sm'}
              >
                <Text>{currentLangData?.app?.show}</Text>
                <Select
                  {...style.select}
                  onChange={e => handleOnChange(e, 'numrecords')}
                >
                  {options.map(option => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Select>
                <Text>{currentLangData?.app?.entries}</Text>
              </Flex>
              <Spacer />
              <Flex
                {...style.showentries}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Text fontSize={'sm'} paddingY={'5px'} mr={'4px'} mt={'2px'}>
                  {currentLangData?.app?.search}:
                </Text>
                {customInputLabel({
                  size: 'sm',
                  onKeyDown: e => handleOnSearch(e, 'searchPhrase'),
                })}
              </Flex>
            </Flex>

            <Tablcomp
              datalist={dataa}
              TotDocs={paginat}
              loaderr={loader}
              screenType="buttons"
              headerNames={colNames}
              onPageChange={page => {
                if (valueSearch) {
                  searchItems(valueSearch, value, page);
                } else {
                  getALLItems(value, page);
                }
              }}
            />
          </Flex>
        </Flex>
        <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
        <Flex position={'sticky'} marginLeft={4}>
          {renderCopyRight()}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default BlackUserProfile;
