import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Heading,
  useToast,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import { OsType, subNonsub, UserData, doughnutData } from '../context/data';
import LangContext from '../context/languageContext';
import { MiniHeader } from '../components/common/customComponent';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { Bar, Doughnut } from 'react-chartjs-2';
import LineChart from '../components/common/LineChart';

const LineManagementDashboard = () => {
  let initialValue = {
    quickSelect: '',
    osType: '',
    subscription: '',
  };
  // const id = 'test-toast';
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const [blackMarketReport, setBlackMarketReport] = React.useState([]);
  const [paymentChannelsReport, setPaymentChannelsReport] = React.useState([]);
  const [value, setValue] = React.useState('10');
  const [allDate, setAllDate] = useState([]);
  const [newData, setNewData] = useState([]);
  const [monthNames, setMonthNames] = useState([]);
  const [monthCount, setMonthCount] = useState([]);
  const [uniqueData, setUniqueData] = useState([]);
  const [payload, setPayload] = React.useState(initialValue);
  const [revenueData, setRevenueData] = useState([]);
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const [paginat, setpaginate] = React.useState();
  const [startD, setStartD] = useState(new Date());
  const [sDate, setSDate] = useState('');
  const [type, setType] = useState('');
  const [eDate, setEDate] = useState('');
  const [err, setErr] = useState('');
  const [id, setId] = useState('test-toast');
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const { currentLangData } = React.useContext(LangContext);
  let apimanager = ApiManager.getInstance();
  const [userData, setUserData] = useState({
    labels: [allDate.map((dat, index) => dat)],
    // labels: [
    //   '2022-09-07',
    //   '2022-08-15',
    //   '2022-08-11',
    //   '2022-08-10',
    //   '2022-08-01',
    //   '2022-06-20',
    //   '2022-06-16',
    // ],
    datasets: [
      {
        label: 'Total Transaction Count',
        // data: [1, 1, 2, 2, 2, 2, 1],
        data: allActiveUsers.map(data => data),
        // data: allActiveUsers,
        backgroundColor: [
          'rgba(75,192,192,1)',
          '#ecf0f1',
          '#50AF95',
          '#f3ba2f',
          '#2a71d0',
        ],
        borderColor: 'black',
        borderWidth: 2,
      },
    ],
  });

  let userPlanType = ['B54', 'B39 PLUS 50', 'B39 PLUS 20', 'B39', 'B10'];

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  // const toast = useToast();
  const toast = createStandaloneToast();

  // Creating Variable //
  let tempArray = [];
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    setValue(ev);
  };
  React.useEffect(() => {
    getALLItems();
    newtotalTransactionReport();
    newUniqueReport();
    revenueReport();
    paymentChannelReport();
    if (!toast.isActive(id)) {
      toast({
        id,
        title: err,
        status: 'success',
        duration: 3000,
        isClosable: true,
        description: 'Successfully Fetch!',
      });
    }
  }, [1]);

  const handleDate = e => {
    let startDate = new Date(e[0]);
    let endDate = new Date(e[1]);
    let finalStartDate = startDate.getTime();
    let finalEndDate = endDate.getTime();
    setSDate(finalStartDate);
    setEDate(finalEndDate);
    setDateRange(e);
    setType('');
    getALLItems(null, finalStartDate, finalEndDate, null);
    newUniqueReport(null, finalStartDate, finalEndDate, null);
    revenueReport(null, finalStartDate, finalEndDate, null);
    newtotalTransactionReport(null, finalStartDate, finalEndDate, null);
  };

  const getALLItems = (typee, startD, endD, osTyp, subNonsubs) => {
    // setLoader(false);

    const positions = ['bottom-right'];

    let id = 'blackMarketReport';
    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('blackMarketReport', body)
      .then(res => {
        if (res.message === 11022) {
          if (res.data.length <= 0) {
            console.log('This array has no DATA ');
            setErr('No Data Found! BlackMarketReport');

            // if (!toast.isActive(id)) {
            //   toast({
            //     id,
            //     title: err,
            //     status: 'error',
            //     duration: 3000,
            //     isClosable: true,
            //     position:'bottom-right',
            //     description: 'BlackMarketReport!',
            //   });
            // }

            // toast({
            //   title: 'No Data Found!',
            //   status: 'error',
            //   duration: 3000,
            //   isClosable: true,
            //   description: 'Failed to Fetch!',
            // });
          }

          res?.data?.map((value, index) => {
            setBlackMarketReport(value);
          });
        }
      })
      .catch(error => {
        setLoader(true);
      });
  };

  let keysblackMarketReport = [];
  let valuesblackMarketReport = [];
  if (blackMarketReport !== []) {
    keysblackMarketReport = Object.keys(blackMarketReport);
    valuesblackMarketReport = Object.values(blackMarketReport);
  }

  const paymentChannelReport = (typee, startD, endD, osTyp, subNonsubs) => {
    // setLoader(false);

    let id = 'paymentChannelsReport';
    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('paymentChannelsReport', body)
      .then(res => {
        if (res.message === 11023) {
          if (res.data.length <= 0) {
            console.log('This array has no DATA ');
            // setErr('PaymentChannelsReport No Data Found!');

            // if (!toast.isActive(id)) {
            //   toast({
            //     id,
            //     title: 'PaymentChannelsReport! No Data Found',
            //     status: 'error',
            //     duration: 3000,
            //     position:'bottom',
            //     isClosable: true,
            //     description: 'PaymentChannelsReport!',
            //   });
            // }
            //   toast({
            //     title: 'No Data Found!',
            //     status: 'error',
            //     duration: 3000,
            //     isClosable: true,
            //     description: 'Failed to Fetch!',
            //   });
          }
          res?.data?.map((value, index) => {
            setPaymentChannelsReport(value);
          });
        }
      })
      .catch(error => {
        setLoader(true);
      });
  };
  let keysPaymentChannels = [];
  let valuesPaymentChannels = [];
  if (paymentChannelsReport !== []) {
    keysPaymentChannels = Object.keys(paymentChannelsReport);
    valuesPaymentChannels = Object.values(paymentChannelsReport);
  }

  const newtotalTransactionReport = (
    typee,
    startD,
    endD,
    osTyp,
    subNonsubs
  ) => {
    // setLoader(false);
    console.log(
      'newUserRegisterationReport=>>',
      typee,
      startD,
      endD,
      osTyp,
      subNonsubs
    );
    let id = 'totalTransactionReport';
    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('totalTransactionReport', body)
      .then(res => {
        if (res.message === 11020) {
          console.log('res.data.lenght=>', res.data.length);

          if (res.data.length <= 0) {
            console.log('This array has no DATA ');
            setErr('No Data Found in totalTransactionReport!');
            // if (!toast.isActive(id)) {
            //   toast({
            //     id,
            //     title: err,
            //     status: 'error',
            //     duration: 3000,
            //     isClosable: true,
            //     position:'bottom-left',
            //     description: 'TotalTransactionReport!',
            //   });
            // }
            // toast({
            //   title: 'No Data Found!',
            //   status: 'error',
            //   duration: 3000,
            //   isClosable: true,
            //   description: 'Failed to Fetch!',
            // });
          }

          res?.data?.map((value, index) => {
            // console.log("value.lenght=>",value);
            tempArray.push(value);
          });
          setNewData(tempArray);
        }
      })
      .catch(error => {
        console.log(error);
        setLoader(true);
      });
  };

  const newUniqueReport = (typee, startD, endD, osTyp, subNonsubs) => {
    console.log(
      'newUserRegisterationReport=>>',
      typee,
      startD,
      endD,
      osTyp,
      subNonsubs
    );

    let id = 'UniqueUserReport';
    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('uniqueUserReport', body)
      .then(res => {
        if (res.message === 11021) {
          if (res.data.length <= 0) {
            setErr('No Data Found in uniqueUserReport');
            console.log('This array has no DATA ');

            // if (!toast.isActive(id)) {
            //   toast({
            //     id,
            //     title: err,
            //     status: 'error',
            //     duration: 3000,
            //     isClosable: true,
            //     position:'bottom',
            //     description: 'UniqueUserReport!',
            //   });
            // }
            // toast({
            //   title: 'No Data Found!',
            //   status: 'error',
            //   duration: 3000,
            //   isClosable: true,
            //   description: 'Failed to Fetch!',
            // });
          }

          res?.data?.map((value, index) => {
            tempArray.push(value);
          });
          setUniqueData(tempArray);
        }
      })
      .catch(error => {
        setLoader(true);
      });
  };

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

  let monthNamesArr = [];
  let monthCountArr = [];
  const revenueReport = (typee, startD, endD, osTyp, subNonsubs) => {
    let tempDate = [];
    let tempUser = [];
    let id = 'RevenueReport';
    const body = {
      type: typee,
      startDate: endD,
      endDate: startD,
      OSType: osTyp,
      subscriptionLevel: subNonsubs,
    };
    apimanager
      .post('revenueReport', body)
      .then(res => {
        if (res.message === 11019) {
          if (res.data.length <= 0) {
            console.log('This array has no DATA ');
            setErr('No Data Found in RevenueReport');

            // if (!toast.isActive(id)) {
            //   toast({
            //     id,
            //     title: err,
            //     status: 'error',
            //     position:'bottom',
            //     duration: 3000,
            //     isClosable: true,
            //     description: 'RevenueReport!',
            //   });
            // }
            // toast({
            //   title: 'No Data Found!',
            //   status: 'error',
            //   duration: 3000,
            //   isClosable: true,
            //   description: 'Failed to Fetch!',
            // });
          }

          res?.data?.map((value, index) => {
            let month = toMonthName(parseInt(value?.date));
            let result = month.slice(0, 3);
            monthNamesArr.push(result);
            monthCountArr.push(value?.count);
          });
          setMonthNames(monthNamesArr);
          setMonthCount(monthCountArr);
        }
      })
      .catch(error => {
        setLoader(true);
      });
  };

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(e => {
          return e;
        }),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dataset 2',
        data: labels.map(e => {
          return e;
        }),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'right',
        rtl: false,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          padding: 20,
        },
      },
    },
  };

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Line Management Dashboard',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Line Management Dashboard',
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {DatewithTimePicker({
                title: 'Date',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,
                changePicker: true,
                onChange: e => handleDate(e),
              })}
              {dropdownCustom({
                title: 'User Plan',
                mandatory: false,
                temparray: userPlanType,
                placeholder: 'Select Option',
                // onChange: e => handleonChangeOSType(e, 'osType'),
              })}
            </SimpleGrid>
          </Flex>
          <Divider />
          <Flex {...style.optionFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              <Flex>
                <Bar
                  style={{ marginTop: 8 }}
                  width={'20%'}
                  height={'10%'}
                  data={{
                    labels: monthNames,
                    datasets: [
                      {
                        label: 'Revenue',
                        data: monthCount,
                        backgroundColor: 'rgba(96, 177, 245, 0.8)',
                      },
                    ],
                  }}
                />
              </Flex>
              <Flex>
                <LineChart
                  chartData={{
                    labels: uniqueData.map(data => data.date),
                    datasets: [
                      {
                        label: 'Unique User Count',
                        data: uniqueData.map(data => data.count),
                        backgroundColor: [
                          'rgba(75,192,192,1)',
                          '#ecf0f1',
                          '#50AF95',
                          '#f3ba2f',
                          '#2a71d0',
                        ],
                        borderColor: 'black',
                        borderWidth: 2,
                      },
                    ],
                  }}
                />
              </Flex>
            </SimpleGrid>
          </Flex>

          <Flex {...style.optionFlex}>
            <SimpleGrid width={'100%'} columns={2} spacingX={'50px'}>
              <Flex>
                <LineChart
                  chartData={{
                    labels: newData.map(data => data.date),
                    datasets: [
                      {
                        label: 'New Transaction Count',
                        data: newData.map(data => data.count),
                        backgroundColor: [
                          'rgba(75,192,192,1)',
                          '#ecf0f1',
                          '#50AF95',
                          '#f3ba2f',
                          '#2a71d0',
                        ],
                        borderColor: 'black',
                        borderWidth: 2,
                      },
                    ],
                  }}
                />
              </Flex>
              <Flex>
                <Bar
                  style={{ marginTop: 8 }}
                  width={'20%'}
                  height={'10%'}
                  data={{
                    labels: keysblackMarketReport,
                    datasets: [
                      {
                        label: 'Most Common Transaction Type',
                        data: valuesblackMarketReport,
                        backgroundColor: 'rgba(96, 177, 245, 0.8)',
                      },
                    ],
                  }}
                />
              </Flex>
            </SimpleGrid>
          </Flex>
          <Divider />
          <Flex marginTop={10} marginLeft={10}>
            <Text as="b" fontSize="17px">
              Plan Type
            </Text>
          </Flex>
          <Flex {...style.optionFlex}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              <Flex width={'100%'}>
                <Doughnut data={doughnutData} options={options} />
              </Flex>
              <Flex
                flexDir={'column'}
                textAlign={'center'}
                marginTop={10}
                marginLeft={30}
              >
                <Text>Most Used</Text>
                <Heading>B54</Heading>
              </Flex>
            </SimpleGrid>
          </Flex>
          <Divider />
          <Flex marginTop={10} marginLeft={10}>
            <Text as="b" fontSize="17px">
              Payment Channels
            </Text>
          </Flex>
          <Flex {...style.optionFlex}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              <Flex width={'111.5%'}>
                {paymentChannelsReport.length != 0 ? (
                  <Doughnut
                    data={{
                      labels: keysPaymentChannels,
                      datasets: [
                        {
                          label: 'Payment Channels',
                          data: valuesPaymentChannels,
                          backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(120, 140, 188, 0.82)',
                            'rgba(0, 167, 153, 0.82)',
                            'rgba(62, 85, 213, 255)',
                          ],
                          borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(62, 85, 213, 255)',
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={options}
                  />
                ) : (
                  <Doughnut data={doughnutData} options={options} />
                )}
              </Flex>
              <Flex
                flexDir={'column'}
                textAlign={'center'}
                marginTop={10}
                marginLeft={30}
              >
                <Text>Most Used</Text>
                <Heading>Credit / Debit Card</Heading>
              </Flex>
            </SimpleGrid>
            {/* {toast({
              title: err,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed to Fetch!',
            })} */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default LineManagementDashboard;
