import { Text, Link, Image, Circle } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

// ----------------------table select options-------------
export const options = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '500',
    value: '500',
  },
  {
    label: '1000',
    value: '1000',
  },
];
export const option = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '500',
    value: '500',
  },
  {
    label: '1000',
    value: '1000',
  },
];
// ------------------------Blockuserprofile---------
export const list = [
  {
    Username: 'testbal@gmail.com',
    MSISDN: '601122331231',
    FirstName: 'Junaid',
    Lastname: 'Asghar',
    OTP: '1',
    Date: '2021-12-12',
    Status: (
      <Circle
        marginStart={2}
        fontSize={'sm'}
        size="90%"
        bg="black"
        color="white"
      >
        Active
      </Circle>
    ),
  },
  {
    Username: 'testbal@gmail.com',
    MSISDN: '601122331231',
    FirstName: 'Junaid',
    Lastname: 'Asghar',
    OTP: '1',
    Date: '2021-12-12',
    Status: (
      <Circle
        marginStart={2}
        fontSize={'sm'}
        size="90%"
        bg="black"
        color="white"
      >
        Active
      </Circle>
    ),
  },
  {
    Username: 'testbal@gmail.com',
    MSISDN: '601122331231',
    FirstName: 'Junaid',
    Lastname: 'Asghar',
    OTPCount: '1',
    Date: '2021-12-12',
    Status: (
      <Circle
        marginStart={2}
        fontSize={'sm'}
        size="100%"
        bg="#34395e"
        color="white"
      >
        Inactive
      </Circle>
    ),
  },
  {
    Username: 'testbal@gmail.com',
    MSISDN: '601122331231',
    FirstName: 'Junaid',
    Lastname: 'Asghar',
    OTPCount: '1',
    Date: '2021-12-12',
    Status: (
      <Circle
        marginStart={2}
        fontSize={'sm'}
        size="90%"
        bg="black"
        color="white"
      >
        Active
      </Circle>
    ),
  },
];
export const colNames = [
  'Username',
  'MSISDN',
  'First Name',
  'Last Name',
  'OTP Count',
  'Email Varification',
  'Date',
  'Status',
  'Action',
];
// ------------------------AppVersion---------
export const listAppVersion = [
  {
    Version: '2.2.15',
    Android: <CheckIcon />,
    Ios: <CheckIcon />,
    ReleaseDate: '2021-08-09',
    Status: 'Inactive',
  },
  {
    Version: '1.1.2',
    Android: <CheckIcon />,
    Ios: <CheckIcon />,
    ReleaseDate: '2021-08-09',
    Status: 'Active',
  },
  {
    Version: '2.2.3',
    Android: <CheckIcon />,
    Ios: '',
    ReleaseDate: '2021-08-09',
    Status: 'Inactive',
  },
  {
    Version: '2.2.1',
    Android: <CheckIcon />,
    Ios: '',
    ReleaseDate: '2021-08-09',
    Status: 'Active',
  },
  {
    Version: '2.2.5',
    Android: <CheckIcon />,
    Ios: <CheckIcon />,
    ReleaseDate: '2021-08-09',
    Status: 'Active',
  },
];
export const colAppVersion = [
  'Version',
  'Android',
  'Ios',
  'Release date',
  'Status',
  'Action',
];
// ---------------------------Luckdrawlist-----------

// ---------------------------Contact Us-----------
export const listContactUs = [
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
  },
];
export const colNamesContactUs = [
  'Created At',
  'Name',
  'Email',
  'Phone',
  'Category',
  'Message',
];

// ---------------------------Payment Transactions-----------
export const listPaymentTransactions = [
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
    Amount: '128',
    Status: 'Approved',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
    Amount: '128',
    Status: 'Approved',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
    Amount: '128',
    Status: 'Approved',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
    Amount: '128',
    Status: 'Approved',
  },
  {
    CreatedAt: '2019-08-25 23:36:45',
    Name: 'Junaid',
    Email: 'junaid@gmail.com',
    Phone: '03458746684',
    Category: 'FeedBack/Bug Report',
    Message: 'Test',
    Amount: '128',
    Status: 'Approved',
  },
];
export const colPaymentTransactions = [
  'Date',
  'Name',
  'Mobile Number',
  'User Type',
  'Refrence No.',
  'Description',
  'Amount',
  'Status',
];

// ---------------------------Payment Transactions-----------
export const listPlansReport = [
  {
    Date: '2019-08-25 23:36:45',
    UserID: 'IDJF345',
    Name: 'Junaid',
    MobileNumber: '03316742533',
    RefrenceNumber: '53728',
    TransactionID: '36742',
    PaymentMethod: 'Ipay88',
    Ipay88PaymentID: '3659283',
    Amount: '128',
    CurrencyID: '6214398349',
    Description: 'Test',
    Status: 'Approved',
  },
  {
    Date: '2019-08-25 23:36:45',
    UserID: 'IDJF345',
    Name: 'Junaid',
    MobileNumber: '03316742533',
    RefrenceNumber: '53728',
    TransactionID: '36742',
    PaymentMethod: 'Ipay88',
    Ipay88PaymentID: '3659283',
    Amount: '128',
    CurrencyID: '6214398349',
    Description: 'Test',
    Status: 'Approved',
  },
  {
    Date: '2019-08-25 23:36:45',
    UserID: 'IDJF345',
    Name: 'Junaid',
    MobileNumber: '03316742533',
    RefrenceNumber: '53728',
    TransactionID: '36742',
    PaymentMethod: 'Ipay88',
    Ipay88PaymentID: '3659283',
    Amount: '128',
    CurrencyID: '6214398349',
    Description: 'Test',
    Status: 'Approved',
  },
  {
    Date: '2019-08-25 23:36:45',
    UserID: 'IDJF345',
    Name: 'Junaid',
    MobileNumber: '03316742533',
    RefrenceNumber: '53728',
    TransactionID: '36742',
    PaymentMethod: 'Ipay88',
    Ipay88PaymentID: '3659283',
    Amount: '128',
    CurrencyID: '6214398349',
    Description: 'Test',
    Status: 'Approved',
  },
];
export const colPlansReport = [
  'Date',
  'User ID',
  'Name',
  'Mobile Number',
  'Refrence Number',
  'Transaction ID',
  'Payment Method',
  'Ipay88 Payment ID',
  'Amount',
  'Currency ID',
  'Description',
  'Status',
];

// ---------------------------Payment Transactions-----------
export const listSoftPinsReport = [
  {
    PurchasedBy: '78000100011',
    SerialNumber: '03780000100011',
    Quantity: '5',
    PriceRM: '20 RM',
    RedeemedBy: 'N',
    Shared: <CloseIcon w={3} h={3} />,
    PurchasedAt: '2020-02-03 14:18:47',
    Expired: '2020-10-20 00:00:00',
  },
  {
    PurchasedBy: '78000100011',
    SerialNumber: '03780000100011',
    Quantity: '5',
    PriceRM: '20 RM',
    RedeemedBy: 'N',
    Shared: <CloseIcon w={3} h={3} />,
    PurchasedAt: '2020-02-03 14:18:47',
    Expired: '2020-10-20 00:00:00',
  },
  {
    PurchasedBy: '78000100011',
    SerialNumber: '03780000100011',
    Quantity: '5',
    PriceRM: '20 RM',
    RedeemedBy: 'N',
    Shared: <CloseIcon w={3} h={3} />,
    PurchasedAt: '2020-02-03 14:18:47',
    Expired: '2020-10-20 00:00:00',
  },
  {
    PurchasedBy: '78000100011',
    SerialNumber: '03780000100011',
    Quantity: '5',
    PriceRM: '20 RM',
    RedeemedBy: 'N',
    Shared: <CloseIcon w={3} h={3} />,
    PurchasedAt: '2020-02-03 14:18:47',
    Expired: '2020-10-20 00:00:00',
  },
  {
    PurchasedBy: '78000100011',
    SerialNumber: '03780000100011',
    Quantity: '5',
    PriceRM: '20 RM',
    RedeemedBy: 'N',
    Shared: <CloseIcon w={3} h={3} />,
    PurchasedAt: '2020-02-03 14:18:47',
    Expired: '2020-10-20 00:00:00',
  },
];
export const colSoftPinsReport = [
  'Purchased By',
  'Serial Number',
  'Quantity',
  'Price (RM)',
  'Redeemed By',
  'Shared',
  'Purchased  At',
  'Expired',
];

// ---------------------------Payment Transactions-----------
export const listPhysicalRedemptionReport = [
  {
    SubscriberName: 'Juniad',
    SubscriberEmail: 'junaid@gmail.com',
    Name: 'Asif',
    Email: 'asif23@gmail.com',
    MSISDN: '4563F55',
    RedeemDate: '2021-11-03 15:33:24',
    SerialNumber: '34692H8979',
    VoucherName: 'Physical Reward',
    FormDetail: 'radio-group-1635905820507 : m ',
    Address: 'dha lahore Pakistan',
    Address2: 'H2 Pindi',
    State: 'Punjab',
    PostCode: '74368',
  },
  {
    SubscriberName: 'Juniad',
    SubscriberEmail: 'junaid@gmail.com',
    Name: 'Asif',
    Email: 'asif23@gmail.com',
    MSISDN: '4563F55',
    RedeemDate: '2021-11-03 15:33:24',
    SerialNumber: '34692H8979',
    VoucherName: 'Physical Reward',
    FormDetail: 'radio-group-1635905820507 : m ',
    Address: 'dha lahore Pakistan',
    Address2: 'H2 Pindi',
    State: 'Punjab',
    PostCode: '74368',
  },
  {
    SubscriberName: 'Juniad',
    SubscriberEmail: 'junaid@gmail.com',
    Name: 'Asif',
    Email: 'asif23@gmail.com',
    MSISDN: '4563F55',
    RedeemDate: '2021-11-03 15:33:24',
    SerialNumber: '34692H8979',
    VoucherName: 'Physical Reward',
    FormDetail: 'radio-group-1635905820507 : m ',
    Address: 'dha lahore Pakistan',
    Address2: 'H2 Pindi',
    State: 'Punjab',
    PostCode: '74368',
  },
  {
    SubscriberName: 'Juniad',
    SubscriberEmail: 'junaid@gmail.com',
    Name: 'Asif',
    Email: 'asif23@gmail.com',
    MSISDN: '4563F55',
    RedeemDate: '2021-11-03 15:33:24',
    SerialNumber: '34692H8979',
    VoucherName: 'Physical Reward',
    FormDetail: 'radio-group-1635905820507 : m ',
    Address: 'dha lahore Pakistan',
    Address2: 'H2 Pindi',
    State: 'Punjab',
    PostCode: '74368',
  },
];
export const colPhysicalRedemptionReport = [
  'Subscriber Name',
  'Subscriber Email',
  'Name',
  'Email',
  'MSISDN',
  'Redeem Date',
  'Serial Number',
  'Voucher Name',
  'Form Detail',
  'Address',
  'Address2',
  'State',
  'Post Code',
];

// ---------------------------Refferal Program-----------
export const listReferralProgram = [
  {
    ReferralDate: '2019-08-25 23:36:45',
    Referrer: 'Junaid',
    MSISDN: '742533',
    Email: 'asad287@gmail.com',
    Referral: 'junaid',
    referralMSISDN: '742533',
    referralEmail: 'asad287@gmail.com',
  },
  {
    ReferralDate: '2019-08-25 23:36:45',
    Referrer: 'Junaid',
    MSISDN: '742533',
    Email: 'asad287@gmail.com',
    Referral: 'junaid',
    referralMSISDN: '742533',
    referralEmail: 'asad287@gmail.com',
  },
  {
    ReferralDate: '2019-08-25 23:36:45',
    Referrer: 'Junaid',
    MSISDN: '742533',
    Email: 'asad287@gmail.com',
    Referral: 'junaid',
    referralMSISDN: '742533',
    referralEmail: 'asad287@gmail.com',
  },
  {
    ReferralDate: '2019-08-25 23:36:45',
    Referrer: 'Junaid',
    MSISDN: '742533',
    Email: 'asad287@gmail.com',
    Referral: 'junaid',
    referralMSISDN: '742533',
    referralEmail: 'asad287@gmail.com',
  },
];
export const colReferralProgram = [
  'Referral Date',
  'Referrer',
  'MSISDN',
  'Email',
  'Referral',
  'MSISDN',
  'Email',
];

export const listluckdraw = [
  {
    Name: '100MB',
    MerchantName: 'testing merchant',
    MerchantId: '61',
    PerDayReward: '2',
    CompaignTotal: '20',
  },
  {
    Name: '250MB',
    MerchantName: 'testing',
    MerchantId: '62',
    PerDayReward: '1',
    CompaignTotal: '35',
  },
];
export const colNameslistluckdraw = [
  'Name',
  'Merchant Name',
  'MerchantId',
  'Per Day Reward',
  'Compaign Total',
  'Action',
];
// ----------------------Luckdrawuserreport---------------
export const listluckreport = [
  {
    RewardType: '',
    Compaign: 'Testing Compaign',
    MSISDN: '601122331231',
    CollectedAt: '2021-12-12',
  },
];
export const colNameslistluckreport = [
  'RewardType',
  'Compaign',
  'MSISDN',
  'CollectedAt',
];
// -----------------------Luck Draw Reward Report------------
export const listLuckyDrawRewardReport = [
  {
    ClaimedAt: '2022-12-12',
    Reward: '250MB',
    Compaign: 'Testing Compaign',
    TotalClaimed: '2',
    TotalRemaining: '33',
    TotalReward: '35',
  },
  {
    ClaimedAt: '2018-12-12',
    Reward: '350MB',
    Compaign: 'Testing Compaign',
    TotalClaimed: '7',
    TotalRemaining: '33',
    TotalReward: '42',
  },
  {
    ClaimedAt: '2020-12-12',
    Reward: '50MB',
    Compaign: 'Testing Compaign',
    TotalClaimed: '12',
    TotalRemaining: '38',
    TotalReward: '36',
  },
];
export const colLuckyDrawRewardReport = [
  'ClaimedAt',
  'Reward',
  'Compaign',
  'TotalClaimed',
  'TotalRemaining',
  'TotalReward',
];
// -----------------------Luck Draw Reward Report------------
export const listMotivationalQuotes = [
  {
    Name: '“Everything you can imagine is real.”― Pablo Picasso',
  },
  {
    Name: '“It’s hard to beat a person who never gives up.” – Babe Ruth',
  },
  {
    Name: '“The best time to plant a tree was 20 years ago. The second best time is now.” – Chinese Proverb',
  },
  {
    Name: '“The secret of getting ahead is getting started.” – Mark Twain',
  },
];
export const colMotivationalQuotes = ['Name', 'Action'];

// --------------------------------DynamicCampaignReport---------------------------------
export const listDynamicCampaignReport = [
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Testing Compaign',
    Status: 'Approved',
    RewardAction: '0',
  },
];
export const colDynamicCampaignReport = [
  'Start Date',
  'End Date',
  'Title',
  'Status',
  'Reward Count',
  'Actions',
];

// --------------------------------CNYCampaignManagement---------------------------------
export const listCNYCampaignManagement = [
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'Testing Compaign',
    Status: 'Approved',
  },
  {
    StartDate: '2019-08-18',
    EndDate: '2020-12-12',
    Name: 'Testing Compaign',
    Status: 'Approved',
  },
  {
    StartDate: '2020-12-12',
    EndDate: '2022-2-12',
    Name: 'Testing Compaign',
    Status: 'Approved',
  },
];
export const colCNYCampaignManagement = [
  'Start Date',
  'End Date',
  'Name',
  'Status',
  'Action',
];
// -------------------------------- BannerManagement---------------------------------
export const listBannerManagement = [
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'Banner for Compaign 3',
    BannerType: 'Compaign',
    Status: 'Approved',
  },
  {
    StartDate: '2019-08-18',
    EndDate: '2020-12-12',
    Name: 'Asif Raza',
    BannerType: 'Banner',
    Status: 'Approved',
  },
  {
    StartDate: '2020-12-12',
    EndDate: '2022-2-12',
    Name: 'Testing Banner',
    BannerType: 'Banner',
    Status: 'Approved',
  },
];
export const colBannerManagement = [
  'Start Date',
  'End Date',
  'Name',
  'Banner Type',
  'Status',
  'Action',
];
// -------------------------------- BannerManagement---------------------------------
export const listDynamicCampaign = [
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'saSa',
    RewardType: 'Voucher',
    Status: 'Approved',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'New Dynamic Compaign',
    RewardType: '',
    Status: 'Approved',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'Reward Compaign to b',
    RewardType: '',
    Status: 'Approved',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Title: 'saSa',
    RewardType: 'Form',
    Status: 'Approved',
  },
];
export const colDynamicCampaign = [
  'Start Date',
  'End Date',
  'Title',
  'Reward Type',
  'Status',
  'Action',
];
// -------------------------------- FaqManagement---------------------------------
export const listFaqManagement = [
  {
    Question: '-----------------No data Available in the table----------------',
    Reorder: '',
  },
];
export const colFaqManagement = ['Question', 'Reorder', 'Action'];
// -------------------------------- GameManagement---------------------------------
export const listGameManagement = [
  {
    GameId: '1',
    Name: 'Game 1',
    AppleId: 'Apple-1',
    GooglePlayId: 'google-1',
    GameURL: '',
  },
  {
    GameId: '2',
    Name: 'Game 2',
    AppleId: 'Apple-2',
    GooglePlayId: '',
    GameURL: 'https://game2.com',
  },
  {
    GameId: '3',
    Name: 'Game 2',
    AppleId: '',
    GooglePlayId: '',
    GameURL: 'http://13.228.129.207:3556',
  },
  {
    GameId: '4',
    Name: 'Flips',
    AppleId: 'Apple-1',
    GooglePlayId: '',
    GameURL: 'http://openhtml5game',
  },
  {
    GameId: '5',
    Name: 'Jen Game1',
    AppleId: '',
    GooglePlayId: 'sunnybebo13@gmail.com',
    GameURL: '',
  },
];
export const colGameManagement = [
  'Game Id',
  'Name',
  'Apple Id',
  'Google Play Id',
  'Game URl',
  'Action',
];

// -------------------------------- Ipay88Credentials---------------------------------
export const listIpayCredentials = [
  {
    Name: 'Asad',
    URL: (
      <Link href="www.google.com" isExternal>
        www.google.com
      </Link>
    ),
    MarchantCode: '76378HJ45',
    MarchantKey: '49827GF',
  },
  {
    Name: 'Asad',
    URL: (
      <Link href="www.google.com" isExternal>
        www.google.com
      </Link>
    ),
    MarchantCode: '76378HJ45',
    MarchantKey: '49827GF',
  },
  {
    Name: 'Asad',
    URL: (
      <Link href="www.google.com" isExternal>
        www.google.com
      </Link>
    ),
    MarchantCode: '76378HJ45',
    MarchantKey: '49827GF',
  },
];
export const colIpayCredentials = [
  'Name',
  'URL',
  'Marchant Code',
  'Marchant Key',
  'Action',
];

// -------------------------------- OauthXoX---------------------------------
export const listOauthXoX = [
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
    Redirect: (
      <Link href="www.google.com" isExternal>
        www.google.com
      </Link>
    ),
  },
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
    Redirect: (
      <Link href="www.google.com" isExternal>
        www.google.com
      </Link>
    ),
  },
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
    Redirect: (
      <Link href="www.google.com" isExternal>
        www.google.com
      </Link>
    ),
  },
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
    Redirect: (
      <Link href="www.google.com" isExternal>
        www.google.com
      </Link>
    ),
  },
];
export const colOauthXoX = [
  'Name',
  'Client ID',
  'Client Secret',
  'Redirect',
  'Action',
];

// -------------------------------- MiddlewareAuthentications---------------------------------
export const listMiddlewareAuthentications = [
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
  },
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
  },
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
  },
  {
    Name: 'Asad',
    ClientID: '493GH56',
    ClientSecret: '54674HJS',
  },
];
export const colMiddlewareAuthentications = [
  'Name',
  'Client ID',
  'Client Secret',
  'Actions',
];

// -------------------------------- Permissions---------------------------------
export const listPermissions = [
  {
    Name: 'Asad',
  },
  {
    Name: 'Asad',
  },
  {
    Name: 'Asad',
  },
];
export const colBannerTypes = ['Name', 'Action'];
export const colPermissions = ['Name', 'Suspend Role', 'Action', 'Update Role'];
export const colMerchantCode = [
  'GatewayName',
  'MerchantName',
  'MerchantCode',
  'MerchantKey',
  // 'ActionId',
  'Action',
  // 'ActionKey',
];
export const colActions = [
  'GatewayName',
  'MerchantName',
  'MerchantCode',
  'MerchantKey',
  'ActionId',
  'ActionKey',
];
export const colMerchantCodeNPG = [
  'GatewayName',
  'ActionName',
  'MerchantCode',
  'PrivateKeyLink',
  'PublicKeyLink',
  'Action',
  // 'ActionKey',
];
export const coSuspendedRoles = ['Name', 'Suspend'];
export const colSuspendUser = ['Email', 'Action'];
export const colDeletedUser = ['Email', 'MSISDN', 'Action'];

// -------------------------------- FAQCategories---------------------------------
export const listFAQCategories = [
  {
    Name: 'Asad',
  },
  {
    Name: 'Asad',
  },
  {
    Name: 'Asad',
  },
];
export const colFAQCategories = ['Name', 'Action'];

// -------------------------------- Rewards---------------------------------
export const listRewards = [
  {
    Name: '10 MB Season Pass Data',
    RewardType: 'Lucky Draw',
    RewardItem: '10 MB',
  },
  {
    Name: '20 MB Season Pass Data',
    RewardType: 'Lucky Draw',
    RewardItem: '20 MB',
  },
  {
    Name: '30 MB Season Pass Data',
    RewardType: 'Lucky Draw',
    RewardItem: '40 MB',
  },
  {
    Name: 'Reward1',
    RewardType: 'Competition',
    RewardItem: '30 MB',
  },
  {
    Name: 'Reward2',
    RewardType: 'Min Store',
    RewardItem: '50 MB',
  },
];
export const colRewards = ['Name', 'RewardType', 'RewardItem', 'Action'];

// -------------------------------- Admins---------------------------------
export const listAdmins = [
  {
    Name: '10 MB Season Pass Data',
    RewardType: 'Lucky Draw',
    RewardItem: '10 MB',
  },
  {
    Name: '20 MB Season Pass Data',
    RewardType: 'Lucky Draw',
    RewardItem: '20 MB',
  },
  {
    Name: '30 MB Season Pass Data',
    RewardType: 'Lucky Draw',
    RewardItem: '40 MB',
  },
  {
    Name: 'Reward1',
    RewardType: 'Competition',
    RewardItem: '30 MB',
  },
  {
    Name: 'Reward2',
    RewardType: 'Min Store',
    RewardItem: '50 MB',
  },
];
export const colAdmins = ['Role', 'Name', 'Email', 'Action'];

// -------------------------------- CampaignGameReward---------------------------------
export const listCampaignGameReward = [
  {
    Campaign: 'Campaign2',
    Game: 'Flips',
    Reward: '10 MB',
  },
  {
    Campaign: 'Campaign2',
    Game: 'Flips',
    Reward: '10 MB',
  },
  {
    Campaign: 'Campaign2',
    Game: 'Flips',
    Reward: '10 MB',
  },
  {
    Campaign: 'Campaign2',
    Game: 'Flips',
    Reward: '10 MB',
  },
];
export const colCampaignGameReward = ['Campaign', 'Game', 'Reward', 'Action'];

// -------------------------------- GameSettings---------------------------------
export const listGameSettings = [
  {
    GameID: '4885Gk9',
    Game: 'Flips',
    TotalNumberofSettings: '10',
  },
  {
    GameID: '4885Gk9',
    Game: 'Flips',
    TotalNumberofSettings: '10',
  },
  {
    GameID: '4885Gk9',
    Game: 'Flips',
    TotalNumberofSettings: '10',
  },
];
export const colGameSettings = [
  'Game ID',
  'Game',
  'Total Number of Settings',
  'Action',
];

// -------------------------------- HappyHourBackgrounds---------------------------------
export const listHappyHourBackgrounds = [
  {
    startDate: '05-05-2019 22:35:17',
    endDate: '05-05-2022 22:35:17',
    name: 'Good Name',
    image: (
      <Image
        boxSize="100px"
        objectFit="cover"
        src="https://bit.ly/dan-abramov"
        alt="Dan Abramov"
      />
    ),
  },
  {
    startDate: '05-05-2019 22:35:17',
    endDate: '05-05-2022 22:35:17',
    name: 'Good Name',
    image: (
      <Image
        boxSize="100px"
        objectFit="cover"
        src="https://bit.ly/dan-abramov"
        alt="Dan Abramov"
      />
    ),
  },
  {
    startDate: '05-05-2019 22:35:17',
    endDate: '05-05-2022 22:35:17',
    name: 'Good Name',
    image: (
      <Image
        boxSize="100px"
        objectFit="cover"
        src="https://bit.ly/dan-abramov"
        alt="Dan Abramov"
      />
    ),
  },
];
export const colHappyHourBackgrounds = [
  'Start Date',
  'End Date',
  'Name',
  'Image',
  'Actions',
];

// -------------------------------- Maintenance---------------------------------
export const listMaintenance = [
  {
    Platforms: 'Black App',
    startDate: '05-05-2019 22:35:17',
    endDate: '05-05-2022 22:35:17',
  },
  {
    Platforms: 'Black App',
    startDate: '05-05-2019 22:35:17',
    endDate: '05-05-2022 22:35:17',
  },
  {
    Platforms: 'Black App',
    startDate: '05-05-2019 22:35:17',
    endDate: '05-05-2022 22:35:17',
  },
  {
    Platforms: 'Black App',
    startDate: '05-05-2019 22:35:17',
    endDate: '05-05-2022 22:35:17',
  },
];
export const colMaintenance = [
  'Platforms',
  'Start Date',
  'End Date',
  'Actions',
];

// -------------------------------- ProductCategories---------------------------------
export const listProductCategories = [
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
];
export const colProductCategories = ['Name', 'Actions'];

// -------------------------------- PromotionsNewsCategories---------------------------------
export const listPromotionsNewsCategories = [
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
];
export const colPromotionsNewsCategories = ['Name', 'Actions'];

// -------------------------------- PromotionsNewsCategories---------------------------------
export const listSMSMasterLibraryCategory = [
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
];
export const colSMSMasterLibraryCategory = ['Name', 'Actions'];

// -------------------------------- StatusForBanners---------------------------------
export const listStatusForBanners = [
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
];
export const colStatusForBanners = ['Name', 'Actions'];

// -------------------------------- StatusPromotionsNews---------------------------------
export const listStatusPromotionsNews = [
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
  {
    Name: 'Mobile Internet',
  },
];
export const colStatusPromotionsNews = ['Name', 'Actions'];

// -------------------------------- States---------------------------------
export const listStates = [
  {
    Country: 'Pakistan',
    Name: 'Punjab',
  },
  {
    Country: 'Pakistan',
    Name: 'Punjab',
  },
  {
    Country: 'Pakistan',
    Name: 'Punjab',
  },
];
export const colStates = ['Country', 'Name', 'Actions'];

// -------------------------------- PrivacyPolicyCategories---------------------------------
export const listPrivacyPolicyCategories = [
  {
    Name: 'Asad',
  },
  {
    Name: 'Asad',
  },
  {
    Name: 'Asad',
  },
];
export const colPrivacyPolicyCategories = ['Name', 'Actions'];

// -------------------------------- TermConditionCategories---------------------------------
export const listTermConditionCategories = [
  {
    Name: 'About This App',
  },
  {
    Name: 'Chatbot',
  },
  {
    Name: 'Chatbot',
  },
];
export const colTermConditionCategories = ['Name', 'Actions'];

// -------------------------------- Cities---------------------------------
export const listCities = [
  {
    Country: 'Pakistan',
    State: 'Punjab',
    Name: 'Lahore',
  },
  {
    Country: 'Pakistan',
    State: 'Punjab',
    Name: 'Lahore',
  },
  {
    Country: 'Pakistan',
    State: 'Punjab',
    Name: 'Lahore',
  },
  {
    Country: 'Pakistan',
    State: 'Punjab',
    Name: 'Lahore',
  },
];
export const colCities = ['Country', 'State', 'Name', 'Action'];

// -------------------------------- Countries---------------------------------
export const listCountries = [
  {
    ISOCode: 'AD',
    Name: 'Pakistan',
  },
  {
    ISOCode: 'AD',
    Name: 'Pakistan',
  },
  {
    ISOCode: 'AD',
    Name: 'Pakistan',
  },
  {
    ISOCode: 'AD',
    Name: 'Pakistan',
  },
];
export const colCountries = ['ISO Code', 'Name', 'Action'];

// -------------------------------- Rewards---------------------------------
export const listRewardItems = [
  {
    Name: '10 MB',
    RewardItemType: 'Data',
    RewardItemValue: '10',
  },
  {
    Name: '20 MB',
    RewardItemType: 'Data',
    RewardItemValue: '20',
  },
  {
    Name: '30 MB',
    RewardItemType: 'Data',
    RewardItemValue: '30',
  },
];
export const colRewardItems = [
  'Name',
  'RewardItemType',
  'RewardItemValue',
  'Action',
];
// -------------------------------- Rewards---------------------------------
export const listRewardItemType = [
  {
    Name: 'Data',
  },
  {
    Name: 'SMS',
  },
  {
    Name: 'Voice',
  },
];
export const colRewardItemType = ['Name', 'Action'];
// -------------------------------- Rewards---------------------------------
export const listRewardTypes = [
  {
    Name: 'Competition',
  },
  {
    Name: 'Lucky Draw',
  },
  {
    Name: 'Min Score',
  },
  {
    Name: 'Min Score for lucky draw',
  },
];
export const colRewardTypes = ['Name', 'Action'];
// -------------------------------- InboxMessage---------------------------------
export const listInboxMessage = [
  {
    Title: 'test-title-donnald',
    Message_EN: 'test-message-donnald',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
  {
    Title: 'test-title',
    Message_EN: 'test-message',
    Status: 'Sent',
  },
];
export const colInboxMessage = ['Title', 'Message EN', 'Status', 'Action'];

// ]
// export const colInboxMessage = [
//   'Title',
//   'Message EN',
//   'Status',
//   "Action",
// ]
// -------------------------------- AdxRewardManagement---------------------------------
export const listAdxRewardManagement = [
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'BAnner For Campaign 3',
    BannerType: 'Compaign',
    Status: 'Suspended',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'asif raza',
    BannerType: 'Banner',
    Status: 'Pending Reviewed',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'test Banner',
    BannerType: 'Compaign',
    Status: 'Suspended',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'sunny',
    BannerType: 'Banner',
    Status: 'Suspended',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'Welcome to Xox',
    BannerType: 'Compaign',
    Status: 'Suspended',
  },
];
export const colAdxRewardManagement = [
  'Start Date',
  'End Date',
  'Name',
  'BannerType',
  'Status',
  'Action',
];
// -------------------------------- Mobilebanner---------------------------------
export const listMobilebanner = [
  {
    StartDate: '',
    EndDate: '',
    Name: 'BAnner For Campaign 3',
    Status: 'Active',
  },
  {
    StartDate: '',
    EndDate: '',
    Name: 'asif raza',
    Status: 'Active',
  },
  {
    StartDate: '',
    EndDate: '',
    Name: 'test Banner sunny',
    Status: 'Active',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: '	Beli SUZZ10 daripada pengedar ONEXOX sekarang!',
    Status: 'Active',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2020-12-12',
    Name: 'Extra Test banner',
    Status: 'Active',
  },
];
export const colMobilebanner = [
  'Start Date',
  'End Date',
  'Name',
  'Status',
  'Action',
];
// --------------------------------One Time Deal mangement---------------------------------
export const listSpecialDealsList = [
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    Name: 'Want to share data',
    TargetUser: 'All user',
    Status: 'Active',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    Name: 'One Time deal DOne',
    TargetUser: 'All user',
    Status: 'Active',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    Name: 'One time Deal One Two Three',
    TargetUser: 'All user',
    Status: 'Active',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    Name: 'One TIme Deal Dual Login',
    TargetUser: 'All user',
    Status: 'Active',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    Name: 'Black Friday Deals',
    TargetUser: 'All user',
    Status: 'Active',
  },
];
export const colSpecialDealsList = [
  'Start Date',
  'End Date',
  'Name',
  'Target User',
  'Status',
  'Action',
];
// --------------------------------OtpLogs---------------------------------
export const listOtpLogs = [
  {
    Name: 'Xxxx Lam',
    MSISDN: '6019755287',
    TacCode: '455397',
    Description: 'xoxmktplace1026234159488317',
    CreatedDate: '2018-12-12',
    Status: '1',
  },
  {
    Name: 'Xxxx Lam',
    MSISDN: '601975667',
    TacCode: '455667',
    Description: 'xoxmktplace1026234159488317',
    CreatedDate: '2018-12-12',
    Status: '1',
  },
  {
    Name: 'Xxxx Lam',
    MSISDN: '6019755678',
    TacCode: '4550011',
    Description: 'xoxmktplace1026234159488317',
    CreatedDate: '2018-12-12',
    Status: '1',
  },
  {
    Name: 'Xxxx Lam',
    MSISDN: '6019754578',
    TacCode: '4553456',
    Description: 'xoxmktplace1026234159488317',
    CreatedDate: '2018-12-12',
    Status: '1',
  },
  {
    Name: 'Xxxx Lam',
    MSISDN: '6019755287',
    TacCode: '455397',
    Description: 'xoxmktplace1026234159488317',
    CreatedDate: '2018-12-12',
    Status: '1',
  },
  {
    Name: 'Xxxx Lam',
    MSISDN: '6019755287',
    TacCode: '455397',
    Description: 'xoxmktplace1026234159488317',
    CreatedDate: '2018-12-12',
    Status: '1',
  },
  {
    Name: 'Xxxx Lam',
    MSISDN: '6019755287',
    TacCode: '455397',
    Description: 'xoxmktplace1026234159488317',
    CreatedDate: '2018-12-12',
    Status: '1',
  },
];
export const colOtpLogs = [
  'Name',
  'MSISDN',
  'TAC Code',
  'Description',
  'Created Date',
  'Status',
  'Action',
];
// -------------------------------- PrivacyPolicyList---------------------------------
export const listPrivacyPolicyList = [
  {
    Category: '',
    Name: '-----------------No data Available in the table----------------',
    Reorder: '',
  },
];
export const colPrivacyPolicyList = ['Category', 'Name', 'Reorder', 'Action'];
export const colBlackAppPage = [
  'Name',
  'Category',
  'Description',
  'Icon',
  'Reorder',
  'Is Suggested',
  'Action',
];
export const colRecommendation = [
  'displayPeriodStart',
  'displayPeriodEnd',
  'tileName',
  'Description',
  'Image',
  'Amount',
  'Status',
  'Action',
];
// -------------------------------- ProductManagement---------------------------------
export const listProductManagement = [
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    XoxProductId: 'PADD-02',
    Name: 'Product Addon 2',
    value: '10',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    XoxProductId: 'PADD-03',
    Name: 'Product Addon 3',
    value: '10',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    XoxProductId: 'XOX-New',
    Name: 'testing new',
    value: '18',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    XoxProductId: 'PADD-01',
    Name: 'Product Addon 1',
    value: '40',
  },
  {
    StartDate: '2018-12-12',
    EndDate: '2019-12-12',
    XoxProductId: 'XOX-TEST',
    Name: 'XOX-TEST',
    value: '1',
  },
];
export const colProductManagement = [
  'Start Date',
  'End Date',
  'XoxProductId',
  'Name',
  'Value(RM)',
  'Action',
];
// -------------------------------- PromoCodeManagement---------------------------------
export const listPromoCodeManagement = [
  {
    StartDate: '2018-12-12 08:00:00',
    EndDate: '2019-12-12',
    CouponCode: 'Coupon-1',
    Quota: '50',
    NumberOfUsed: '0',
  },
  {
    StartDate: '2018-12-12 08:00:00',
    EndDate: '2019-12-12',
    CouponCode: 'Coupon-2',
    Quota: '1010',
    NumberOfUsed: '0',
  },
];
export const colPromoCodeManagement = [
  'Start Date',
  'End Date',
  'CouponCode',
  'Quota',
  'Number Of Used',
  'Action',
];
// --------------------------------PromotionAndNewsManagement---------------------------------
export const listPromotionAndNewsManagement = [
  {
    StartDate: '2018-12-12 08:00:00',
    EndDate: '2019-12-12',
    Title: 'Get 500MB FREE with Referral Rewards!',
    PromotionAndNewsCategory: 'Promotions',
    Status: 'Active',
  },
  {
    StartDate: '2018-12-12 08:00:00',
    EndDate: '2019-12-12',
    Title: 'Happy Hour Podium Special.',
    PromotionAndNewsCategory: 'Promotions',
    Status: 'InActive',
  },
];
export const colPromotionAndNewsManagement = [
  'Start Date',
  'End Date',
  'Title',
  'Promotion & News Category',
  'Status',
  'Action',
];
// --------------------------------PhysicalRedemptionFormDetail---------------------------------
export const listPhysicalRedemptionFormDetail = [
  {
    VoucherId: '106',
    Status: 'Active',
  },
];
export const colPhysicalRedemptionFormDetail = [
  'Voucher Id',
  'Status',
  'Action',
];
// --------------------------------StoreManagement---------------------------------
export const listStoreManagement = [
  {
    StoreType: 'AD/MD',
    BranchName: '	ONEXS002',
    ContactNo: '010-6022620',
  },
  {
    StoreType: 'SELFCARE',
    BranchName: 'SELANGOR (HQ PETALING JAYA)',
    ContactNo: '	03-7884 2388',
  },
  {
    StoreType: 'SELFCARE',
    BranchName: 'SELANGOR (SUNWAY PYRAMID)',
    ContactNo: '010-6022620',
  },

  {
    StoreType: 'SELFCARE',
    BranchName: 'SELANGOR (TAYLOR’S LAKESIDE, SUBANG JAYA)',
    ContactNo: '03 5885 9123',
  },
  {
    StoreType: 'SELFCARE',
    BranchName: 'WP KUALA LUMPUR (SETIAWANGSA)',
    ContactNo: '	011-1373 1117',
  },
  {
    StoreType: 'SELFCARE',
    BranchName: '	PERAK (SETIAWAN)',
    ContactNo: '010-6022620',
  },
  {
    StoreType: 'SELFCARE',
    BranchName: 'NEGERI SEMBILAN (SEREMBAN)',
    ContactNo: '	06-601 4516',
  },
];
export const colStoreManagement = [
  'Store Type',
  'Branch No',
  'Contact No',
  'Action',
];
export const colPages = [
  'pageId',
  'pageName',
  'createdAt',
  'updatedAt',
  'Action',
];
export const colMerchantActions = [
  'Created At',
  'Action Name',
  'Gateway Name',
  'Merchant Code',
  'Merchant Key',
];
// -------------------------------SmsMasterlibrary---------------------------------
export const listSmsMasterlibrary = [
  {
    SmsCategory: 'AC Status',
    SmsID: '	ONEXS002',
    Content:
      '	Your minutes is running low. Want more? Get 999 minutes that you can carry forward & share with other XOX users for only RM90 via the BLACK app.',
  },
  {
    SmsCategory: 'AC Status',
    SmsID: '	ONEXS002',
    Content:
      'We are sorry to inform that your Data Buffet does not support this feature. Please call 12273 for further assistance. Thank you.',
  },
  {
    SmsCategory: 'AC Status',
    SmsID: '	ONEXS002',
    Content:
      'Oops! You have reach limit of Data Buffet. Select Add On Data 5GB@RM40 or get SP Purchase in your Black App to continue surfing until next renewal.',
  },
  {
    SmsCategory: 'AC Status',
    SmsID: '	ONEXS002',
    Content:
      '	Good news! We have top up additional 20GB into your account. This is valid for Data Buffet only. #BURNPROOF',
  },
  {
    SmsCategory: 'AC Status',
    SmsID: '	ONEXS002',
    Content:
      'Your SMS is running low. Get 1200 SMS that you can carry forward & share with other XOX users for only RM100 via the app.',
  },
];
export const colSmsMasterlibrary = [
  'SMS Category',
  'SMS ID',
  'Content',
  'Action',
];

// -------------------------------SoftpinCategories---------------------------------
export const listSoftpinCategories = [
  {
    Name: 'RM 10',
    Price: '10.00',
    Amount: '10.00',
  },
  {
    Name: 'RM 100',
    Price: '100.00',
    Amount: '100.00',
  },
  {
    Name: 'RM 30',
    Price: '50.00',
    Amount: '50.00',
  },
  {
    Name: 'RM 50',
    Price: '50.00',
    Amount: '50.00',
  },
];
export const colSoftpinCategories = ['Name', 'Price', 'Amount', 'Action'];
// -------------------------------Softpins---------------------------------
export const listSoftpins = [
  {
    SoftoinCategory: 'RM 10',
    PinNumber: '10.00',
    Price: '100.00',
    Amount: '100.00',
    ExpiryDate: '10.00',
  },
  {
    SoftoinCategory: 'RM 100',
    PinNumber: '	3636939437926046',
    Price: '50.00',
    Amount: '50.00',
    ExpiryDate: '	2021-01-01',
  },
  {
    SoftoinCategory: 'RM 50',
    PinNumber: '39437926046tt',
    Price: '50.00',
    Amount: '50.00',
    ExpiryDate: '2021-01-01',
  },
  {
    SoftoinCategory: 'RM 10',
    PinNumber: '10.00',
    Price: '100.00',
    Amount: '100.00',
    ExpiryDate: '2021-01-01',
  },
  {
    SoftoinCategory: 'RM 10',
    PinNumber: '6474859609',
    Price: '50.00',
    Amount: '50.00',
    ExpiryDate: '	2021-01-01',
  },
];
export const colSoftpins = [
  'SoftpinCategory',
  'PinNumber',
  'Price',
  'Amount',
  'Expiry Date',
  'Action',
];
// -------------------------------Softpins---------------------------------
export const listSoftpinimport = [
  {
    UploadedAt: '2022-03-09 18:41:46',
    FileNameOriginal:
      'SP- Test - Copy.xlsx / b8d681ee542a59e13cc89a287e3fca59b2cadb22.xlsx',
    status: 'Importing',
  },
  {
    UploadedAt: '2022-03-09 18:41:46',
    FileNameOriginal:
      'SP- Test - Copy.xlsx / b8d681ee542a59e13cc89a287e3fca59b2cadb22.xlsx',
    status: 'Importing',
  },
  {
    UploadedAt: '2022-03-09 18:41:46',
    FileNameOriginal:
      'SP- Test - Copy.xlsx / b8d681ee542a59e13cc89a287e3fca59b2cadb22.xlsx',
    status: 'Proceed',
  },
];
export const colSoftpinimport = [
  'Uplaoded At',
  'File Name Original',
  'Status',
  'Action',
];
// -------------------------------Softpins---------------------------------
export const listSoftpinStock = [
  {
    SoftpinCategory: 'RM10',
    Qty: '1',
    MinimumQty: '500',
  },
  {
    SoftpinCategory: 'RM100',
    MinimumQty: '500',
    Qty: '1',
  },
  {
    SoftpinCategory: 'RM30',
    Qty: '1',
    MinimumQty: '500',
  },
  {
    SoftpinCategory: 'RM50',
    Qty: '4936',
    MinimumQty: '500',
  },
];
export const colSoftpinStock = ['SoftpinCategory', 'Qty', 'Minimum Qty'];
// -------------------------------TermConditionManagement---------------------------------
export const listTermConditionManagement = [
  {
    Category: 'Chatbot',
    Name: 'Chatbot',
    Reorder: 'Arrows There',
  },
];
export const colTermConditionManagement = [
  'Category',
  'Name',
  'Reorder',
  'Action',
];
// -------------------------------UpsellCompaigns---------------------------------
export const listUpsellCompaigns = [
  {
    StartDate: '20-11-2021 01:38:19',
    EndDate: '20-11-2021 01:38:19',
    Title: 'test Up',
    Amount: '0.50',
    Status: 'Active',
  },
  {
    StartDate: '20-11-2021 01:38:19',
    EndDate: '20-11-2021 01:38:19',
    Title: 'Want to join our lucky draw?',
    Amount: '100.00',
    Status: 'InActive',
  },
  {
    StartDate: '20-11-2021 01:38:19',
    EndDate: '20-11-2021 01:38:19',
    Title: '	Lucky Draw iPhone',
    Amount: '30.00',
    Status: 'InActive',
  },
];
export const colUpsellCompaigns = [
  'Start Date',
  'End date',
  'Title',
  'Amount',
  'Status',
  'Action',
];
export const colNotificationCompaign = ['ID', 'Title', 'Message', 'Total'];
// -------------------------------listLuckyDrawUpsell---------------------------------
export const listLuckyDrawUpsell = [
  {
    Category: 'Chatbot',
    Name: 'Chatbot',
    Reorder: 'Arrows There',
  },
];
export const colLuckyDrawUpsell = ['Category', 'Name', 'Reorder', 'Action'];
export const Roles = ['1', '2', '3'];

export const temp = [0, 1, 2, 3, 4];
export const DATABUTTON = [
  {
    _uid: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28b1',
    title: 'XOX Mobile',
    route: 'SuperAppDashBoardScreen',
  },
  {
    _uid: 'avbhfbvs-c1b1-46c2-3ad53abb28b1-aed5',
    title: 'Non XOX Mobile',
    route: 'SuperAppStackxoxMobile',
  },
  {
    _uid: '3ac68afc-c605-48d3-a4f8-fbd91aa97f62',
    title: 'Black Market',
    route: 'SuperAppBlackMarket',
  },
  {
    _uid: '58694a0f-3da1-471f-bd96-145571e29d73',
    title: 'EPP',
    route: 'SuperAppEppMarket',
  },
  {
    _uid: '58694a0f-3da1-471f-bd96-145571e29d74',
    title: 'ADX',
    route: 'AdxHome',
  },
  {
    _uid: '58694a0f-3da1-471f-bd96-145571e29d75',
    title: 'More',
  },
];
export const DATABUTTONS = [
  {
    val: 'SuperAppDashBoardScreen',
    list: 'XOX Mobile',
  },
  {
    val: 'SuperAppStackxoxMobile',
    list: 'Non XOX Mobile',
  },
  {
    val: 'SuperAppBlackMarket',
    list: 'Black Market',
  },
  {
    val: 'SuperAppEppMarket',
    list: 'EPP',
  },
  {
    val: 'AdxHome',
    list: 'ADX',
  },
  // {
  //   val: '58694a0f-3da1-471f-bd96-145571e29d75',
  //   list: 'More',
  // },
];
export const NotificationType = [
  {
    list: 'General',
    val: 0,
  },
  {
    list: 'Promotion',
    val: 1,
  },
  {
    list: 'News',
    val: 2,
  },
  {
    list: 'Actionable',
    val: 3,
  },
];

export const Productlist = [
  {
    list: 'XOX Mobile',
    val: 'SuperAppDashBoardScreen',
  },
  {
    list: 'Non XOX Mobile',
    val: 'SuperAppStackxoxMobile',
  },
  {
    list: 'Black Market',
    val: 'SuperAppBlackMarket',
  },
  {
    list: 'EPP',
    val: 'SuperAppEppMarket',
  },
];
export const isMobileList = [
  {
    val: 'true',
    list: 'Yes',
  },
  {
    val: 'false',
    list: 'No',
  },
];
export const OsType = ['IOS', 'Android OS', 'Huawei'];
export const subNonsub = ['XOX Subscriber', 'Non-XOX Subscriber'];
export const PromotionAndNewsCategoryList = ['Promotion', 'News'];
export const PromotionAndNewsStatus = ['Active', 'Inactive', 'Draft'];
export const UserData = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
];

export const doughnutData = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
