import React from 'react';
import {
  Text,
  Button,
  Flex,
  Image,
  useToast,
  Td,
  Tr,
  Spacer,
  Input,
  Select,
  Circle,
  Tooltip,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { colDynamicCampaign } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import LangContext from '../context/languageContext';
import ApiManager from '../config/apiManager';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { options } from '../context/data';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import config from '../config/config';
import { deleteImage } from '../config/imageUploader';
import RewardTypes from './GameRewardManagement/RewardTypes';

const DynamicCampaign = () => {
  let history = useHistory();
  const [dataa, setDataa] = React.useState([]);
  const [error, setError] = React.useState(null);
  const { currentLangData } = React.useContext(LangContext);
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  let apimanager = ApiManager.getInstance();
  const toast = useToast();
  // Creating Variable //
  let tempArray = [];
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    setValue(ev);
  };
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  // const ArrowUp = ID => {
  //   let index = dataa.findIndex(e => e._id === ID); //find id from Api
  //   // console.log("Index",index,"id",ID)
  //   if (index > 0) {
  //     let el = dataa[index];
  //     dataa[index] = dataa[index - 1];
  //     dataa[index - 1] = el;
  //     // console.log("EL>>",el,"Up>>>",dataa[index])
  //     setDataa([...dataa]);
  //   }
  // };

  // const ArrowDown = ID => {
  //   let index = dataa.findIndex(e => e._id === ID); //find id from Api
  //   // console.log("Index",index,"id",ID)
  //   if (index >= 0) {
  //     let el = dataa[index];
  //     dataa[index] = dataa[index + 1];
  //     dataa[index + 1] = el;
  //     // console.log("EL>>",el,"Up>>>",dataa[index])
  //     setDataa([...dataa]);
  //   }
  // };
  // const deleteImagefile = e => {
  //   // setShow(true);
  //   console.log('e.target=>', e);
  //   deleteImage(e)
  //     .then(res => {
  //       console.log('image uri', res);
  //       // setS3Image(e.target.files[0].name);
  //       // setShow(false);
  //     })
  //     .catch(error => {
  //       console.log('error', error);
  //     });
  // };

  const getALLItems = (limit, page) => {
    setLoader(false);

    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAllCampaigns', body)
      .then(res => {
        console.log('getAllCompaigns', res);
        if (res.message == 8627) {
          res?.results?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.results);
          setLoader(true);
        } else if (res.message === 8104) {
          setLoader(false);
          setError('Failed');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Number Records is Not Proper`,
          });
        } else {
          setLoader(false);
          setError('Failed');
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `failed to fetch all campaigns`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    console.log('Deletion done', item.id);
    let apimanager = ApiManager.getInstance();
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('deleteCampaign', body)
      .then(response => {
        console.log('delete Compaign', response);
        if (response.message === 8629) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems(value, 1);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const RewardType = reward => {
    if (reward == 0) return <p>Voucher</p>;
    else if (reward == 1) return <p>Non-Voucher</p>;
    else return <p>Form</p>;
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Campaigns List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Compaign Management',
          useSlash: true,
          path2: 'Compaign',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/create/campaign')}
            >
              Create
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}>
              {/* <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text> */}
              {/* <Input {...style.searchinput}></Input> */}
            </Flex>
          </Flex>

          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colDynamicCampaign}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              let startdate = new Date(item?.startDate * 1000);
              let enddate = new Date(item?.endDate * 1000);

              return (
                <Tr key={index} width={'100%'}>
                  <Td>{startdate.toLocaleDateString()}</Td>
                  <Td>{enddate.toLocaleDateString()}</Td>
                  <Td>{item?.title}</Td>

                  <Td>{RewardType(item?.rewardType)}</Td>
                  {item?.status === 0 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="black"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.inactive}
                        </Text>
                      </Circle>
                    </Td>
                  ) : item?.status === 1 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'12px'}>
                          {currentLangData?.app?.active}
                        </Text>
                      </Circle>
                    </Td>
                  ) : null}
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Tooltip label={currentLangData?.app?.edit}>
                      <Button
                        {...style.editbtn}
                        leftIcon={<EditIcon />}
                        onClick={() =>
                          history.push({
                            pathname: '/create/campaign',
                            status: 'create',
                            state: { val: item },
                          })
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip label={currentLangData?.app?.delete} bg={'red'}>
                      <Button
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      ></Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default DynamicCampaign;
