import React, { useState } from 'react';
import { Flex, Box, Button, Divider, Text, SimpleGrid } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../../styles/styles.css';
import theme from '../../../config/color';
import DateWithTitle from '../../../components/DateComponent/DateWithTitle';
import style from './../styles';

import { lineOptions, lineData } from './ChartData';
import DropDownWithTitle from '../../../components/DateComponent/DropDownWithTitle';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { customUpdateCancelBtn } from '../../../components/createAppVersionComponent/createAppVersionComponent';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const GraphTab = () => {
  const [startDate, setStartDate] = useState(new Date());

  const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 50);
  };

  return (
    <Flex
      w={'100%'}
      flexDirection={'column'}
      // backgroundColor={'red.100'}
      marginTop={'2%'}
      marginLeft={'-2%'}
    >
      <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
        <DateWithTitle title={'Start Date'} style={style.dateComponentStyles} />
        <DateWithTitle title={'End Date'} style={style.dateComponentStyles} />
        <DropDownWithTitle
          title={'Plan Type'}
          style={style.dateComponentStyles}
        />
      </SimpleGrid>

      <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}
      marginLeft={'1%'}
      >
        {customUpdateCancelBtn({
          title1: 'View',
          title2: 'Cancel',
        })}

        {/* <Flex flexDirection={'row'} width={'100%'}>
          <Button {...style.downloadButton}width={'100%'} border={0} ml={'5%'}>
            View
          </Button>
          <Button {...style.cancelButton} border={0}width={'100%'}>
            Cancel
          </Button>
        </Flex> */}
      </SimpleGrid>

      <Divider
        color={theme.customColors.blackAdminColor[100]}
        background={theme.customColors.blackAdminColor[200]}
        orientation="horizontal"
        marginY={'2.5%'}
      />
      <Text fontSize={'lg'} color={theme.customColors.blackAdminColor[200]}>
        Period: 2022-03-01 - 2022-03-31
      </Text>
      <Bar
        style={{ marginTop: 8 }}
        width={'100%'}
        height={25}
        data={{
          labels: [
            '2022-03-08',
            '2022-08-09',
            '2021-02-02',
            '2022-03-08',
            '2022-08-09',
            '2021-02-02',
            '2022-03-08',
            '2022-08-09',
            '2021-02-02',
          ],
          datasets: [
            {
              label: 'months',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: 'lightGrey',
            },
          ],
        }}
      />
    </Flex>
  );
};
