import React from 'react';
import { Flex, Divider, Spacer, useToast, SimpleGrid } from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import style from '../components/common/style';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ApiManager from '../config/apiManager';

import {
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';

const CreateCity = () => {
  let initialValue = {
    country: '',
    state: '',
    name: '',
  };

  let toast = useToast();
  let history = useHistory();
  let location = useLocation();

  let apimanager = ApiManager.getInstance();
  const [states, setStates] = React.useState([]);
  const [stateId, setStateId] = React.useState([]);
  const [status, setstatus] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);
  const [countriesId, setCountriesId] = React.useState([]);

  React.useEffect(() => {
    console.log('pathdata', location.state);
    if (location.status === 'create') {
      setstatus(true);
    }
    if (location.state?.val?.name)
      setValue(_p => ({ ..._p, name: location?.state?.val?.name }));

    if (location.state?.val?.countryName)
      setValue(_p => ({ ..._p, country: location?.state?.val?.countryName }));
    if (location.state?.val?.stateName)
      setValue(_p => ({ ..._p, state: location?.state?.val?.stateName }));
    
  }, [location]);
  console.log(value, 'value');

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
    console.log('value=>', value);
  };

  const onClickSubmit = () => {
    setLoading(true);
    console.log('Api Run');

    let tempSelectedID = '';
    countriesId.map((val, index) => {
      console.log('ValueOfCountries ID', val[1]);
      console.log('ValueOfCountries Name', value['country']);
      if (val[1] === value['country']) {
        console.log('selected value', val[0]);
        tempSelectedID = val[0];
      }
    });

    let tempStateID = '';
    stateId.map((val, index) => {
      console.log('ValueOfCountries ID', val[1]);
      console.log('ValueOfCountries Name', value['state']);
      if (val[1] === value['state']) {
        console.log('selected value', val[0]);
        tempStateID = val[0];
      }
    });

    const body = {
      countryName: value['country'],
      countryId: tempSelectedID,
      stateId: tempStateID,
      stateName: value?.state,
      name: value?.name,
    };
    console.log('body>>>>', body);
    apimanager
      .post('addCity', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8065) {
          setLoading(false);
          toast({
            title: 'Successfully Submitted',
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        }
        else if (response.message === 8371) {
          setLoading(false);
          toast({
            title: 'Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Country required`,
          });
        }
        else if (response.message === 8059) {
          setLoading(false);
          toast({
            title: 'Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `State required`,
          });
        }
        else if (response.message === 8058) {
          setLoading(false);
          toast({
            title: 'Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `City Name required`,
          });
        }
         else {
          setLoading(false);
          toast({
            title: 'Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Something went wrong!`,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  let tempArray = [];
  React.useEffect(() => {
    getALLItems();
    getAllstate();
  }, []);

  const getALLItems = () => {
    console.log('countries Screen');
    let tempId = [];
    apimanager
      .get('getAllCountries')
      .then(res => {
        if (res.message === 8372) {
          // console.log('Get add country responce', res);
          res.result.docs.map((value, index) => {
            console.log('valuesssss', value);
            tempId.push([value?._id, value?.name]);
            tempArray.push(value?.name);
          });
          setCountriesId(tempId);
          setCountries(tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getAllstate = () => {
    let tempState = [];
    // console.log('');
    let tempId = [];

    apimanager
      .get('getAllState')
      .then(res => {
        if (res.message === 8224) {
          console.log('getAllState responce', res);
          res.record.docs.map((value, index) => {
            console.log('valuesssss', value);
            tempId.push([value?._id, value?.name]);
            tempState.push(value?.name);
          });
          setStateId(tempId);
          setStates(tempState);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const onClickUpdate = () => {
    setLoading(true);
    console.log('Api Run');
    let tempSelectedID = '';
    countriesId.map((val, index) => {
      console.log('ValueOfCountries ID', val[1]);
      console.log('ValueOfCountries Name', value['country']);
      if (val[1] === value['country']) {
        console.log('selected value', val[0]);
        tempSelectedID = val[0];
      }
    });

    let tempStateID = '';
    stateId.map((val, index) => {
      console.log('ValueOfCountries ID', val[1]);
      console.log('ValueOfCountries Name', value['state']);
      if (val[1] === value['state']) {
        console.log('selected value', val[0]);
        tempStateID = val[0];
      }
    });
    const body = {
      id: location?.state?.val?._id,
      countryName: value['country'],
      countryId: tempSelectedID,
      stateId: tempStateID,
      stateName: value?.state,
      name: value?.name,
    };
    console.log('body>>>>', body);
    apimanager
      .post('updateCity', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8068) {
          setLoading(false);
          toast({
            title: 'Successfully Submitted',
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit City',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'App Setup',
              useSlash: true,
              path2: 'Cities',
              path3: 'Edit',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Country',
                mandatory: true,
                temparray: countries,
                placeholder: value['country'],
                onChange: e => handleonChange(e, 'country'),
              })}
              {dropdownCustom({
                title: 'State',
                mandatory: true,
                temparray: states,
                placeholder: value['state'],
                onChange: e => handleonChange(e, 'state'),
              })}
              {customInputLabel({
                title: 'City Name',
                mandatory: true,
                placeholder:value?.name,
                onChange: e => handleonChange(e, 'name'),
                value: value['name'],
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create City',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'App Setup',
              useSlash: true,
              path2: 'Cities',
              path3: 'Create',
            })}
          </Flex>

          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Country',
                mandatory: true,
                placeholder: 'Select Country',
                temparray: countries,
                onChange: e => handleonChange(e, 'country'),
              })}{' '}
              {dropdownCustom({
                title: 'State',
                mandatory: true,
                placeholder: 'Select State',
                temparray: states,
                onChange: e => handleonChange(e, 'state'),
              })}{' '}
              {customInputLabel({
                title: 'City Name',
                mandatory: true,
                onChange: e => handleonChange(e, 'name'),
              })}
            </SimpleGrid>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreateCity;
