import React, { lazy, Suspense } from 'react';
import Dashboard from '../pages/Dashboard';
import AppVersion from '../pages/AppVersion';
import {
  FaUserTie,
  FaFire,
  FaUserAlt,
  FaRegImages,
  FaVolumeUp,
  FaUserTag,
  FaUser,
  FaSms,
  FaTags,
  FaRegNewspaper,
} from 'react-icons/fa';
import { StarIcon, PhoneIcon, WarningIcon, SearchIcon } from '@chakra-ui/icons';
import {
  AiFillQuestionCircle,
  AiTwotoneBank,
  AiFillSetting,
  AiFillFileText,
  AiOutlineAppstoreAdd,
  AiOutlineNotification,
} from 'react-icons/ai';
import { RiPagesLine, RiTerminalWindowFill } from 'react-icons/ri';
import { MdPayment } from 'react-icons/md';
import OtpLogs from '../pages/Otplogs';
import ApiLog from '../pages/Reports/ApiLog';
import Softpins from '../pages/Softpins/Softpins';
import AuditTrail from '../pages/Reports/AuditTrail';
import ContactUs from '../pages/Reports/ContactUs';
import Mobilebanner from '../pages/MobileBanners';
import InboxMessage from '../pages/InboxMessaging';
import PlansReport from '../pages/Reports/PlansReport';
import BlackUserProfile from '../pages/BlackUserProfile';
import BlackAppPage from '../pages/BlackAppPage';
import FaqManagement from '../pages/FAQManagement';
import Pages from '../pages/Pages';
import SoftpinStock from '../pages/Softpins/SoftpinStock';
import StoreManagement from '../pages/StoreManagement';
import SearchOptimization from '../pages/SearchOptimization';
import RecentSearch from '../pages/RecentSearch';
import SmsMasterlibrary from '../pages/SmsMasterLibrary';
import Softpinimport from '../pages/Softpins/Softpinimport';
import GameManagement from '../pages/GameManagement';
import { BiUsb, BiHourglass, BiTimer } from 'react-icons/bi';
import DynamicCampaign from '../pages/DynamicCampaign';
import AppUserReport from '../pages/Reports/AppUserReport';
import MotivationalQuotes from '../pages/MotivationalQuotes';
import SoftpinsReport from '../pages/Reports/SoftpinsReport';
import LuckyDrawReward from '../pages/LuckyDrawReward';
import BannerManagement from '../pages/BannerManagement';
import ProductManagement from '../pages/ProductMangement';
import ReferralProgram from '../pages/Reports/ReferralProgram';
import SpecialDealsList from '../pages/OnetimeDealManagement';
import SoftpinCategory from '../pages/Softpins/SoftpinCategories';
import { MdLocationOn, MdPhotoLibrary } from 'react-icons/md';
import AboutUsManagement from '../pages/AboutUsManagement';
import Rewards from '../pages/GameRewardManagement/Rewards';
import YetToBeImplemented from '../navigation/YetToBeImplemented';
import LuckyDrawUserReport from '../pages/LuckyDrawUserReport';
import PrivacyPolicyList from '../pages/PrivacyPoliciesManagement';
import { OauthXOX } from '../pages/CMSBackendSetup/OauthXOX';
import { Permissions } from '../pages/CMSBackendSetup/Permissions';
import PromoCodeManagement from '../pages/PromoCodeManagement';
import AdxRewardManagement from '../pages/AdxRewardManagement';
import PaymentTransactions from '../pages/Reports/PaymentTransactions';
import LuckyDrawRewardReport from '../pages/LuckyDrawRewardReport';
import RewardTypes from '../pages/GameRewardManagement/RewardTypes';
import RewardsItems from '../pages/GameRewardManagement/RewardItems';
import UpsellCompaigns from '../pages/Upsell Compaigns/UpsellCompaigns';
import CNYCampaignManagement from '../pages/CNYCampaignManagement';
import TermConditionManagement from '../pages/TermConditionManagement';
import DemographicReport from '../pages/DemographicReport';
import LuckyDrawUpsell from '../pages/Upsell Compaigns/LuckyDrawUpsell';
import { Ipay88Credentials } from '../pages/CMSBackendSetup/Ipay88Credentials';
import DynamicCampaignReport from '../pages/Reports/DynamicCampaignReport';
import RewardsItemTypes from '../pages/GameRewardManagement/RewardItemType';
import PromotionAndNewsManagement from '../pages/PromotionAndNewsManagement';
import { MiddlewareAuthentications } from '../pages/CMSBackendSetup/MiddlewareAuthentications';
import { SuspendedRoles } from '../pages/SuspendedRoles';
import SuspendUser from '../pages/SuspendUser';
import DeletedUser from '../pages/DeletedUser';
import { BannerTypes } from '../pages/AppSetup/BannerTypes';
import { CampaignGameRewards } from '../pages/AppSetup/CampaignGameRewards';
import { Cities } from '../pages/AppSetup/Cities';
import { Countries } from '../pages/AppSetup/Countries';
import FAQCategories from '../pages/AppSetup/FAQCategories';
import { GameSettings } from '../pages/AppSetup/GameSettings';
import { HappyHourBackgrounds } from '../pages/AppSetup/HappyHourBackgrounds';
import Maintenance from '../pages/AppSetup/Maintenance';
import PrivacyPolicyCategories from '../pages/AppSetup/PrivacyPolicyCategories';
import ProductCategories from '../pages/AppSetup/ProductCategories';
import { PromotionsNewsCategories } from '../pages/AppSetup/PromotionsNewsCategories';
import { SMSMasterLibraryCategory } from '../pages/AppSetup/SMSMasterLibraryCategory';
import { States } from '../pages/AppSetup/States';
import { StatusForBanners } from '../pages/AppSetup/StatusForBanners';
import NotificationCompaigns from '../pages/Notification/NotificationCompaigns';
import NotifiyAllUsers from '../pages/Notification/NotifiyAllUsers';
import NotifySelectedUsers from '../pages/Notification/NotifySelectedUsers';
import MerchantCodes from '../pages/MerchantCodes';
import PaymentNMerchantActions from '../pages/PaymentNMerchantActions';
import { StatusPromotionsNews } from '../pages/AppSetup/StatusPromotionsNews';
import TermConditionCategories from '../pages/AppSetup/TermConditionCategories';
import ProductRecomendations from '../pages/ProductRecomendations';
import PhysicalRedemptionReport from '../pages/Reports/PhysicalRedemptionReport';
import PhysicalRedemptionFormDetail from '../pages/PhysicalRedemptionFormDetail';
import CreateCompaign from '../pages/CreateCompaign';
import EditAboutUs from '../pages/EditAboutUs';
import CreateAppVersion from '../pages/CreateAppVersion';
import Actions from '../pages/Actions';
import BlackAppDashboard from '../pages/BlackAppDashboard';

import LineManagementDashboard from '../pages/LineManagementDashboard';
import GetAllPaymentGateway from '../pages/GetAllPaymentGateway';
import BlackAppNewRegistration from '../pages/Reports/BlackAppNewRegistration';
import BlackAppNotificationReport from '../pages/Reports/BlackAppNotificationReport';
import PageVisited from '../pages/PageVisited';
import LineManagement from '../pages/LineManagement';
// import Roles from '../pages/Roles';
import Admins from '../pages/Admins';
import IAM from '../pages/IAM';

const LazyRoles = lazy(() => import('../pages/Roles'));

export const menuItems = [
  {
    name: 'Dashboard',
    exact: true,
    to: '/Dashboard',
    component: <Dashboard />,
    path: '/Dashboard',
    iconClassName: <FaFire />,
    subMenus: [
      {
        name: 'Black App Dashboard',
        to: '/Dashboard/BlackAppDashboard',
        component: <BlackAppDashboard />,
      },
      {
        name: 'Line Management Dashboard',
        to: '/Dashboard/LineManagementDashboard',
        component: <LineManagementDashboard />,
      },
      //   // {
      //   //   name: 'Reward Item Types',
      //   //   to: '/GameRewardsManagement/RewardItemTypes',
      //   //   component: <RewardsItemTypes />,
      //   // },
      //   // {
      //   //   name: 'Reward Types',
      //   //   to: '/GameRewardsManagement/RewardTypes',
      //   //   component: <RewardTypes />,
      //   // },
    ],
  },
  {
    name: 'About Us Management',
    exact: true,
    component: <AboutUsManagement />,
    to: `/AboutUsManagement`,
    iconClassName: <FaUserTie />,
  },
  {
    name: 'App Versions Management',
    exact: true,
    to: '/AppVersion',
    component: <AppVersion />,
    path: '/AppVersion',
    iconClassName: <BiUsb />,
  },
  {
    name: 'Adx Rewards Management',
    exact: true,
    to: `/AdxRewardManagement`,
    component: <AdxRewardManagement />,
    iconClassName: <StarIcon />,
  },
  {
    name: 'Black User Profile',
    exact: true,
    to: '/BlackUserProfile',
    component: <BlackUserProfile />,
    path: '/AppVersion',
    iconClassName: <FaUserAlt />,
  },
  {
    name: 'Black App Page',
    exact: true,
    to: '/BlackAppPage',
    component: <BlackAppPage />,
    path: '/AppVersion',
    iconClassName: <FaUserAlt />,
  },
  // {
  //   name: 'CNY Campaign Management',
  //   exact: true,
  //   // to: `/CNYCampaign Management`,
  //   // component: <CNYCampaignManagement />,
  //   iconClassName: <FaRegImages />,
  //   subMenus: [
  //     {
  //       name: 'CNY Campaign Management',
  //       // to: '/CNYCampaignManagement/CNYCampaignManagement',
  //       // component: <CNYCampaignManagement />,
  //     },
  //     {
  //       name: 'Motivational Quotes',
  //       to: '/CNYCampaign Management/MotivationalQuotes',
  //       component: <MotivationalQuotes />,
  //     },
  //     {
  //       name: 'Luck Draw Reward',
  //       to: '/CNYCampaign Management/LuckyDrawReward',
  //       component: <LuckyDrawReward />,
  //     },
  //     {
  //       name: 'Luck Draw User Report',
  //       to: '/CNYCampaign Management/LuckDrawUserReport',
  //       component: <LuckyDrawUserReport />,
  //     },
  //     {
  //       name: 'Lucky Draw Reward Report',
  //       to: '/CNYCampaign Management/LuckyDrawRewardReport',
  //       component: <LuckyDrawRewardReport />,
  //     },
  //   ],
  // },
  {
    name: 'Banner Management',
    exact: true,
    to: '/BannerManagement',
    component: <BannerManagement />,
    path: '/BannerManagement',
    iconClassName: <FaRegImages />,
  },

  {
    name: 'Campaign Management',
    exact: true,
    // to: `/Campaign Management`,
    // component: <DynamicCampaign />,
    iconClassName: <FaVolumeUp />,
    subMenus: [
      {
        name: 'Dynamic Campaign',
        to: '/CNYCampaignManagement/DynamicCampaign',
        component: <DynamicCampaign />,
      },
    ],
  },

  {
    name: 'FAQ Management',
    exact: true,
    to: `/FAQManagement`,
    component: <FaqManagement />,
    iconClassName: <AiFillQuestionCircle />,
  },
  {
    name: 'Notification',
    exact: true,
    iconClassName: <AiOutlineNotification />,
    subMenus: [
      {
        name: 'Notification Compaigns',
        to: '/users/NotificationCompaigns',
        component: <NotificationCompaigns />,
      },
      {
        name: 'Notification To All Users',
        to: '/users/NotifiyAllUsers',
        component: <NotifiyAllUsers />,
      },
      {
        name: 'Notification To Selected Users',
        to: '/NotifySelectedUsers',
        component: <NotifySelectedUsers />,
      },
    ],
  },
  // {
  //   name: 'Game Management',
  //   exact: true,
  //   to: `/GameManagement`,
  //   component: <GameManagement />,
  //   iconClassName: <FaGamepad />,
  // },

  // {
  //   name: 'Inbox Messaging',
  //   exact: true,
  //   to: `/InboxMessaging`,
  //   component: <InboxMessage />,
  //   iconClassName: <AiOutlineMessage />,
  // },

  // {
  //   name: 'Mobile Banners',
  //   exact: true,
  //   to: `/Mobilebanner`,
  //   component: <Mobilebanner />,
  //   iconClassName: <AiOutlineBars />,
  // },
  {
    name: 'One Time Deal Management',
    exact: true,
    to: `/OnetimeDealManagement`,
    component: <SpecialDealsList />,
    iconClassName: <BiHourglass />,
  },
  {
    name: 'OTP Logs',
    exact: true,
    to: `/Otplogs`,
    component: <OtpLogs />,
    iconClassName: <FaSms />,
  },
  {
    name: 'Pages',
    exact: true,
    to: `/Pages`,
    component: <Pages />,
    iconClassName: <RiPagesLine />,
  },
  {
    name: 'Payment',
    exact: true,
    // to: `/users`,
    // component: <Reports />,https://git-codecommit.ap-southeast-1.amazonaws.com/v1/repos/superapp-cms
    // iconClassName: 'bi bi-vector-pen',
    iconClassName: <MdPayment />,
    subMenus: [
      {
        name: 'All Payment Gateway',
        to: '/payment/paymentGateway',
        component: <GetAllPaymentGateway />,
      },
      {
        name: 'Merchants',
        to: '/payment/MerchantsCodes',
        component: <MerchantCodes />,
      },
      {
        name: 'Actions',
        to: '/payment/PaymentAndMerchantActions',
        component: <PaymentNMerchantActions />,
      },
      // {
      //   name: 'Suspended Users',
      //   to: '/SuspendUser',
      //   component: <SuspendUser />,
      // },
      // {
      //   name: 'Deleted Users',
      //   to: '/DeletedUser',
      //   component: <DeletedUser />,
      // },
      // {
      //   name: 'Suspended Roles',
      //   to: '/SuspendedRoles',
      //   component: <SuspendedRoles />,
      // },
      // {
      //   name: 'Actions',
      //   to: '/users/Actions',
      //   component: <Actions />,
      // },
    ],
  },
  {
    name: 'Privacy Policies Management',
    exact: true,
    to: `/PrivacyPoliciesManagement`,
    component: <PrivacyPolicyList />,
    iconClassName: <AiTwotoneBank />,
  },
  {
    name: 'Product Recomendation',
    exact: true,
    to: '/ProductRecomendations',
    component: <ProductRecomendations />,
    path: '/ProductRecomendations',
    iconClassName: <AiOutlineAppstoreAdd />,
  },
  {
    name: 'Product Management',
    exact: true,
    to: `/ProductManagement`,
    component: <ProductManagement />,
    iconClassName: <FaTags />,
  },
  {
    name: 'Promo Code Management',
    exact: true,
    to: `/PromoCodeManagement`,
    component: <PromoCodeManagement />,
    iconClassName: <BiTimer />,
  },
  {
    name: '  Promotion & News Management',
    exact: true,
    to: `/  PromotionAndNewsManagement`,
    component: <PromotionAndNewsManagement />,
    iconClassName: <FaRegNewspaper />,
  },

  {
    name: 'Rewards Management',
    exact: true,
    // to: `/GameRewardsManagement`,
    // component: <Rewards />,
    iconClassName: <FaVolumeUp />,
    subMenus: [
      {
        name: 'Rewards',
        to: '/GameRewardsManagement/Rewards',
        component: <Rewards />,
      },
      // {
      //   name: 'Rewards Items',
      //   to: '/GameRewardsManagement/RewardsItems',
      //   component: <RewardsItems />,
      // },
      // {
      //   name: 'Reward Item Types',
      //   to: '/GameRewardsManagement/RewardItemTypes',
      //   component: <RewardsItemTypes />,
      // },
      // {
      //   name: 'Reward Types',
      //   to: '/GameRewardsManagement/RewardTypes',
      //   component: <RewardTypes />,
      // },
    ],
  },
  {
    name: 'Roles',
    exact: true,
    to: `/setup/roles`,
    component: (
      <Suspense>
        <LazyRoles />
      </Suspense>
    ),
    iconClassName: <FaUserTag />,
  },
  // {
  //   name: 'Physical Redemption Form',
  //   exact: true,
  //   // to: `/  PhysicalRedemptionFormDetail`,
  //   // component: <PhysicalRedemptionFormDetail />,
  //   iconClassName: <FaRegNewspaper />,
  // },
  {
    name: ' Store Management ',
    exact: true,
    to: `/StoreManagement`,
    component: <StoreManagement />,
    iconClassName: <MdLocationOn />,
  },
  {
    name: '  Sms Master library ',
    exact: true,
    to: `/SmsMasterlibrary`,
    component: <SmsMasterlibrary />,
    iconClassName: <FaSms />,
  },

  {
    name: 'Softpin Management',
    exact: true,
    // to: `/SoftpinManagement`,
    // component: <SoftpinCategory />,
    iconClassName: <PhoneIcon />,
    subMenus: [
      {
        name: 'Softpin Categories',
        to: '/SoftpinManagement/SoftpinCategory',
        component: <SoftpinCategory />,
      },
      {
        name: 'Softpins',
        to: '/SoftpinManagement/Softpins',
        component: <Softpins />,
      },
      // {
      //   name: 'Softpin Import',
      //   to: '/SoftpinManagement/Softpinimport',
      //   component: <Softpinimport />,
      // },
      {
        name: 'Softpin Stock',
        to: '/SoftpinManagement/SoftpinStock',
        component: <SoftpinStock />,
      },
    ],
  },
  {
    name: 'Search Optimization',
    exact: true,
    // to: `/SearchOptimization`,
    // component: <SearchOptimization />,
    iconClassName: <SearchIcon />,
    subMenus: [
      {
        name: 'Trending Searh',
        to: '/SearchOptimization/TrendingSearch',
        component: <SearchOptimization />,
      },
      {
        name: 'Recent Search',
        to: '/SoftpinManagement/RecentSearch',
        // component: <RecentSearch />,
        component: <RecentSearch />,
      },
    ],
  },
  {
    name: 'Term & Condition Management',
    exact: true,
    to: `/TermConditionManagementt`,
    component: <TermConditionManagement />,
    iconClassName: <RiTerminalWindowFill />,
  },
  // {
  //   name: 'Upsell Campaigns',
  //   exact: true,
  //   // to: `/UpsellCompaigns`,
  //   // component: <SoftpinCategory />,
  //   iconClassName: <MdPhotoLibrary />,
  //   subMenus: [
  //     {
  //       name: 'Upsell Campaigns',
  //       to: '/UpsellCompaigns/UpsellCompaigns',
  //       component: <UpsellCompaigns />,
  //     },
  //     {
  //       name: 'LuckyDraw-Upsell',
  //       to: '/UpsellCompaigns/LuckyDrawUpsell',
  //       component: <LuckyDrawUpsell />,
  //     },
  //   ],
  // },
  {
    name: 'Users',
    exact: true,
    // to: `/users`,
    // component: <Reports />,https://git-codecommit.ap-southeast-1.amazonaws.com/v1/repos/superapp-cms
    // iconClassName: 'bi bi-vector-pen',
    iconClassName: <FaUser />,
    subMenus: [
      {
        name: 'Admins',
        to: '/users/Admin',
        component: <Admins />,
      },
      {
        name: 'Suspended Users',
        to: '/SuspendUser',
        component: <SuspendUser />,
      },
      {
        name: 'Deleted Users',
        to: '/DeletedUser',
        component: <DeletedUser />,
      },
      {
        name: 'Suspended Roles',
        to: '/SuspendedRoles',
        component: <SuspendedRoles />,
      },
      {
        name: 'Actions',
        to: '/users/Actions',
        component: <Actions />,
      },
    ],
  },

  {
    name: 'App Setup',
    exact: true,
    // to: `/setup/banner_types`,
    // component: <Reports />,https://git-codecommit.ap-southeast-1.amazonaws.com/v1/repos/superapp-cms
    iconClassName: <AiFillSetting />,
    subMenus: [
      {
        name: 'Banner Types',
        to: '/setup/banner_types',
        component: <BannerTypes />,
      },
      // {
      //   name: 'Campaign Game Rewards',
      //   to: '/setup/campaign_game_rewards',
      //   // component: <CampaignGameRewards />,
      //   component: <YetToBeImplemented />,
      // },
      {
        name: 'Cities',
        to: '/setup/cities',
        component: <Cities />,
      },
      {
        name: 'Countries',
        to: '/setup/countries',
        component: <Countries />,
      },
      {
        name: 'FAQ Categories',
        to: '/setup/FAQCategories',
        component: <FAQCategories />,
      },
      // {
      //   name: 'Game Settings',
      //   to: '/setup/Game_Settings',
      //   // component: <GameSettings />,
      //   component: <YetToBeImplemented />,
      // },
      {
        name: 'Happy Hour Backgrounds',
        to: '/setup/HappyHourBackgrounds',
        component: <HappyHourBackgrounds />,
      },
      {
        name: 'Maintenance',
        to: '/setup/Maintenance',
        component: <Maintenance />,
      },
      {
        name: 'Privacy Policy Categories',
        to: '/setup/Privacy_Policy_Categories',
        component: <PrivacyPolicyCategories />,
        // component: <YetToBeImplemented />,
      },
      // {
      //   name: 'Product Categories',
      //   to: '/setup/Product_Categories',
      //   component: <ProductCategories />,
      // },
      // {
      //   name: 'Promotions & News Categories',
      //   to: '/setup/Promotions_News_Categories',
      //   component: <PromotionsNewsCategories />,
      // },
      {
        name: 'SMS Master Library Category',
        to: '/setup/SMSMasterLibraryCategory',
        component: <SMSMasterLibraryCategory />,
      },
      {
        name: 'States',
        to: '/setup/States',
        component: <States />,
      },
      // {
      //   name: 'Status For Banners',
      //   to: '/setup/StatusForBanners',
      //   component: <StatusForBanners />,
      // },
      // {
      //   name: 'Status For Promotions & News',
      //   to: '/setup/StatusPromotionsNews',
      //   component: <StatusPromotionsNews />,
      // },
      {
        name: 'Term & Condition Categories',
        to: '/setup/TermConditionCategories',
        component: <TermConditionCategories />,
      },
    ],
  },

  // {
  //   name: 'CMS & Backend Setup',
  //   exact: true,
  //   // to: `/CMSBackendSetup/Ipay88Credentials`,
  //   // component: <Reports />,https://git-codecommit.ap-southeast-1.amazonaws.com/v1/repos/superapp-cms
  //   // iconClassName: 'bi bi-vector-pen',
  //   iconClassName: <AiFillSetting />,
  //   subMenus: [
  //     {
  //       name: 'Ipay88 Credentials',
  //       to: '/CMSBackendSetup/Ipay88Credentials',
  //       component: <Ipay88Credentials />,
  //     },
  //     {
  //       name: 'Oauth XOX',
  //       to: '/CMSBackendSetup/OauthXOX',
  //       component: <OauthXOX />,
  //     },
  //     {
  //       name: 'MiddleWare Authentications',
  //       to: '/CMSBackendSetup/MiddlewareAuthentications',
  //       component: <MiddlewareAuthentications />,
  //     },
  //     {
  //       name: 'Permissions',
  //       to: '/CMSBackendSetup/Permissions',
  //       component: <Permissions />,
  //     },
  //   ],
  // },
  {
    name: 'Reports',
    exact: true,
    // to: `/Reports`,
    // component: <ApiLog />,
    iconClassName: <AiFillFileText />,
    subMenus: [
      // {
      //   name: 'Api Log',
      //   to: '/Reports/ApiLog',
      //   component: <ApiLog />,
      // },
      // {
      //   name: 'Audit Trail',
      //   to: '/Reports/AuditTrail',
      //   component: <AuditTrail />,
      // },
      // {
      //   name: 'App User Report',
      //   to: '/Reports/AppUserReport',
      //   component: <AppUserReport />,
      // },
      // {
      //   name: 'Black App Notification Report',
      //   to: '/Reports/BlackAppNotificationReport',
      //   component: <BlackAppNotificationReport />,
      // },
      {
        name: 'Black App New Registration',
        to: '/Reports/BlackAppNewRegistration',
        component: <BlackAppNewRegistration />,
      },
      {
        name: 'Contact Us',
        to: '/Reports/ContactUs',
        component: <ContactUs />,
      },
      // {
      //   name: 'Dynamic Campaign Report',
      //   to: '/Reports/DynamicCampaignReport',
      //   component: <DynamicCampaignReport />,
      // },
      // {
      //   name: 'Payment Transactions',
      //   to: '/Reports/PaymentTransactions',
      //   component: <PaymentTransactions />,
      // },
      {
        name: 'Page Visited',
        to: '/Reports/PageVisited',
        component: <PageVisited />,
      },
      {
        name: 'Demographic Report',
        to: '/Reports/DemographicReport',
        component: <DemographicReport />,
      },
      {
        name: 'Line Management',
        to: '/Reports/LineManagement',
        component: <LineManagement />,
      },
      // {
      //   name: 'Plans Report',
      //   to: '/Reports/PlansReport',
      //   component: <PlansReport />,
      // },
      // {
      //   name: 'Referral Program',
      //   to: '/Reports/ReferralProgram',
      //   component: <ReferralProgram />,
      // },
      // {
      //   name: 'Softpins Report',
      //   to: '/Reports/SoftpinsReport',
      //   component: <SoftpinsReport />,
      // },
      // {
      //   name: 'Physical Redemption Report',
      //   to: '/Reports/PhysicalRedemptionReport',
      //   component: <PhysicalRedemptionReport />,
      // },
    ],
  },
];

export const CustomMenuItemsList = [
  {
    name: 'Identity and Access Management',
    exact: true,
    component: <IAM />,
    to: `/IAM`,
    iconClassName: <FaUserTie />,
  },
];
