import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import {
  checkMandatory,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import {
  MiniHeader,
  customTextarea,
} from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import styles from '../styles/mobileBannerStyles';
import style from '../components/common/style';
import { EditorState, convertToRaw } from 'draft-js';
import { useHistory, useLocation } from 'react-router-dom';
import ControlledEditor from '../components/textEditor/classEditor';
import ControlledEditorReselect from '../components/textEditor/textEditor';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const CreateFrequentlyAskedQuestion = () => {
  let toast = useToast();
  let location = useHistory();
  let location1 = useLocation();
  // let dropdownArray = ['General', 'Feedback'];
  let previousData = location?.location?.state?.data;
  console.log('previousData', previousData);

  let initialValue = {
    category: '',
    answerCN: '',
    answerMY: '',
    answerENG: '',
    questionCN: '',
    questionMY: '',
    questionENG: '',
    editorState: EditorState.createEmpty(),
  };

  let mandatoryFields = {
    // category: true,
    answerCN: true,
    answerMY: true,
    answerENG: true,
    questionCN: true,
    questionMY: true,
    questionENG: true,
  };

  const [error, setError] = useState(null);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [faqCate, setFaqCate] = useState([]);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [changeApi, setChangeApi] = useState(false);
  let history = useHistory();

  // Handle Functions //
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleSingleonChange = (evt, key) => {
    let ev = evt;
    console.log('EVT->', evt);

    let val = IFame(evt);

    convertCommentFromJSONToHTML(val?.__html, key);
  };
  const IFame = dat => {
    let data = dat;
    console.log('location data=>', data);
    return {
      __html: data,
    };
  };

  const convertCommentFromJSONToHTML = (text, key) => {
    console.log('convert Text=>', text);
    let toHTML = stateToHTML(convertFromRaw(text));
    console.log('toHTML', toHTML);
    // setHtml(toHTML);
    setValue({
      ...value,
      [key]: toHTML,
    });
  };

  // building hooks //
  useEffect(() => {
    getAllCategory();
    console.log('Current data=>', location);
    console.log('Current data1=>', location1);
    if (location1) {
      if (location1?.state?.useEditApi === true) {
        let useEditApi = location?.location?.state.useEditApi;
        console.log('useEditApi', useEditApi);
        setChangeApi(useEditApi);
        setValue({
          ...value,
          answerCN: previousData?.answerCN,
          answerMY: previousData?.answerMY,
          answerENG: previousData?.answerENG,
          questionCN: previousData?.questionCN,
          questionMY: previousData?.questionMY,
          questionENG: previousData?.questionENG,
        });
      }
    }
  }, []);

  // Api implementation //
  const addFAQ = () => {
    // console.log('addFAQ Set=value==>', value);
    setLoading(!loading);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      setLoading(loading);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        questionENG: value?.questionENG,
        questionCN: value?.questionCN,
        questionMY: value?.questionMY,
        answerMY: value.answerMY,
        answerCN: value.answerCN,
        answerENG: value.answerENG,
        FAQCategory: value.category ? value?.category : faqCate[0],
      };

      // console.log('body>>>>', body);
      apiManager
        .post('addFAQ', body)
        .then(res => {
          // console.log(res, 'response');
          if (res.message === 8164) {
            setSuccess('Successfully Recorded!');
            setLoading(loading);

            toast({
              title: success,
              status: 'success',
              duration: 3000,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [2000]);
          } else {
            setError('Please enter mandatory fields');
            setLoading(loading);
          }
        })
        .catch(error => {
          setLoading(loading);
          console.log(error);
        });
    }
  };

  const getAllCategory = () => {
    let tempArray = [];
    apiManager
      .get('getAllFAQCategories')
      .then(res => {
        console.log('RES=>', res);
        if (res.message === 8156) {
          console.log('Get add country responce', res.results);
          res?.result?.docs?.map((value, index) => {
            console.log('valuesssss', value.name);
            tempArray.push(value.name);
          });
          setFaqCate(tempArray);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const updateFAQ = () => {
    // console.log('updateFAQ Set=value==>', value);
    setLoading(!loading);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      setLoading(loading);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        questionENG: value?.questionENG,
        questionCN: value?.questionCN,
        questionMY: value?.questionMY,
        answerMY: value.answerMY,
        answerCN: value.answerCN,
        answerENG: value.answerENG,
        FAQCategory: value.category,
        id: previousData?._id,
      };

      console.log('body>>>>', body);
      apiManager
        .post('updateFAQ', body)
        .then(res => {
          console.log(res, 'response');

          if (res.message === 8167) {
            setSuccess('Successfully Recorded!');
            setLoading(loading);

            toast({
              title: success,
              status: 'success',
              duration: 2000,
              isClosable: true,
              description: `Recorded, Please proceed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [2000]);
          } else {
            setError('Please enter mandatory fields');
            setLoading(loading);
          }
        })
        .catch(error => {
          setLoading(loading);

          console.log('error', error);
          setLoading(loading);
        });
    }
  };

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: !changeApi
            ? 'Create Frequently Asked Question'
            : 'Update Frequently Asked Question',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'FAQ Management',
          useSlash: true,
          path2: !changeApi
            ? 'Create Frequently Asked Question'
            : 'Update Frequently Asked Question',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {dropdownCustom({
            title: 'FAQ Category',
            mandatory: true,
            temparray: faqCate,
            onChange: e => {
              handleonChange(e, 'category');
            },
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customTextarea({
            mandatory: true,
            title: 'Question ENG',
            value: value.questionENG,
            onChange: e => handleonChange(e, 'questionENG'),
          })}
          <Flex {...styles.flexAnswerCn}>
            <Flex flexDirection={'row'} marginTop={4}>
              <Text {...styles.txtAnswerCn}>Answer ENG</Text>
              <Text {...styles.txtRed}>*</Text>
            </Flex>
            {!changeApi ? (
              <ControlledEditor
                tempData={e => handleSingleonChange(e, 'answerENG')}
              />
            ) : (
              <ControlledEditorReselect
                value={value['answerENG']}
                tempData={e => handleSingleonChange(e, 'answerENG')}
              />
            )}
            {/* {customTextarea({
              // title: 'Answer EN',
              // mandatory: true,
              value: value.answerENG,
              onChange: e => handleonChange(e, 'answerENG'),
            })} */}
          </Flex>

          {customTextarea({
            title: 'Question CN',
            mandatory: true,
            value: value.questionCN,
            onChange: e => handleonChange(e, 'questionCN'),
          })}

          <Flex
            {...styles.flexAnswerCn}
            //   background={'red.100'}
          >
            <Flex flexDirection={'row'} marginTop={4}>
              <Text {...styles.txtAnswerCn}>Answer CN</Text>
              <Text {...styles.txtRed}>*</Text>
            </Flex>
            {!changeApi ? (
              <ControlledEditor
                tempData={e => handleSingleonChange(e, 'answerCN')}
              />
            ) : (
              <ControlledEditorReselect
                value={value['answerCN']}
                tempData={e => handleSingleonChange(e, 'answerCN')}
              />
            )}
            {/* <UncontrolledEditor /> */}
            {/* {customTextarea({
              // title: 'Answer EN',
              // mandatory: true,
              value: value.answerCN,
              // onChange: e => setValue({ answerCN: e.target.value }),
              onChange: e => handleonChange(e, 'answerCN'),
            })} */}
          </Flex>

          {customTextarea({
            title: 'Question MY',
            mandatory: true,
            value: value.questionMY,
            onChange: e => handleonChange(e, 'questionMY'),

            // onChange: e => setValue({ questionMY: e.target.value }),
          })}
          <Flex {...styles.flexAnswerCn}>
            <Flex flexDirection={'row'} marginTop={4}>
              <Text {...styles.txtAnswerCn}>Answer MY</Text>
              <Text {...styles.txtRed}>*</Text>
            </Flex>
            {!changeApi ? (
              <ControlledEditor
                tempData={e => handleSingleonChange(e, 'answerMY')}
              />
            ) : (
              <ControlledEditorReselect
                value={value['answerMY']}
                tempData={e => handleSingleonChange(e, 'answerMY')}
              />
            )}
            {/* <UncontrolledEditor /> */}
            {/* {customTextarea({
              // title: 'Question MY',
              // mandatory: true,
              value: value.answerMY,
              // onChange: e => setValue({ answerMY: e.target.value }),
              onChange: e => handleonChange(e, 'answerMY'),
            })} */}
          </Flex>
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Update',
          title2: 'Cancel',
          onClickCancel: () => history.goBack(),
          isLoading: loading,
          onClickSubmit: () => {
            if (location1?.state?.useEditApi === true) {
              updateFAQ();
            } else {
              addFAQ();
            }
          },
        })}
      </Flex>
      <Spacer />
      <Divider
        orientation="horizontal"
        marginTop={5}
        marginBottom={2}
        marginX={5}
      />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};
export default CreateFrequentlyAskedQuestion;
