import theme from '../../config/color';
const style = {
  iconDateContainer: {
    width: '100%',
    height: '70%',
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: theme.customColors.blackAdminColor['defaultFontColor'],
    alignItems: 'center',
    // marginTop: 2,
  },
  selectContainer: {
    width: '100%',
    height: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.customColors.blackAdminColor['defaultFontColor'],
    alignItems: 'center',
    marginTop: 2,
  },
  selectorStyle: {
    marginTop: '1%',
    borderWidth: 1,
    width: '100%',
    borderRadius: 4,
    borderColor: theme.customColors.blackAdminColor['defaultFontColor'],
  },
};
export default style;
