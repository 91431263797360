import { uploadFile, deleteFile } from 'react-s3';
import S3FileUpload from 'react-s3';
import { Buffer } from 'buffer';
import config from '../config/config';

Buffer.from(uploadFile, 'base64');
window.Buffer = window.Buffer || require('buffer').Buffer;

const options = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};
// 'https://'+bucketName+'s3.azmo...com'+imgeUlr

const uploadImage = uri => {
  console.log('url_Hit>>>>', uri);
  return new Promise((resolve, reject) => {
    uploadFile(uri, options)
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

const deleteImage = uri => {
  console.log('deleteFile for s3', uri);
  return new Promise((resolve, reject) => {
    console.log('uri', uri);
    // deleteFile(uri, options)
    deleteFile(uri, options)
      .then(response => {
        resolve(response);
        console.log('response', response);
      })
      .catch(err => {
        reject(err);
        console.log('err', err);
      });
  });
};

export { uploadImage, deleteImage };
