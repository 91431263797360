import React, { useEffect } from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';
import { Roles } from '../context/data';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';
import {
  // dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

const AddMerchantCode = () => {
  let initialValue = {
    gatewayId: '',
    merchantName:'',
    merchantCode: '',
    merchantKey: '',
    privateKeyLink: '',
    publicKeyLink: '',
  };
  const [data, setData] = React.useState('');
  const [value, setValue] = React.useState(initialValue);
  const [loading, setLoading] = React.useState(false);
  const [success, setsuccess] = React.useState(false);
  const [error, setError] = React.useState();
  let apiManager = ApiManager.getInstance();
  let history = useHistory();
  //   const location = useLocation();
  let toast = useToast();

  let mandatoryFields = {
    gatewayId: true,
    merchantName:true,
    merchantCode: true,
    merchantKey: true,
    privateKeyLink: true,
    publicKeyLink: true,
  };
  console.log('Values', value);
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value, 'Key>>', key);
    setValue({
      ...value,
      [key]: ev,
    });
  };

  React.useEffect(() => {
    getAllCategory();
  }, []);
  const getAllCategory = () => {
    setLoading(true);
    let tempArray = [];
    // console.log('page=>', page);
    // let body = {
    //   page: page,
    //   // nextPage: 3,
    //   numRecords: value,
    // };
    apiManager
      .get('getPaymentGateways')
      .then(res => {
        console.log('getPaymentGateways=>RES', res);
        if (res.message === 11004) {
          console.log('Get getPaymentGateways', res);
          // pageCount = res.gateways.length;
          res.gateways.map((value, index) => {
            console.log('valuesssss', value);
            tempArray.push({ list: value?.name, val: value?.gatewayId });
          });
          setData(tempArray);
          // setLoading(false);
        }
      })
      .catch(error => {
        // setLoading(false);
        console.log('error', error);
      });
  };
  console.log('value==', value);
  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    console.log(value, 'Value');
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.Mandatory Field must be filled',
      });
    } else {
      const body = {
        gatewayId: value?.gatewayId,
        merchantName:value?.merchantName,
        merchantCode: value?.merchantCode,
        merchantKey: value?.merchantKey,
        privateKeyLink: value?.privateKeyLink,
        publicKeyLink: value?.publicKeyLink,
      };
      console.log('body>>>>', body);
      apiManager
        .post('addMerchantCodes', body)
        .then(response => {
          console.log(response, 'response=12002');
          if (response.message === 11010) {
            setLoading(false);
            setsuccess('Successfully Sent To All Users');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Merchant has been successfully recordd`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
          if (response.message === 11009) {
            setLoading(false);
            setError('Failed');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Merchant Code Adding Failed`,
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  console.log('Data=', data);
  console.log('value=', value);
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Add Merchant',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Add Merchant',
        })}
      </Flex>
      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {dropdownCustomSvalue({
            title: 'Gateway Id :',
            mandatory: true,
            placeholder: 'Select Option',
            temparray: data,
            onChange: e => handleonChange(e, 'gatewayId'),
          })}
          {customInputLabel({
            title: 'Merchant Name :',
            mandatory: true,
            onChange: e => handleonChange(e, 'merchantName'),
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Merchant Code :',
            mandatory: true,
            onChange: e => handleonChange(e, 'merchantCode'),
          })}
          {customInputLabel({
            title: 'Merchant Key :',
            mandatory: true,
            onChange: e => handleonChange(e, 'merchantKey'),
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Private Key Link :',
            // type: 'file',
            // accept: '.pem,.pub',
            mandatory: true,
            onChange: e => handleonChange(e, 'privateKeyLink'),
          })}
          {customInputLabel({
            title: 'Public Key Link :',
            mandatory: true,
            // type: 'file',
            // accept: '.pem,.pub',
            onChange: e => handleonChange(e, 'publicKeyLink'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default AddMerchantCode;
