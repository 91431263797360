import React from 'react';
import {
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Text,
  Table,
  Tbody,
  Thead,
  Divider,
  Heading,
  useToast,
  SimpleGrid,
  TableContainer,
} from '@chakra-ui/react';
import {
  customResetBtn,
  customBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import '../App.css';
import { BsCheckCircle } from 'react-icons/bs';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import Loading from '../components/common/Loading';
import { useLocation, useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';

const PaymentGatewayViewActions = () => {
  let initialValue = {
    email: '',
    userId: '',
    name: '',
    country: '',
    profileId: '',
    address: '',
    MSISDN: '',
    ageGroup: '',
    isXoxUser: '',
    ssoMigrated: '',
    emailVerified: '',
    maritalStatus: '',
  };
  let apiManager = ApiManager.getInstance();
  const [error, setError] = React.useState(null);
  const [actions, setActions] = React.useState([]);
  const [ssUser, setSsoUser] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [value, setValue] = React.useState(initialValue);
  const [middlewareUser, setMiddleUser] = React.useState([]);
  const [loadingApiCall, setLoadingApiCall] = React.useState(false);

  let location = useLocation();
  let history = useHistory();
  let toast = useToast();
  let data = '';
  React.useEffect(() => {
    console.log('dataa', location);

    if (location) {
      console.log('Location Data', location);
      data = location?.state.data;
      setValue({
        ...value,
        email: data?.email,
        userId: data?.userId,
        country: data?.country,
        profileId: data?.profileId,
        address: data?.address,
        MSISDN: data?.MSISDN,
        ageGroup: data?.ageGroup,
        isXoxUser: data?.isXoxUser,
        maritalStatus: data?.maritalStatus,
        emailVerified: data?.emailVerified,
        ssoMigrated: data?.ssoMigrated,
        name: data?.firstName + ' ' + data?.lastName,
      });
      getUserDetailsForCMSApi(data);
    }
  }, [location]);

  console.log('UserDetailValue>>', value);

  const getUserDetailsForCMSApi = userId => {
    console.log('UserIDD=>', userId);
    setLoadingApiCall(true);
    let tempArray = [];
    let body = {
      gatewayId: userId,
    };
    apiManager
      .post('getGatewayActions', body)
      .then(res => {
        console.log('getAllActions=>RES', res);
        if (res.message === 11006) {
          console.log('Get getAllActions', res);
          console.log('Get getAllActions', res?.actions[0].actionName);

          setActions(res?.actions);
          // pageCount = res.results.totalDocs;
          // setSsoUser(res?.result?.ssoUser);
          // setMiddleUser(res?.result?.middlewareUser);

          // if (res?.result?.ssoUser) {
          //   setSsoUser(res?.result?.ssoUser);
          // } else if (res?.result?.result) {
          //   setSsoUser(res?.result?.result);
          // }
          setLoadingApiCall(false);
        }
      })
      .catch(error => {
        setLoadingApiCall(false);
        console.log('error', error);
      });
  };

  const onClickEmailVerification = () => {
    setLoadingApiCall(true);
    const body = {
      userId: value?.userId,
    };
    apiManager
      .post('verifyUserEmail', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 4030) {
          setLoadingApiCall(false);

          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            duration: 3000,
            isClosable: true,
            status: 'success',
            description: `Recorded, User Is successfully Varified`,
          });
        }
        getUserDetailsForCMSApi(value?.userId);
      })
      .catch(error => {
        console.log(error);
        setLoadingApiCall(true);
      });
  };

  const noName = tempName => {
    console.log('tempName', tempName);
    if (
      tempName === 'undefined undefined' ||
      tempName === '' ||
      tempName === ' '
    ) {
      return 'No-Name';
    } else {
      return tempName;
    }
  };

  const goToBackRefreshPage = () => {
    history.push('/');
  };

  const deleteRecord = data => {
    setLoading(true);

    console.log('deleteRecord', data);
    // deleteS3(data.companyLogo);
    let body = {
      userId: data,
    };
    apiManager
      .delete('deleteUserFromCMS', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 4031) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
          // getUserDetailsForCMSApi(value?.userId);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          // getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const deleteFromSelfcareRecord = data => {
    setLoading(true);

    console.log('deleteFromSelfcareRecord', data);
    // deleteS3(data.companyLogo);
    let body = {
      profileId: data,
    };
    apiManager
      .post('deleteSelfcareProfile', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 2004) {
          setLoading(false);
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted Selfcare Profile!`,
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
          // getUserDetailsForCMSApi(value?.userId);
        } else {
          setLoading(false);
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Deleted Selfcare Profile!`,
          });
          // getAllCategory(1, value);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'Inactive';
    } else if (value === 1) {
      return 'Active';
    }
  };

  const convertingDate = date => {
    let newDate = new Date(date);
    return newDate.toLocaleString();
  };

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Actions',
          path: 'Dashboard',
          path1: 'View Actions',
        })}
      </Flex>

      <Flex {...style.box}>
        {!loadingApiCall ? (
          <Flex
            flexDirection={'row'}
            justifyContent={'space-around'}
            background={'white'}
          >
            <Flex
              padding={'2%'}
              marginRight={'5%'}
              background={'white'}
              flexDirection={'column'}
              width={'100%'}
              justifyContent={'space-around'}
            >
              <Heading
                textAlign={'center'}
                marginY={'5%'}
                className={'headingMiddleware'}
              >
                Actions
              </Heading>

              <TableContainer>
                <Table size="sm">
                  <Thead marginTop={10}>
                    <Tr>
                      <Th fontSize={'lg'}>Action Name</Th>
                      <Th fontSize={'lg'}>Gateway Name</Th>
                      <Th fontSize={'lg'}>GatewayID</Th>
                      <Th fontSize={'lg'}>Merchant Code</Th>
                      <Th fontSize={'lg'}>Date & Time</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {actions &&
                      actions?.map((value, index) => {
                        console.log('Actions=>', value);
                        // let time = value?.createdAt;
                        // let date = new Date(time);
                        // console.log('time', date.toLocaleString());
                        return (
                          <Tr key={index} {...style.tr}>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.actionName}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.gatewayName}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.gatewayId}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {value?.merchantCode}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {convertingDate(value?.createdAt)}
                            </Td>
                          </Tr>
                        );
                      })}
                    {/* <Tr>
                      <Td>Action Name</Td>
                      <Td fontWeight={'bold'}>{actions?.actionName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Gateway Name</Td>
                      <Td fontWeight={'bold'}>{ssUser?.email}</Td>
                    </Tr>
                    <Tr>
                      <Td>MSISDN</Td>
                      <Td fontWeight={'bold'}>{ssUser?.MSISDN}</Td>
                    </Tr>
                    <Tr>
                      <Td>Profile Id</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.profileId ? ssUser?.profileId : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Country</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.country ? ssUser?.country : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Address</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.address ? ssUser?.address : '--'}
                      </Td>
                    </Tr> */}

                    {/* <Tr>
                      <Td>Is Xox User</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.isXoxUser === false ? (
                          <Text>No</Text>
                        ) : ssUser?.isXoxUser === true ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>User ID</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.userId ? ssUser?.userId : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>SSO Migrated</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.ssoMigrated === false ? (
                          <Text>No</Text>
                        ) : ssUser?.ssoMigrated === true ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td fontWeight={'bold'}>
                        {changeStatus(ssUser?.status)}
                      </Td>
                    </Tr> */}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>

            {/* <Flex
              padding={'2%'}
              marginLeft={'5%'}
              width={'50%'}
              background={'white'}
              flexDirection={'column'}
              justifyContent={'space-around'}
            >
              <Heading
                textAlign={'center'}
                marginY={'5%'}
                className="headingMiddleware"
              >
                Middleware
              </Heading>
              <TableContainer>
                <Table
                  size="sm"
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Thead>
                    <Tr>
                      <Th>Title</Th>
                      <Th>Detail</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td fontWeight={'bold'}>
                        {noName(middlewareUser?.full_name)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Email</Td>
                      <Td fontWeight={'bold'}>{middlewareUser?.email}</Td>
                    </Tr>
                    <Tr>
                      <Td>MSISDN</Td>
                      <Td fontWeight={'bold'}>{middlewareUser?.msisdn}</Td>
                    </Tr>
                    <Tr>
                      <Td>Profile Id</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.profileId
                          ? middlewareUser?.profileId
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Name & black number</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.full_name_with_black_number
                          ? middlewareUser?.full_name_with_black_number
                          : '--'}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Has Pin</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.has_pin?.toString()
                          ? middlewareUser?.has_pin?.toString()
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Address</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.address
                          ? middlewareUser?.address
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>User ID</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.user_id
                          ? middlewareUser?.user_id
                          : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Is Xox User</Td>
                      <Td fontWeight={'bold'}>
                        {middlewareUser?.is_xox_user === 0 ? (
                          <Text>No</Text>
                        ) : middlewareUser?.is_xox_user === 1 ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Status</Td>
                      <Td fontWeight={'bold'}>
                        {changeStatus(middlewareUser?.status)
                          ? changeStatus(middlewareUser?.status)
                          : '--'}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex> */}
          </Flex>
        ) : (
          // <Tr>
          //   <Td></Td>
          //   <Td></Td>
          //   <Td paddingY={'15vh'}>
          <Flex
            style={{
              w: '100%',
              height: '50vh',
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loading />
          </Flex>
          //   </Td>
          //   <Td></Td>
          //   <Td></Td>
          // </Tr>
        )}

        <Flex
          padding={'2%'}
          marginTop={'10'}
          background={'white'}
          flexDirection={'column'}
        >
          {/* <Text fontSize={'lg'} marginBottom={2}>
            Operation
          </Text> */}
          <Divider orientation="horizontal" />
          {/* <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
            <Flex alignItems={'center'} paddingY={3}>
              <Text
                //   fontWeight={'black'}
                fontSize={'small'}
                color={'black'}
              >
                Email Verification :{' '}
              </Text>
              {ssUser?.emailVerified === 0 ? (
                <>
                  <Text
                    fontWeight={'bold'}
                    fontSize={'xlg'}
                    color={'green'}
                    marginLeft={2}
                  >
                    Varified
                  </Text>
                  <BsCheckCircle
                    style={{
                      fontWeight: 'bold',
                      marginLeft: 2,
                      color: 'green',
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </>
              ) : ssUser?.emailVerified === 1 ? (
                <Text
                  fontWeight={'bold'}
                  fontSize={'lg'}
                  marginLeft={2}
                  color={'gray.700'}
                >
                  <Text>Unvarified</Text>
                </Text>
              ) : null}
            </Flex>
          </SimpleGrid> */}
          <Divider orientation="horizontal" />
          
        </Flex>
      </Flex>
      <Divider marginStart={5} orientation="horizontal" />
      {renderCopyRight()}
    </Flex>
  );
};

export default PaymentGatewayViewActions;
