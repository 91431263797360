import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';

import {
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  MiniHeader,
} from '../components/common/customComponent';

const CreateCampaignRewards = () => {
  console.log('CreateCampaignRewards gg bro');
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      {MiniHeader({
        title: 'Create Campaign Game Reward',
        path: 'Dashboard /App Setup / Campaign Game Rewards / Create Campaign Game Reward',
      })}

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
          {dropdownCustom({
            title: 'Campaign',
            mandatory: true,
          })}{' '}
          {dropdownCustom({
            title: 'Game',
            mandatory: true,
          })}{' '}
          {dropdownCustom({
            title: 'Reward',
            mandatory: true,
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateCampaignRewards;
