import React, { useState, useEffect } from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
} from '@chakra-ui/react';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';

import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';

const AssignMerchantCodesToActions = () => {
  let initialValue = {
    gatewayId: '',
    gatewayName: '',
    merchantKey: '',
    merchantKeyId: '',
    actionId: '',
    merchantName: '',
    merchantCode: '',
  };

  let tempPaymentMethod = [
    { list: 'IPAY88', val: '1' },
    { list: 'NPG', val: '2' },
  ];

  const [showDetailsView, setShowDetailsView] = useState(false);
  const [merhantKeySingle, setMerchantKeySingle] = useState('');
  const [actions, setActionArray] = React.useState([]);
  const [changeApi, setChangeApi] = useState(false);
  const [success, setSuccess] = React.useState(null);
  const [merchantK, setMerchantK] = useState([]);
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState(null);
  const [details, setDetails] = useState([]);

  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const location = useLocation();
  const toast = useToast();

  let mandatoryFields = {
    gatewayId: true,
    actionId: true,
  };

  useEffect(() => {
    if (location?.state?.data != null || location?.state?.data != undefined) {
      console.log('PREVIOUS DATA', location);
      setChangeApi(location?.state?.useEditApi);
      let dataOfEdit = location?.state?.data;
      console.log('dataOfEdit', dataOfEdit);
      setValue({
        ...value,
        gatewayId: dataOfEdit?.gatewayId,
        merchantKey: dataOfEdit?.merchantKey,
        merchantCode: dataOfEdit?.merchantCode,
        merchantName: dataOfEdit?.merchantName,
        gatewayName: dataOfEdit?.gatewayName,
        merchantKeyId: dataOfEdit?.merchantKeyId,
      });

      getGatewayActions(dataOfEdit?.gatewayId);
    }
    // else {
    //   getAllCmsRoless();
    // }
  }, []);

  const convertToName = (previousId, roleData) => {
    console.log('IDD=>', previousId);
    console.log('roleData=>', roleData);
    let idSingle = '';

    let tempName = '';
    roleData?.map((dat, index) => {
      console.log('dat==<name and id>', dat);
      console.log('dat==<Name>', dat[0]);
      console.log('dat==<ID>', dat[1]);
      if (previousId === dat[1]) {
        console.log('dat[1]', dat);
        idSingle = dat[1];
        tempName = dat[0];
      }
    });

    // setValue({
    //   // value,
    //   oldRoleName: tempName,
    // });
    // setOldRole(tempName);
    // oldNameVariable = tempName;
    // // setOldRole(oldNameVariable);

    // console.log('Previous id=>', idSingle);
    // console.log('Previous name=>', tempName);
    // console.log('Previous oldNameVariable=>', oldNameVariable);
  };

  const handleonChangeMerchantName = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', ev);
    setActionArray('');

    getGatewayActions(ev);

    setValue({
      ...value,
      [key]: ev,
    });
  };
  const handleonChangeAction = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', ev);

    setValue({
      ...value,
      [key]: ev,
    });

    merchantK?.map((value, index) => {
      console.log('Action Names', ev);
      console.log('Action Names', ev === value.list);

      if (ev === value.list) {
        console.log('Merchant Key will be', value?.val);

        setMerchantKeySingle(value?.val);
      }
    });
  };
  //   console.log('Value of everything', value);

  const getGatewayActions = gatewayID => {
    console.log('gatewayIDD=>', gatewayID);
    // setLoadingApiCall(true);
    let tempArray = [];
    let tempMerchat = [];
    let body = {
      gatewayId: gatewayID,
    };
    apimanager
      .post('getGatewayActions', body)
      .then(res => {
        console.log('getAllActions=>RES', res);
        if (res.message === 11006) {
          console.log('Get getAllActions', res);
          console.log('Get getAllActions actions', res?.actions.actionName);
          res?.actions.map((value, index) => {
            // console.log('value', value);
            tempArray.push({ list: value?.actionName, val: value?.actionId });

            tempMerchat.push({
              list: value?.actionId,
              val: value?.merchantKey,
            });
          });
          setMerchantK(tempMerchat);

          setActionArray(tempArray);
        }
      })
      .catch(error => {
        // setLoadingApiCall(false);
        console.log('error', error);
      });
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    console.log(value, 'Value');
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      const body = {
        gatewayId: value?.gatewayId,
        merchantKeyId: value?.merchantKeyId,
        actionId: value?.actionId,
        merchantName: value['merchantName'],
      };
      console.log('Update body', body);
      apimanager
        .post('assignMerchantCodesToAction', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 11011) {
            setDetails(response?.action);
            setShowDetailsView(true);
            setSuccess('Successfully Assigned!');
            setLoading(false);
            toast({
              title: success,
              duration: 2000,
              isClosable: true,
              status: 'success',
              description: `User has been Updated!`,
            });
            // setTimeout(() => {
            //   history.goBack();
            // }, 3000);
          } else if (response.message === 11003) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Error',
              description: `Choose other payment method!`,
            });
          } else {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Error',
              description: `Not Allowed to do this operation!`,
            });
            // }
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const convertingDate = date => {
    let newDate = new Date(date);
    return newDate.toLocaleString();
  };

  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: !changeApi ? 'Create User' : 'Assign Merchant Code',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Payment',
          useSlash: true,
          path2: 'Merchant',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Merchant Code',
            value: value['merchantCode'],
            disabled: true,
            mandatory: false,
            // onChange: e => setCurrentEmail(e.target.value),
          })}

          {customInputLabel({
            title: 'Merchant Name',
            value: value['merchantName'],
            // disabled: true,
            mandatory: true,
            onChange: e => handleonChangeMerchantName(e, 'merchantName'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {dropdownCustomSvalue({
            title: 'Gateway Method',
            mandatory: true,
            temparray: tempPaymentMethod,
            placeholder: 'Select Method',
            onChange: e => handleonChange(e, 'gatewayId'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={5}>
          {dropdownCustomSvalue({
            title: 'Actions',
            mandatory: true,
            temparray: actions,
            placeholder: 'Select Option',
            onChange: e => handleonChangeAction(e, 'actionId'),
          })}

          {customInputLabel({
            title: 'Selected Merchant Code',
            value: merhantKeySingle,
            disabled: true,
            mandatory: false,
            // onChange: e => setCurrentEmail(e.target.value),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Assign',
          title2: 'Go Back',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
        {showDetailsView ? (
          <Flex
            width={'100%'}
            padding={'2%'}
            marginTop={'-5%'}
            marginRight={'5%'}
            background={'white'}
            flexDirection={'column'}
            justifyContent={'space-around'}
          >
            <Heading
              textAlign={'center'}
              marginY={'5%'}
              className={'headingMiddleware'}
            >
              Current Details
            </Heading>

            <TableContainer>
              <Table size="sm">
                <Thead marginTop={10}>
                  <Tr>
                    <Th fontSize={'lg'}>Action Name</Th>
                    <Th fontSize={'lg'}>Gateway Name</Th>
                    <Th fontSize={'lg'}>GatewayID</Th>
                    <Th fontSize={'lg'}>Merchant Code</Th>
                    <Th fontSize={'lg'}>Merchant Key</Th>
                    <Th fontSize={'lg'}>Date & Time</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    // details &&
                    //   details?.map((value, index) => {
                    console.log('Actions=>', value)
                  }
                  {/* // let time = value?.createdAt;
                      // let date = new Date(time);
                      // console.log('time', date.toLocaleString()); */}
                  {/* return ( */}
                  <Tr {...style.tr}>
                    <Td
                      flex={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {details?.actionName}
                    </Td>
                    <Td
                      flex={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {details?.gatewayName}
                    </Td>
                    <Td
                      flex={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {details?.gatewayId}
                    </Td>
                    <Td
                      flex={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {details?.merchantCode}
                    </Td>
                    <Td
                      flex={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {details?.merchantKey}
                    </Td>
                    <Td
                      flex={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {convertingDate(details?.createdAt)}
                    </Td>
                  </Tr>
                  {/* ); */}
                  {/* // })} */}
                  {/* <Tr>
                      <Td>Action Name</Td>
                      <Td fontWeight={'bold'}>{actions?.actionName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Gateway Name</Td>
                      <Td fontWeight={'bold'}>{ssUser?.email}</Td>
                    </Tr>
                    <Tr>
                      <Td>MSISDN</Td>
                      <Td fontWeight={'bold'}>{ssUser?.MSISDN}</Td>
                    </Tr>
                    <Tr>
                      <Td>Profile Id</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.profileId ? ssUser?.profileId : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Country</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.country ? ssUser?.country : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Address</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.address ? ssUser?.address : '--'}
                      </Td>
                    </Tr> */}

                  {/* <Tr>
                      <Td>Is Xox User</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.isXoxUser === false ? (
                          <Text>No</Text>
                        ) : ssUser?.isXoxUser === true ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>User ID</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.userId ? ssUser?.userId : '--'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>SSO Migrated</Td>
                      <Td fontWeight={'bold'}>
                        {ssUser?.ssoMigrated === false ? (
                          <Text>No</Text>
                        ) : ssUser?.ssoMigrated === true ? (
                          <Text>Yes</Text>
                        ) : null}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td fontWeight={'bold'}>
                        {changeStatus(ssUser?.status)}
                      </Td>
                    </Tr> */}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        ) : null}
      </Flex>
      <Spacer />
      <Divider
        marginLeft={10}
        orientation="horizontal"
        marginTop={5}
        marginBottom={2}
      />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default AssignMerchantCodesToActions;