import React, { useState, useEffect } from 'react';
import '../styles/styles.css';
import style from './Reports/styles';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import 'react-datepicker/dist/react-datepicker.css';
import LangContext from '../context/languageContext';
import {
  Tr,
  Td,
  Text,
  Flex,
  Circle,
  Tooltip,
  useToast,
  SimpleGrid,
  Button,
  Select,
  Spacer,
} from '@chakra-ui/react';
import { option } from '../context/data';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { MiniHeader } from '../components/common/customComponent';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';
import { CSVLink } from 'react-csv';

const LineManagement = () => {
  let quickSelect = [
    'Yesterday',
    'Last 7 Days',
    'Last 28 Days',
    'This Week',
    'This Month',
    'This Year',
    'Last Week',
    'Last month',
  ];
  let initialValue = {
    quickSelect: '',
    actionType: '',
    numrecords: '',
    gatewayId: '',
    paymentId: '',
    status: '',
  };

  let actionType = [
    { list: 'BLACKAPP_Paybill', val: 1 },
    {
      list: 'BLACKAPP_SPPurchasePostpaid',
      val: 2,
    },
    { list: 'BLACKAPP_AddOnPostpaid', val: 3 },
    {
      list: 'BLACKAPP_DowngradeToPrepaid',
      val: 4,
    },
    { list: 'BLACKAPP_UpgradeOrDowngrade', val: 5 },
    {
      list: 'BLACKAPP_COBPToBlack',
      val: 6,
    },
    { list: 'BLACKAPP_TopupPrepaid', val: 7 },
    {
      list: 'BLACKAPP_EWalletTopup',
      val: 8,
    },
    { list: 'BLACKAPP_SPPurchasePrepaid', val: 9 },
    { list: 'BLACKAPP_BindCard', val: 10 },
    {
      list: 'dataSubscription',
      val: 11,
    },
    { list: 'BLACKAPP_ShareSeasonPass', val: 12 },
    {
      list: 'BLACKAPP_AutoDebit',
      val: 13,
    },
    { list: 'BLACKAPP_OneTimeDeal', val: 14 },
    {
      list: 'BLACKAPP_OneTimeDealPrepaid',
      val: 37,
    },
    { list: 'BLACKAPP_OneTimeDealPostpaid', val: 38 },
    {
      list: 'BLACKAPP_StandingRegistration',
      val: 44,
    },
    { list: 'BLACKAPP_StandingTermination', val: 46 },
    {
      list: 'BLACKAPP_WelcomeDeal',
      val: 47,
    },
    {
      list: 'BLACKAPP_FastOrderPostpaid',
      val: 40,
    },
    {
      list: 'BLACKAPP_FastOrderPrepaid',
      val: 42,
    },
    { list: 'SPPurchaseUsingEwallet', val: 51 },
    {
      list: 'SPPurchaseUsingAirtime',
      val: 52,
    },
    { list: 'BLACKMAEKET_Payment', val: 53 },
    {
      list: 'ONLINESTORE_BlackOrder',
      val: 16,
    },
    { list: 'ONLINESTORE_MNPOrderPostpaid', val: 17 },
    {
      list: 'ONLINESTORE_PrepaidOrder',
      val: 18,
    },
    { list: 'ONLINESTORE_FastOrderPostpaid', val: 19 },
    {
      list: 'ONLINESTORE_MNPOrderPrepaid',
      val: 20,
    },
    { list: 'ONLINESTORE_FastOrderPrepaid', val: 21 },
    {
      list: 'SELFCAREPORTAL_PayBills',
      val: 22,
    },
    { list: 'SELFCAREPORTAL_SPPurchasePostpaid', val: 23 },
    {
      list: 'SELFCAREPORTAL_UpgradeOrDowngrade',
      val: 24,
    },
    { list: 'SELFCAREPORTAL_COBPToBlack', val: 25 },
    {
      list: 'SELFCAREPORTAL_TopupPrepaid',
      val: 26,
    },
    { list: 'SELFCAREPORTAL_EWalletTopup', val: 27 },
    {
      list: 'SELFCAREPORTAL_SPPurchasePrepaid',
      val: 28,
    },
    { list: 'SELFCAREPORTAL_AutoDebit', val: 29 },
    {
      list: 'SELFCAREPORTAL_OneTimeDeal',
      val: 30,
    },
    { list: 'SELFCAREPORTAL_DowngradeToPrepaid', val: 31 },
    {
      list: 'SELFCAREPORTAL_SimReplacementPostpaid',
      val: 35,
    },
    { list: 'SELFCAREPORTAL_SimReplacementPrepaid', val: 36 },
    {
      list: 'SELFCAREPORTAL_StandingRegistration',
      val: 43,
    },
    { list: 'SELFCAREPORTAL_StandingTermination', val: 45 },
    {
      list: 'SELFCAREPORTAL_WelcomeDeal',
      val: 48,
    },
    { list: 'ONLINESTORE_individual_dealerRegistration', val: 49 },
    {
      list: 'ONLINESTORE_company_dealerRegistration',
      val: 50,
    },
    {
      list: 'Refferal_relation',
      val: 55,
    },
  ];

  let gatewayId = [
    { list: 'ipay88', val: '1' },
    { list: 'npg', val: '2' },
  ];

  let status = [
    { list: 'Success', val: '1' },
    { list: 'Failed', val: '0' },
    { list: 'Pending', val: '2' },
    { list: 'Rejected', val: '3' },
  ];

  let paymentId = [
    { list: 'Credit-Card', val: 2 },
    { list: 'Online Banking', val: 999 },
    { list: 'Air-Time', val: 199 },
    { list: 'E-Wallet', val: 198 },
  ];

  let tempArray = [];
  const toast = useToast();
  let apimanager = ApiManager.getInstance();

  const [page, setPage] = useState('');
  const [type, setType] = useState('');
  const [sDate, setSDate] = useState('');
  const [eDate, setEDate] = useState('');
  const [range, setRange] = useState('');
  const [dataa, setDataa] = React.useState([]);
  const [totalPage, setTotalPage] = useState('');
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [dataCsv, setDataCsv] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [valueSearch, setValueSearch] = React.useState();
  const [payload, setPayload] = React.useState(initialValue);
  const { currentLangData } = React.useContext(LangContext);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const handleType = selectedName => {
    if (selectedName === 'Yesterday') {
      return 1;
    } else if (selectedName === 'Last 7 Days') {
      return 2;
    } else if (selectedName === 'Last 28 Days') {
      return 3;
    } else if (selectedName === 'This Week') {
      return 4;
    } else if (selectedName === 'This Month') {
      return 5;
    } else if (selectedName === 'This Year') {
      return 6;
    } else if (selectedName === 'Last Week') {
      return 7;
    } else if (selectedName === 'Last Month') {
      return 8;
    }
  };

  let history = useHistory();
  let columsOfData = [
    'Email',
    'Msisdn',
    'Name',
    'Transaction Type',
    'ReferenceNumber',
    'Payment Method',
    'Gateway Id',
    'Action Type',
    'Amount',
    'Description',
    'Status',
    'Date',
  ];

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    let tempType = handleType(ev);
    setType(tempType);
    // setSDate('');
    // setEDate('');
    // setPayload({
    //   // ...payload,
    //   actionType: '',
    // });
    setPayload({
      ...payload,
      [key]: ev,
    });

    getALLItems(
      payload['numrecords'],
      tempType,
      page,
      sDate,
      eDate,
      payload?.actionType,
      valueSearch,
      payload?.gatewayId,
      payload?.paymentId,
      payload?.status
    );
  };

  const handleonChangeActionType = (evt, key) => {
    const ev = evt.target.value;
    // console.log('EVT=>', evt.target.value);
    // let tempType = handleType(ev);
    // setRange(tempType);
    // setType('');
    // setSDate('');
    // setEDate('');

    getALLItems(
      payload['numrecords'],
      type,
      page,
      sDate,
      eDate,
      ev,
      valueSearch,
      payload?.gatewayId,
      payload?.paymentId,
      payload?.status
    );
    setPayload({
      ...payload,
      [key]: ev,
    });
  };

  const handleonChangeGatewayId = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    // let tempType = handleType(ev);
    // setRange(tempType);
    // setType('');
    // setSDate('');
    // setEDate('');
    setPayload({
      ...payload,
      [key]: ev,
    });

    getALLItems(
      payload['numrecords'],
      type,
      page,
      sDate,
      eDate,
      payload?.actionType,
      valueSearch,
      ev,
      payload['paymentId'],
      payload?.status
    );
  };

  const handleonChangeStatus = (evt, key) => {
    let statusValue = evt.target.value;
    console.log('EVT=>', evt.target.value);
    // let tempType = handleType(ev);
    // setRange(tempType);
    // setType('');
    // setSDate('');
    // setEDate('');
    setPayload({
      ...payload,
      [key]: statusValue,
    });

    getALLItems(
      payload?.numrecords,
      type,
      page,
      sDate,
      eDate,
      payload?.actionType,
      valueSearch,
      payload?.gatewayId,
      payload?.paymentId,
      statusValue
    );
  };
  console.log('payload', payload);

  const handleonChangePaymentId = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    // let tempType = handleType(ev);
    // setRange(tempType);
    // setType('');
    // setSDate('');
    // setEDate('');
    setPayload({
      ...payload,
      [key]: ev,
    });

    getALLItems(
      payload['numrecords'],
      type,
      page,
      sDate,
      eDate,
      payload?.actionType,
      valueSearch,
      payload?.gatewayId,
      ev,
      payload?.status
    );
  };

  const handleonChangeSelect = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    // let tempType = handleType(ev);
    // setType(tempType);
    // setSDate('');
    // setEDate('');
    setPayload({
      ...payload,
      [key]: ev,
    });

    getALLItems(
      ev,
      type,
      page,
      sDate,
      eDate,
      payload?.actionType,
      valueSearch,
      payload?.gatewayId,
      payload?.paymentId,
      payload?.status
    );
  };

  const handleDate = e => {
    console.log('Date==>', e);
    let startDate = new Date(e[0]);
    let endDate = new Date(e[1]);

    console.log('StartD =>', startDate.getTime());
    console.log('EndD =>', endDate.getTime());
    let finalStartDate = startDate.getTime();
    let finalEndDate = endDate.getTime();
    setSDate(finalStartDate);
    setEDate(finalEndDate);
    setDateRange(e);
    // setType('');
    // setPayload({
    //   actionType: '',
    // });

    getALLItems(
      payload['numrecords'],
      type,
      page,
      finalStartDate,
      finalEndDate,
      payload?.actionType,
      valueSearch,
      payload?.gatewayId,
      payload?.paymentId,
      payload?.status
    );
  };

  const csvReport = {
    filename: 'lineManagementReport.csv',
    // Headers: colNames,
    data: dataCsv,
  };

  useEffect(() => {
    getALLItems();
  }, [1]);

  const convertStatus = status => {
    if (status == 1) return 'Success';
    else if (status == 0) return 'Failed';
    else if (status == 2) return 'Pending';
    else if (status == 3) return 'Rejected';
  };
  const convertPaymentMethod = paymentMethod => {
    if (paymentMethod == 2) return 'Credit-Card';
    else if (paymentMethod == 999) return 'Online Banking';
    else if (paymentMethod == 199) return 'Air-Time';
    else if (paymentMethod == 198) return 'E-Wallet';
    else return paymentMethod;
  };

  const convertGatewayId = gatewayId => {
    if (gatewayId == 1) return 'Ipay88';
    else if (gatewayId == 2) return 'NPG';
    else return gatewayId;
  };

  const convertcreatedAt = createdAt => {
    let tempDate = new Date(createdAt);
    return tempDate.toUTCString();
  };

  const getALLItems = (
    Range,
    type,
    page,
    startD,
    endD,
    aType,
    searchPhrase,
    gatewayId,
    paymentId,
    status
  ) => {
    console.log(
      'bodygetAllItem',
      Range,
      type,
      page,
      startD,
      endD,
      aType,
      searchPhrase,
      gatewayId,
      paymentId,
      status
    );
    setLoader(false);
    const body = {
      limit: Range,
      type: type,
      startDate: endD,
      endDate: startD,
      page: page,
      actionKey: parseInt(aType),
      searchPhrase: searchPhrase,
      gatewayId: gatewayId,
      paymentId: parseInt(paymentId),
      status: parseInt(status),
    };
    console.log('body=>', body);
    apimanager
      .post('transactionReport', body)
      .then(res => {
        console.log('Line Management Report=>', res);
        console.log('Pages=>', res?.data?.totalPages);
        let tempCSV = [];

        if (res.message === 11017) {
          res?.data?.docs?.map((value, index) => {
            tempArray?.push(value);
            // console.log('Value ==>', value);

            let {
              userEmail,
              userContact,
              createdAt,
              extraParams,
              transactionType,
              referenceNumber,
              paymentMethod,
              amount,
              description,
              gatewayId,
              status,
              actionType,
              apiGatewayStatus,
              remarks,
              userId,
              profileId,
              currency,
            } = value;
            let convertedStatus = convertStatus(status);
            let convertedCreatedAt = convertcreatedAt(createdAt);
            let convertedpaymentMethod = convertPaymentMethod(paymentMethod);
            let convertedGatewayId = convertGatewayId(gatewayId);
            let convertedActionType = handleActionType(actionType);
            tempCSV.push({
              UserName: extraParams?.userName,
              Email: userEmail,
              MSISDN: userContact,
              Time: convertedCreatedAt,
              ActionType: convertedActionType,
              Description: description,
              TransactionType: transactionType,
              ReferenceNumber:referenceNumber,
              PaymentMethod: convertedpaymentMethod,
              GatewayId: convertedGatewayId,
              Amount: amount,
              Status: convertedStatus,
              ApiGatewayStatus: apiGatewayStatus,
              Remarks: remarks,
              UserId: userId,
              ProfileId: profileId,
              Currency: currency,
            });
          });
          // console.log('tempCSV', tempCSV);

          setDataCsv(tempCSV);
          setTotalPage(res?.data?.totalPages);
          setpaginate(res?.data);
          setDataa(tempArray);
          setLoader(true);
        }
        if (res.message === 8336) {
          setLoader(true);
          toast({
            title: 'Mongodb pagination Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
        if (res.message === 2454) {
          setLoader(true);
          toast({
            title: 'No Data Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Add Data to Display.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  // const searchItems = (value, limit, page) => {
  //   // console.log(limit,"limit,page",page)
  //   setLoader(false);
  //   const body = {
  //     searchPhrase: value,
  //     numRecords: limit,
  //     page: page,
  //   };
  //   console.log('body', body);
  //   apimanager
  //     .post('searchRecommendations', body)
  //     .then(res => {
  //       console.log('res=>serch', res);
  //       if (res.message === 8719) {
  //         // console.log('Get Condition responce', res);
  //         // let data = res?.results?.docs;
  //         // console.log("data>>>>>>>data",data)
  //         res?.record?.docs.map((value, index) => {
  //           tempArray.push(value);
  //         });
  //         setDataa(tempArray);
  //         setpaginate(res?.result);
  //         setLoader(true);
  //       }
  //     })
  //     .catch(error => {
  //       console.log('error', error);
  //       setLoader(true);
  //     });
  // };

  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    if (evt.key === 'Enter') {
      console.log(evt.key, 'do validate');
      setValueSearch(ev);
      // searchItems(ev, value, 1);

      getALLItems(
        payload['numrecords'],
        type,
        page,
        sDate,
        eDate,
        payload?.actionType,
        ev,
        payload?.gatewayId,
        payload?.paymentId,
        payload?.status
      );
    }
  };

  const handlePaginationGetData = page => {
    setPage(page);
    getALLItems(
      payload['numrecords'],
      type,
      page,
      sDate,
      eDate,
      payload?.actionType,
      valueSearch,
      payload?.gatewayId,
      payload?.paymentId,
      payload?.status
    );
  };

  const handleActionType = action => {
    if (action == 1) return 'BLACKAPP_Paybill';
    else if (action == 2) return 'BLACKAPP_SPPurchasePostpaid';
    else if (action == 3) return 'BLACKAPP_AddOnPostpaid';
    else if (action == 4) return 'BLACKAPP_DowngradeToPrepaid';
    else if (action == 5) return 'BLACKAPP_UpgradeOrDowngrade';
    else if (action == 6) return 'BLACKAPP_COBPToBlack';
    else if (action == 7) return 'BLACKAPP_TopupPrepaid';
    else if (action == 8) return 'BLACKAPP_EWalletTopup';
    else if (action == 9) return 'BLACKAPP_SPPurchasePrepaid';
    else if (action == 10) return 'BLACKAPP_BindCard';
    else if (action == 11) return 'dataSubscription';
    else if (action == 12) return 'BLACKAPP_ShareSeasonPass';
    else if (action == 13) return 'BLACKAPP_AutoDebit';
    else if (action == 14) return 'BLACKAPP_OneTimeDeal';
    else if (action == 37) return 'BLACKAPP_OneTimeDealPrepaid';
    else if (action == 38) return 'BLACKAPP_OneTimeDealPostpaid';
    else if (action == 44) return 'BLACKAPP_StandingRegistration';
    else if (action == 46) return 'BLACKAPP_StandingTermination';
    else if (action == 47) return 'BLACKAPP_WelcomeDeal';
    else if (action == 51) return 'SPPurchaseUsingEwallet';
    else if (action == 52) return 'SPPurchaseUsingAirtime';
    else if (action == 53) return 'BLACKMAEKET_Payment';
    else if (action == 16) return 'ONLINESTORE_BlackOrder';
    else if (action == 17) return 'ONLINESTORE_MNPOrderPostpaid';
    else if (action == 18) return 'ONLINESTORE_PrepaidOrder';
    else if (action == 19) return 'ONLINESTORE_FastOrderPostpaid';
    else if (action == 20) return 'ONLINESTORE_MNPOrderPrepaid';
    else if (action == 21) return 'ONLINESTORE_FastOrderPrepaid';
    else if (action == 22) return 'SELFCAREPORTAL_PayBills';
    else if (action == 23) return 'SELFCAREPORTAL_SPPurchasePostpaid';
    else if (action == 24) return 'SELFCAREPORTAL_UpgradeOrDowngrade';
    else if (action == 25) return 'SELFCAREPORTAL_COBPToBlack';
    else if (action == 26) return 'SELFCAREPORTAL_TopupPrepaid';
    else if (action == 27) return 'SELFCAREPORTAL_EWalletTopup';
    else if (action == 28) return 'SELFCAREPORTAL_SPPurchasePrepaid';
    else if (action == 29) return 'SELFCAREPORTAL_AutoDebit';
    else if (action == 30) return 'SELFCAREPORTAL_OneTimeDeal';
    else if (action == 31) return 'SELFCAREPORTAL_DowngradeToPrepaid';
    else if (action == 35) return 'SELFCAREPORTAL_SimReplacementPostpaid';
    else if (action == 36) return 'SELFCAREPORTAL_SimReplacementPrepaid';
    else if (action == 43) return 'SELFCAREPORTAL_StandingRegistration';
    else if (action == 45) return 'SELFCAREPORTAL_StandingTermination';
    else if (action == 48) return 'SELFCAREPORTAL_WelcomeDeal';
    else if (action == 49) return 'ONLINESTORE_individual_dealerRegistration';
    else if (action == 50) return 'ONLINESTORE_company_dealerRegistration';
    else if (action == 40) return 'BLACKAPP_FastOrderPostpaid';
    else if (action == 42) return 'BLACKAPP_FastOrderPrepaid';
    else if (action == 55) return 'Refferal_relation';
  };

  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Line Management',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: true,
          path2: 'LineManagement',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex
            {...style.datesContainer}
            padding={5}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {DatewithTimePicker({
                title: 'Select Date Range',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,

                changePicker: true, // onChange: e => setDateRange(e),
                onChange: e => handleDate(e),
              })}

              {dropdownCustom({
                title: 'Quick Select Date',
                mandatory: false,
                temparray: quickSelect,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'quickSelect'),
              })}

              {dropdownCustomSvalue({
                title: 'Action Type',
                mandatory: false,
                temparray: actionType,
                placeholder: 'Select Option',
                onChange: e => handleonChangeActionType(e, 'actionType'),
              })}
            </SimpleGrid>
            <SimpleGrid width={'100%'} columns={4} spacingX={'50px'} mt={'30px'}>
              <Flex marginTop={3}>
                {dropdownCustomSvalue({
                  title: 'Gateway ID',
                  mandatory: false,
                  temparray: gatewayId,
                  placeholder: 'Select Option',
                  onChange: e => handleonChangeGatewayId(e, 'gatewayId'),
                })}
              </Flex>

              <Flex marginTop={3}>
                {dropdownCustomSvalue({
                  title: 'Payment Method',
                  mandatory: false,
                  temparray: paymentId,
                  placeholder: 'Select Option',
                  onChange: e => handleonChangePaymentId(e, 'paymentId'),
                })}
              </Flex>
              <Flex marginTop={3}>
                {dropdownCustomSvalue({
                  title: 'Status',
                  mandatory: false,
                  temparray: status,
                  placeholder: 'Select Option',
                  onChange: e => handleonChangeStatus(e, 'status'),
                })}
              </Flex>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                marginLeft={'5'}
                marginTop={8}
              >
                <CSVLink {...csvReport}>
                  <Button {...style.downloadbutton}>
                    {currentLangData?.app?.download}
                  </Button>
                </CSVLink>
              </Flex>
            </SimpleGrid>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.optionFlex}>
              <Flex
                {...style.showentries}
                alignItems={'center'}
                fontSize={'sm'}
              >
                <Text>{currentLangData?.app?.show}</Text>
                <Select
                  {...style.select}
                  onChange={e => handleonChangeSelect(e, 'numrecords')}
                >
                  {option.map(option => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Select>
                <Text>{currentLangData?.app?.entries}</Text>
              </Flex>
            </Flex>
            <Spacer />
            <Flex
              {...style.showentries}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Text fontSize={'sm'} paddingY={'5px'} mr={'4px'} mt={'2px'}>
                {currentLangData?.app?.search}:
              </Text>
              {customInputLabel({
                size: 'sm',
                onKeyDown: e => handleOnSearch(e, 'searchPhrase'),
              })}
            </Flex>
          </Flex>

          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={columsOfData}
            onPageChange={page => handlePaginationGetData(page)}
            renderItem={({ item, index }) => {
              // console.log('renderitem', item);
              let date = item?.createdAt;
              let newDate = new Date(date);
              let finalDate = newDate.toDateString();
              let finalTime = newDate.toLocaleTimeString();

              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.userEmail}</Td>
                  <Td>{item?.extraParams?.msisdn}</Td>
                  <Td>{item?.extraParams?.userName}</Td>
                  <Td>{item?.transactionType}</Td>
                  <Td>{item?.referenceNumber}</Td>
                  <Td>
                    {item?.paymentMethod == 2
                      ? 'Credit-Card'
                      : item?.paymentMethod == 999
                      ? 'Online Banking'
                      : item?.paymentMethod == 199
                      ? 'Air-Time'
                      : item?.paymentMethod == 198
                      ? 'E-Wallet'
                      : item?.paymentMethod}
                  </Td>
                  <Td>
                    {item?.gatewayId === '1'
                      ? 'Ipay88'
                      : item?.gatewayId === '2'
                      ? 'NPG'
                      : null}
                  </Td>
                  <Td>{handleActionType(item?.actionType)}</Td>
                  <Td>{item?.amount}</Td>
                  <Td>
                    <Tooltip label={item?.remarks}>
                      <Text noOfLines={1}>{item?.remarks}</Text>
                    </Tooltip>
                  </Td>
                  {item?.status === 0 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="black"
                        color="white"
                      >
                        <Text fontSize={'sm'}>Failed</Text>
                      </Circle>
                    </Td>
                  ) : item?.status === 1 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'sm'}>Success</Text>
                      </Circle>
                    </Td>
                  ) : item?.status === 2 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'sm'}>Pending</Text>
                      </Circle>
                    </Td>
                  ) : item?.status === 3 ? (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'sm'}>Rejected</Text>
                      </Circle>
                    </Td>
                  ) : (
                    <Td {...style.statusStyle}>
                      <Circle
                        paddingY={'4px'}
                        paddingX={'10px'}
                        bg="#34395e"
                        color="white"
                      >
                        <Text fontSize={'sm'}>Failed</Text>
                      </Circle>
                    </Td>
                  )}
                  <Td>{finalDate}</Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LineManagement;
