import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  GridItem,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import style from '../components/common/style';
import styles from '../styles/mobileBannerStyles';
import { Roles } from '../context/data';
import theme from '../config/color';
import UncontrolledEditor from '../components/textEditor/textEditor';

import {
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useAuthDispatch, useAuthState } from '../context/authContext';
import { useLocation } from 'react-router-dom';

const ChangePassword = () => {
  let initialValue = {
    email: '',
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };

  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [nameRoles, setNameRoles] = React.useState([]);
  const [changeApi, setChangeApi] = useState(false);
  const [loading, setLoading] = React.useState(false);
  let apimanager = ApiManager.getInstance();
  let history = useHistory();
  const location = useLocation();
  const dispatch = useAuthDispatch();
  const toast = useToast();

  let mandatoryFields = {
    oldPassword: true,
    password: true,
    confirmPassword: true,
  };

  let mandatoryField1 = {
    password: true,
    confirmPassword: true,
  };
  console.log('Value', value);

  React.useEffect(() => {
    if (location.state?.data?.email)
      setValue(_p => ({ ..._p, email: location.state?.data?.email }));
    // console.log('pathdata', location);
  }, [location]);

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    setValue({
      ...value,
      [key]: ev,
    });
    console.log('value=>', value);
  };

  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else if (value?.password === value?.confirmPassword) {
      const body = {
        oldPassword: value?.oldPassword,
        newPassword: value?.confirmPassword,
      };
      console.log('body', body);
      apimanager
        .post('changeCMSUserPassword', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 7037) {
            setSuccess('Successfully Recorded!');
            setLoading(false);
            toast({
              title: success,
              duration: 3000,
              isClosable: true,
              status: 'success',
              description: `Login With Your New Password`,
            });
            setTimeout(() => {
              onLogout();
              // history.goBack();
            }, 4000);
          }
          if (response.message === 4001) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Role is not defined',
              description: `Please Select Role!`,
            });
          }
          if (response.message === 7013) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Failed!',
              description: `Old Password incorrect!`,
            });
          }
          if (response.message === 7063) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Email Duplication',
              description: `Use Different Email!`,
            });
          } else if (response.message === 5068) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Something Went Wrong',
              description: `Wait A while Server is not Responding!`,
            });
          } else {
            setLoading(false);
            setError('Please enter mandatory fields');
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setError('Password & ConfirmPassword field must be Same');
      setLoading(false);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description:
          'Failed to Submit.Password & ConfirmPassword field must be Same',
      });
    }
  };

  const onAdminChangePassword = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryField1,
    });
    if (ifError) {
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else if (value?.password === value?.confirmPassword) {
      const body = {
        email: value?.email,
        password: value?.confirmPassword,
      };
      console.log('body', body);
      apimanager
        .post('changePasswordSuperUser', body)
        .then(response => {
          console.log(response, 'response');
          if (response.message === 7108) {
            setSuccess('Successfully Recorded!');
            setLoading(false);
            toast({
              title: success,
              duration: 3000,
              isClosable: true,
              status: 'success',
              description: `Login With Your New Password`,
            });
            setTimeout(() => {
              // onLogout();
              history.goBack();
            }, 4000);
          }
          if (response?.message === 7014) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: response?.errors[1].msg,
            });
          }
          if (response?.errors[0].msg === 7023) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Something went Wrong!Unable to Change Password',
            });
          }
          if (response?.message === 7000) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'User Not Found',
            });
          }
          if (response.message === 5068) {
            setLoading(false);
            toast({
              status: 'error',
              duration: 3000,
              isClosable: true,
              title: 'Something Went Wrong',
              description: `Wait A while Server is not Responding!`,
            });
          } else {
            setLoading(false);
            setError('Please enter mandatory fields');
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setError('Password & ConfirmPassword field must be Same');
      setLoading(false);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description:
          'Failed to Submit.Password & ConfirmPassword field must be Same',
      });
    }
  };

  const onLogout = async () => {
    await localStorage.removeItem('currentUser');
    await localStorage.removeItem('user');
    console.log('pressed Logging out');
    //localStorage.removeItem("mdgSession");
    dispatch('LOGOUT');
    history.push('/Login');
    window?.location?.reload();
  };

  console.log('value==', value?.email);
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Change Password',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'User',
          useSlash: true,
          path2: 'Change Password',
        })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: value?.email ? 'Email' : 'old Password',
            value: value?.email ? value?.email : null,
            disabled: value?.email ? true : false,
            mandatory: true,
            onChange: e => handleonChange(e, 'oldPassword'),
          })}
        </SimpleGrid>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'New Password',
            mandatory: true,
            type: 'password',
            onChange: e => handleonChange(e, 'password'),
          })}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'} marginTop={5}>
          {customInputLabel({
            title: 'Confirm Password',
            mandatory: true,
            type: 'password',
            onChange: e => handleonChange(e, 'confirmPassword'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          isLoading: loading,
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => {
            value?.email ? onAdminChangePassword() : onClickSubmit();
          },
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default ChangePassword;
