import React, { useState, useEffect } from 'react';
import '../styles/styles.css';
import style from './Reports/styles';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import 'react-datepicker/dist/react-datepicker.css';
import LangContext from '../context/languageContext';
import { Flex, Tr, Td, useToast, SimpleGrid } from '@chakra-ui/react';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { MiniHeader } from '../components/common/customComponent';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';

const PageVisited = () => {
  let quickSelect = [
    'Yesterday',
    'Last 7 Days',
    'Last 28 Days',
    'This Week',
    'This Month',
    'This Year',
    'Last Week',
    'Last Month',
  ];
  let initialValue = {
    quickSelect: '',
  };
  let tempArray = [];
  const toast = useToast();
  let apimanager = ApiManager.getInstance();

  const [type, setType] = useState('');
  const [sDate, setSDate] = useState('');
  const [eDate, setEDate] = useState('');
  const [dataa, setDataa] = React.useState([]);
  const [totalPage, setTotalPage] = useState('');
  const [value, setValue] = React.useState('10');
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [payload, setPayload] = React.useState(initialValue);
  const { currentLangData } = React.useContext(LangContext);

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  let history = useHistory();
  let columsOfData = ['Email', 'Msisdn', 'Page To', 'Date Visited', 'Time Visited'];

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('EVT=>', evt.target.value);
    let tempType = handleType(ev);
    setType(tempType);
    setSDate('');
    setEDate('');
    getALLItems(tempType, 1, null, null);
    setPayload({
      ...value,
      [key]: ev,
    });
  };

  const handleType = selectedName => {
    if (selectedName === 'Yesterday') {
      return 1;
    } else if (selectedName === 'Last 7 Days') {
      return 2;
    } else if (selectedName === 'Last 28 Days') {
      return 3;
    } else if (selectedName === 'This Week') {
      return 4;
    } else if (selectedName === 'This Month') {
      return 5;
    } else if (selectedName === 'This Year') {
      return 6;
    } else if (selectedName === 'Last Week') {
      return 7;
    } else if (selectedName === 'Last Month') {
      return 8;
    }
  };

  const handleDate = e => {
    console.log('Date==>', e);
    let startDate = new Date(e[0]);
    let endDate = new Date(e[1]);

    console.log('StartD =>', startDate.getTime());
    console.log('EndD =>', endDate.getTime());
    let finalStartDate = startDate.getTime();
    let finalEndDate = endDate.getTime();
    setSDate(finalStartDate);
    setEDate(finalEndDate);
    setDateRange(e);
    setType('');
    getALLItems(null, 1, finalStartDate, finalEndDate);
  };

  useEffect(() => {
    getALLItems();
  }, [1]);

  const getALLItems = (limit, page, startD, endD) => {
    console.log('getAllItem', limit, page, startD, endD);
    setLoader(false);
    const body = {
      type: limit,
      startDate: endD,
      endDate: startD,
      page: page,
    };
    console.log('body=>', body);
    apimanager
      .post('pageTrackingReport', body)
      .then(res => {
        console.log('pageTrackingReport=>', res);
        console.log('Pages=>', res?.data?.totalPages);

        if (res.message === 8812) {
          res?.data?.docs?.map((value, index) => {
            tempArray?.push(value);
          });
          setTotalPage(res?.data?.totalPages);
          setpaginate(res?.data);
          setDataa(tempArray);
          setLoader(true);
        }
        if (res.message === 8336) {
          setLoader(true);
          toast({
            title: 'Mongodb pagination Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
        if (res.message === 2454) {
          setLoader(true);
          toast({
            title: 'No Data Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Add Data to Display.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Page Visited',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: true,
          path2: 'PageVisited',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={5} width={'80%'}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {DatewithTimePicker({
                title: 'Select Date Range',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,

                changePicker: true,
                // onChange: e => setDateRange(e),
                onChange: e => handleDate(e),
              })}

              {dropdownCustom({
                title: 'Quick Select Date',
                mandatory: false,
                temparray: quickSelect,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'quickSelect'),
              })}
            </SimpleGrid>
          </Flex>

          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={columsOfData}
            onPageChange={page => getALLItems(type, page, sDate, eDate)}
            renderItem={({ item, index }) => {
              // console.log('renderitem', item);
              let date = item?.createdAt;
              let newDate = new Date(date);
              let finalDate = newDate.toDateString();
              let finalTime = newDate.toLocaleTimeString();

              return (
                <Tr key={index} width={'100%'}>
                  <Td>{item?.email}</Td>
                  <Td>{item?.MSISDN}</Td>
                  <Td>{item?.pageTo}</Td>
                  <Td>{finalDate}</Td>
                  <Td>{finalTime}</Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PageVisited;
