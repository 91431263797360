import React from 'react';
import theme from '../config/color';
import { FcGoogle } from 'react-icons/fc';
import ApiManager from '../config/apiManager';
import { GoogleLogin } from 'react-google-login';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import {
  Flex,
  Text,
  Stack,
  Center,
  Button,
  Divider,
  Heading,
  Skeleton,
  useToast,
  SkeletonCircle,
} from '@chakra-ui/react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { styles } from '../styles/socialStyles';
import { useHistory } from 'react-router-dom';
import config from '../config/config';
import { useAuthDispatch, useAuthState } from '../context/authContext';

// import FacebookLogin from 'react-facebook-login';
// let token = "EAAqtZB6192B4BAJZBbnXSU0fQkwIz9l83txgSJaZAF8Xj4r9rbqktHoZAFjFcXfPs4kXVAYZA2PLSfBvMFAltZAHTrKg1ZBaomZBkA2SnoosA1qq1R8flTav6CcYT7TrSpd9ZC8QJTisrxZBXiBPeUfupZCYCZAS8wEjgu39RNTBoOIyIOh8OKW7vci6BZC2vm6f5hOHufjgU4fEm0HlXHEA8uhIl2PDLorliEYUvl0jZAEg8jsyZABLo0ews8v"

const SocailLogin = () => {
  const [skeletonLoad, setSkeletonLoad] = React.useState(true);
  let history = useHistory();
  const [success, setSuccess] = React.useState(false);
  const [successg, setSuccessgoogle] = React.useState(false);
  const dispatch = useAuthDispatch();
  const toast = useToast();
  let apimanager = ApiManager.getInstance();
  console.log('Login=> for Login page');

  const responseFacebook = response => {
    setSkeletonLoad(!skeletonLoad);

    console.log('responseFacebook', response?.accessToken);
    let body = {
      access_token: response?.accessToken,
    };

    console.log('body', body);
    apimanager
      .post('facebookLogin', body)
      .then(res => {
        console.log('FB Login API Response', res);

        if (res.message === 7020) {
          let user = {
            token: res.userDetails.token,
            email: res.userDetails.loginEmail,
          };
          toast({
            title: 'Successfully Signed in.',
            description: 'Welcome to Black Admin CMS.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          console.log('FB Login API Body', user);
          setSkeletonLoad(skeletonLoad);

          localStorage.setItem('user', JSON.stringify(user));
          apimanager._setToken(
            res.userDetails.loginEmail,
            res.userDetails.token
          );

          dispatch('AUTHENTICATED', user);
          dispatch('STOP_LOADING');

          setSuccess(!success);
          setTimeout(() => {
            setSuccess(success);
            history.push('/');
            window?.location?.reload();
          }, 3000);
        }
       if (res.message === 7021) {
          let user = {
            token: res.userDetails.token,
            userId: res.userDetails.userId,
          };
          toast({
            title: 'Successfully Signed in.',
            description: 'Welcome to Black Admin CMS.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          console.log('FB Login API Body', user);
          setSkeletonLoad(skeletonLoad);

          localStorage.setItem('user', JSON.stringify(user));
          apimanager._setToken(
            res.userDetails.userId,
            res.userDetails.token
          );

          dispatch('AUTHENTICATED', user);
          dispatch('STOP_LOADING');

          setSuccess(!success);
          setTimeout(() => {
            setSuccess(success);
            history.push('/');
            window?.location?.reload();
          }, 3000);
        }

        if (res?.errors[0].msg === 7016) {
          toast({
            title: 'Error in getting User details.',
            description: 'Facebook Token Expired.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }

        if (res?.message === 7016) {
          toast({
            title: 'Error in getting User details.',
            description: 'Facebook Token Expired.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          // setSkeletonLoad(false);
        }

        if (res?.message === 7017) {
          toast({
            title: 'Error in getting User details.',
            description: 'Facebook Token Required.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          // setSkeletonLoad(false);
        }
      })
      .catch(error => {
        setSkeletonLoad(skeletonLoad);
        console.log('FB error', error);
      });
  };

  const componentClicked = response => {
    console.log('response', response);
  };

  const responseGoogle = response => {
    setSkeletonLoad(!skeletonLoad);

    console.log('responce from google', response);
    console.log('responseGoogle', response?.tokenId);
    let body = {
      access_token: response?.tokenId,
    };
    console.log('body', body);
    apimanager
      .post('googleLogin', body)
      .then(res => {
        console.log('Google Login API Response', res);
        if (res.message === 7020) {
          let user = {
            token: res.userDetails.token,
            email: res.userDetails.loginEmail,
          };
          setSkeletonLoad(skeletonLoad);

          localStorage.setItem('user', JSON.stringify(user));
          apimanager._setToken(res.loginEmail, res.token);

          setSuccessgoogle(!success);
          setTimeout(() => {
            setSuccessgoogle(success);
          }, 3000);

          dispatch('AUTHENTICATED', user);
          setTimeout(() => {
            console.log('====Navigate to next===');
            history.push('/');
            window?.location?.reload();
          }, 300);
        }
        if (res.message === 7021) {
          let user = {
            token: res.token,
            loginEmail: response.profileObj.email,
            // email: res.userDetails.loginEmail,
          };
          setSkeletonLoad(skeletonLoad);

          localStorage.setItem('user', JSON.stringify(user));
          apimanager._setToken(res.loginEmail, res.token);

          setSuccessgoogle(!success);
          setTimeout(() => {
            setSuccessgoogle(success);
          }, 3000);

          dispatch('AUTHENTICATED', user);
          setTimeout(() => {
            console.log('====Navigate to next===');
            history.push('/');
            window?.location?.reload();
          }, 300);
        }
      })
      .catch(error => {
        setSkeletonLoad(skeletonLoad);
        console.log('Google error', error);
      });
  };

  return (
    <Flex {...styles.mainFlex}>
      <Flex {...styles.secondMainFlex}>
        <Flex width={'90%'} alignItems={'flex-start'} justify={'flex-start'}>
          <Heading
            as="h6"
            size="18px"
            color={theme.customColors.blackAdminColor[200]}
            marginStart={1}
          >
            Social Login
          </Heading>
        </Flex>

        <Divider
          borderColor={theme.customColors.blackAdminColor[300]}
          marginY={10}
        />
        <Flex {...styles.socialFlex} width={'100%'}>
          {skeletonLoad ? (
            <>
              <Flex marginBottom={5} boxShadow={'lg'}>
                <GoogleLogin
                  clientId="900023426928-3hpvrfhbiut2tt49p67umg9hihp40v4s.apps.googleusercontent.com"
                  buttonText="Login"
                  render={renderProps => (
                    <Button
                      onClick={renderProps.onClick}
                      w={'full'}
                      variant={'outline'}
                      leftIcon={<FcGoogle />}
                    >
                      <Center>
                        <Text color={theme.customColors.blackAdminColor[200]}>
                          Sign in with Google
                        </Text>
                      </Center>
                    </Button>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </Flex>
              <Flex marginBottom={5} boxShadow={'lg'}>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APPID}
                  // fields="name,email,picture"
                  fields="name,public_profile,email,picture"
                  onClick={componentClicked}
                  callback={responseFacebook}
                  render={renderProps => (
                    <Button
                      onClick={renderProps.onClick}
                      w={'full'}
                      colorScheme={'facebook'}
                      leftIcon={<FaFacebook />}
                    >
                      <Center>
                        <Text>Continue with Facebook</Text>
                      </Center>
                    </Button>
                  )}
                />
              </Flex>
            </>
          ) : (
            <Flex width={'100%'} flexDir={'column'} marginBottom={5}>
              <Skeleton h={'7vh'} width={'100%'} marginBottom={5} />
              <Skeleton h={'7vh'} width={'100%'} />
            </Flex>
          )}

          {success ? (
            <Text {...styles.successMsg}>
              Getting Facebook Token Successfully!!
            </Text>
          ) : null}
          {successg ? (
            <Text {...styles.successMsg}>
              Getting Google Token Successfully!!
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default SocailLogin;
