import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Spacer,
  Textarea,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';
import Cron from 'react-js-cron';
import 'antd/dist/antd.min.css';
import UncontrolledEditor from '../components/textEditor/textEditor';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
  customInputImage,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import {
  dropdownCustom,
  dropdownCustomSvalue,
} from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import ControlledEditor from '../components/textEditor/classEditor';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import style from '../components/common/style';
import { useHistory } from 'react-router-dom';
import {
  PromotionAndNewsCategoryList,
  PromotionAndNewsStatus,
} from '../context/data';
import { DATABUTTONS } from '../context/data';
import { useLocation } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import Multiselect from 'multiselect-react-dropdown';
import { uploadImage } from '../config/imageUploader';

const CreatePromoAndNews = () => {
  const initialValue = {
    title: '',
    url: '',
    sms: '',
    data: '',
    voice: '',
    status: '',
    image: '',
    endDate: '',
    deeplink: '',
    startDate: '',
    vouchers: '',
    isVoucher: '',
    rewardQty: '',
    description: '',
    vMerchantId: '',
    isHappyHour: '',
    oneTimeDeal: '',
    productCode: '',
    showInMobile: '',
    productNameID: '',
    SelectLink: false,
    isOneTimeDeal: '',
    rewardEndDate: '',
    shortDescription: '',
    mobilinkDeeplink: '',
    rewardMerchantId: '',
    rewardPromoCode: '',
    isSpecialHappyHour: '',
    checkURLorDeeplink: '',
    sendPushNotification: '',
    promotionNewsCategory: '',
  };

  const mandatoryFields = {
    // promotionNewsCategory: true,
    title: true,
    description: true,
    shortDescription: true,
    status: true,
    showInMobile: true,
  };

  let statusArray = ['Active', 'Non-Active'];
  let showPopUpMobileArray = ['Yes', 'No'];

  const defaultValue = null;
  const [v, setV] = React.useState([]);
  const [vId, setVId] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [status, setstatus] = React.useState(false);
  const [s3image, setS3Image] = React.useState();
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [endD, setEndD] = React.useState('');
  const [value, setValue] = React.useState(initialValue);
  const [startD, setStartD] = React.useState('');
  const [getOneTime, setGetOneTime] = React.useState([]);
  const [concantinate, setConcatinate] = React.useState([]);
  const [voucherName, setVoucherName] = React.useState([]);
  const [productName, setProductNames] = React.useState([]);
  const [cornvalue, setCornValue] = React.useState(defaultValue);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [checkSchedule, setcheckSchedule] = React.useState(false);
  const [rewardEndD, setRewardEndD] = React.useState(new Date());
  const [voucherLoading, setVoucherLoading] = React.useState(false);
  const [laterNotiChange, setlaterNotiChange] = React.useState(false);

  let history = useHistory();
  let toast = useToast();
  let apimanager = ApiManager.getInstance();
  let location = useLocation();

  useEffect(() => {
    getAllProducts(50, 1);
    getAllOneTimeDeal(50, 1);
    // getVouchers();

    if (location.status === 'create') {
      setstatus(true);
    }

    // if (location?.state?.val?.voucherDetails.voucherMerchantId) {
    //   console.log(
    //     'runnning this',
    //     location?.state?.val?.voucherDetails.voucherMerchantId
    //   );
    //   await getVouchers(
    //     location?.state?.val?.voucherDetails.voucherMerchantId
    //   );
    // } else {
    //   console.log('this should not run');
    //   await getVouchers();
    // }

    let tempIsHappyHour = changeFromHappyHour(
      location?.state?.val?.isHappyHour
    );

    let tempIsSpecial = changeFromHappyHour(
      location?.state?.val?.isSpecialHappyHour
    );

    let tempIsVoucher = changeFromHappyHour(location?.state?.val?.isVoucher);
    let tempIsoneTimeDeal = changeFromHappyHour(
      location?.state?.val?.isOneTimeDeal
    );

    if (location?.state?.val) {
      setValue({
        ...value,
        url: location?.state?.val?.url,
        isVoucher: tempIsVoucher,
        title: location?.state?.val?.title,
        isHappyHour: tempIsHappyHour,
        status: location?.state?.val?.status,
        isSpecialHappyHour: tempIsSpecial,
        isOneTimeDeal: tempIsoneTimeDeal,
        endDate: location?.state?.val?.endDate,
        startDate: location?.state?.val?.startDate,
        sms: location?.state?.val?.dataDetails.sms,
        data: location?.state?.val?.dataDetails.data,
        voice: location?.state?.val?.dataDetails.voice,
        description: location?.state?.val?.description,
        productNameID: location?.state?.val?.productId,
        productCode: location?.state?.val?.productName,
        showInMobile: location?.state?.val?.showInMobile,
        oneTimeDealId: location?.state?.val?.oneTimeDealId,
        oneTimeDeal: location?.state?.val?.oneTimeDealName,
        SelectLink: location?.state?.val?.mobileDeeplinkScreen,
        shortDescription: location?.state?.val?.shortDescription,
        rewardEndDate: location?.state?.val?.voucherExpiryDate,
        rewardQty: location?.state?.val?.voucherDetails.voucherQty,
        vMerchantId: location?.state?.val?.voucherDetails.voucherMerchantId,
        promotionNewsCategory: location?.state?.val?.promotionNewsCategory,
        rewardPromoCode: location?.state?.val?.voucherDetails.voucherPromoCode,
      });

      setRewardEndD(
        parseInt(location?.state?.val?.voucherDetails.voucherExpiryDate)
      );
      console.log(
        'preivous date',
        location?.state?.val?.voucherDetails.voucherExpiryDate
      );

      setStartD(location?.state?.val?.startDate);
      setEndD(location?.state?.val?.endDate);
    }

    if (location.state?.val?.image) {
      setS3Image(location?.state?.val?.image);
    }
    if (location.state?.val?.schedule) {
      setlaterNotiChange(!laterNotiChange);
      setcheckSchedule(!checkSchedule);
      setCornValue(location.state?.val?.schedule);
    }
    console.log('pathdata', location.state?.val);
  }, [location]);

  useEffect(async () => {
    if (location?.state?.val?.voucherDetails.voucherMerchantId) {
      console.log(
        'runnning this',
        location?.state?.val?.voucherDetails.voucherMerchantId
      );
      await getVouchers(location?.state?.val?.voucherDetails.voucherMerchantId);
    } else {
      console.log('this should not run');
      await getVouchers();
    }
  }, [1]);

  const handleonChange = (evt, key) => {
    console.log(key, 'evt=>', evt.target.value);
    const ev = evt.target.value;
    console.log('evt=', ev);
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleSingleonChange = (evt, key) => {
    setlaterNotiChange(!laterNotiChange);
    console.log('EVNTTT=>', evt);
    const ev = evt;
    console.log('ev', ev);
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const handleonSwitch = (evt, key) => {
    const ev = evt;
    console.log('Switch', ev, 'key', key);
    // setUrlORdeeplink(ev)
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const upload = e => {
    setImageLoading(true);
    console.log('e.target=>', e.target.files[0].name);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('image uri', res.location);
        setS3Image(e.target.files[0].name);
        setImageLoading(false);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const changeFromHappyHour = value => {
    console.log('value=>', value);
    if (value === true) {
      return 'Yes';
    } else if (value === false) {
      return 'No';
    }
  };

  const onSelectedFuntion = e => {
    console.log('onSelect=>', e);
    let tempId = [];
    e.map((value, index) => {
      console.log(index, 'value', value[0]);

      tempId.push(value[0]);
    });
    console.log('tempId', tempId);
    setValue({
      ...value,
      vouchers: e,
      vMerchantId: tempId,
    });
  };

  const getAllProducts = (limit, page) => {
    // setLoader(false);
    let tempArray = [];
    let tempValKey = [];
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAllProducts', body)
      .then(res => {
        console.log('res>>', res);
        if (res.message === 8250) {
          res?.record.docs?.map((value, index) => {
            tempArray.push(value?.name);
            tempValKey.push({ list: value?.name, val: value?._id });
          });
          console.log('tempArray', tempArray);

          // setProductNames(tempArray);
          setProductNames(tempValKey);
        } else {
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Something Went Wrong`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getAllOneTimeDeal = (limit, page) => {
    // setLoader(false);
    let tempArray = [];
    let tempValKey = [];
    const body = {
      numRecords: limit,
      page: page,
    };
    apimanager
      .get('getAllOneTimeDeals', body)
      .then(res => {
        console.log('res getAllOneTimeDeals>>', res);
        if (res.message === 8308) {
          res?.deals.docs?.map((value, index) => {
            tempArray.push(value?.name);
            tempValKey.push({ list: value?.name, val: value?._id });
          });
          // console.log('tempArray', tempArray);

          // setProductNames(tempArray);
          setGetOneTime(tempValKey);
        } else {
          toast({
            title: error,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Something Went Wrong`,
          });
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getVouchers = async vouch => {
    setVoucherLoading(true);
    console.log('Previous of vouch =>', vouch);

    let tempVoucher = [];
    let tempName = [];
    let tempMId = [];
    let iddd = [];

    await apimanager
      .get('rewards/merchantList', {})
      .then(res => {
        setVoucherLoading(false);

        console.log('getAllRewards=>RES', res);
        if (res.status === true) {
          setVoucherLoading(false);

          res.data.map((value, index) => {
            tempVoucher.push(value);
            iddd.push(value?.idmerchant);
            tempName.push(value?.merchantname);
            tempMId.push([value?.idmerchant, value?.merchantname]);
          });

          setVId(iddd);
          setVoucherName(tempName);
          setV(tempMId);
          setLoading(false);

          let data = getProjectId(res.data, vouch);
          console.log('Returned data=>', data);
          let dat = [];
          data.map(da => {
            dat.push([da.idmerchant, da.merchantname]);
          });
          console.log('Dattt==>', dat);

          setConcatinate(dat);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getProjectId = (data, value) => {
    console.log(data, 'getvalues', value);
    let selectData = [];
    data.map((dataVouch, index) => {
      if (value.toString().includes(dataVouch.idmerchant)) {
        // console.log('yesIncludds', dataVouch);
        selectData.push(dataVouch);
      }
    });
    console.log('selectData', selectData);
    return selectData;
  };

  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 'Non-Active') {
      return 0;
    } else if (value === 'Active') {
      return 1;
    }
  };

  const changeHappyHour = value => {
    console.log('value=>', value);
    if (value === 'Yes') {
      return true;
    } else if (value === 'No') {
      return false;
    }
  };

  const changeIsOneTimeDeal = value => {
    console.log('value=>', value);
    if (value === 'Yes') {
      return true;
    } else if (value === 'No') {
      return false;
    }
  };

  const changeOnetimeDeal = value => {
    console.log('value=>', value);
    if (value === 'No') {
      return 0;
    } else if (value === 'Yes') {
      return 1;
    }
  };

  const changePromotionNews = value => {
    console.log('value=>', value);
    if (value === 'Promotion') {
      return 0;
    } else if (value === 'News') {
      return 1;
    }
  };

  // API CALLING //
  const onClickSubmit = () => {
    setLoading(true);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (laterNotiChange == false) {
      setCornValue(null);
    }
    // console.log('value', value);
    // console.log('mandatory', mandatoryFields);
    // console.log('iferror', ifError);
    if (ifError) {
      setLoading(false);
      setError('mandatory fields must be filled');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    }
    //  else if (s3image === '' || s3image === undefined) {
    //   setLoading(false);
    //   setError('Add Image');
    //   toast({
    //     title: error,
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //     description: 'Failed to Submit.Please Add Image to Submit',
    //   });
    // }
    else if (startD === '' || startD === undefined) {
      setLoading(false);
      setError('Start Date / End Date');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Fields Must be Filled.Failed to Submit',
      });
    } else if (endD === '' || endD === undefined) {
      setLoading(false);
      setError('Start Date / End Date');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Fields Must be Filled.Failed to Submit',
      });
    } else {
      let statusValue = changeStatus(value['status']);
      let selectValue = changeHappyHour(value['isHappyHour']);
      let oneTimeValue = changeIsOneTimeDeal(value?.isOneTimeDeal);
      let onePromotion = changePromotionNews(value['promotionNewsCategory']);
      let isSpecisl = changeIsOneTimeDeal(value['isSpecialHappyHour']);
      let isVoucherTemp = changeIsOneTimeDeal(value['isVoucher']);
      let tempShowInMobile = changeOnetimeDeal(value['showInMobile']);

      console.log('Is oneTimeValue', oneTimeValue);
      console.log('isSpecialHappyHour', isSpecisl);
      console.log('isVoucherTemp', isVoucherTemp);

      let body = {
        url: value?.url,
        title: value?.title,
        image: s3image,
        sms: value['sms'],
        data: value['data'],
        voice: value['voice'],
        status: statusValue,
        isHappyHour: selectValue,
        isVoucher: isVoucherTemp,
        isSpecialHappyHour: isSpecisl,
        isOneTimeDeal: oneTimeValue,
        description: value?.description,
        voucherQty: value['rewardQty'],
        productId: value?.productCode,
        oneTimeDealId: value?.oneTimeDeal,
        schedule: cornvalue ? cornvalue : '',
        showInMobile: tempShowInMobile,
        endDate: String(convertedDate(endD)),
        startDate: String(convertedDate(startD)),
        voucherMerchantId: value['vMerchantId'],
        promotionNewsCategory: onePromotion,
        shortDescription: value?.shortDescription,
        voucherPromoCode: value['rewardPromoCode'],
        mobileDeeplinkScreen: value['SelectLink'].toString(),
        voucherExpiryDate: rewardEndD.toDateString(),
        // sendPushNotification: value?.schedule,
      };
      console.log('<==body=>', body);
      apimanager
        .post('addPromotionAndNews', body)
        .then(res => {
          console.log('res=>', res);
          if (res.message === 8353) {
            setLoading(false);

            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            }, [3000]);
          }
          if (res.message === 8058) {
            setLoading(false);

            toast({
              title: 'Something went wrong',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed. Response Not saved',
            });
          }
          // PRODUCT_NOT_FOUND: 8636,
          else if (res.message === 8620) {
            setLoading(false);
            toast({
              title: 'VOUCHER Merchant ID REQUIRED',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed',
            });
          } else if (res.message === 8622) {
            setLoading(false);
            toast({
              title: 'VOUCHER Quantity REQUIRED',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed',
            });
          } else if (res.message === 8621) {
            setLoading(false);
            toast({
              title: 'VOUCHER Promocode REQUIRED',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed',
            });
          } else if (res.message === 8623) {
            setLoading(false);
            toast({
              title: 'VOUCHER Expiry Date REQUIRED',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed',
            });
          } else if (res.message === 8634) {
            setLoading(false);
            toast({
              title: 'PRODUCT ID REQUIRED',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed',
            });
          } else if (res.message === 8635) {
            setLoading(false);
            toast({
              title: 'ONE TIME DEAL ID REQUIRED',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed',
            });
          } else if (res.message === 8636) {
            setLoading(false);
            toast({
              title: 'PRODUCT NOT FOUND',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'Failed',
            });
          } else {
            setLoading(false);
            toast({
              title: 'Something went wrong',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `${res.errors[0].msg}`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error', error);
        });
    }
  };

  const onClickUpdate = () => {
    setLoading(true);
    console.log('rewardEndD.toISOString(),', Date.parse(rewardEndD));
    console.log('rewardEndD.toISOString(),', rewardEndD);

    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (laterNotiChange == false) {
      setCornValue(null);
    }
    console.log('value', value);
    console.log('mandatory', mandatoryFields);
    console.log('iferror', ifError);
    if (ifError) {
      setLoading(false);

      console.log('Resolved');
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    }
    // else if (s3image === '' || s3image === undefined) {
    //   setLoading(false);
    //   setError('Add Image');
    //   toast({
    //     title: error,
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //     description: 'Failed to Submit.Please Add Image to Submit',
    //   });
    // }
    else {



      let statusValue = changeStatus(value['status']);

      console.log('select Link ', value['SelectLink']);

      let happyHour = changeHappyHour(value['isHappyHour']);
      let onetimedeal = changeHappyHour(value['isOneTimeDeal']);
      let isSpecialHappyHour = changeHappyHour(value['isSpecialHappyHour']);
      let isVoucher = changeHappyHour(value['isVoucher']);
      let showInMobile = changeHappyHour(value['showInMobile']);
      let valueSelectLink = changeHappyHour(value['SelectLink']);

      let body = {
        id: location?.state?.val?._id,
        url: value?.url,
        endDate: endD,
        image: s3image,
        title: value?.title,
        sms: value['sms'],
        startDate: startD,
        data: value['data'],
        voice: value['voice'],
        status: value['status'],
        isVoucher: isVoucher,
        isHappyHour: happyHour,
        isOneTimeDeal: onetimedeal,
        showInMobile: showInMobile,
        description: value?.description,
        voucherQty: value['rewardQty'],
        productCode: value?.productCode,
        schedule: cornvalue ? cornvalue : '',
        oneTimeDeal: value?.oneTimeDeal,
        productId: value?.productNameID,
        oneTimeDealId: value?.oneTimeDeal,
        showPopUpMobile: value?.showInMobile,
        voucherMerchantId: value['vMerchantId'],
        shortDescription: value?.shortDescription,
        isSpecialHappyHour: isSpecialHappyHour,
        voucherPromoCode: value['rewardPromoCode'],
        mobileDeeplinkScreen: valueSelectLink
          ? valueSelectLink
          : value['SelectLink'],
        // voucherExpiryDate: rewardEndD,
        voucherExpiryDate: Date.parse(rewardEndD)
          ? Date.parse(rewardEndD)
          : rewardEndD,
        promotionNewsCategory: value?.promotionNewsCategory,
      };
      console.log('rewardEndD.toISOString(),', Date.parse(rewardEndD));

      console.log('<==body=>', body);
      apimanager
        .post('editPromotionAndNews', body)
        .then(res => {
          console.log('res=>', res);
          if (res.message === 8356) {
            setLoading(false);
            setSuccess('Successfully Recorded!');
            toast({
              title: success,
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: `Recorded, Please processed to List!`,
            });
            setTimeout(() => {
              history.goBack();
            });
          }
          if (res.message === 8355) {
            setLoading(false);
            setError('Opps! Something Went Wrong');
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Date Must Not be Empty!`,
            });
          } else if (res.message === 8620) {
            setError('Merchant ID Requried');

            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed, Something missing!`,
            });
          } else if (res.message === 8623) {
            setError('VOUCHER EXPIRY DATE REQUIRED');

            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed, Something missing!`,
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error', error);
        });
    }
  };

  return (
    <>
      {status ? (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Edit Promotion & News',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Promotion & News Management',
              useSlash: true,
              path2: 'Edit',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Promotion & News Category',
                mandatory: true,
                temparray: PromotionAndNewsCategoryList,
                placeholder: value['promotionNewsCategory'],
                onChange: e => handleonChange(e, 'promotionNewsCategory'),
              })}
            </SimpleGrid>

            {value?.promotionNewsCategory === 'News' ? null : (
              <>
                {value?.promotionNewsCategory === '' ? null : (
                  <>
                    <SimpleGrid
                      width={'100%'}
                      columns={3}
                      spacingX={'50px'}
                      marginTop={5}
                    >
                      {dropdownCustom({
                        title: 'Is Happy Hour ?',
                        placeholder: value['isHappyHour'].toString(),
                        temparray: showPopUpMobileArray,
                        mandatory:
                          value?.promotionNewsCategory === 'Promotion'
                            ? true
                            : false,
                        isDisabled:
                          value?.promotionNewsCategory === 'News'
                            ? true
                            : value?.promotionNewsCategory === 'Promotion'
                            ? false
                            : value?.promotionNewsCategory === ''
                            ? true
                            : true,
                        onChange: e => handleonChange(e, 'isHappyHour'),
                      })}

                      {dropdownCustomSvalue({
                        title: 'Product Code',
                        temparray: productName,
                        mandatory:
                          value['isHappyHour'] === 'true' ? true : false,
                        placeholder:
                          value['productCode'] === '' || undefined
                            ? 'Select Option'
                            : value['productCode'],
                        isDisabled:
                          value?.isHappyHour.toString() === 'true'
                            ? false
                            : value?.isHappyHour.toString() === 'false'
                            ? true
                            : value?.isHappyHour.toString() === 'Yes'
                            ? false
                            : true,
                        onChange: e => handleonChange(e, 'productCode'),
                      })}
                    </SimpleGrid>

                    <SimpleGrid
                      width={'100%'}
                      columns={3}
                      spacingX={'50px'}
                      marginTop={5}
                    >
                      {dropdownCustom({
                        title: 'Is One Time Deal',
                        temparray: showPopUpMobileArray,
                        mandatory: false,
                        isDisabled: value?.isHappyHour === 'No' ? false : true,

                        placeholder: value['isOneTimeDeal'].toString(),
                        onChange: e => handleonChange(e, 'isOneTimeDeal'),
                      })}

                      {dropdownCustomSvalue({
                        title: 'One Time Deal',

                        placeholder: value['oneTimeDeal'].toString()
                          ? value['oneTimeDeal'].toString()
                          : 'Select Option',
                        temparray: getOneTime,
                        mandatory: false,
                        isDisabled:
                          value?.isOneTimeDeal === 'Yes' ? false : true,

                        onChange: e => handleonChange(e, 'oneTimeDeal'),
                      })}
                    </SimpleGrid>

                    {value?.isHappyHour === 'Yes' ? (
                      <SimpleGrid
                        width={'100%'}
                        columns={3}
                        spacingX={'50px'}
                        marginTop={5}
                      >
                        {dropdownCustom({
                          title: 'Is Special Happy Hour?',
                          mandatory: true,
                          placeholder: value['isSpecialHappyHour'].toString(),
                          temparray: showPopUpMobileArray,

                          onChange: e =>
                            handleonChange(e, 'isSpecialHappyHour'),
                        })}

                        {value.isSpecialHappyHour === 'Yes'
                          ? dropdownCustom({
                              title: 'Is Voucher',
                              temparray: showPopUpMobileArray,
                              mandatory: true,
                              placeholder: value['isVoucher'].toString(),
                              onChange: e => handleonChange(e, 'isVoucher'),
                            })
                          : null}
                      </SimpleGrid>
                    ) : null}
                    {console.log('valueisVoucher', value['isVoucher'])}
                    {value.isSpecialHappyHour === 'Yes' ? (
                      value?.isVoucher === 'Yes' ? (
                        <SimpleGrid
                          width={'100%'}
                          columns={4}
                          spacingX={'50px'}
                          marginTop={5}
                        >
                          <Flex
                            marginBottom={'4px'}
                            // marginTop={'2px'}
                            flexDirection={'column'}
                          >
                            <Flex>
                              <Text {...styles.redAsterisk}>
                                Reward Merchant Id
                              </Text>
                              <Text color={'#ff0000'}>*</Text>
                            </Flex>
                            {!voucherLoading ? (
                              <Multiselect
                                id={vId}
                                options={v}
                                isObject={false}
                                singleSelect={true}
                                hidePlaceholder={true}
                                style={{ ...styles.multiSelect }}
                                selectedValues={concantinate}
                                onSelect={e => onSelectedFuntion(e)}
                              />
                            ) : (
                              <Skeleton height="20px" />
                            )}
                          </Flex>

                          {customInputLabel({
                            title: 'Reward Promo Code',
                            mandatory: true,
                            value: value['rewardPromoCode'],
                            onChange: e => handleonChange(e, 'rewardPromoCode'),
                          })}

                          {customInputLabel({
                            type: 'number',
                            mandatory: true,
                            title: 'Reward Qty',
                            value: value['rewardQty'],
                            onChange: e => handleonChange(e, 'rewardQty'),
                          })}

                          {console.log('rewardQuantity', value.rewardQty)}

                          {customDateWithTitle({
                            mandatory: true,
                            title: 'Reward End Date',
                            selectedDate: rewardEndD,
                            onChange: e => setRewardEndD(e),
                          })}
                        </SimpleGrid>
                      ) : value.isVoucher === 'No' ? (
                        <SimpleGrid
                          width={'100%'}
                          columns={4}
                          spacingX={'50px'}
                          marginTop={5}
                        >
                          {customInputLabel({
                            title: 'Data',
                            mandatory: true,
                            value: value['data'],
                            type: 'number',
                            onChange: e => handleonChange(e, 'data'),
                          })}
                          {customInputLabel({
                            type: 'number',
                            title: 'Voice',
                            mandatory: true,
                            value: value['voice'],
                            onChange: e => handleonChange(e, 'voice'),
                          })}
                          {customInputLabel({
                            type: 'number',
                            title: 'SMS',
                            mandatory: true,
                            value: value['sms'],
                            onChange: e => handleonChange(e, 'sms'),
                          })}
                        </SimpleGrid>
                      ) : null
                    ) : null}
                  </>
                )}
              </>
            )}

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Title',
                mandatory: true,
                value: value['title'],
                onChange: e => handleonChange(e, 'title'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={'5'}
            >
              {value?.promotionNewsCategory === 'News' ? null : (
                <Flex>
                  {customSwtich({
                    title: 'URL/DeepLink',
                    label: 'Select Link Type',
                    checked: value?.SelectLink,
                    check: e => handleonSwitch(e.target.checked, 'SelectLink'),
                  })}
                </Flex>
              )}

              {customInputLabel({
                title: 'URL',
                value: value['url'],
                disabled:
                  value?.SelectLink === true
                    ? true
                    : value?.SelectLink === false
                    ? false
                    : false,
                onChange: e => handleonChange(e, 'url'),
              })}

              {dropdownCustomSvalue({
                title: 'Deeplink',
                placeholder: value['url'] ? value['url'] : 'Select Option',
                isDisabled:
                  value?.SelectLink === true
                    ? false
                    : value?.SelectLink === false
                    ? true
                    : true,
                temparray: DATABUTTONS,
                onChange: e => handleonChange(e, 'url'),
              })}
            </SimpleGrid>
            {customTextarea({
              title: 'Description',
              mandatory: true,
              value: value['description'],
              onChange: e => handleonChange(e, 'description'),
            })}
            {customTextarea({
              title: 'Short Description',
              mandatory: true,
              value: value['shortDescription'],
              onChange: e => handleonChange(e, 'shortDescription'),
            })}
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={2}
            >
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: false,
                selectedDate: startD,
                onChange: e => setStartD(e),
                value: new Date(value['startDate'] * 1000).toLocaleDateString(),
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: false,
                selectedDate: endD,
                onChange: e => setEndD(e),
                value: new Date(value['endDate'] * 1000).toLocaleDateString(),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Show as Pop-up in Mobile',
                mandatory: true,
                value: value['showInMobile'],
                onChange: e => handleonChange(e, 'showInMobile'),
              })}
              {dropdownCustom({
                title: 'Status',
                mandatory: true,
                placeholder: value['status'],
                temparray: PromotionAndNewsStatus,
                onChange: e => handleonChange(e, 'status'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={4}
            >
              {!imageLoading ? (
                customInputImage({
                  title: 'Image',
                  mandatory: false,
                  type: 'file',
                  src: process.env.REACT_APP_S3URL + s3image,
                  placeholder: process.env.REACT_APP_S3URL + s3image,
                  onChange: e => upload(e),
                })
              ) : (
                <Skeleton height="30px" />
              )}
            </SimpleGrid>
            <Flex
              alignSelf={'flex-start'}
              marginTop={14}
              flexDirection={'column'}
            >
              {customSwtich({
                title: 'Schedule',
                label: 'Send Push Notification',
                checked: checkSchedule,
                check: e => handleSingleonChange(e.target.checked, 'schedule'),
              })}

              {laterNotiChange ? (
                <Flex marginTop={3}>
                  <Cron value={cornvalue} setValue={setCornValue} />
                </Flex>
              ) : null}
            </Flex>

            {customUpdateCancelBtn({
              title1: 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickUpdate(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexDirection={'column'}
          backgroundColor={'#FAFDFB'}
          height={'80vh'}
        >
          <Flex {...style.miniheader} marginBottom={'2%'}>
            {MiniHeader({
              title: 'Create Promotion & News',
              path: 'Dashboard',
              onclickForRoute: () => history.push('/Dashboard'),
              path1: 'Promotion & News Management',
              useSlash: true,
              path2: 'Create',
            })}
          </Flex>
          <Flex {...styles.parentFlex}>
            <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
              {dropdownCustom({
                title: 'Promotion & News Category',
                mandatory: true,
                temparray: PromotionAndNewsCategoryList,
                mandatory: true,
                placeholder: 'Select Option',
                onChange: e => handleonChange(e, 'promotionNewsCategory'),
              })}
            </SimpleGrid>

            {value?.promotionNewsCategory === 'News' ? null : (
              <>
                {value?.promotionNewsCategory === '' ? null : (
                  <>
                    <SimpleGrid
                      width={'100%'}
                      columns={3}
                      spacingX={'50px'}
                      marginTop={5}
                    >
                      {dropdownCustom({
                        title: 'Is Happy Hour?',
                        temparray: showPopUpMobileArray,
                        mandatory:
                          value?.promotionNewsCategory === 'Promotion'
                            ? true
                            : false,
                        isDisabled:
                          value?.promotionNewsCategory === 'News'
                            ? true
                            : value?.promotionNewsCategory === 'Promotion'
                            ? false
                            : value?.promotionNewsCategory === ''
                            ? true
                            : true,
                        placeholder: 'Select Option',
                        onChange: e => handleonChange(e, 'isHappyHour'),
                      })}

                      {dropdownCustomSvalue({
                        title: 'Product Code',
                        temparray: productName,
                        mandatory:
                          value['isHappyHour'] === 'Yes' ? true : false,
                        placeholder: 'Select Option',
                        isDisabled:
                          value?.isHappyHour === 'Yes'
                            ? false
                            : value?.isHappyHour === 'No'
                            ? true
                            : true,
                        onChange: e => handleonChange(e, 'productCode'),
                      })}
                    </SimpleGrid>

                    <SimpleGrid
                      width={'100%'}
                      columns={3}
                      spacingX={'50px'}
                      marginTop={5}
                    >
                      {dropdownCustom({
                        title: 'Is One Time Deal',
                        temparray: showPopUpMobileArray,
                        mandatory: false,
                        isDisabled: value?.isHappyHour === 'No' ? false : true,
                        placeholder: 'Select Option',
                        onChange: e => handleonChange(e, 'isOneTimeDeal'),
                      })}

                      {dropdownCustomSvalue({
                        title: 'One Time Deal',
                        placeholder: 'Select Option',
                        temparray: getOneTime,
                        mandatory: false,
                        isDisabled:
                          value?.isOneTimeDeal === 'Yes' ? false : true,
                        onChange: e => handleonChange(e, 'oneTimeDeal'),
                      })}
                    </SimpleGrid>

                    {value?.isHappyHour === 'Yes' ? (
                      <SimpleGrid
                        width={'100%'}
                        columns={3}
                        spacingX={'50px'}
                        marginTop={5}
                      >
                        {dropdownCustom({
                          title: 'Is Special Happy Hour?',
                          mandatory: true,
                          placeholder: 'Select Option',
                          temparray: showPopUpMobileArray,
                          onChange: e =>
                            handleonChange(e, 'isSpecialHappyHour'),
                        })}

                        {value.isSpecialHappyHour === 'Yes'
                          ? dropdownCustom({
                              title: 'Is Voucher',
                              temparray: showPopUpMobileArray,
                              mandatory: true,
                              placeholder: 'Select Option',
                              onChange: e => handleonChange(e, 'isVoucher'),
                            })
                          : null}
                      </SimpleGrid>
                    ) : null}
                    {value.isSpecialHappyHour === 'Yes' ? (
                      value?.isVoucher === 'Yes' ? (
                        <SimpleGrid
                          width={'100%'}
                          columns={4}
                          spacingX={'50px'}
                          marginTop={5}
                        >
                          <Flex
                            marginBottom={'4px'}
                            // marginTop={'2px'}
                            flexDirection={'column'}
                          >
                            <Flex>
                              <Text {...styles.redAsterisk}>
                                Reward Merchant Id
                              </Text>
                              <Text color={'#ff0000'}>*</Text>
                            </Flex>
                            {!voucherLoading ? (
                              <Multiselect
                                id={vId}
                                options={v}
                                isObject={false}
                                singleSelect={true}
                                hidePlaceholder={true}
                                style={{ ...styles.multiSelect }}
                                selectedValues={concantinate}
                                onSelect={e => onSelectedFuntion(e)}
                              />
                            ) : (
                              <Skeleton height="20px" />
                            )}
                          </Flex>

                          {customInputLabel({
                            title: 'Reward Promo Code',
                            mandatory: true,
                            onChange: e => handleonChange(e, 'rewardPromoCode'),
                          })}

                          {customInputLabel({
                            title: 'Reward Qty',
                            mandatory: true,
                            type: 'number',
                            onChange: e => handleonChange(e, 'rewardQty'),
                          })}

                          {customDateWithTitle({
                            title: 'Reward End Date',
                            mandatory: true,
                            selectedDate: rewardEndD,
                            onChange: e => setRewardEndD(e),
                          })}
                        </SimpleGrid>
                      ) : value.isVoucher === 'No' ? (
                        <SimpleGrid
                          width={'100%'}
                          columns={4}
                          spacingX={'50px'}
                          marginTop={5}
                        >
                          {customInputLabel({
                            title: 'Data',
                            type: 'number',
                            mandatory: true,
                            onChange: e => handleonChange(e, 'data'),
                          })}
                          {customInputLabel({
                            title: 'Voice',
                            type: 'number',
                            mandatory: true,
                            onChange: e => handleonChange(e, 'voice'),
                          })}
                          {customInputLabel({
                            title: 'SMS',
                            type: 'number',
                            mandatory: true,
                            onChange: e => handleonChange(e, 'sms'),
                          })}
                        </SimpleGrid>
                      ) : null
                    ) : null}
                  </>
                )}
              </>
            )}

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={5}
            >
              {customInputLabel({
                title: 'Title',
                mandatory: true,
                onChange: e => handleonChange(e, 'title'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={3}
              spacingX={'50px'}
              marginTop={'5'}
            >
              {value?.promotionNewsCategory === 'News' ? null : (
                <Flex>
                  {customSwtich({
                    title: 'URL/DeepLink',
                    label: 'Select Link Type',
                    checked: value?.SelectLink,
                    check: e => handleonSwitch(e.target.checked, 'SelectLink'),
                  })}
                </Flex>
              )}

              {customInputLabel({
                title: 'URL',
                disabled:
                  value?.SelectLink === true
                    ? true
                    : value?.SelectLink === false
                    ? false
                    : false,
                onChange: e => handleonChange(e, 'url'),
              })}

              {dropdownCustomSvalue({
                title: 'Deeplink',
                placeholder: 'Select Option',
                isDisabled:
                  value?.SelectLink === true
                    ? false
                    : value?.SelectLink === false
                    ? true
                    : true,
                temparray: DATABUTTONS,
                onChange: e => handleonChange(e, 'url'),
              })}
            </SimpleGrid>

            {customTextarea({
              title: 'Description',
              mandatory: true,
              onChange: e => handleonChange(e, 'description'),
            })}
            {customTextarea({
              title: 'Short Description',
              mandatory: true,
              onChange: e => handleonChange(e, 'shortDescription'),
            })}
            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={2}
            >
              {customDateWithTitle({
                title: 'Start Date',
                mandatory: false,
                selectedDate: startD,
                onChange: e => setStartD(e),
              })}
              {customDateWithTitle({
                title: 'End Date',
                mandatory: false,
                selectedDate: endD,
                onChange: e => setEndD(e),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={2}
              spacingX={'50px'}
              marginTop={5}
            >
              {dropdownCustom({
                title: 'Show as Pop-up in Mobile',
                placeholder: 'Select Option',
                mandatory: true,
                temparray: showPopUpMobileArray,
                onChange: e => handleonChange(e, 'showInMobile'),
              })}
              {dropdownCustom({
                title: 'Status',
                placeholder: 'Select Option',
                mandatory: true,
                temparray: statusArray,
                onChange: e => handleonChange(e, 'status'),
              })}
            </SimpleGrid>

            <SimpleGrid
              width={'100%'}
              columns={1}
              spacingX={'50px'}
              marginTop={4}
            >
              {!imageLoading ? (
                customInputImage({
                  title: 'Image',
                  mandatory: false,
                  type: 'file',
                  src: process.env.REACT_APP_S3URL + s3image,
                  onChange: e => upload(e),
                })
              ) : (
                <Skeleton height="30px" />
              )}
            </SimpleGrid>
            <Flex
              alignSelf={'flex-start'}
              marginTop={14}
              flexDirection={'column'}
            >
              {customSwtich({
                title: 'Schedule',
                label: 'Send Push Notification',
                check: e => handleSingleonChange(e.target.checked, 'schedule'),
              })}
              {laterNotiChange ? (
                <Flex marginTop={3}>
                  <Cron value={cornvalue} setValue={setCornValue} />
                </Flex>
              ) : null}
            </Flex>

            {customUpdateCancelBtn({
              title1: 'Submit',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
          <Spacer />
          <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
          <Flex position={'sticky'} marginLeft={4}>
            {renderCopyRight()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CreatePromoAndNews;
