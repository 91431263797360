import { tokenToCSSVar } from '@chakra-ui/styled-system';
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';

import ApiManager from '../config/apiManager';
import config from '../config/config';
// import firebaseApp from '../firebase'
const StateContext = createContext({
  authenticated: false,
  user: null,
  loading: true,
  fcmToken: '',
});

let apiManager = ApiManager.getInstance();

const DispatchContext = createContext(null);
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'AUTHENTICATED':
      return {
        ...state,
        authenticated: true,
        user: payload,
      };

    case 'SET_MERCHANT':
      return {
        ...state,
        merchant: payload,
      };

    case 'LOGOUT':
      return { ...state, authenticated: false, user: null };

    case 'STOP_LOADING':
      return { ...state, loading: false };

    case 'SET_PAGES':
      return { ...state, pages: payload };

    default:
      throw new Error(`Unknow action type: ${type}`);
  }
};

export const AuthProvider = ({ children }) => {
  //const isInitialMount = useRef(true);
  const [state, defaultDispatch] = useReducer(reducer, {
    user: null,
    authenticated: false,
    loading: true,
  });

  const dispatch = (type, payload) => defaultDispatch({ type, payload });

  const sendSessionToServer = async (fcmToken, token) => {
    // console.log('token=', token);
    let session = await localStorage.getItem('mdgSession');
    let savedUser = await localStorage.getItem('user');
    // let parseSavedUser = JSON.parse(savedUser);
    let currentUser = await localStorage.getItem('user');
    let currentMerchant = await localStorage.getItem('currentMerchant');
    let parsedCurrentMerchant = '';
    try {
      parsedCurrentMerchant =
        currentMerchant !== undefined || currentMerchant !== 'undefined'
          ? JSON.parse(currentMerchant)
          : '';
    } catch (e) {}
    console.log('currentMerchant', currentMerchant);
    let parsedCurrentUser = JSON.parse(currentUser);

    if (session) {
      let body = {
        fcmKey: token,
        sessionId: session && session,
      };
      apiManager.updateSession(body, responce => {
        if (responce === false) {
          apiManager.createSession(body.fcmKey, response => {
            if (response) {
              dispatch('STOP_LOADING');
              // user ? apiManager._setToken(users.email, users.token) : null;
            }
          });
        }
        console.log('body', body);

        let { user, authenticated } = state;
        if (!authenticated && user === null) {
          dispatch('AUTHENTICATED', parsedCurrentUser);
          dispatch('SET_MERCHANT', parsedCurrentMerchant);
          dispatch('STOP_LOADING');
          // apiManager._setToken(parseSavedUser.email, parseSavedUser.token);
        }
      });
    } else {
      let body = {
        fcmKey: token,
        // fcmToken:token
      };
      apiManager.createSession(body.fcmKey, response => {
        console.log('response.message', body.fcmKey);
        if (response) {
          // user ? apiManager._setToken(users.email, users.token) : null;
          dispatch('STOP_LOADING');
        }
      });
    }
  };

  useEffect(async () => {
    // sendSessionToServer();
  }, []);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useAuthState = () => useContext(StateContext);
export const useAuthDispatch = () => useContext(DispatchContext);
