import React, { useEffect, useState } from 'react';
import {
  Divider,
  Flex,
  Button,
  Text,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import ControlledEditor from '../components/textEditor/classEditor';
import styles from '../components/editAboutUsComponent/styles';
import { customPageHeading } from '../components/editAboutUsComponent/editAboutUsComponent';
import {
  MiniHeader,
  customTextarea,
} from '../components/common/customComponent';
import style from '../components/common/style';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import { useLocation } from 'react-router-dom';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import ControlledEditorReselect from '../components/textEditor/textEditor';

const EditAboutUs = () => {
  let initialValue = {
    ENG: '',
    CN: '',
    MY: '',
  };
  const [value, setValue] = useState(initialValue);
  const [status, setstatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  let apimanager = ApiManager.getInstance();
  let location = useLocation();
  let history = useHistory();
  const toast = useToast();
  // console.log('value>>>', value);

  useEffect(() => {
    if (location?.state?.val)
      setValue({
        ...value,
        ENG: location?.state?.val[0]?.ENG,
        MY: location?.state?.val[0]?.MY,
        CN: location?.state?.val[0]?.CN,
      });
    console.log('pathdata', location?.state?.val);
  }, [location]);

  const handleSingleonChange = (evt, key) => {
    console.log('EVT->', evt);

    let val = IFame(evt);

    convertCommentFromJSONToHTML(val?.__html, key);
  };

  const IFame = dat => {
    let data = dat;
    console.log('location data=>', data);
    return {
      __html: data,
    };
  };

  const convertCommentFromJSONToHTML = (text, key) => {
    console.log('convert Text=>', text);
    let toHTML = stateToHTML(convertFromRaw(text));
    console.log('toHTML', toHTML);
    // setHtml(toHTML);
    setValue({
      ...value,
      [key]: toHTML,
    });
  };

  const onClickUpdate = () => {
    setLoading(true);
    const body = {
      ENG: value['ENG'],
      CN: value['CN'],
      MY: value['MY'],
    };
    console.log('body>>>>', body);
    apimanager
      .post('editAboutUs', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8235) {
          setLoading(false);
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        } else {
          setLoading(false);
          setSuccess('Something went wrong!');
          toast({
            title: success,
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: `Failed, Please Retry Later !`,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <Flex {...styles.parentFlexing}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: 'Edit About Us',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'About Us management',
          useSlash: true,
          path2: 'Edit',
        })}
      </Flex>
      <Flex
        {...styles.parentFlexing}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Flex
          padding={5}
          borderWidth={1}
          flexDirection={'column'}
          height={'50vh'}
          width={'90%'}
        >
          <Text marginX={1} marginY={2} fontSize={'sm'}>
            About Us EN *
          </Text>
          {value?.ENG ? (
            <ControlledEditorReselect
              value={value['ENG'] ? value['ENG'] : value.ENG}
              tempData={e => handleSingleonChange(e, 'ENG')}
            />
          ) : (
            <Skeleton height={250} />
          )}
        </Flex>
        <Divider marginY={5} width={'90%'} />
        <Flex
          padding={5}
          borderWidth={1}
          flexDirection={'column'}
          height={'50vh'}
          width={'90%'}
        >
          <Text marginX={1} marginY={2} fontSize={'sm'}>
            About Us CN *
          </Text>
          {value?.CN ? (
            <ControlledEditorReselect
              value={value?.CN ? value?.CN : ''}
              tempData={e => handleSingleonChange(e, 'CN')}
            />
          ) : (
            <Skeleton height={250} />
          )}
        </Flex>
        <Divider marginY={5} width={'90%'} />
        <Flex
          padding={5}
          borderWidth={1}
          flexDirection={'column'}
          height={'50vh'}
          width={'90%'}
        >
          <Text marginX={1} marginY={2} fontSize={'sm'}>
            About Us MY *
          </Text>
          {value?.MY ? (
            <ControlledEditorReselect
              value={value['MY'] ? value['MY'] : ''}
              tempData={e => handleSingleonChange(e, 'MY')}
            />
          ) : (
            <Skeleton height={250} />
          )}
        </Flex>
        <Divider marginY={5} width={'90%'} />
        <Flex alignSelf={'flex-start'} width={'90%'} marginStart={'50px'}>
          {customUpdateCancelBtn({
            title1: 'Submit',
            title2: 'Cancel',
            isLoading: loading,
            onClickCancel: () => history.goBack(),
            onClickSubmit: () => onClickUpdate(),
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default EditAboutUs;
