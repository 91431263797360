import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import styles from '../styles/mobileBannerStyles';

import {
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  MiniHeader,
} from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import { useHistory } from 'react-router-dom';

const CreateBannerStatus = () => {
  let initialValue = {
    name: '',
  };
  const toast = useToast();
  let history = useHistory();
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);
  let apimanager = ApiManager.getInstance();
  const [success, setSuccess] = React.useState('');

  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
    // console.log('value=>>>>>', value);
  };

  const onClickSubmit = () => {
    console.log('Api Run');
    const body = {
      list: value?.name,
      modelName: 'banner_status',
    };
    console.log('body>>>>', body);
    apimanager
      .post('addCategory', body)
      .then(response => {
        console.log(response, 'response');
        if (response.message === 8186) {
          setSuccess('Successfully Recorded!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Recorded, Please processed to List!`,
          });
          setTimeout(() => {
            history.goBack();
          }, [3000]);
        }
        if (response.errors !== undefined || response.errors !== null) {
          if (response.message === 5001) {
            setLoading(loading);
            console.log('ERROR to 5001');
            console.log('response.messag', response.message);
            console.log('response.messag', response.errors[1]?.msg);
            toast({
              title: error,
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: `Failed,${response.errors[1]?.msg} !`,
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...styles.miniheader} marginBottom={'2%'}>
      {MiniHeader({
        title: 'Create Banner Status',
        path: 'Dashboard / Application Setup /Create State',
      })}
      </Flex>

      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Name',
            mandatory: true,
            onChange: e => handleonChange(e, 'name'),
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: 'Submit',
          title2: 'Cancel',
          onClickCancel: () => history.goBack(),
          onClickSubmit: () => onClickSubmit(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateBannerStatus;
