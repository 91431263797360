import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Spacer,
  Divider,
  Skeleton,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import theme from '../config/color';
import ApiManager from '../config/apiManager';
import styles from '../styles/mobileBannerStyles';
import { uploadImage } from '../config/imageUploader';
import { useHistory, useLocation } from 'react-router-dom';
import DatewithTimePicker from '../components/DateComponent/datewithTImePicker';
import {
  customSwtich,
  checkMandatory,
  customInputLabel,
  customUpdateCancelBtn,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import { dropdownCustom } from '../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../components/common/customComponent';
import style from '../components/common/style';
import Multiselect from 'multiselect-react-dropdown';
import { customSaveButton } from '../components/inboxMessagingComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';

const CreateSpecialDeal = () => {
  let dataOfEdit = '';
  let tempVoucher = [];
  let tempName = [];
  let tempMId = [];
  let iddd = [];

  let initialState = {
    name: '',
    // startD: '',
    // endD: '',
    data: '',
    sms: '',
    price: '',
    idd:'',
    voice: '',
    status: '',
    vouchers: '',
    targetUser: '',
    description: '',
    voucherQty: '',
    vMerchantId: '',
    additionalDeal: false,
    voucherPromoCode: '',
    maxPurchasePerUser: '',
  };

  let mandatoryFields = {
    name: true,
    description: true,
    // startD: true,
    // endD: true,
    price: true,
    maxPurchasePerUser: true,
    data: true,
    voice: true,
    sms: true,
    // vMerchantId: true,
    targetUser: true,
    status: true,
  };

  let halaDropArray = ['Yes', 'No'];
  let statusDrop = ['Active', 'Inactive'];
  let targetUser = ['All User', 'New User'];

  const toast = useToast();
  let history = useHistory();
  let location = useLocation();
  let apimanager = ApiManager.getInstance();

  const [v, setV] = useState([]);
  const [vId, setVId] = useState([]);
  const [option, setOption] = useState();
  const [error, setError] = useState(null);
  const [image, setImage] = useState('');
  const [s3Image, setS3Image] = useState('');
  const [editData, setEditData] = useState([]);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialState);
  const [endD, setEndD] = useState(new Date());
  const [startD, setStartD] = useState(new Date());
  const [concantinate, setConcatinate] = useState([]);
  const [changeApi, setChangeApi] = useState(false);
  const [voucherEndD, setVoucherEndD] = useState('');
  const [voucherName, setVoucherName] = useState([]);
  const [voucherLoading, setVoucherLoading] = useState(false);

  useEffect(async () => {
    if (location?.state?.useEditApi) {
      getVouchers(location?.state?.data?.additionalDeal);
    } else {
      getVouchers();
    }

    if (location?.state?.data != null || location?.state?.data != undefined) {
      dataOfEdit = location?.state?.data;
      console.log('in changeApi the check');
      let aray = [];

      console.log('dataOfEdit', dataOfEdit);
      let changeApi = location?.state?.useEditApi;
      setChangeApi(changeApi);

      let tempStatus = changeStatusEdit(dataOfEdit?.status);

      let tempHalal = changeHalalEdit(dataOfEdit?.targetUser);
      console.log('targetUser', targetUser);
      console.log('tempStatus', tempStatus);
      console.log(
        'dataOfEdit.details?.voucherMerchantId',
        dataOfEdit.details?.voucherMerchantId,
        dataOfEdit?.details?.voucherPromoCode,
        dataOfEdit?.details.voucherQty
      );

      setValue({
        ...value,
        idd:dataOfEdit?._id,
        status: tempStatus,
        targetUser: tempHalal,
        price: dataOfEdit?.price,
        name: dataOfEdit?.name,
        sms: dataOfEdit?.deal.sms,
        data: dataOfEdit?.deal.data,
        voice: dataOfEdit?.deal.voice,
        description: dataOfEdit?.description,
        additionalDeal: dataOfEdit.additionalDeal,
        voucherQty: dataOfEdit?.details.voucherQty,
        maxPurchasePerUser: dataOfEdit?.maxPerUser,
        vMerchantId: dataOfEdit.details?.voucherMerchantId,
        voucherPromoCode: dataOfEdit?.details?.voucherPromoCode,
      });

      setVoucherEndD(dataOfEdit?.details?.voucherExpiryDate);

      // setImage(dataOfEdit?.image);
      // setS3Image(dataOfEdit?.companyLogo);

      var startDate = new Date(dataOfEdit?.startDate);
      var endDate = new Date(dataOfEdit?.endDate);
      setStartD(startDate);
      setEndD(endDate);

      !changeApi
        ? setOption(['Black App', 'Selfcare', 'Online-Store'])
        : setOption(aray);

      if (!changeApi) {
        console.log('in changeApi first');
        await getVouchers();
      } else {
        console.log('in changeApi second');

        await getVouchers(dataOfEdit.details?.voucherMerchantId);

        console.log(
          editData?.additionalDeal,
          'in changeApi second additionalDeal',
          dataOfEdit?.details?.voucherMerchantId
        );
      }
    }
    setEditData(dataOfEdit);
    // console.log('editDataeditData=>', dataOfEdit.details?.voucherMerchantId);
  }, []);

  const handleonSwitch = (evt, key) => {
    const ev = evt;
    console.log('Switch', ev, 'key', key);
    // setUrlORdeeplink(ev)
    setValue({
      ...value,
      [key]: ev,
    });
  };

  // console.log('Edit Data in value==> ', value, s3Image, image);

  const getVouchers = async vouch => {
    setVoucherLoading(true);
    console.log('vouch=>', vouch);
    await apimanager
      .get('rewards/merchantList', {})
      .then(res => {
        setVoucherLoading(false);

        console.log('getAllRewards=>RES', res);
        if (res.status === true) {
          setVoucherLoading(false);

          res.data.map((value, index) => {
            tempVoucher.push(value);
            iddd.push(value?.idmerchant);
            tempName.push(value?.merchantname);
            tempMId.push([value?.idmerchant, value?.merchantname]);
          });

          setVId(iddd);
          setVoucherName(tempName);
          setV(tempMId);
          setLoading(false);

          let data = getProjectId(res.data, vouch);
          console.log('Returned data=>', data);
          let dat = [];
          data.map(da => {
            dat.push([da.idmerchant, da.merchantname]);
          });
          console.log('Dattt==>', dat);

          setConcatinate(dat);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getProjectId = (data, value) => {
    console.log(data, 'getvalues', value);
    let selectData = [];
    data.map((dataVouch, index) => {
      if (value.toString().includes(dataVouch.idmerchant)) {
        console.log('yesIncludds', dataVouch);
        selectData.push(dataVouch);
      }
    });
    console.log('selectData', selectData);
    return selectData;
  };

  // Utility Functions //
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };

  const onSelectedFuntion = e => {
    console.log('onSelect=>', e);
    let tempId = [];
    e.map((value, index) => {
      console.log(index, 'value', value[0]);

      tempId.push(value[0]);
    });
    console.log('tempId', tempId);
    setValue({
      ...value,
      vouchers: e,
      vMerchantId: tempId,
    });
  };

  const handleonChangeImage = (evt, key) => {
    console.log(evt);
    const ev = evt;
    console.log('Image=>', ev);
    // upload(ev);
    // setValue({
    //   ...value,
    //   [key]: ev,
    // });
  };

  const upload = e => {
    console.log('e.target=>', e.target.files);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('image uri', res.location);
        setS3Image(res.location);
        setValue({
          ...value,
          componyLogo: res.location,
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const uploadI = e => {
    // setShow(true);
    console.log('e.target=>', e.target.files);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('image uri', res.location);
        setImage(res.location);
        setValue({
          ...value,
          image: res.location,
        });
        // setShow(false);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const changeStatus = value => {
    console.log('value=>', value);
    if (value === 'Inactive') {
      return 0;
    } else if (value === 'Active') {
      return 1;
    }
  };

  const changeStatusEdit = value => {
    console.log('value=>', value);
    if (value === true) {
      return 'Active';
    } else if (value === false) {
      return 'Inactive';
    }
  };

  const changeHalal = value => {
    console.log('value=>', value);
    if (value === 'All User') {
      return 0;
    } else if (value === 'New User') {
      return 1;
    } else if (value === 'Specific User') {
      return 2;
    }
  };

  const changeHalalEdit = value => {
    console.log('value=>', value);
    if (value === 0) {
      return 'All User';
    } else if (value === 1) {
      return 'New User';
    } else if (value === 2) {
      return 'Specific User';
    }
  };

  const convertedDateAndTime = _callback => {
    let date =
      startD.getFullYear() +
      '-' +
      (startD.getMonth() + 1) +
      '-' +
      startD.getDate();

    let time =
      startD.getHours() + ':' + startD.getMinutes() + ':' + startD.getSeconds();

    var StartdateTime = date + ' ' + time;
    console.log('StartdateTime=>', StartdateTime);

    let endDate =
      endD.getFullYear() + '-' + (endD.getMonth() + 1) + '-' + endD.getDate();

    let endTime =
      endD.getHours() + ':' + endD.getMinutes() + ':' + endD.getSeconds();

    var endDateTime = endDate + ' ' + endTime;
    console.log('endDateTime=>', endDateTime);

    _callback([StartdateTime, endDateTime]);
  };

  // Api Implementation //
  const onClickSubmit = () => {
    setLoading(true);
    console.log('onClickSubmit', value);
    console.log('Api Run message', value['vMerchantId']);

    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });
    if (ifError) {
      setLoading(loading);
      setError('Please enter mandatory fields');
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    }
    // else if (value.platform === [] || value.platform.length === 0) {
    //   setLoading(loading);
    //   console.log('value.platform', value.platform);
    //   setError('Please enter mandatory fields "Platform"');
    //   toast({
    //     title: error,
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //     description: 'Failed to Submit.',
    //   });
    // }
    else {
      let startDate = '';
      let endDate = '';
      convertedDateAndTime(callback => {
        startDate = callback[0];
        endDate = callback[1];
      });
      console.log('startDate =', startDate, 'endDate = ', endDate);
      let newStartDate = new Date(startDate);
      let startTimestamp = newStartDate.getTime();

      console.log('timestamp', startTimestamp);

      let newEndDate = new Date(endDate);
      let endTimestamp = newEndDate.getTime();

      let newVoucherEndDate = new Date(voucherEndD);
      let endVoucherTimestamp = newVoucherEndDate.getTime();

      console.log('End timestamp', endTimestamp);

      let tempStatus = changeStatus(value['status']);
      console.log('tempStatus=>', tempStatus);

      let tempHalal = changeHalal(value['targetUser']);
      console.log('tempHalal=>', tempHalal);

      // console.log(
      //   'valuevMerchantId[0].toString()',
      //   value['vMerchantId'][0].toString()
      // );

      const body = {
        sms: parseInt(value['sms']),
        data: parseInt(value['data']),
        status: tempStatus,
        price: parseInt(value['price']),
        voice: parseInt(value['voice']),
        name: value['name'],
        targetUser: tempHalal,

        endDate: endTimestamp,
        startDate: startTimestamp,
        description: value['description'],
        additionalDeal: value['additionalDeal'],
        maxPerUser: parseInt(value['maxPurchasePerUser']),

        voucherMerchantId: value['vMerchantId'][0]?.toString(),
        voucherPromoCode: value['voucherPromoCode'],
        voucherQty: value['voucherQty'],
        voucherExpiryDate: endVoucherTimestamp,
      };
      console.log('body>>>>', body);

      if (!changeApi) {
        apimanager
          .post('addOneTimeDeal', body)
          .then(response => {
            console.log(response, 'response');
            console.log('response.message', response.message);
            if (response.message === 8301) {
              setLoading(loading);
              setSuccess('Successfully Added Deal');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Added Deal!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }
            if (response.message === 8300) {
              console.log('response 8300', response.message);
              setLoading(loading);

              setError('Name cannot be same');
              console.log('response.messag', response.message);

              toast({
                title: error,
                status: 'error',
                duration: 3000,
                isClosable: true,
                description: `Failed, Duplication Error on ${response.err.keyValue.name}`,
              });
            }
            if (response.errors !== undefined || response.errors !== null) {
              if (response.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[0]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      } else {
        console.log('IDDD=>', dataOfEdit);
        console.log('editData=>', editData);
        console.log('value?.idd=>', value?.idd);
        let payload = {
          id: value?.idd,

          sms: parseInt(value['sms']),
          data: parseInt(value['data']),
          status: tempStatus,
          price: parseInt(value['price']),
          voice: parseInt(value['voice']),
          name: value['name'],
          targetUser: tempHalal,

          endDate: endTimestamp,
          startDate: startTimestamp,
          description: value['description'],
          additionalDeal: value['additionalDeal'],
          maxPerUser: parseInt(value['maxPurchasePerUser']),

          voucherMerchantId: value['vMerchantId'][0]?.toString(),
          voucherPromoCode: value['voucherPromoCode'],
          voucherQty: value['voucherQty'],
          voucherExpiryDate: endVoucherTimestamp,
        };
        console.log('update payload', payload);
        apimanager
          .post('updateOneTimeDeal', payload)
          .then(response => {
            console.log(response, 'response');
            console.log('response.message', response.message);
            if (response.message === 8304) {
              setLoading(loading);
              setSuccess('Successfully Updated Reward');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Successfully Updated Reward!`,
              });
              setTimeout(() => {
                history.goBack();
              }, 3000);
            }

            if (response.errors !== undefined || response.errors !== null) {
              if (response.message === 5001) {
                setLoading(loading);

                setError('Please enter mandatory fields');
                console.log('response.messag', response.message);
                console.log('response.messag', response.errors[1]?.msg);

                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${response.errors[1]?.msg} Please fill all fields!`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log(error);
          });
      }
    }
  };

  const convertingDate = date => {
    let newDate = new Date(date);
    return newDate.toLocaleString();
  };

  return (
    <Flex flexDirection={'column'} backgroundColor={'#FAFDFB'} height={'80vh'}>
      <Flex {...style.miniheader} marginBottom={'2%'}>
        {MiniHeader({
          title: !changeApi ? 'Create Special Deal' : 'Update Special Deal',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'One Time Deals Management',
          useSlash: true,
          path2: !changeApi ? 'Create Special Deal' : 'Update Special Deal',
        })}
      </Flex>
      <Flex {...styles.parentFlex}>
        <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
          {customInputLabel({
            title: 'Name',
            mandatory: true,
            value: value['name'],
            onChange: e => handleonChange(e, 'name'),
          })}
        </SimpleGrid>

        {customTextarea({
          title: 'Description',
          mandatory: true,
          value: value['description'],
          onChange: e => handleonChange(e, 'description'),
        })}

        <SimpleGrid
          columns={2}
          marginTop={2}
          width={'100%'}
          spacingX={'50px'}
          spacingY={'20px'}
        >
          {DatewithTimePicker({
            title: 'Start Date',
            mandatory: false,
            selectedDate: startD,
            // changePicker: true,

            onChange: e => setStartD(e),
          })}
          {DatewithTimePicker({
            title: 'End Date',
            mandatory: false,
            // changePicker: true,

            selectedDate: endD,
            onChange: e => setEndD(e),
          })}
          {customInputLabel({
            title: 'Price',
            mandatory: true,
            type: 'number',
            value: value['price'],
            onChange: e => handleonChange(e, 'price'),
          })}
          {customInputLabel({
            title: 'Max Purchase per User',
            type: 'number',
            mandatory: true,
            value: value['maxPurchasePerUser'],
            onChange: e => handleonChange(e, 'maxPurchasePerUser'),
          })}
        </SimpleGrid>

        <Flex alignSelf={'flex-start'} marginTop={5}>
          <Text
            fontSize={'16px'}
            color={theme.customColors.blackAdminColor[200]}
            fontWeight="bold"
          >
            Deal
          </Text>
        </Flex>

        <Divider marginY={6} />

        <SimpleGrid width={'100%'} columns={3} spacingX={'50px'} marginTop={4}>
          {customInputLabel({
            title: 'Data',
            type: 'number',
            mandatory: true,
            value: value['data'],
            onChange: e => handleonChange(e, 'data'),
          })}
          {customInputLabel({
            title: 'Voice',
            mandatory: true,
            type: 'number',
            value: value['voice'],
            onChange: e => handleonChange(e, 'voice'),
          })}
          {customInputLabel({
            title: 'Sms',
            type: 'number',
            mandatory: true,
            value: value['sms'],
            onChange: e => handleonChange(e, 'sms'),
          })}
        </SimpleGrid>

        <Flex alignSelf={'flex-start'} marginTop={5}>
          <Text
            fontSize={'16px'}
            color={theme.customColors.blackAdminColor[200]}
            fontWeight="bold"
          >
            Additional Deal (Voucher)
          </Text>
          <Spacer />
        </Flex>

        <Divider marginY={6} />
        <Flex marginTop={5} marginBottom={5} alignSelf={'flex-start'}>
          {customSwtich({
            title: 'Enable Additional Deal',
            label: 'Additional Deal',
            checked: value?.additionalDeal,
            check: e => handleonSwitch(e.target.checked, 'additionalDeal'),
          })}
        </Flex>
        <SimpleGrid width={'100%'} columns={4} spacingX={'50px'} marginTop={4}>
          <Flex marginBottom={'4px'} marginTop={'4px'} flexDirection={'column'}>
            <Flex>
              <Text {...styles.redAsterisk}>Vouchers</Text>
              {/* <Text color={'#ff0000'}>*</Text> */}
            </Flex>
            {!voucherLoading ? (
              <Multiselect
                id={vId}
                options={v}
                isObject={false}
                singleSelect={true}
                hidePlaceholder={true}
                style={{ ...styles.multiSelect }}
                selectedValues={concantinate}
                onSelect={e => onSelectedFuntion(e)}
              />
            ) : (
              <Skeleton height="20px" />
            )}
          </Flex>

          {value?.additionalDeal ? (
            <>
              {customInputLabel({
                title: 'Voucher Promo Code',
                mandatory: true,
                type: 'number',
                value: value['voucherPromoCode'],
                onChange: e => handleonChange(e, 'voucherPromoCode'),
              })}

              {customInputLabel({
                title: 'Voucher Qty',
                mandatory: true,
                value: value['voucherQty'],
                type: 'number',
                onChange: e => handleonChange(e, 'voucherQty'),
              })}

              {customDateWithTitle({
                title: 'Voucher Expiry Date',
                mandatory: true,
                selectedDate: endD,
                onChange: e => setVoucherEndD(e),
              })}
            </>
          ) : null}
        </SimpleGrid>

        <SimpleGrid width={'100%'} columns={2} spacingX={'50px'} marginTop={4}>
          {dropdownCustom({
            title: 'Target User',
            mandatory: true,
            placeholder: value['targetUser']
              ? value['targetUser']
              : 'Select Option',
            temparray: targetUser,
            onChange: e => {
              handleonChange(e, 'targetUser');
            },
          })}
          {dropdownCustom({
            title: 'Status',
            mandatory: true,
            temparray: statusDrop,
            placeholder: value['status'] ? value['status'] : 'Select Option',
            onChange: e => {
              handleonChange(e, 'status');
            },
          })}
        </SimpleGrid>

        {customUpdateCancelBtn({
          title1: !changeApi ? 'Submit' : 'Update',
          title2: 'Cancel',
          isLoading: loading,
          onClickSubmit: () => onClickSubmit(),
          onClickCancel: () => history.goBack(),
        })}
      </Flex>
      <Spacer />
      <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
      <Flex position={'sticky'} marginLeft={4}>
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default CreateSpecialDeal;
